@import "shared/sc-promo-code-form";

body.checkout_landing {
  .user-entry-container {
      font-size: 1rem;
      line-height: 25px;
      margin: 0 auto;
      &.full {
          .guest-checkout-section {
              display: block;
          }
      }
  }
  .reset-password-link {
      margin-top: 15px;
  }
  .sc-container-default {
      .respond-to(@bpDesktopSmall, {
          max-width: 768px;
      });
  }
  .sc-login-component {
      border-bottom: @borderLightGray;
      margin-bottom: 3em;
      padding-bottom: 48px;
      .respond-to(@bpTablet, {
          border-bottom: none;
          margin-bottom: 4em;
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
      });
      .respond-to(@bpDesktopSmall, {
          width: 50%;
      });
      .login-component-container {
          width: 280px;
          margin: 0 auto;
          .respond-to(@bpMobileLarge, {
              width: 376px;
          });
          .respond-to(@bpDesktopSmall, {
              width: 344px;
          });
      }
      input, button {
          .default-browser-focus;
          max-width: 280px;
          .respond-to(@bpMobileLarge, {
              max-width: 376px;
          });
      }
      button {
          margin-top: 20px;
      }
      &:last-child {
          margin-bottom: 0;
          border: none;
          .respond-to(@bpTablet, {
              padding-bottom: 48px;
          });
      }
  }
  .checkout-login-section {
      h3 {
          margin-bottom: 28px;
      }
      .respond-to(@bpDesktopSmall, {
          padding-right: 2.5em;
          float: left;
      });
  }
  .checkout-create-section,
  .checkout-guest-section {
      .respond-to(@bpDesktopSmall, {
          clear: right;
          padding-left: 2.5em;
          float: right;
      });
      .form-expand {
          max-width: 280px;
          margin: 0 auto;
          .respond-to(@bpMobileLarge, {
              max-width: 376px;
          });
      }
      form .field-row .field-error,
      form .section-row .field-error,
      form .field-error input,
      form .form-error,
      form .field-error .error-message {
          float: none;
          display: inline-block;
      }
      form .field-error .error-message,
      form .form-error {
          display: block;
      }
      form .field-error {
          max-width: 280px !important;
          .respond-to(@bpMobileLarge, { max-width: 376px !important; });
          .respond-to(@bpDesktopSmall, { max-width: 344px !important; });
      }
  }

  .checkout-create-section {
      form {
          .frm-label {
              top: 1em;
          }

          .field-row {
              .intl-tel-input {
                  &+ .frm-label {
                      &.show {
                          left: 0;
                          top: -1.5em;
                      }
                  }

                  input {
                      margin-bottom: 10px;
                  }
              }

              input {
                  width: 280px;

                  .respond-to(@bpTablet, {
                      width: 376px;
                  });

                  .respond-to(@bpDesktopSmall, {
                      width: 344px;
                  });

                  &:focus {
                      &+ .frm-label {
                          left: 0;
                          top: -1.5em;
                      }
                  }

                  &+ .frm-label {
                      &.show {
                          left: 0;
                          top: -1.5em;
                      }
                  }
              }
          }
      }
  }

  .terms-agreement {
      .font-family(bold);
      line-height: 25px;
      margin-top: 30px;
      margin-bottom: 6px;
  }

  .recaptcha-message {
    padding: 1rem 0 2rem;
  }
}

.checkout-page-container {
  padding-top: 5.5rem; /* accomodate fixed headers */
  select,
  input[type=tel],
  input[type=password],
  input[type=email],
  input[type=text] {
      display: block;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
      .respond-to(@bpDesktopLarge, {
          margin-right: 0.75em;
          max-width: 344px;
      });
  }
  form .field-error {
      .error-message {
          display: none;
      }
  }
  .checkbox-field {
      display: block;
      margin-bottom: 2.1em;
      &.same-as-shipping-address {
          margin: 1em 0;
      }
  }
  .checkout-header {
      .box-sizing(border-box);
      font-size: 0.875rem;
      background: @gray-main;
      color: @gray-54;
      height: 2.875rem;
      position: fixed;
      padding: 1.14em 1.875rem;
      text-align: center;
      top: @headerHeight;
      width: 100%;
      z-index: 1;
      .respond-to(@bpDesktopSmall, {
          font-size: 1rem;
          padding-left: 0;
          padding-right: 0;
      });
  }
  .checkout-steps {
      list-style: none;
      .font-family(medium);
      margin: 0 auto;
      padding: 0;
      .flex-display();
      .justify-content(space-between);
      width: 100%;
      .respond-to(@bpTablet, {
          max-width: @widthMobile;
      });
      .respond-to(@bpDesktopSmall, {
          max-width: 46rem;
      });
      .respond-to(@bpDesktopLarge, {
          max-width: @widthDesktopLarge;
      });
      li {
          display: inline-block;
          position: relative;
          &.active {
              color: black;
              &:after {
                  border: 0.14em solid #000;
                  bottom: -1em;
                  content: '';
                  left: 0;
                  position: absolute;
                  width: 100%;
              }
          }
      }
  }
  .checkout-main {
      margin: 0 auto;
      &:after {
          content: " ";
          display: block;
          clear: both;
      }
      .respond-to(@bpDesktopSmall, {
          max-width: 46rem;
          padding-bottom: @footerHeightDesktop * 2;
      });

      .respond-to(@bpDesktopLarge, {
          max-width: @widthDesktopLarge;
      });
    .full-order-summary {
      float: none;
      margin-bottom: 1rem;
      .respond-to(@bpDesktopSmall, {
        float: right;
      });
      #applepay-container {
        .respond-to(@bpDesktopSmall, {
          justify-content: center;
        });
      }
    }
  }
  #applepay-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .respond-to(@bpDesktopSmall, {
      justify-content: left;
    });
  }
  .checkout-steps-container {
      .respond-to(@bpDesktopSmall, {
          float: left;
          margin-left: 0;
          margin-left: 0;
          max-width: 17.5rem;
      });
      .respond-to(@bpDesktopLarge, {
          max-width: 41.25rem;
      });
  }
  .checkout-container {
    .box-sizing(border-box);
    font-size: 1rem;
    line-height: 1.6em;
    width: 100%;
    label {
      display: block;
      font-size: 0.875em;
      text-transform: capitalize;
    }
    .checkbox-field {
        label {
          text-transform: inherit;
        }
    }
    .editable-title {
      display: inline-block;
      margin-right: 1em;
    }
    .edit-link {
      color: @gray-medium;
      font-size: 0.75em;
      line-height: 0.875em;
      border-left: @borderLightGray;
      margin-left: 1em;
      padding-left: 1em;
      text-transform: lowercase;
    }
    .editable-title + .edit-link {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }

    #frm-shipping_method,
    .cta-next .submit-button {
      .default-browser-focus;
    }

    #main-checkout-form {
      .input-form {
        margin-top: 24px;
        position: relative;

        .field-error {
          input {
            float: none;
          }

          & + .frm-label:not(.not-floated) {
            left: 0;
            top: -19px;
          }
        }

        .frm-label {
          color: @gray-59;
          font-size: 14px;
          left: 0;
          position: absolute;
          top: -19px;

          &:not(.not-floated) {
            .transition(all, 0.1s, linear);
            .respond-to(@bpDesktopSmall, { top: 1em; });
            left: 1.2em;
            top: 16px;
          }

          &.floated {
            left: 0;
            top: -19px;
          }
        }

        input,
        select {
          .default-browser-focus;
        }

        input {
          &:focus {
            & + .frm-label {
                left: 0;
                top: -19px;
            }
          }
        }
      }
    }

    .exchanges-note {
      align-items: flex-start;
      display: flex;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1.2em;
      margin-left: -30px;
      text-align: justify;
      width: 100%;

      .control {
        background: #ffffff;
        cursor: pointer;
        display: block;
        font-family: arial;
        font-size: 12px;
        margin-bottom: 5px;
        padding-left: 30px;
        padding-top: 0px;
        position: relative;
      }

      .control input {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      .control_indicator {
        border: 2px solid #000000;
        height: 15px;
        left: 0;
        position: absolute;
        width: 15px;
      }

      .control-radio .control_indicator {
        border-radius: 2%;
      }

      .control_indicator:after {
        box-sizing: unset;
        content: '';
        display: none;
        position: absolute;
      }

      .control input:checked ~ .control_indicator:after {
        display: block;
      }

      .control-checkbox .control_indicator:after {
        border: solid #000000;
        border-width: 0 3px 3px 0;
        height: 11px;
        left: 5px;
        top: 1px;
        transform: rotate(45deg);
        width: 3px;
      }

      .exchanges-paragraph {
        margin-bottom: 25px;
      }

      .exchanges-block {
        margin-top: 3px;
      }
    }
  }

  .cta-next {
      display: inline-block;
      margin: 2em 0;
      width: 100%;
      .form-button {
          width: 280px;
          .respond-to(@bpMobileLarge, {
              width: 376px;
          });
          .respond-to(@bpDesktopSmall, {
              margin-left: 0;
              margin-right: 0;
              width: 280px;
          });
          .respond-to(@bpDesktopLarge, {
              width: 344px;
              max-width: none;
          });
      }
      .respond-to(@bpDesktopLarge, {
          max-width: 50%;
      });
  }

  .grecaptcha-badge {
    visibility: hidden !important;
  }

  .recaptcha-message {
    display: block;
    padding-bottom: 10px;
    p {
      font-size: 11px;
      line-height: 18px;
    }

    p > a {
      color: @blue-main;
    }

  }

  .cancel-button {
      display: block;
      margin: 0.5em auto 2em;
      text-align: center;
      .respond-to(@bpDesktopLarge, {
          display: inline-block;
          margin: 0 0 0 112px;
          text-align: left;
      });
  }
  .checkout-summary-container,
  .order-summary {
      .respond-to(@bpDesktopSmall, {
          clear: right;
          float: right;
          max-width: 20rem;
      });
      .sc-container-default {
          .respond-to(@bpTablet, {
              max-width: 30rem;
          });
      }
      .item-remove {
          right: -1rem;
      }
  }
  .checkout-summary-container {
      .checkout-summary {
          .respond-to(@bpTablet, {
              padding-left: 6.125rem;
          });
          .respond-to(@bpDesktopSmall, {
              padding-left: 0;
          });
      }
      .sc-list-summary {
          .respond-to(@bpDesktopLarge, {
              font-size: 0.875rem;
          });

          .label {
              .respond-to(@bpDesktopLarge, {
                  font-size: 14px;
              });
          }
      }

      &.confirmation-order-summary {
        .summary-promotion {
          .value {
            margin-right: 0;
          }
        }

        .remove-promo-code {
          display: none;
        }
      }
  }
  .order-summary {
      .sc-container-default {
          .respond-to(@bpMobileLarge, {
              max-width: 376px;
          });
      }
      .list-item {
          .respond-to(@bpMobileLarge, {
              padding-left: 0;
              padding-right: 0;
          });
          .respond-to(@bpDesktopSmall, {
              padding-left: 2em;
              padding-right: 2em;
          });

          .item-quantity-price {
              .respond-to(@bpDesktopLarge, {
                  padding-left: 0;
              });
          }
      }
      .order-summary-header {
          padding: 2.625em 2em 0;
          .respond-to(@bpMobileLarge, {
              padding-left: 0;
              padding-right: 0;
          });
          .respond-to(@bpDesktopSmall, {
              padding: 2.625em 2em 0;
          });
          h3 {
              display: inline-block;
              font-size: 1.125em;
              margin-right: 1em;
          }
          .change-link {
              color: @gray-54;
              font-size: 0.75em;
          }
      }
  }
  .checkout-summary-container {
      background: @gray-main;
      padding: 1.5em @gutterMobile 2em @gutterMobile;
      .sc-list-summary {
          border-bottom: none;
      }
      .value {
          float: right;
      }
      .sc-container-default {
          .respond-to(@bpMobileLarge, {
              padding: 1.5em 0 2em 0;
              max-width: 376px;
              margin: 0 auto;
          });
      }

    .summary-promotion {
      .value {
        margin-right: -20px;
      }
    }
  }
  .footer-cta-next {
      padding-left: @gutterMobile;
      padding-right: @gutterMobile;
      display: none;
      .respond-to(@bpMobileLarge, {
          margin: 0 auto;
          padding-left: 0;
          padding-right: 0;
      });
  }

  /* checkout steps */
  .cta-login {
      font-size: 1.125rem;
      margin-bottom: 2em;
      padding-right: @gutterMobile;
      a {
          color: black;
          .font-family(medium);
      }
  }
  .billing-section,
  .shipping-section {
      margin-left: auto;
      margin-right: auto;
      max-width: 17.5rem;
      .respond-to(@bpMobileLarge, {
          max-width: 376px;
      });
      .respond-to(@bpDesktopSmall, {
          margin-left: 0;
      });
      .respond-to(@bpDesktopLarge, {
          max-width: 100%;
      });
  }
  .cc_cvc_request_enable {
    width: 125px;
  }
  .checkout-form {
      .fld {
          display: inline-block;
          width: 100%;
          &.email {
              margin-bottom: 1.875em;
          }
          &:nth-child(2n) {
              input[type=tel],
              input[type=password],
              input[type=text],
              input[type=email],
              select {
                  .respond-to(@bpDesktopLarge, {
                      margin-right: 0;
                  });
              }
          }
      }
      .credit-card-static,
      .card-fieldset,
      .address-fieldset {
          margin-bottom: 2em;
      }
      .address-fieldset {
          .state-options {
              margin-right: 0.75em;
          }
      }
      .card-fieldset {
          display: block;
          .cc-number {
              .respond-to(@bpDesktopLarge, {
                  margin-right: 100%;
              });
          }
          .cc-month {
              margin-right: 0.75em;
              .respond-to(@bpDesktopLarge, {
                  max-width: 344px;
              });
          }
          .cc-year {
              .respond-to(@bpDesktopLarge, {
                  max-width: 344x;
              });
          }
          .cc-ccv {
              max-width: 246px;
              display: inline-block;
              .respond-to(@bpMobileLarge, {
                  max-width: 342px;
              });
              .respond-to(@bpDesktopSmall, {
                  max-width: 246px;
              });
              .respond-to(@bpDesktopLarge, {
                  max-width: 310px;
                  margin-left: 0;
                  margin-right: 0;
              });
          }
          .ccv-info {
              cursor: pointer;
              display: inline-block;
              margin-left: 5px;
              position: relative;
              .respond-to(@bpDesktopSmall, {
                  &:hover {
                      .tooltip {
                          display: block;
                      }
                  }
              });
              img {
                  position: relative;
                  z-index: 10;
              }
              .icon {
                  background: @gray-medium;
                  border-radius: 50%;
                  color: white;
                  display: inline-block;
                  height: 1.5em;
                  line-height: 1.8em;
                  text-align: center;
                  width: 1.5em;
              }
              .tooltip {
                  background: white;
                  border: 1px solid @gray-medium-dark;
                  display: none;
                  padding: 0.5em;
                  position: absolute;
                  top: calc(~"100% + 0.5em");
                  left: -8.5em;
                  .respond-to(@bpMobileLarge, {
                      left: auto;
                      right: -0.4em;
                  });
                  &:before,
                  &:after {
                      background: white;
                      border: 1px solid @gray-medium-dark;
                      content: " ";
                      height: 1em;
                      position: absolute;
                      top: 0;
                      right: .95em;
                      .transform(rotate(45deg) translate(0, -50%));
                      width: 1em;
                  }
                  &:after {
                      border: none;
                      .transform(none);
                      right: 0;
                      width: 2em;
                  }
              }
          }
      }

      .cards-div {
          .adyen_cc_label {
            display: inline-block;
          }

          .input-field {
            background-color: @gray-main;
            border-radius: 0.245rem;
            border: @borderLightGray;
            display: block;
            height: 3.125rem;
            margin-bottom: 0.5rem;

            &.adyen-input-error {
               border: 1px solid @red-error;
            }
            &.adyen-input-focus {
              border-color: rgba(59, 153, 252, 0);
              outline: 0;
              outline: thin dotted \9;
              outline: -webkit-focus-ring-color auto 0.3125rem;
            }

            .respond-to(@bpDesktopLarge, {
                max-width: 21.5rem;
            });
          }
      }
  }
  .billing-section {
      .change-billing-address-link,
      .billing-address-fields,
      .billing-address {
          &.hide {
              display: none;
          }
          &.show {
              display: block;
          }
      }
      .credit-card-info,
      .billing-address {
          display: block;
          .font-family(medium);
          font-size: 0.875em;
          span {
              display: block;
          }
      }
      .billing-address-fields {
          display: none;
      }
      ul.form-error {
          display: none;
      }
  }
  .shipping-method {
      .box-sizing(border-box);
      clear: both;
      display: inline-block;
      margin: 1em 0;
      width: 100%;
      select {
          margin: 0;
      }
  }
  .shipping-note {
      padding-top: 20px;
      display: inline-block;
      .font-family(bold);
      font-size: 14px;
      line-height: 19px;
      width: 100%;
      max-width: 376px;
      margin: 0;

      p {
          margin-bottom: 0;
          margin-top: 0;
      }
  }
  .sc-giftcard-form {
    .using-container {
      max-width: 23.5rem;
    }
      border-bottom: @borderLightGray;
      border-top: @borderLightGray;
      padding: 2em 0;
      max-width: 100%;
      width: 100%;
      input, .submit {
          .respond-to(@bpDesktopLarge, {
              max-width: 344px;
              display: block;
              margin-left: 0;
              margin-right: 0;
          });
      }
    .submit {
      cursor: not-allowed;
    }
    .field-row {
      display: inline-block;
      position: relative;
      width: 100%;

      .frm-label {
        .transition(all, 0.1s, linear);
        font-size: 0.875rem;
        left: 1.2em;
        position: absolute;
        top: 1.2em;
        z-index: 11;
      }

      input {
        margin-bottom: 1.25rem;

        &:focus {
          &+ .frm-label {
            left: 0;
            top: -1.3em;
          }
        }

        &+ .frm-label {
          &.floated {
            left: 0;
            top: -1.3em;
          }
        }
      }
    }
  }
  .sc-promo-code-form,
  .sc-giftcard-form{
    border-top: @borderLightGray;
    padding: 2em 0;
    width: 100%;

    .respond-to(@bpMobileLarge, {
      max-width: initial;
    });

    .expandable-title,
    .field-row {
      .respond-to(@bpDesktopSmall, {
        margin: 0;
      });
    }

    .expandable-title {
      span {
        &.arrow-down-grey {
          margin-left: 9.3em;

          .respond-to(@bpDesktopSmall, {
            margin-left: 5.3em;
          });
          .respond-to(@bpDesktopLarge, {
            margin-left: 9.3em;
          });
        }
      }
    }

    .expandable-widget {
      .respond-to(@bpDesktopSmall, {
        display: inline-block;
        max-width: 344px;
        width: 100%;
      });

      .expandable-content {
        .field-row {
          .frm-label {
            top: -19px;

            &:not(.not-floated) {
              top: 15px;
            }

            &.floated {
              left: 0;
              top: -19px;
            }
          }

          input {
            &:focus {
              & + .frm-label {
                left: 0;
                top: -19px;
              }
            }
          }
        }
      }
    }

    .applied-promo-message {
      .respond-to(@bpDesktopLarge, {
        display: inline-block;
        float: right;
        padding: 0 0 0 5.5em;
      });
    }

    .submit {
      .respond-to(@bpDesktopLarge, {
        max-width: 344px;
        display: block;
        margin-right: 0;
      });

      .respond-to(@bpDesktopSmall, {
        margin-left: 0;
      });
    }
  }

  .verify-section {
      width: 100%;
      padding-left: @gutterMobile;
      padding-right: @gutterMobile;
      .respond-to(@bpMobileLarge, {
          padding-left: 0;
          padding-right: 0;
          max-width: 376px;
      });
      .respond-to(@bpDesktopLarge, {
          max-width: none;
      });
      .verify-content {
          margin: 0 auto;
          width: inherit;
      }
      .cta-next {
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;
          .respond-to(@bpDesktopSmall, {
              max-width: 17.5rem;
              margin-left: 0;
              margin-left: 0;
          });
          &.hidden {
              display: none;
          }
      }
  }
  .verify-order-column {
      display: inline-block;
      margin-bottom: 3em;
      vertical-align: top;
      width: 100%;
      .respond-to(@bpDesktopSmall, {
          width: 100%;
      });
      &:last-child {
          margin-bottom: 0;
          margin-right: 0;
      }
      header {
          display: block;
          margin-bottom: 0.75em;
          h3 {
              font-size: 0.75em;
          }
      }
      .verify-fieldset {
          margin-bottom: 1.5em;
          &:last-child {
              margin-bottom: 0;
          }
          .cc_cvc_request_enable {
            .ccv-info {
              position: absolute;
              .icon {
                left: 130px;
                position: absolute;
                top: -46px;
              }
              .tooltip {
                display: none;
                position: relative;
              }
              &:hover {
                .tooltip {
                  display: block;
                }
              }
            }
          }
      }
      .verify-info {
          .font-family(medium);
          font-size: 0.875em;
          span {
              display: block;
          }
          .cc-expires {
              margin-bottom: 1em;
          }
      }
  }
  .create-account-section {
      border-top: @borderLightGray;
      margin-bottom: 2.5em;
      margin-top: 2.5em;
      padding-top: 2.5em;
      width: 100%;
      h3 {
          .font-family(medium);
          font-size: 1.125em;
          margin-bottom: 1.77em;
      }
  }
  .confirmation-section-container {
      display: flex;
      flex-wrap: wrap;
      .checkout-steps-container {
          margin-bottom: 2.625rem;
          position: relative;
          .respond-to(@bpDesktopSmall, {
              float: none;
              max-width: 100%;
          });
          &:after {
              background: @gray-light;
              bottom: 0;
              content: " ";
              height: 1px;
              left: 50%;
              position: absolute;
              .translate(-50%, 0);
              width: 100vw;
          }
      }
      .full-order-summary {
          position: relative;
          max-width: 30rem;
          margin: 0 auto;
          order: 1;
          .respond-to(@bpDesktopSmall, {
              float: left;
              width: 57%;
              max-width: none;
              padding-left: 0;
              padding-right: 0;
              flex-basis: 50%;
              margin-bottom: 1rem;
          });
          &:after {
              background: @gray-light;
              bottom: 0;
              content: " ";
              height: 1px;
              left: 50%;
              position: absolute;
              .translate(-50%, 0);
              width: 100vw;
              .respond-to(@bpDesktopSmall, {
                  display: none;
              });
          }
      }
      .confirmation-order-summary,
      .confirmation-item-summary-list {
          background: white;
          .order-summary-header {
              padding-top: 0;
          }
          .respond-to(@bpDesktopSmall, {
              clear: left;
              float: none;
          });
      }
      .confirmation-billing-shipping {
              max-width: 424px;
              order: 2;
          .respond-to(@bpMobileLarge, {
              max-width: 376px;
          });
          .respond-to(@bpDesktopSmall, {
              float: right;
              max-width: 100%;
              padding-top: 0;
              width: 43%;
              text-align: center;
              flex-basis: 50%;
          });
          .verify-fieldset {
              max-width: 280px;
              .respond-to(@bpMobileLarge, {
                  max-width: none;
              });
              .respond-to(@bpDesktopSmall, {
                  padding-left: 0;
              });
          }
      }
      .order-summary {
          .respond-to(@bpDesktopSmall, {
              display: block;
          });
          .respond-to(@bpDesktopLarge, {
              float: none;
          });
      }
      .verify-content {
          display: inline-block;
          text-align: left;
          .respond-to(@bpDesktopSmall, {
              width: 155px;
          });
          .respond-to(@bpDesktopLarge, {
              width: 205px;
          });
      }
      .verify-order-column {
          width: 100%;
          .respond-to(@bpDesktopSmall, {
              margin-right: 0;
          });
          .respond-to(@bpDesktopLarge, {
              width: 100%;
          });
      }
      .checkout-container {
           order: 0;
          .respond-to(@bpDesktopSmall, {
              margin-left: auto;
              margin-right: auto;
              flex-basis: 100%;
          });
      }
  }
  .confirmation-section {
      .flex-display();
      .flex-direction(column);
      font-size: 1rem;
      .justify-content(center);
      .align-items(center);
      min-height: 20em;
      padding: 0 @gutterMobile;
      text-align: center;
      max-width: 100%;
      .confirmation-message {
          color: @gray-medium;
          font-size: 1.125em;
          line-height: 1.6em;
          .thanks {
              display: block;
              .respond-to(@bpDesktopSmall, {
                  display: inline;
              });
          }
      }
      .cta-message {
          color: @gray-darker;
          font-size: 1.75em;
          margin-bottom: 0.57em;
          margin-top: 0;
      }
  }
  .confirmation-billing-shipping {
      font-size: 1rem;
      line-height: 1.4em;
      margin: 0 auto;
      max-width: 30rem;
      padding-bottom: @footerHeightDesktop;
      padding-top: 2em;
      .respond-to(@bpDesktopSmall, {
          float: right;
          max-width: 100%;
          padding-top: 0;
          width: 48%;
      });
      .respond-to(@bpDesktopLarge, {
          width: 60%;
      });
  }
}
