.promo-code-offer-container {
    .flex-display(flex);
    .flex-flow(column);
    .justify-content(center);
    background-color: @black;
    color: @scWhiteC;

    .respond-to(@bpTablet, {
        .flex-flow(row);
        background: top left / cover no-repeat url(/assets/images/promo_codes/background_large.png);
        background-color: @black;
        height: calc(~"100vh" - (@footerHeightDesktop + @headerHeight));
    });

    .respond-to(@bpDesktopLarge, {
        background: top left / cover no-repeat url(/assets/images/promo_codes/background_xlarge.png);
    });

    .respond-to(@bpDesktopXL, {
        background: top left / cover no-repeat url(/assets/images/promo_codes/background_xxlarge.png);
    });

    .promo-code-offer-atf {
        .flex-display(flex);
        .justify-content(center);
        background: top left / cover no-repeat url(/assets/images/promo_codes/background_small.png);
        height: 430px;
        padding: 16px;
        position: relative;

        &:before {
            .gradient-custom(~"to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%");
            content: '';
            height: 200px;
            left: 0;
            position: absolute;
            bottom: 0;
            width: 100%;

            .respond-to(@bpTablet, {
                .gradient-custom(~"to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%");
                height: 100%;
                left: auto;
                right: 0;
                width: 200px;
            });
        }

        .respond-to(@bpMobileLarge, {
            background: top left / cover no-repeat url(/assets/images/promo_codes/background_mid.png);
            padding: 32px;
        });

        .respond-to(@bpTablet, {
            .flex-display(flex);
            .justify-content(flex-end);
            background: none;
            padding: 0;
            height: auto;
            width: 50%;
        });

        .respond-to(@bpDesktopLarge, {
            padding: 0;
        });

        .offer-discount {
            padding-bottom: 100px;
            padding-top: 100px;
            width: 343px;

            .respond-to(@bpMobileLarge, {
                padding-bottom: 0;
                padding-top: 80px;
                width: 536px;
            });

            .respond-to(@bpTablet, {
                .align-items(flex-end);
                .flex-display(flex);
                .justify-content(center);
                .flex-flow(column);
                margin-right: 0;
                padding-top: 0;
                width: 385px
            });

            .respond-to(@bpDesktopSmall, {
                width: 335px;
            });

            .respond-to(@bpDesktopLarge, {
                .align-items(flex-start);
                width: 470px;
            });

            h1 {
                .font-family(heavy);
                font-size: 48px;
                line-height: 40px;
                max-width: 225px;
                text-transform: uppercase;

                .respond-to(@bpMobileLarge, {
                    font-size: 72px;
                    line-height: 60px;
                    max-width: 350px;
                });

                .respond-to(@bpTablet, {
                    max-width: 310px;
                    font-size: 64px;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 80px;
                    max-width: 380px;
                    padding-bottom: 16px;
                });
            }

            h2 {
                .font-family(bold);
                color: @sc-yellow;
                font-size: 20px;
                line-height: 32px;

                .respond-to(@bpMobileLarge, {
                    font-size: 24px;
                    line-height: 34px;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 36px;
                });
            }
        }
    }

    .promo-code-offer-details {
        .flex-display(flex);
        .justify-content(center);
        margin-top: -97px;
        min-height: 430px;
        padding: 16px;
        position: relative;

        .respond-to(@bpMobileLarge, {
            padding: 32px;
            margin-top: -84px;
        });

        .respond-to(@bpTablet, {
            .flex-display(flex);
            .justify-content(flex-start);
            background: @black;
            margin-top: 0;
            min-width: 346px;
            padding: 0;
            width: 50%;
        });

        .offer-details-container {
            width: 343px;

            .respond-to(@bpMobileLarge, {
                width: 536px;
            });

            .respond-to(@bpTablet, {
                .align-items(flex-start);
                .flex-display(flex);
                .justify-content(center);
                .flex-flow(column);
                margin-left: 32px;
                margin-right: 24px;
                width: 385px;
            });

            .respond-to(@bpDesktopSmall, {
                margin-right: 56px;
            });

            .respond-to(@bpDesktopLarge, {
                width: 430px;
            });

            .top-info {
                padding-bottom: 32px;
                text-align: center;
                text-transform: uppercase;
                width: 100%;

                .respond-to(@bpTablet, {
                    padding-top: 64px;
                });

                h3 {
                    .font-family(heavy);
                    font-size: 16px;
                    letter-spacing: 2px;
                    line-height: 20px;
    
                    .respond-to(@bpMobileLarge, {
                        font-size: 14px;
                    });
                }
            }
    
            .offer-box {
                .flex-display(flex);
                .justify-content(center);
                border: 1px solid @scWhiteC;
                padding: 31px;
    
                .respond-to(@bpMobileLarge, {
                    background: none;
                    padding: 24px 106px;
                });
    
                .respond-to(@bpTablet, {
                    justify-content: flex-start;
                    padding: 32px;
                });

                .respond-to(@bpDesktopSmall, {
                    min-height: 264px;
                });
    
                .respond-to(@bpDesktopLarge, {
                    padding: 54px;
                    padding-bottom: 48px;
                });
    
                .offer {
                    h1 {
                        .font-family(heavy);
                        font-size: 28px;
                        line-height: 24px;
                        padding-bottom: 32px;
    
                        .respond-to(@bpMobileLarge, {
                            font-size: 36px;
                            line-height: 30px;
                            padding-top: 32px;
                        });
    
                        .respond-to(@bpTablet, {
                            font-size: 30px;
                            padding-top: 0;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            font-size: 36px;
                            max-width: 300px;
                        });
                    }
    
                    .price {
                        .font-family(roman);
                        font-size: 18px;
                        padding-bottom: 8px;
    
                        .respond-to(@bpMobileLarge, {
                            font-size: 24px;
                            padding-bottom: 16px;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            font-size: 28px;
                        });
    
                        .old-price {
                            padding-right: 4px;
                            text-decoration: line-through;
    
                            .respond-to(@bpMobileLarge, {
                                padding-right: 13px;
                            });
                        }
    
                        .new-price {
                            .font-family(medium);
                            color: @sc-yellow;
                            padding-bottom: 32px;
                        }
                    }
    
                    .expiration {
                        .font-family(roman);
                        font-size: 18px;
                        line-height: 12px;
                        padding-bottom: 32px;
    
                        .respond-to(@bpMobileLarge, {
                            font-size: 20px;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            font-size: 24px;
                        });
                    }
    
                    .checkout {
                        margin-bottom: 24px;
                        width: 160px;
    
                        .respond-to(@bpMobileLarge, {
                            width: 165px;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            margin-bottom: 40px;
                        });
                    }
    
                    .disclaimer {
                        .font-family(bold);
                        color: @white-70;
                        font-size: 13px;
                        margin: 0;
    
                        .respond-to(@bpMobileLarge, {
                            font-size: 16px;
                        });

                        .respond-to(@bpTablet, {
                            font-size: 13px;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            font-size: 16px;
                        });
                    }
                }
            }
    
            .bottom-info {
                padding-bottom: 72px;
                padding-top: 48px;
                width: 100%;
    
                .respond-to(@bpMobileLarge, {
                    padding-top: 24px;
                    padding-bottom: 16px;
                });

                .respond-to(@bpTablet, {
                    padding-bottom: 68px;
                });

                .respond-to(@bpDesktopLarge, {
                    padding-bottom: 32px;
                });
        
                .reject-offer {
                    margin: 0;
                    text-align: center;
    
                    a {
                        .font-family(roman);
                        border-bottom: 1px solid @scWhiteC;
                        color: @scWhiteC;
                        font-size: 16px;
                        line-height: 29px;
                        text-decoration: none;
    
                        .respond-to(@bpDesktopSmall, {
                            font-size: 18px;
                        });
    
                        .respond-to(@bpDesktopLarge, {
                            font-size: 24px;
                        });
                    }
                }
            }
        }
    }
}
