/* CORPORATE PACKAGES STYLES
-----------------------------------------------------------------------------*/

.corporate-packages-container {
  .center-container();
  overflow: hidden;
  font-size: 16px;
  .row {
    border-top: none;
  }
  >.row:first-child {
    border-bottom: none;
  }
  .row-content {
    width: 280px;
    .center-container();
  }
  .authentication-block.row {
    padding-bottom: 48px;
  }
  form {
    width: initial;
    display: block;
    .field-error {
      width: 280px;
    }
  }
  input,
  button {
    width: 280px !important;
  }
  .label {
    font-size: 16px;
    padding: 20px 0;
  }
  .corporate-packages-info {
    font-size: 16px;
    line-height: 25px;
  }
  .corporate-packages-title {
    .font-family(light);
    padding-bottom: 28px;
    font-size: 22px;
  }

  .form-corporate-register {
    .form-button {
      margin-top: 0;
    }
    padding-top: 20px;
    button.save:disabled {
      opacity: .3;
      cursor: default;
    }
    .corporate-group-wrong {
      p {
        color: @red-error;
      }
      display: none;
    }
    .corporate-group-info {
      display: none;
      p > span {
        font-weight: bold;
      }
    }
  }
  .unverified-description {
    text-align: center;
    div {
      margin: 15px 0;
    }
  }
  .unverified-email {
    margin: 20px 0;
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
  }
  .unverified-resend-btn {
    margin-top: 20px;
    width: 50%;
    display: inline-block;
  }
  .reset-password-link {
    margin-top: 15px;
    line-height: 25px;
  }
  .terms-agreement {
    .font-family(bold);
    line-height: 25px;
    margin-top: 30px;
    margin-bottom: 6px;

    a {
      text-decoration: underline;
    }
  }
}

/* MEDIA QUERIES - HEADER STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .corporate-packages-container {
    input,
    button {
      width: 376px !important;
    }
    .form-sign-in {
      width: 376px;
    }
    .row-content {
      width: 376px;
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .corporate-packages-container {
    .row {
      border-bottom: none;
    }
  }
}



// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
    .page_main_content {
      padding-bottom: @footerHeightDesktop;
    }
  .corporate-packages-container {
    .input-container {
      .center-container();
      width: 768px;
      .row {
        width: 50%;
        float: left;
      }
    }
    input,
    button {
      width: 344px !important;
    }
    .row-content {
      width: 344px;
      &.corporate-packages-title, &.corporate-packages-info {
        width: 768px;
        margin: auto;
      }
    }
    .form-sign-in {
      width: 344px;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .corporate-packages-container {
    input,
    button,
    .reset-password-link {
      margin-left: 0 !important;
    }
    form {
      .field-error {
        width: 340px;
      }
    }
    .row-content {
      width: initial;
      margin: initial;
    }
    .input-container,
    .top-page-info {
      .center-container();
      width: 768px;
    }
    .registration {
      margin-top: 30px;
    }
    .corporate-packages-register {
      .row-content {
        width: 280px;
        margin: 0 auto;
      }
    }
  }
}

.sc-login-component {
  form {
    #frm-dob_placeholder,
    #frm-location_placeholder {
      display: none;
    }

    .dob_input {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0 0 0;
    
      .global__input-select-dob_group {
        width: 31%;
    
        select {
          width: 100%;
        }
    
        label {
          left: 0.45rem;
          text-transform: initial;
          top: -1.4rem;
        }
      }
    }
    
    .location_input {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0 0 0;
    
      .global__input-select-location_group {
        width: 48.5%;
    
        select {
          width: 100%;
        }
    
        label {
          left: 0.45rem;
          text-transform: initial;
          top: -1.4rem;
        }
      }
    }
  }
}
