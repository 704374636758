/*
New To Soul/Experience/experience Page Styles
*/

.new-to-soul__section--experience {
  .align-items(center);
  .flex-display(flex);
}

.new-to-soul__section__experience__content--wrapper {
  .align-items(center);
  .flex-display(flex);
  .flex-wrap(wrap);
  .justify-content(center);
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    .justify-content(flex-start); // Use flex-start and not unset to make it IE compatible.
    height: 26.875rem;
    margin: 3rem 0;
  });
}

.new-to-soul__section__experience-background {
  .flex-display(flex);
  .justify-content(flex-end);
  background-color: @black;
  height: 100%;
  width: 100%;
}

.new-to-soul__section__experience-description {
  .align-self(flex-end);
  .flex-direction(column);
  .flex-display(flex);
  color: @scWhiteC;
  font-size: 0.9375rem;
  line-height: 1.5;
  padding: 0 1.5rem 3rem;
  margin-top: -8rem;
  max-width: 23.4375rem;
  z-index: 2;

  h3 {
    font-size: 2.5rem;
    max-width: 12.5rem;
  }

  p {
    .font-family(roman);
    max-width: inherit;
  }

  .respond-to(@bpMobileLarge, {
    max-width: 31.5rem;
    padding: 0 0 3rem;
  });

  .respond-to(@bpTablet, {
    margin-top: -10rem;
  });

  .respond-to(@bpDesktopSmall, {
    .align-self(center);
    font-size: 1.125rem;
    left: 0;
    margin: 0 0 0 18%;
    max-width: 23.5rem;
    padding: 0;
    position: absolute;

    h3 {
      font-size: 2.5rem;
      max-width: none;
    }

    &.is-old-browser {
      margin-top: -10rem;
    }
  });

  .respond-to(@bpDesktopLarge, {
    max-width: 30rem;
  });
}

.new-to-soul__section__experience-description-button-wrapper {
  .align-items(baseline);
  .flex-display(flex);
  .justify-content(flex-start);
  width: 9.375rem;
}

.new-to-soul__section__experience-description-right-arrow {
  font-size: 0.8rem;
  margin-left: 0.625rem;
  position: static;
  transform: rotate(270deg);
}

.new-to-soul__section__experience-image {
  background-position: center center;
  background-size: cover;
  height: 28rem;
  position: relative;
  width: 100%;

  .respond-to(@bpTablet, {
    height: 30rem;
  });

  .respond-to(@bpDesktopSmall, {
    height: auto;
    width: 64%;

    &:before {
      background: linear-gradient(to right, #000, rgba(0,0,0,0.65) 5%, rgba(0,0,0,0) 20%);
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  });

  .respond-to(@bpDesktopLarge, {
    width: 55%;
  });

  &:after {
    background: linear-gradient(to top, #000 10%, rgba(0,0,0,0.65) 25%, rgba(0,0,0,0) 45%);
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      background: linear-gradient(to top, #000, rgba(0,0,0,.65) 8%, rgba(0,0,0,0) 16%, rgba(0,0,0,0) 84%, rgba(0,0,0,.65) 92%, #000);
    });
  }
}
