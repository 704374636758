.landing__footer {
  background-color: @gray-87-bg;
  padding: 11.75% 0 15%;
  width: 100%;

  .respond-to(@bpTablet, {
    padding-top: 2.6rem; 
    padding-bottom: 2.5rem ;
  });
  
  @media only screen and (min-width : 1250px) {  
    padding-top: 2.6rem; padding-bottom: 3rem;
  };

  .respond-to(@bpDesktopLarge, {
    height: 7rem;
    padding-bottom: 0;
    padding-top: 1.5rem;
  });

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .content__heading {
    .font-family(bold);
    color: @white;
  }
  .content__socials {
    display: flex;
    min-width: 7rem;
    padding-top: 2rem;
    .justify-content(space-between);
  }
}
