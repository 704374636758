body.shopping_bag {
    .shopping-bag-container {
        .respond-to(@bpMobileLarge, {
            padding: 100px 75px 0;
        });

        .respond-to(@bpTablet, {
            max-width: none;
            padding: 100px 95px 0;
        });

        .respond-to(@bpDesktopSmall, {
            margin-bottom: 4rem;
            padding: 60px 60px 0;
        });

        .respond-to(@bpDesktopLarge, {
            margin-bottom: 4rem;
            padding: 60px 150px 0;
        });

        .item-remove {
            .default-browser-focus;
            background-color: transparent;
            border: 0;
            font-size: 14px;
            padding: 0;
            top: 2.1em;

            .respond-to(@bpMobileLarge, {
                right: 0;
                top: 2.5em;
            });

            .respond-to(@bpTablet, {
                top: 2.6em;
            });

            .respond-to(@bpDesktopSmall, {
                top: 2.7em;
            });

            img {
                display: inline-block;
                width: 13px;
            }
        }

        .sc-item-list {
            .list-item {
                .respond-to(@bpMobileLarge, {
                    padding: 1.875em 1em 1.875em 0;
                });

                .respond-to(@bpTablet, {
                    padding: 1.875em 0;
                });
            }

            .item-name {
                font-size: 16px;
                letter-spacing: -0.76px;
                line-height: 18px;
                padding-bottom: 4px;

                .respond-to(@bpMobileLarge, {
                    font-size: 18px;
                    letter-spacing: -0.86px;
                    line-height: 21px;
                });

                .respond-to(@bpDesktopSmall, {
                    font-size: 21px;
                    letter-spacing: -1px;
                    line-height: 25px;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 24px;
                    letter-spacing: -0.89px;
                    line-height: 29px;
                });
            }

            .item-quantity {
                .font-family(light);
                font-size: 12px;
                line-height: 14px;

                .respond-to(@bpDesktopSmall, {
                    font-size: 16px;
                    line-height: 19px;
                });

                label {
                    font-size: 14px;
                    line-height: 18px;

                    .respond-to(@bpDesktopSmall, {
                        font-size: 16px;
                        line-height: 19px;
                    });
                }

                select {
                    .font-family(light);
                }

                input {
                    .font-family(light);
                    font-size: 12px;
                    line-height: 14px;
                    width: 60px;

                    .respond-to(@bpDesktopSmall, {
                        font-size: 16px;
                        line-height: 19px;
                        width: 80px;
                    });

                    &:disabled {
                        margin-top: -0.4em;
                    }
                }
            }

            .item-description .item-quantity-price.item-discounted {
                text-decoration: line-through;
            }

            .item-thumb,
            .credits {
                font-size: 18px;

                .respond-to(@bpMobileLarge, {
                    margin: 0;
                });
            }
        }

        .sb-summary-container {
            .item-remove {
                font-size: 14px;
                right: 0;
                top: 2px;

                .respond-to(@bpDesktopSmall, {
                    top: 5px;
                });

                .respond-to(@bpDesktopLarge, {
                    top: 6px;
                });
            }
        }
    }

    .shop-button {
        display: none;
    }

    .cta-next,
    .checkout-summary {
        .respond-to(@bpDesktopSmall, {
            width: 376px;
        });
    }

    .checkout-summary {
        padding: 2em 0;
        border: none;
        .respond-to(@bpMobileLarge, {
            padding-left: 5.75rem + @gutterMobile;
            clear: both;
        });

        .respond-to(@bpTablet, {
            padding: 2em 0;
        });

        .respond-to(@bpDesktopSmall, {
            border-bottom: none;
            float: right;
            font-size: 1rem;
            padding: 3.5em 0 2em;
        });
    }

    .cta-next {
        .box-sizing(border-box);
        margin: 0 auto;
        padding: @gutterMobile;
        text-align: center;

        .respond-to(@bpMobileLarge, {
            width: 17.5rem;
            padding: @gutterMobile 0;
        });

        .respond-to(@bpDesktopSmall, {
            clear: right;
            float: right;
            margin-top: 2rem;
            max-width: 376px;
            padding: 0;
            width: 100%;
        });

        .yellow-button {
            .respond-to(@bpDesktopSmall, {
                margin: 0;
            });
        }

        .credits-buy {
            margin: 0 auto;
        }

        .continue-shopping {
            display: inline-block;
            font-size: 1.4em;
            margin-top: 1.6em;
            text-transform: lowercase;
        }
        .exchanges-note {
            .border-radius(5px);
            align-items: flex-start;
            border: 1px solid @gray-tiu;
            box-sizing: border-box;
            font-size: 1.3em;
            letter-spacing: 0;
            line-height: 1.2em;
            margin-bottom: 30px;
            max-height: 200px;
            margin-left: -24px;
            max-width: 376px;
            overflow-y: scroll;
            padding: 9px 6px 0 16px;
            text-align: justify;
            width: 100%;

            .floating-check {
                margin: 16px 0px;
            }

            .respond-to(@bpMobileSmall, @bpTablet, {
                letter-spacing: -0.1px;
                margin-bottom: 40px;
                padding: 9px 17px 0 12px;
            });

            .respond-to(@bpMobileSmall, {
                font-size: 1.2em;
                max-height: 205px;
            });

            .respond-to(@bpMobileLarge, {
                font-size: 1.3em;
                max-height: 216px;
                max-width: 280px;
            });

            .respond-to(@bpTablet, {
                max-height: 218px;
                max-width: 280px;
                padding-right: 15px;
            });

            .respond-to(@bpDesktopSmall, {
                max-height: 200px;
                max-width: 376px;
                padding: 9px 15px 0 16px;
                margin-left: 0px;
            });

            .exchanges-paragraph {
                margin-top: 0;
            }
        }
        .terms-and-conditions {
            .font-family(bold);
            font-size: 1.4em;
            line-height: 1.4em;
            width: 90%;
        }
        .checkbox-terms-and-cond {
            max-width: 100%;

            .gc-terms-link {
                text-decoration: underline;

                &:hover {
                    color: @gray-54;
                }
            }
        }
    }

    .sb-summary-container {
        padding-left: 30px;
        padding-right: 2.8em;
        border-bottom: 1px solid @gray-light;
        .respond-to(@bpMobileLarge, {
          padding-left: 0;
          padding-right: 0;
        });
        .respond-to(@bpDesktopSmall, {
          border: none;
        });
        .summary-container {
            .respond-to(@bpMobileLarge, {
              max-width: none;
              margin: inherit;
            });
            .respond-to(@bpTablet, {
                margin-left: 43%;
                padding-right: 0;
            });
            .respond-to(@bpDesktopSmall, {
                margin-left: 0;
                padding-left: 0;
            });
        }
        .value {
          .respond-to(@bpMobileLarge, {
            padding-right: 2.66rem;
          });

          .respond-to(@bpTablet, {
            padding-right: 0;
          });

          .respond-to(@bpDesktopSmall, {
            font-size: 16px;
          });
          .respond-to(@bpDesktopLarge, {
            font-size: 18px;
          });

          &#estimated-total {
              .respond-to(@bpDesktopSmall, {
                  font-size: 21px;
              });
              .respond-to(@bpDesktopLarge, {
                font-size: 24px;
              });
          }
        }
    }
    .giftcard-container {
        .using-container {
            max-width: 23.5rem;
        }
        .gc-pin,
        .gc-number,
        .using-giftcard {
            margin-left: auto;
            margin-right: auto;
            display: block;
            .respond-to(@bpMobileLarge, {
                display: inline-block;
            });
        }

        .field-row {
            display: inline-block;
            position: relative;
            width: 100%;

            .frm-label {
                .transition(all, 0.1s, linear);
                font-size: 14px;
                left: 1.2em;
                position: absolute;
                top: 1.5em;
                z-index: 11;
            }

            input {
                margin-bottom: 20px;

                &:focus {
                    &+ .frm-label {
                        left: 0;
                        top: -1em;
                    }
                }

                &+ .frm-label {
                    &.show {
                        left: 0;
                        top: -1em;
                    }
                }
            }
        }
    }
    .empty-cart {
        height: calc(100vh - @headerHeight);
        position: relative;
        top: -(@headerHeight);

        .respond-to(@bpDesktopSmall, {
            height: calc(100vh - @headerHeight - @footerHeightDesktop);
        });

        .shopping-bag-sections {
            margin: 0 auto;
            text-align: center;
            width: 80%;
            .translate-center;
        }

        .message-empty {
            padding: 0.5em 0;
        }

        .shop-button {
            display: inline-block;
        }

        .sc-giftcard-form,
        .cta-next,
        .checkout-summary {
            display: none;
        }
    }
}
