.profile-social-container {
  .align-items(center);
  .flex-display(flex);
  height: 2.25rem;
  min-width: 18.75rem;
  order: 1;
  padding-left: 4.5rem;

  .respond-to(@bpMobileLarge, {
    min-width: inherit;
    order: inherit;
    padding-left: 0;
  });
}

.profile-social-container__item {
  list-style: none;
  margin-right: 2rem;
}

.profile-social-container__label {
  .font-family(medium);
  color: @black;
  font-size: 0.8125rem;
}

.profile_rewards {
  .profile-social-container {
    .flex-display(inline-flex);
    padding-left: 3.5rem;
  }
}
