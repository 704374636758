.confirm-dialog-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: @zOverlay;

  .confirm-dialog-overlay {
    background: rgba(0,0,0,.3);
    height: 100%;
    width: 100%;
  }

  .confirm-dialog__banner {
    .align-items(center);
    .flex-display(flex);
    .justify-content(center);
    background: @gray-darker;
    height: 2rem;

    &.hidden {
      display: none;
    }
  }

  .confirm-dialog__banner__soul-early {
    .font-family(bold);
    color: @scWhiteC;
    font-size: 0.6875rem;
    letter-spacing: .175rem;
    margin: 0;
  }

  .confirm-dialog-box {
    .border-radius(4px);
    background: @scWhiteC;
    color: @gray-darker;
    font-size: 14px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    .respond-to(@bpMobileLarge, {
      right: auto;
      width: 500px;
    });

    .confirm-dialog-close {
      background: url('/assets/images/confirm_dialog_close.png');
      background-size: cover;
      cursor: pointer;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 16px;

      &:hover,
      &:active {
        background: url('/assets/images/confirm_dialog_close_hover.png');
        background-size: cover;
      }
    }

    .confirm-dialog-section {
      box-sizing: border-box;
      padding: 35px 15px;
      width: 100%;
      .respond-to(@bpMobileLarge, {
        padding: 40px;
      });

      >div:not(:last-child) {
        margin-bottom: 24px;
        .respond-to(@bpMobileLarge, {
          margin-bottom: 40px;
        });
      }

      >.hidden {
        display: none;
        margin-bottom: 0;
      }

    }

    .confirm-dialog-section-action {
      background: @gray-main;
      border-top: 1px solid @gray-tiu
    }

    .confirm-dialog-header {
      .font-family(heavy);
      font-size: 24px;
    }

    .confirm-dialog-text-main {
      .font-family(bold);
      line-height: 17px;
    }

    .confirm-dialog-text-disclaimer {
      .font-family(roman);
      line-height: 17px;

      a {
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .soul-early-notice-container {
      color: @gray-darker;
      font-size: 0.875rem;
      margin-top: 2rem;
    }

    .soul-early-notice__header {
      .font-family(bold);
    }

    .soul-early-notice__body {
      .font-family(roman);
      line-height: 1.25rem;
      margin: 0;
    }

    button {
      border: none;
      cursor: pointer;
    }

    .confirm-dialog-accept {
      .border-radius(4px);
      .font-family(medium);
      .transition(background, .1s);
      background: @gray-darker;
      color: #fff;
      font-size: 20px;
      margin-bottom: 20px;
      padding: 15px;
      width: 100%;

      &:hover,
      &:active {
        background: @sc-yellow;
      }
    }

    .confirm-dialog-decline {
      .font-family(medium);
      .transition(color, .1s);
      background: none;
      border: none;
      font-size: 12px;
      text-decoration: underline;

      &:hover,
      &:active {
        color: @gray-medium-darker;
      }
    }
  }
}
