.soulcup-register {
  min-width: 320px;
  text-align: center;

  .soulcup-register-hero {
    background: @black;
    color: @scWhiteC;
    height: 16.875rem;
    position: relative;

    .respond-to(@bpTablet, {
      height: 21.875rem;
    });

    .respond-to(@bpDesktopLarge, {
      height: 34.125rem;
    });

    .background {
      background: bottom right / cover no-repeat
        url(/assets/images/turnitup/FallChallenge_Mobile-2.jpg) @black;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      .respond-to(@bpTablet, {
        background-position-y: 50%;
        background-size: 100%;

        background: bottom center / cover no-repeat
        url(/assets/images/turnitup/2024TIU-Desktop-LP.jpg) @black;
        background-position-y: -50px;
      });

      .respond-to(@bpDesktopLarge, {
        background: bottom right / cover no-repeat
        url(/assets/images/turnitup/2024TIU-Desktop-LP.jpg) @black;
        background-position-y: -148px;
      });
    }

    .soulcup-register-hero-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;


      .respond-to(@bpTablet, {
        padding-top: 0;
      });

      .soulcup-logo {
        background: center center / 100% auto no-repeat url(/assets/images/turnitup/2024TIU-StudioSignage-07.png) @transparent-color;
        height: 44px;
        width: 246px;
        z-index: 1;

        .respond-to(@bpTablet, {
          height: 51px;
          width: 295px;
        });

        .respond-to(@bpDesktopLarge, {
          height: 63px;
          width: 388.96px;
        });
      }

      .soulcup-logo-message {
        .font-family(bold);
        color: @white;
        font-size: 1.5rem;
        letter-spacing: -0.24px;
        line-height: 1.75rem;
        max-width: 310px;
        padding-top: 10px;
        text-align: center;
        z-index: 1;

        .respond-to(@bpTablet, {
          padding-top: 1.25rem;
        });

        .respond-to(@bpDesktopLarge, {
          max-width: none;
        });
      }

      .date {
        .font-family(bold);
        color: @white;
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.0625rem;
        margin: 8px 0 0;
        text-align: center;
        text-transform: capitalize;
        z-index: 1;

        .respond-to(@bpDesktopLarge, {
          font-size: 1rem;
          line-height: 1.125;
          margin: 14px 0 0;
        });
      }
    }
  }

  .soulcup-register-form {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 1.5rem 1.625rem 3.75rem;
    text-align: left;
    width: 100%;

    .respond-to(@bpTablet, {
      margin: 0 auto;
      padding: 2rem 1.5rem;
      width: auto;
    });

    .respond-to(@bpDesktopSmall, {
      margin: 0 auto;
      padding: 2rem;
      width: auto;
    });

    .respond-to(@bpDesktopLarge, {
      padding: 2rem 23rem;
    });


    &__question-wrapper {
      .flex-direction(row);
      .flex-display(flex);
      h3 {
        .font-family(heavy);
        color: @black;
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: 1rem;

        span {
          padding-right: 0.5rem;
        }

        .respond-to(@bpTablet, {
          font-size: 1.5rem;
        });
      }
    }

    .divider {
      background: @scYellowC;
      height: 6px;
      margin-bottom: 16px;
      width: 150px;

      .respond-to(@bpDesktopSmall, {
        margin-bottom: 24px;
      });
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 48px;

      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
      });
    }

    .step {

      h4 {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 18px;
        margin-bottom: 16px;

        .respond-to(@bpDesktopSmall, {
          font-size: 24px;
        });
      }

      .help-text {
        .font-family(light);
        color: @gray-59;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 16px;

        .respond-to(@bpDesktopSmall, {
          font-size: 16px;
        });
      }
    }

    .charity-selection {
      overflow: hidden;
      position: relative;
      width: 100%;
      max-width: 1000px;
      margin-bottom: 32px;

      &-wrapper {
        overflow: auto;
        position: relative;
      }

      &-boxes {
        display: inline-block;
        overflow: hidden;
        text-align: left;
        white-space: nowrap;

        img {
          max-height: 36px;
        }

        .respond-to(@bpTablet, {
          white-space: normal;
        });
      }

      &-box {
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.12);
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        height: 156px;
        padding: 1rem;
        position: relative;
        vertical-align: top;
        width: 260px;
        margin: 0 8px 8px 0;
        white-space: normal;

        .respond-to(@bpTablet, {
          height: 140px;
          overflow: visible;
          width: 342px;
        });

        &:hover {
          border: 2px solid @black;
        }

        &-heading {
          display: flex;
          align-items: center;
          margin-top: 8px;

          &-tooltip {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 14px;
            width: 14px;
            border: 1px solid @abbeyGray;
            border-radius: 50%;
            opacity: 0.64;
            font-weight: bold;
            margin-left: 7px;

            span {
              .font-family(bold);
              margin-top: 3px;
            }
          }

          &-name {
            .font-family(bold);
            margin-top: 3px;
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }

        &-description {
          font-size: 0.75rem;
          line-height: 1rem;
          margin: 4px 0;
        }
      }
    }

    .charity-selection-no-charities {
      text-align: center;

      p {
        font-size: 1.3rem;
        line-height: 1.7rem;
      }

      .disclaimer {
        font-size: 0.8rem;
      }
    }

    .goal-selection-content {
      margin-bottom: 32px;
      overflow: hidden;
      position: relative;
      width: 100%;

      .respond-to(@bpTablet, {
        display: inline-block;
      });

      .goal-selection-description {
        .font-family(heavy);
        color: @gray-38;
        font-size: 18px;
        margin: 48px 16px 16px;

        .respond-to(@bpTablet, {
          font-size: 24px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
          margin: 56px 40px 24px;
        });

        .respond-to(@bpDesktopLarge, {
          margin: 56px 56px 24px;
          white-space: nowrap;
        });

        .goal-select {
          .font-family(heavy);
          color: @gray-87;
          cursor: pointer;
          font-size: inherit;
          background: url(/assets/images/dropdown_arrow_down.png) no-repeat 96% center;
          background-size: 14px 8px;
          border: none;
          border-bottom: 2px solid;
          border-radius: 0;
          margin: 0;
          padding: 0 20px 0 0;
          text-indent: 0;

          .respond-to(@bpMobileLarge, {
            padding-right: 24px;
          });

          .respond-to(@bpTablet, {
            padding-right: 26px;
          });

          .respond-to(@bpDesktopSmall, {
            background-size: 20px 11px;
            padding-right: 40px;
          });

          &:hover {
            color: @gray-54;
          }
        }

        >span {
          display: none;

          .respond-to(@bpMobileLarge, {
            display: inline-block;
            white-space: pre;
          });
        }
      }
    }

    .recommended-goal-tip {
      .transition(opacity, 500ms);
      background-color: @black;
      border-radius: 0 0 2px 2px;
      bottom: -2px;
      color: @white;
      left: 0;
      opacity: 1;
      position: absolute;
      width: 100%;

      .respond-to(@bpTablet, {
        font-size: 10px;
        line-height: 10px;
      });

      &.recommended-goal-tip-hide {
        opacity: 0;
      }

      p {
        .font-family(bold);
        text-align: center;
        white-space: nowrap;
      }

      &:before {
        border-top: 6px solid @scWhiteC;
        border-left: 5px solid transparent;
        border-right:5px solid transparent;
        bottom: -6px;
        content:'';
        display:block;
        height:0;
        left:14px;
        position:absolute;
        width:0;
      }

    }

    .goal-selection-boxes-wrapper {
      overflow: auto;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .goal-selection-boxes {
      color: @black;
      display: inline-block;
      overflow: hidden;
      text-align: left;
      white-space: nowrap;

      .goal-selection-box {
        border-radius: 4px;
        border: 1px solid @gray-12;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        height: 208px;
        padding: 1rem;
        position: relative;
        vertical-align: top;
        width: 133px;

        &:not(:first-child) {
          margin-left: 7px;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.selected-goal {
          border: 2px solid @black;
        }

        .radio-box {
          position: absolute;
          top: 8px;
          right: 8px;

          .respond-to(@bpDesktopLarge, {
            top: 16px;
            right: 16px;
          });

          input {
            cursor: pointer;
            margin: 0;
          }
        }

        .goal-count {
          .font-family(bold);
          border-bottom: 1px solid;
          display: inline-block;
          font-size: 24px;
          margin-bottom: 16px;
          width: 32px;
        }

        .rider-prize {
          .font-family(medium);
          line-height: 1rem;
          margin: 0;

          .rider-prize-item {
            display: block;
            font-size: 0.75rem;
            white-space: normal;

            > span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .pickup-studio-selection {
      margin-bottom: 48px;

      select {
        margin: 0;
      }

      &__paragraph {
        .font-family(roman);
        color: @black;
        display: none;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        margin-top: 0;

        .respond-to(@bpTablet, {
          display: block;
        });

      }

    }

    .confirm-selection {
      label {
        .flex-display();
        .flex-wrap(nowrap);
        margin: 2.125rem 0;

        .respond-to(@bpDesktopSmall, {
          .align-items(center);
        });

        input {
          .flex(none);
        }

        span {
          .font-family(bold);
          align-self: center;
          color: @gray-darker;
          float: left;
          font-size: 0.875rem;
          line-height: 1rem;
          padding-left: 8px;
          width: 475px;

          .respond-to(@bpDesktopSmall, {
            width: auto;
          });
        }
      }

      p {
        .font-family(medium);
        color: @gray-darker;
        font-size: 14px;
        line-height: 16px;
        margin: 16px 0;

        a {
          text-decoration: underline;
        }
      }

      .checkbox {
        float: left;
      }
    }

    .join-team {
      label {
        .flex-display();
        .flex-wrap(nowrap);
        margin: 0.5rem 0 2.125rem;

        .respond-to(@bpDesktopSmall, {
          .align-items(center);
        });

        span {
          .font-family(medium);
          align-self: center;
          color: @gray-darker;
          float: left;
          font-size: 0.825rem;
          line-height: 1rem;
          padding-left: 8px;
          width: 475px;

          .respond-to(@bpDesktopSmall, {
            width: auto;
          });
        }
      }

      .join-team-input-wrapper {
        h6 {
          .font-family(roman);
          color: @gray-54;
          font-size: 0.875rem;
          line-height: 1.125rem;
          margin-bottom: 0;
        }

        input[type=text] {
          .flex(none);
          background-color: @white;
        }

        input[type=text]:disabled {
          background-color: @gray-main;
        }

        p {
          .font-family(medium);
          display: none;
          font-size: 0.875rem;
          line-height: 1.125rem;
          margin-top: 0;
        }

        &-error {
          h6 {
            color: @red-error;
          }

          input[type=text] {
            border-color: @red-error;
          }

          p {
            display: block;
            color: @red-error;
          }
        }
      }

      p {
        .font-family(medium);
        color: @gray-darker;
        font-size: 14px;
        line-height: 16px;
        margin: 16px 0;

        a {
          text-decoration: underline;
        }

        span {
          .font-family(bold);
        }
      }

      .checkbox {
        float: left;
      }

      &-error {
        h6 {
          color: red;
        }

        input[type=text] {
          .flex(none);
          border-color: red;
        }
      }
    }

    .submit-section {
      button {
        .font-family(bold);
        font-size: 1rem;
        height: 56px;
        letter-spacing: 0.5px;
        line-height: 1.25rem;
        padding: 5px 53px;
        width: 100%;

        .respond-to(@bpTablet, {
          width: 312px;
        });
      }
    }

    .pickup-studio {
      background: url(/assets/images/dropdown_arrow_down.png) no-repeat 96% center;
      background-size: 16px 9px;
      border: 1px solid @gray-59;
      border-radius: 5px;
      max-width: 346px;
      width: 100%;

      &.field-error {
        border-color: @error;
      }
    }
  }


  &-footer {
    background: @gray-6;
    padding: 40px 16px;

    .respond-to(@bpTablet, {
      padding: 56px 0;
    });

    .respond-to(@bpDesktopSmall, {
        margin-bottom: @footerHeightDesktop;
    });

    p {
      .font-family(medium);
      color: @gray-darker;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;

      a {
        text-decoration: underline;
      }
    }
  }

  .soulcup-checkbox {
    .appearance(none);
    background-color: transparent !important;
    border-radius: 4px;
    border: 1px solid @gray-medium;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    margin: 0 10px 0 0;
    min-width: 24px;
    padding: 0;
    position: relative;
    text-align: left;
    width: 24px;

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/spritesheet.png) -248px -174px no-repeat;
      color: @yellow-light;
      content: '';
      font-size: 14px;
      height: 24px;
      left: 0;
      position: absolute;
      width: 24px;
    }
  }

  .soulcup-radio {
    .appearance(none);
    border-radius: 50%;
    border: 1px solid @gray-54;
    cursor: pointer;
    height: 20px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 20px;

    &:checked {
      background-color: @black;
      border: none;
    }

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/soulcup/selected-check.svg) no-repeat;
      content: '';
      display: block;
      height: 100%;
      width: 100%;

      .respond-to(@bpDesktopLarge, {
        background-size: 20px 20px;
      });
    }
  }
}
