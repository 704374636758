/*doc
---
title: SC Buttons
name: sc_buttons
category: buttons
---

Button styles can be applied to any element. Typically you'll want to
use an `<a>` element:

```html_example
<a class="yellow-button">Click</a>
<a class="yellow-button large-button" href="http://www.soul-cycle.com">Don't click</a>
```

Button                                                            | Modifier Class
----------------------------------------------------------------- | -----------------
<a class="yellow-button">Default</a>                              | `yellow-button`
<a class="yellow-button large-button">Large Button</a>            | `yellow-button button-large`
<a class="yellow-button large-button full">Full Button</a>        | `yellow-button button-large full`
*/

.light-yellow-button,
.ghost-button,
.yellow-button,
.gray-button,
.gray-button-dark {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  .box-sizing(border-box);
  .default-browser-focus;
  .font-family(medium);
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  text-align: center;

  &.small-button {
    display: inline-block;
    font-size: 12px;
    line-height: 30px;
  }
  &.large-button {
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    padding: 0 34px;
  }
  &:disabled {
    background: @gray-light;
    cursor: default;
  }
  &.full {
    display: block;
    padding-left: 0;
    padding-right: 0;
    max-width: 23.5rem;
    width: 100%;
    .respond-to(@bpDesktopSmall,{
      max-width: 100%;
    });
  }
}

.yellow-button {
  background-color: @sc-yellow;
  border: none;
  color: @gray-darker;
}

.light-yellow-button {
  background-color: @yellow-light;
  border: none;
  color: @gray-darker;
  &:hover {
    background: @yellow-light;
    box-shadow: 0 0 5px 0 @gray-darker;
  }
}

.gray-button {
  background-color: @gray-light;
  border: none;
  color: @gray-darker;
}

.ghost-button {
  background-color: transparent;
  border: 1px solid @gray-medium;
  color: @gray-darker;
}

.gray-button-dark {
  background-color: @gray-darker;
  color: #fff;
  border: none;
  &:hover {
    color: #fff;
  }
}
