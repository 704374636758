.sc-class-list {
	font-size: 1rem;

	.sc-class-row {
		border-bottom: @borderLightGray;
		margin-top: 2em;
		padding: 1.25em 0 1.75em;
		position: relative;
	}

	.sc-class-list-info {
		font-size: 0.9em;
		position: relative;
		z-index: 2;
		line-height: 1.6em;

		.respond-to(@bpDesktopLarge, {
			font-size: 1em;
			padding-left: 0;
			width: 100%;
		});
	}

	.bike-number {
		z-index: 1;
	}

    .sc-class-list-instructor-icon {
		margin: -0.3em 0.75rem 0 0;
		background-position: center top;
		background-size: 175%;
		background-repeat: no-repeat;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		display: inline-block;
		float: left;

		.respond-to(@bpTablet, {
			margin-left: 0.75rem;
			width: 3.75rem;
			height: 3.75rem;
		});

		.respond-to(@bpDesktopLarge, {
			height: 4.5rem;
			width: 4.5rem;
    	});
    }

	.bike-number-container {
		margin-top: 2.25em;
	}

	.sc-class-info {
		margin-left: 4.75rem;

		.respond-to(@bpTablet, {
			margin-left: 5.25rem;
		});

		.respond-to(@bpDesktopLarge, {
			margin-left: 6rem;
		});
	}

	.bike-number-container {
		color: @gray-main;
		.font-family(heavy);
		left: 4.75rem;
		position: absolute;
		bottom: 0;

		.respond-to(@bpTablet, {
			left: 5.25rem;
		});

		.respond-to(@bpDesktopLarge, {
			left: 6rem;
		});
	}

	.bike-number {
		.font-family(medium);
		font-size: 2.8em;
		position: relative;
		top: 0.21em;

		.respond-to(@bpDesktopSmall, {
			font-size: 3.4em;
		});

		.respond-to(@bpDesktopLarge, {
			font-size: 6em;
			top: 0.1em;
		});
	}

	.class-instructor-and-studio {
		font-size: 1.25em;
	}

	.class-title,
	.class-instructor {
		color: @gray-darker;
		.font-family(medium);
	}

	.class-datetime {
		.font-family(medium);
		font-size: 0.875em;
	}

	.class-type {
		font-size: 0.875em;
	}
}