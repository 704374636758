.tenthanniversary_landing {
  .card-front {
    .respond-to(@bpDesktopSmall, {
      z-index: 2;
      background-color: #fff;
      -webkit-transition: transform .2s;
      -moz-transition: transform .2s;
      -o-transition: transform .2s;
      transition: transform .2s;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      opacity: 1;
    });
  }
  .card-front:hover {
    .respond-to(@bpDesktopSmall, {
      -webkit-transition: translate(0, -70px);
      -moz-transition: translate(0, -70px);
      -o-transition: translate(0, -70px);
      transform: translate(0, -70px);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
    });
  }
  .font-dark {
    color: @gray-darker;
  }
  .row {
    border-bottom: @borderDarkGray;
    .respond-to(@bpDesktopSmall, {
      border-top: none;
    });
  }
  .share-icons {
    .share-icon {
      cursor: pointer;
      margin: 15px 6px;
      z-index: 1;
    }

    .share-icon-link {
      height: 65px;
      width: 60px;

      .respond-to(@bpDesktopSmall, {
        height: 40px;
        width: 40px;
      });
    }
  }
  .tenth-empty-state {
    position: absolute;
    z-index: 20;
    background: rgba(255,255,255,.95);
    width: 100%;
    height: 100%;
  }
  .tenth-empty-modal {
    position: absolute;
    width: 100%;
    z-index: 101;
    background: rgba(255,255,255,1);
    text-align: center;
    padding-top: 50px;
    .respond-to(@bpMobileLarge, {
      padding: 50px 20px;
    });
    .respond-to(@bpTablet, {
      width: 640px;
      left: 50%;
      margin-left: -340px;
      top: 80px;
      border: 1px solid #eee;
    });
    .respond-to(@bpDesktopSmall, {
      top: 150px;
      width: 855px;
      margin-left: -445px;
    });
    p.motivational-message {
      text-align: left;
      line-height: 24px;
      margin: 0 auto;
      padding: 0 20px 15px;
      .respond-to(@bpMobileLarge, {
        line-height: 35px;
        width: 540px;
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 33px;
        line-height: 40px;
        width: 715px;
      });
    }
    .motivational-message,
    .me-progress {
      font-size: 20px;
      .font-family(bold);
      .respond-to(@bpMobileLarge, {
        font-size: 28px;
      });
    }
    .grey-background {
      background-color: @gray-light;
      line-height: 0;
      margin-bottom: 10px;
      position: relative;
      .respond-to(@bpMobileLarge, {
        width: 500px;
        margin: 0 auto;
      });
      .respond-to(@bpDesktopSmall, {
        width: 580px;
      });
      img {
        width: 100%;
        position: relative;
      }
      .yellow-fill {
        position:absolute;
        bottom: 0;
        background-color: @sc-yellow;
        height: 0%;
        width: 100%;
      }
    }
    .yellow-button {
      margin-top: 20px;
    }
  }
  .soulsquad-modal {
    display: none;
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.85);
    img.close-modal {
      cursor: pointer;
      display: inline-block;
      width: 15px;
      height: 15px;
      float: right;
      margin-right: 5px;
      margin-top: 15px;
      .respond-to(@bpDesktopMedium, {
        display: none;
        width: 20px;
        height: 20px;
      });
    }
    img.close-modal.desktop {
      display: none;
      .respond-to(@bpDesktopMedium, {
        display: block;
        width: 20px;
        height: 20px;
        margin-top: 0;
        margin-right: 30px;
      });
    }
    .your-soulsquad-container {
      background-color: #fff;
      margin: 10px 20px;
      overflow: hidden;
      .respond-to(@bpMobileLarge, {
        margin: 40px 30px;
      });
      .respond-to(@bpDesktopSmall, {
        margin: 60px 30px;
      });
      .respond-to(@bpDesktopMedium, {
        width: 1140px;
        margin: 60px auto;
      });
      .soulsquad-header {
        padding: 2px 10px 8px;
        border-bottom: 2px solid @gray-darker;
        .respond-to(@bpMobileLarge, {
          padding: 5px 15px 24px;
        });
        .respond-to(@bpTablet, {
          padding: 6px 15px 16px;
        });
        .respond-to(@bpDesktopSmall, {
          padding-bottom: 7px;
        });
        .respond-to(@bpDesktopMedium, {
          padding: 0px 30px 12px;
        });
        h1 {
          font-size: 28px;
          .font-family(bold);
          display: inline-block;
          .respond-to(@bpMobileLarge, {
            font-size: 55px;
            line-height: 31px;
          });
          .respond-to(@bpTablet, {
            font-size: 60px;
            line-height: 40px;
          });
          .respond-to(@bpDesktopMedium, {
            font-size: 70px;
          });
          span {
            padding-left: 10px;
            margin-top: 10px;
          }
        }
        .yellow-highlight {
          display: inline-block;
          height: 15px;
          width: 220px;
          padding-left: 5px;
          .respond-to(@bpMobileLarge, {
            width: 440px;
            padding-left: 15px;
            padding-top: 20px;
          });
          .respond-to(@bpTablet, {
            width: 480px;
            height: 21px;
          });
          .respond-to(@bpDesktopMedium, {
            width: 550px;
          });
        }
      }
      .soulsquad-instructor {
        padding-bottom: 0;
        height: 220px;
        overflow: hidden;
        position: relative;
        .respond-to(@bpMobileLarge, {
          height: 200px;
          width: 50%;
          box-sizing: border-box;
          float: left;
          border-left: @borderDarkGray;
        });
        .respond-to(@bpTablet, {
          height: 260px;
        });
        .respond-to(@bpDesktopSmall, {
          width: 33.33%;
          height: 245px;
        });
        .respond-to(@bpDesktopMedium, {
          height: 275px;
        });
        .instructor-name,
        .percent-of-rides {
          font-size: 20px;
          .font-family(bold);
          margin-left: 20px;
          line-height: 29px;
          position: relative;
          z-index: 2;
          .respond-to(@bpTablet, {
            margin-left: 30px;
          });
        }
        .instructor-name {
          .respond-to(@bpMobileLarge, {
            font-size: 16px;
          });
          .respond-to(@bpTablet, {
            font-size: 20px;
            margin-bottom: 3px;
          });
        }
        .percent-of-rides {
          .respond-to(@bpMobileLarge, {
            font-size: 14px;
            line-height: 20px;
          });
          .respond-to(@bpTablet, {
            font-size: 18px;
            line-height: 28px;
          });
          .yellow-highlight {
            display: inline-block;
            height: 10px;
            width: 47px;
            padding-left: 5px;
            .respond-to(@bpMobileLarge, {
              height: 7px;
              width: 30px;
            });
            .respond-to(@bpTablet, {
              height: 9px;
              width: 47px;
              padding-left: 7px;
            });
          }
        }
        .instructor-pic {
          position: absolute;
          line-height: 0;
          top: 0;
          left: 10%;
          .respond-to(@bpMobileLarge, {
            left: 50%;
            margin-left: -150px;
          });
          img {
            width: 314px;
            .respond-to(@bpMobileLarge, {
              width: 300px;
            });
            .respond-to(@bpTablet, {
              width: 338px;
            });
            .respond-to(@bpDesktopMedium, {
              width: 358px;
            });
          }
          img.missing-image {
            margin-top: -22px;
            margin-left: 20px;
            width: 280px;
            .respond-to(@bpMobileLarge, {
              margin-top: -29px;
              margin-left: 40px;
              width: 250px;
            });
            .respond-to(@bpTablet, {
              width: 300px;
              margin-left: 20px;
              margin-top: -22px;
            });
            .respond-to(@bpDesktopSmall, {
              margin-top: -30px;
            });
            .respond-to(@bpDesktopMedium, {
              margin-top: -15px;
            });
          }
        }
      }
      .soulsquad-share {
        text-align: center;
        overflow: hidden;
        .respond-to(@bpTablet, {
          text-align: right;
        });
        .respond-to(@bpDesktopSmall, {
          position: absolute;
          top: 60px;
          right: 80px;
          border-bottom: none;
        });
        .respond-to(@bpDesktopMedium, {
          width: 1140px;
          margin: 0 auto;
          right: initial;
        });

        .share-icons {
          .share-icon {
            z-index: 1;
          }

          .share-icon-link {
            .respond-to(@bpTablet, {
              height: 50px;
              width: 50px;
            });

            .respond-to(@bpDesktopSmall, {
              height: 40px;
              width: 40px;
            });
          }

          .share-icon-title {
            z-index: 0;
          }
        }

        h3 {
          font-size: 18px;
          .font-family(medium);
          .respond-to(@bpTablet, {
            float: none;
            display: inline;
            top: -17px;
            right: 5px;
          });
          .respond-to(@bpDesktopSmall, {
            font-size: 14px;
            .font-family(bold);
          });
        }
        .share-hide {
          .respond-to(@bpDesktopSmall, {
            display: none;
          });
        }
        .share-icons {
          .respond-to(@bpTablet, {
            display: inline;
            margin-right: 20px;
          });
          .respond-to(@bpDesktopSmall, {
            margin-right: 100px;
          });
          .respond-to(@bpDesktopMedium, {
            margin-right: 60px;
          });
          .share-icon {
            .respond-to(@bpMobileLarge, {
                margin: 35px 10px 20px;
                .share-icon-large;
            });
            .respond-to(@bpTablet, {
              margin: 0 10px 0;
              .share-icon-medium;
            });
            .respond-to(@bpDesktopSmall, {
              margin: 0 7px 0px;
              .share-icon-small;
            });
          }
        }
      }
    }
  }
  .tenth-desktop-container {
    .respond-to(@bpDesktopSmall, {
      border: 3px solid @gray-darker;
      height: 660px;
      margin: 0 20px;
      overflow: hidden;
    });
    .respond-to(@bpDesktopMedium, {
      width: 1100px;
      margin: 0 auto;
      height: 709px;
    });
    .one-of-two,
    .one-of-three,
    .two-of-three {
      .tenth-section {
        .respond-to(@bpDesktopSmall, {
          padding: 20px 20px;
          height: 331px;
          width: 100%;
          border-left: @borderDarkerGray;
          border-bottom: @borderDarkerGray;
          box-sizing: border-box;
        });
        .respond-to(@bpDesktopMedium, {
          height: 355px;
          padding: 25px 20px;
        });
      }
    }
    .one-of-two {
      .respond-to(@bpDesktopSmall, {
        width: 50%;
        float: left;
        overflow: hidden;
      });
    }
    .one-of-three {
      .respond-to(@bpDesktopSmall, {
        width: 33.33%;
        float: left;
        overflow: hidden;
      });
    }
    .two-of-three,
    &.chart-conatiner {
      .respond-to(@bpDesktopSmall, {
        width: 66.67%;
        float: right;
        overflow: hidden;
        position: relative;
      });
    }
    .chart-container {
      .respond-to(@bpMobileLarge, {
        width: 50%;
        float: left;
        box-sizing: border-box;
      });
      &.second {
        .respond-to(@bpMobileLarge, {
          border-left: @borderMediumGray;
        });
      }
      .respond-to(@bpDesktopSmall, {
        width: 33.33%;
        float: left;
        overflow: hidden;
      });
    }
  }
  .tenth-container {
    min-height: 600px;
    font-size: 16px;
    position: relative;
    .font-family(bold);
    .respond-to(@bpTablet, {
      min-height: 800px;
    });
    .respond-to(@bpDesktopSmall, {
      padding-bottom: 60px;
    });
    .respond-to(@bpDesktopMedium, {
      padding-bottom: @footerHeightDesktop;
    });
    .section-title {
      padding-bottom: 10px;
      .respond-to(@bpMobileLarge, {
        font-size: 20px;
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 18px;
        line-height: 20px;
        .font-family(medium);
      });
      .respond-to(@bpDesktopMedium, {
        font-size: 20px;
      });
    }
    .number-classes-container {
      .row {
        padding-bottom: 0;
        .respond-to(@bpMobileLarge, {
          padding-bottom: 50px;
        });
      }
      .tenth-section {
        padding-right: 0;
      }
    }
    .tenth-section {
      position: relative;
      padding: 35px 15px;
      overflow: hidden;
      .respond-to(@bpMobileLarge, {
        padding: 50px 40px;
      });
      &.bar-chart-container,
      &.line-chart-container {
        .respond-to(@bpMobileLarge, {
          padding-bottom: 30px;
        });
        .respond-to(@bpTablet, {
          padding-bottom: 40px;
        });
      }
      &.first {
        min-height: 60px;
        overflow: initial;
        line-height: 1;
        .respond-to(@bpMobileLarge, {
          min-height: 85px;
          border-bottom: 2px solid @gray-darker;
        });
        .respond-to(@bpTablet, {
          min-height: 70px;
        });
        .respond-to(@bpDesktopSmall, {
          border-bottom: none;
          min-height: 60px;
          padding-left: 20px;
        });
        .respond-to(@bpDesktopMedium, {
          min-height: 85px;
          width: 1100px;
          margin: 0 auto;
          padding-left: 0;
          padding-right: 0;
        });
      }
    }

    .share-icons {
      .share-icon {
        .share-icon-title {
          font-size: 8px;
        }
      }
    }

    .number-classes {
      display: inline-block;
      position: relative;
      text-align: center;
      &.font-large {
        font-size: 167px;
      }
      &.font-medium {
        font-size: 127px;
      }
      &.font-small {
        font-size: 97px;
      }
      .respond-to(@bpMobileLarge, {
        float: left;
        &.font-large,
        &.font-medium,
        &.font-small {
          font-size: 144px;
        }
      });

      .respond-to(@bpDesktopSmall, {
        width: 85%;

        &.font-large {
          font-size: 4.875rem;

          .yellow-highlight {
            height: 60px;
            padding: 10px 5px 10px 3px;
          }
        }

        &.font-medium {
          font-size: 50px;

          .yellow-highlight {
            padding: 5px 8px 0 2px;
            height: 40px;
          }
        }

        &.font-small {
          font-size: 35px;

          .yellow-highlight {
            padding: 5px 5px 0 2px;
            height: 30px;
          }
        }
      });

      .respond-to(@bpDesktopMedium, {
        &.font-large,
        &.font-medium {
          .yellow-highlight {
            padding: 15px 10px 20px;
          }
        }

        &.font-large {
          font-size: 5.875rem;
        }

        &.font-medium {
          font-size: 70px;
        }

        &.font-small {
          font-size: 40px;

          .yellow-highlight {
            padding: 15px 10px;
          }
        }
      });

      .yellow-highlight {
        background-color: transparent;
        .respond-to(@bpMobileLarge, {
          background-color: @sc-yellow;
          height: 80px;
          padding: 5px 15px 30px 5px;
        });
      }
    }
    img.soul-bike {
      display: none;
      .respond-to(@bpMobileLarge, {
        display: inline-block;
        width: 125px;
        margin-left: 30px;
        margin-top: 10px;
        float: left;
      });
      .respond-to(@bpDesktopSmall, {
        margin-left: 0;
        margin-top: 40px;
        width: 85%;
      });
      .respond-to(@bpDesktopMedium, {
        width: 142px;
      });
    }
    .tenth-logo {
      position: absolute;
      right: 50px;
      top: 20px;
      .respond-to(@bpMobileLarge, {
        right: 110px;
      });
      .respond-to(@bpDesktopSmall, {
        right: 75px;
        top: 61px;
      });
      .respond-to(@bpDesktopMedium, {
        right: 0;
        top: 53px;
      });
      .tenth-logo-label {
        display: inline-block;
        margin-right: 40px;
        padding-top: 10px;
        font-size: 14px;
        .respond-to(@bpMobileLarge, {
          padding-top: 15px;
          font-size: 20px;
        });
        .respond-to(@bpTablet, {
          font-size: 18px;
        });
        .respond-to(@bpDesktopSmall, {
          padding-top: 0;
          margin-right: 5px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 26px;
          margin-right: 0;
        });
      }
      img {
        width: 32px;
        position: absolute;
        top: 7px;
        right: 0;
        .respond-to(@bpMobileLarge, {
          top: 11px;
        });
        .respond-to(@bpDesktopSmall, {
          top: 24px;
          width: 136px;
        });
        .respond-to(@bpDesktopMedium, {
          width: 195px;
        });
      }
    }
    .rider-name {
      position: absolute;
      z-index: 10;
      bottom: -14px;
      .respond-to(@bpMobileLarge, {
        bottom: -23px;
      });
      .respond-to(@bpDesktopSmall, {
        bottom: -32px;
      });
      .respond-to(@bpDesktopMedium, {
        bottom: -41px;
      });
      .name {
        font-size: 33px;
        letter-spacing: -.92px;
        .respond-to(@bpMobileLarge, {
          font-size: 60px;
        });
        .respond-to(@bpDesktopSmall, {
          font-size: 48px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 64px;
        });
      }
      .soul-journey {
        font-size: 49px;
        letter-spacing: -1.36px;
        .respond-to(@bpMobileLarge, {
          font-size: 80px;
        });
        .respond-to(@bpDesktopSmall, {
          font-size: 112px;
          letter-spacing: -2px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 144px;
          letter-spacing: -4.36px;
        });
      }
    }
    ul.rider-cities {
      list-style-type: none;
      font-size: 29px;
      .font-family(condensed-black);
      .respond-to(@bpMobileLarge, {
        font-size: 60px;
        width: 440px;
        line-height: 50px;
      });
      .respond-to(@bpTablet, {
        width: initial;
        padding-right: 100px;
      });
      .respond-to(@bpDesktopSmall, {
        padding-right: 0;
        margin-top: -3px;
      });
      &.cities-large {
        .respond-to(@bpDesktopSmall, {
          font-size: 32px;
          line-height: 28px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 38px;
          line-height: 31px;
        });
      }
      &.cities-medium {
        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
          line-height: 25px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 33px;
          line-height: 28px;
        });
      }
      &.cities-small {
        .respond-to(@bpDesktopSmall, {
          font-size: 26px;
          line-height: 22px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 31px;
          line-height: 26px;
        });
      }
      li {
        float: left;
        margin-right: 10px;
        .respond-to(@bpDesktopSmall, {
          float: none;
        });
        &.selected {
          color: @sc-yellow;
        }
      }
    }
    ul.year-stats {
      list-style-type: none;
      font-size: 25px;
      color: @gray-medium;
      .font-family(condensed-black);
      .respond-to(@bpMobileLarge, {
        width: 70%;
        float: left;
        margin-left: 30px;
        margin-top: 10px;
        font-size: 34px;
      });
      li {
        border-bottom: @borderLightGray;
        padding: 10px 0 5px;
        letter-spacing: -.13px;
        margin-right: 35px;
        .respond-to(@bpMobileLarge, {
          line-height: 27px;
          letter-spacing: 0.08px;
          padding-left: 15px;
        });
        &.rider-souliversary-horiz {
          letter-spacing: initial;
          .respond-to(@bpMobileLarge, {
            display: none;
          });
          .yellow-highlight {
            padding-right: 30px;
          }
        }
      }
    }
    .rider-souliversary-vert {
      display: none;
      font-size: 34px;
      .respond-to(@bpMobileLarge, {
        float: left;
        display: inline-block;
      });
      .respond-to(@bpDesktopSmall, {
        float: none;
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 100px;
      });
      .yellow-highlight {
        .respond-to(@bpMobileLarge, {
          border-top: @borderDarkerGray;
          font-size: 4.375rem;
          padding: 0.625rem;

          .font-family(condensed-black);
          &:first-child {
            border-top: none;
          }
        });
        .respond-to(@bpDesktopSmall, {
          background-color: transparent;
          font-size: 4.375rem;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 6rem;
          padding: 0;
        });
      }
    }
    .souliversary-container {
      clear:both;
    }
    .rider-cities-container,
    .souliversary-container {
      position: relative;
      overflow: visible;
      height: 100%;
    }
    .rider-cities-front,
    .rider-cities-back,
    .souliversary-container-front,
    .souliversary-container-back {
      .respond-to(@bpDesktopSmall, {
        position: absolute;
        background-color: #fff;
      });
    }
    .rider-cities-front,
    .souliversary-container-front {
      .respond-to(@bpDesktopSmall, {
        z-index: 2;
      });
    }
    .souliversary-container-front {
      .respond-to(@bpMobileSmall, {
        border-top: @borderDarkGray;
      });
      .respond-to(@bpDesktopSmall, {
        border-top: none;
      });
      .year-stats {
        .respond-to(@bpDesktopSmall, {
          display: none;
        });
      }
    }
    .rider-cities-back,
    .souliversary-container-back {
        display: none;
    }
    .rider-cities-back {
      .respond-to(@bpDesktopSmall, {
        z-index: 1;
        display: block;
      });
      .rider-fav-studio {
        color: @gray-medium;
        font-size: 83px;
        line-height: 63px;
        margin-top: 5px;
        text-align: center;
        width: 80px;
      }
    }
    .souliversary-container-back {
      .respond-to(@bpDesktopSmall, {
        z-index: 1;
        display: block;
      });
      ul.year-stats {
        .respond-to(@bpDesktopSmall, {
          font-size: 26px;
          margin-left: 0;
          margin-top: 3px;
          width: 100%;
        });
        li {
          .respond-to(@bpDesktopSmall, {
            padding-left: 0;
            margin-right: 15px;
            line-height: 20px;
          });
          &:nth-child(2) {
            .respond-to(@bpDesktopSmall, {
              margin-top: 5px;
            });
          }
        }
        li.rider-souliversary-horiz {
          .respond-to(@bpDesktopSmall, {
            display: initial;
            border-bottom: none;

          });
        }
      }
    }
    .soulsquad-container {
      position: relative;
      padding-bottom: 0;
      min-height: 280px;
      background-position: 100% 15%;
      background-size: 130%;
      background-repeat: no-repeat;
      border-top: none;
      &.missing-instructor-image {
        background-size: 250px;
        background-position: 100%;
        padding-right: 0;
        .respond-to(@bpMobileLarge, {
          background-size: 70%;
        });
        .respond-to(@bpTablet, {
          background-size: 50%;
        });
        .respond-to(@bpDesktopSmall, {
          background-size: 300px;
          background-position: 100% 10%;
        });
        .respond-to(@bpDesktopMedium, {
          background-size: 370px;
          background-position: 100% -20%;
        });
      }
      .respond-to(@bpMobileLarge, {
        min-height: 420px;
        background-size: 100%;
      });
      .soulsquad-title {
        .respond-to(@bpMobileLarge, {
          font-size: 68px;
          letter-spacing: -2.08px;
        });
        .respond-to(@bpDesktopSmall, {
          color: @gray-medium;
          font-size: 60px;
        });
        .respond-to(@bpDesktopMedium, {
          font-size: 72px;
        });
      }
      .rider-soulsquad {
        padding: 15px 0;
        display: inline-block;
        .respond-to(@bpMobileLarge, {
          padding: 0 0 15px;
        });
        .respond-to(@bpDesktopSmall, {
          padding-bottom: 0;
        });
        .soulsquad {
          margin-right: 5px;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          display: inline-block;
          background-color: @gray-light;
          background-position: center top;
          background-size: 175%;
          background-repeat: no-repeat;
          &.missing-image-thumb {
            background-color: transparent;
            background-position: center;
            background-size: 130%;
          }
          .respond-to(@bpMobileLarge, {
            width: 90px;
            height: 90px;
          });
          .respond-to(@bpTablet, {
            width: 115px;
            height: 115px;
          });
          .respond-to(@bpDesktopSmall, {
            width: 104px;
            height: 104px;
          });
        }
      }
      .gray-button {
        display: block;
        width: 85px;
        margin: 0;
        padding: 0;
        .respond-to(@bpMobileLarge, {
          width: 166px;
        });
        .respond-to(@bpDesktopSmall, {
          background-color: rgba(255,255,255,0.85);
          position: absolute;
          top: 140px;
          left: 60px;
          width: 250px;
          display: none;
        });
        .respond-to(@bpDesktopMedium, {
          top: 154px;
          left: 60px;
        });
        .small {
          .respond-to(@bpMobileLarge, {
            display: none;
          });
        }
        .large {
          display: none;
          .respond-to(@bpMobileLarge, {
            display: block;
          });
        }
      }
    }
    .spotify-container {
      padding: 15px;
      text-align: center;
      .respond-to(@bpMobileLarge, {
        border-bottom: none;
        padding-top: 40px;
      });
      .respond-to(@bpDesktopSmall, {
        position: absolute;
        top: 235px;
        border-top: none;
        text-align: left;
      });
      .respond-to(@bpDesktopMedium, {
        top: 255px;
      });
      .spotify-cta {
        &.yellow-button {
          line-height: 20px;
          padding: 15px 15px 15px 48px;
          text-align: left;
          margin-right: 30px;
          .respond-to(@bpMobileLarge, {
            width: 95%;
            text-align: center;
          });
          .respond-to(@bpTablet, {
            width: 85%;
          });
          .respond-to(@bpDesktopSmall, {
            width: 525px;
            padding: 15px 5px 15px 48px;
          });
        }
      }
      img.spotify-logo {
        width: 25px;
        position: absolute;
        left: 15px;
        top: 13px;
        .respond-to(@bpMobileLarge, {
          width: 30px;
          left: 12px;
          top: 8px;
        });
        .respond-to(@bpTablet, {
          width: 35px;
          left: 40px;
        });
        .respond-to(@bpDesktopSmall, {
          width: 32px;
          left: 25px;
          top: 6px;
        });
      }
    }
    .share-icons {
      .share-icon {
        .respond-to(@bpMobileLarge, {
          margin: 20px 10px;
          .share-icon-large;
        });
        .respond-to(@bpDesktopSmall, {
          margin: 15px 0 15px 15px;
          .share-icon-small;
        });
      }
    }
    .share-container {
      text-align: center;
      padding-left: 0;
      h3 {
        .respond-to(@bpDesktopMedium, {
          font-size: 20px;
        });
      }
      .respond-to(@bpMobileLarge, {
        border-top: none;
        padding-top: 0;
      });
      .respond-to(@bpDesktopSmall, {
        border-bottom: none;
        text-align: right;
        padding-top: 30px;
        padding-bottom: 0;
        right: 23px;
      });
      .respond-to(@bpDesktopMedium, {
        margin: 0 auto;
        right: 0;
        padding-right: 0;
        width: 1100px;
      });
      .small-divider {
        display: none;
        .respond-to(@bpMobileLarge, {
          display: inline-block;
          width: 100px;
          border-top: @borderDarkGray;
          margin: 25px 0 35px;
        });
        .respond-to(@bpDesktopSmall, {
          display: none;
        });
      }
    }
    // bar graph styles
    .bar-chart,
    .line-chart {
      width: 260px;
      margin: 0 auto;
      .respond-to(@bpMobileLarge, {
        width: 240px;
      });
      .respond-to(@bpTablet, {
        width: 280px;
      });
      .respond-to(@bpDesktopSmall, {
        width: 245px;
        margin: 0 auto;
      });
      .respond-to(@bpDesktopMedium, {
        width: 278px;
      });
    }
    .line-chart {
      .respond-to(@bpMobileLarge, {
        width: 258px;
      });
      .respond-to(@bpTablet, {
        width: 300px;
      });
    }
    #bar-chart,
    #line-chart {
      margin: 20px 0 0;
      .respond-to(@bpMobileLarge, {
        height: 180px;
      });
      .respond-to(@bpDesktopSmall, {
        margin-top: 0;
      });
    }
    .bar-chart-container,
    .line-chart-container {
      padding-left: 0;
      .respond-to(@bpMobileLarge, {
        border-bottom: none;
      });
      .respond-to(@bpDesktopSmall, {
        border-bottom: @borderDarkGray;
      });
      .section-title {
        padding-left: 15px;
        .respond-to(@bpMobileLarge, {
          padding-left: 40px;
        });
        .respond-to(@bpTablet, {
          padding-left: 80px;
        });
        .respond-to(@bpDesktopSmall, {
          padding-left: 0;
        });
      }
    }
    .line-chart-container {
      .respond-to(@bpTablet, {
        padding-bottom: 60px;
      });
      .line-chart {
        .respond-to(@bpDesktopSmall, {
          padding-left: 0;
          width: 265px;
        });
        .respond-to(@bpDesktopMedium, {
          width: 295px;
          margin: 0 auto;
        });
      }
    }
    .y-index-label {
      display: inline-block;
      font-size: 11px;
      position: absolute;
      margin-left: -35px;
      top: 50%;
      letter-spacing: 1.3px;
      .font-family(medium);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      .respond-to(@bpMobileLarge, {
        font-size: 14px;
        margin-left: -38px;
      });
      .respond-to(@bpTablet, {
        margin-left: -43px;
      });
    }
  }
}
