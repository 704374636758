.profile_my_soul_preferences {
  & .main-container {
    overflow: visible;
  }
}

.profile-mysoul-inner-container {
  margin: 0 auto;
  max-width: 39rem;
  padding: 3rem 2.25rem 6rem;

  .respond-to(@bpMobileLarge, {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  });

  .respond-to(@bpTablet, {
    padding-left: 7.813rem;
    padding-right: 7.813rem;
  });

  .respond-to(@bpDesktopSmall, {
    padding-left: 10.63rem;
    padding-right: 10.63rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding-left: 22.5rem;
    padding-right: 22.5rem;
  });
}

.profile-preferences-modal {
  background: @gray-50;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 101;
}

// MY STUDIOS
.pref__studios-container {
  margin-bottom: 1.875rem;
  position: relative;
  z-index: 3;
}

.pref__studios__header {
  .font-family(roman);
  border-bottom: @borderMediumGray;
  font-size: 0.875rem;
  padding: 0.3125rem 0;
  position: relative;

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.pref__studios__text {
  float: left;
  margin-right: 0.3125rem;
}

.pref__primary__state-container--hidden,
.pref__instructors__state-container--hidden {
  display: none;
}

.pref__studios__initial__subtext {
  font-size: 0.75rem;
  margin-top: 0.6125rem;
}

.pref__initial__select__button {
  .font-family(medium);
  color: @gray-darker;
  cursor: pointer;
  font-size: .857rem;
  margin-top: 1rem;
}

.primary__selected-container {
  margin-top: 1.5rem;
}

.favorite__selected-container {
  margin-top: 3rem;
}

.primary__selected-container--hidden,
.favorite__selected-container--hidden {
  display: none;
}

.primary__selected__title,
.favorite__selected__title {
  .font-family(heavy);
  color: @gray-darker;
  font-size: 1rem;
  margin-top: 1rem;
}

.primary__selected__studio,
.favorite__selected__studio {
  .font-family(heavy);
  color: @gray-38;
  font-size: 1rem;
  margin-top: 0.375rem;
}

.favorite__selected__studio {
  list-style: none;
}

.favorite__selected__studio-item {
  margin-top: 0.375rem;
}

.pref__primarystudio__container,
.pref__favoritestudios__container {
  display: none;
  margin: 1.5rem 0;
}

.pref__favoritestudios__container--hidden {
  display: none;
}

.pref__studios__subheader {
  .font-family(medium);
  color: @gray-darker;
  font-size: 0.875rem;
  margin-top: 0.6125rem;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
  });
}

.pref__studios__subtext {
  font-size: 0.75rem;
  margin-top: 0.6125rem;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
    margin-left: 0.4375rem;
  });
}

.favorite__studios__selected-container {
  .flex-display();
  .flex-wrap(wrap);
  .justify-content(start);
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.favorite__studios__selected {
  .border-radius(0.1875rem);
  background-color: @gray-light;
  border: @borderLightGray;
  display: block;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  padding: 1rem 0.3125rem 0.5rem 0.624rem;

}

.favorite__studios__selected__text {
  .font-family(medium);
  color: @gray-darker;
  display: block;
  float: left;
  font-size: 0.875rem;
}

.favorite__studios__selected__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  float: right;
  height: 1.25rem;
  margin: -0.125rem 0.5rem 0;
  text-align: center;
  width: 1.25rem;
}

.favorite__studios__selected__delete {
  display: inline-block;
  font-size: 1.25rem;
}
// END MY STUDIOS

// STUDIO DROPDOWNS
.pref__dropdown-container {
  display: inline-block;
  margin-top: 0.625rem;
  position: relative;
  width: 100%;
  z-index: 3;
}

.pref__dropdown__primary {
  z-index: 4;
}

.pref__dropdown__arrow-container {
  cursor: pointer;
  float: right;
  height: 1.25rem;
  margin-right: 0.3125rem;
  margin-top: -0.125rem;
  width: 0.3125rem;
}

.pref__dropdown__arrow{
  .transition(all 3s ease-in-out);
  .transform(rotate(0));
  border-bottom: 0;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid @gray-hex97;
  cursor: inherit;
  display: inline-block;
  height: 0;
  width: 0;
}

.pref__dropdown__arrow--up {
  .transform(rotate(180deg));
}

.pref__dropdown {
  .border-radius(.3125rem);
  .font-family(medium);
  background-color: @scWhiteC;
  border: 1px solid @gray-38-bg;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 1.25rem 1rem 0.75rem;
  text-align: left;
  width: 100%;
}

.pref__dropdown__select {
  .box-sizing(border-box);
  background-color: @scWhiteC;
  cursor: inherit;
  display: none;
  position: absolute;
  top: calc(100% - 16px);
  width: 100%;
  z-index: 1;
}

.pref__dropdown__select--open {
  display: block;
}

.pref__dropdown__regions {
  .scrollbar(@gray-darker, 0.5rem,0.1875rem);
  border-left: 1px solid @gray-38-bg;
  border-right: 1px solid @gray-38-bg;
  max-height: 27.19rem;
  overflow-y: scroll;
  padding: 0 1rem 1rem;
}

.pref__dropdown__region__title {
  .font-family(medium);
  font-size: 1.25rem;
  margin: 1.5rem 0;
}

.pref__dropdown__studios {
  margin-bottom: 1rem;
}

.pref__dropdown__studios--hidden {
  display: none;
}

input.pref__dropdown__primary__input,
input.pref__dropdown__favorites__input {
  .appearance(none);
  .border-radius(0.125rem);
  .default-browser-focus;
  border: @borderGrayHex97;
  cursor: pointer;
  height: 1.25rem;
  margin: 0;
  padding: 0;
  position: relative;
  width: 1.25rem;

  &:checked {
    background: none;
    display: inline-block;

    &:after {
      .transform(rotate(45deg));
      content: "";
      border-bottom: 0.125rem solid @black;
      border-left: none;
      border-right: 0.125rem solid @black;
      border-top: none;
      display: block;
      height: 0.625rem;
      left: 0.375rem;
      position: absolute;
      top: 0.0625rem;
      width: 0.25rem;
    }
  }
}

label.pref__dropdown__primary__label,
label.pref__dropdown__favorites__label {
  .font-family(medium);
  color: @gray-hex97;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 0.3125rem;
  position: relative;
  top: -0.3125rem;
}

.pref__dropdown__primary__buttons,
.pref__dropdown__favorite__buttons {
  .box-sizing(border-box);
  border-bottom: 1px solid @gray-38-bg;
  border-left: 1px solid @gray-38-bg;
  border-right: 1px solid @gray-38-bg;
  padding: 0.625rem 1.875rem 0.9375rem;
}

.pref__dropdown__primary--submit,
.pref__dropdown__favorite--submit {
  .border-radius(0.3125rem);
  background-color: @scWhiteC;
  border: 1px solid @black;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  padding: 0.9375rem;
  width: 100%;
}

.pref__dropdown__primary--clear,
.pref__dropdown__favorite--clear {
  .font-family(light);
  border: none;
  cursor: pointer;
  display: block;
  font-size: 0.8125rem;
  margin: 1rem auto;
}
// END STUDIO DROPDOWNS

// MY INSTRUCTORS
.pref__instructors-container {
  margin-top: 2.5rem;
  position: relative;
}

.pref__instructors__header {
  .font-family(roman);
  border-bottom: @borderMediumGray;
  font-size: 0.875rem;
  padding: 0.3125rem 0;
  position: relative;

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.pref__instructors__text {
  float: left;
  margin-right: 0.3125rem;
}

.pref__instructors__state-container--hidden {
  display: none;
}

.pref__instructors__subtext {
  font-size: 0.75rem;
  margin-top: 0.6125rem;
}

.pref__favorites__list {
  .flex-display();
  .flex-wrap(wrap);
  .justify-content(start);
  list-style: none;
}

.pref__favorites__item {
  margin-top: 2rem;
  width: 50%;

  &:last-child {
    display: none;
  }

  &:nth-child(2n) {
    text-align: right;
  }

  .respond-to(@bpMobileMedium, {
    width: 33.333%;

    &:nth-child(2n) {
      text-align: left;
    }

    &:nth-child(4n+1) {
      text-align: left;
    }

    &:nth-child(3n+2) {
      text-align: center;
    }

    &:nth-child(3n) {
      text-align: right;
    }
  });

  .respond-to(@bpTablet, {
    width: 22%;

    &:nth-child(3n),
    &:nth-child(3n+2) {
      text-align: left;
    }

    &:nth-child(4n+2),
    &:nth-child(4n+3) {
      text-align: center;
      width: 28%
    }

    &:nth-child(4n) {
      text-align: right;
      width: 22%;
    }
  });

  & .pref__favorite__heart {
    display: none;
  }
}

.pref__favorites__item__link {
  border: none;
  text-decoration: underline;
}

.pref__favorite__instructor-container {
  display: inline-block;
  position: relative;
  text-align: center;
}

.pref__favorite__instructor {
  .border-radius(50%);
  background-color: @gray-light;
  display: inline-block;
  height: 7.5rem;
  overflow: hidden;
  position: relative;
  width: 7.5rem;

  &:hover {
    opacity: 0.8;
  }
}

.pref__favorite__image {
  height: 10rem;
  position: absolute;
  right: -2.8125rem;
  top: 0;
}

.pref__favorite__name {
  .font-family(heavy);
  color: @gray-darker;
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
}

.pref__favorites__item__add-container {
  .default-browser-focus;
  background-color: @scWhiteC;
  border: 0;
  cursor: pointer;
  text-align: center;
}

.pref__favorites__add {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pref__favorite__remove-button {
  .border-radius(50%);
  .box-shadow(0 0.125rem 0.3125rem 0 @gray-light);
  .default-browser-focus;
  background-color: @scWhiteC;
  border: 0;
  cursor: pointer;
  display: none;
  height: 1.875rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.875rem;
  z-index: 1;
}

.pref__favorite__remove-icon {
  .transform(translate(-50%,-50%));
  left: 50%;
  position: absolute;
  top: 50%;
}
// END MY INSTRUCTORS


// CONTROLS
.pref__controls-container {
  margin: 3rem auto;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    .align-items(center);
    .flex-direction(column);
    .flex-display(flex);
    .justify-content(space-between);
  });

  &.pref__controls-top-container {
    .align-items(center);
    .flex-direction(row-reverse);
    .flex-display(flex);
    .justify-content(space-between);
  }

  .pref__email-container {
    border-bottom: 1px solid @gray-tiu;
    border-top: 1px solid @gray-tiu;
    margin-bottom: 32px;
    padding: 32px 0;
    width: 100%;
  }

  .pref__edit-actions-container {
    .align-items(center);
    .flex-direction(column);
    .flex-display(flex);

    .respond-to(@bpDesktopSmall, {
      .flex-direction(row-reverse);
      .justify-content(space-between);
      width: 100%;
    });
  }
}

.pref__edit {
  .border-radius(0.25rem);
  .default-browser-focus;
  background-color: @scWhiteC;
  border-color: @gray-darker;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 0.3125rem 0;
  width: 6.25rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });
}

.pref__edit-container {
  display: table;
  width: 100%;
}

.pref__edit__text,
.pref__edit__icon-container {
  display: table-cell;
  font-size: 0.75rem;
  vertical-align: middle;
  width: 50%;
}

.pref__edit__text {
  text-align: right;
}
.pref__edit__icon-container {
  text-align: left;
}

.pref__edit__icon {
  margin-left: 0.3125rem;
}

.pref__handle-container {
  display: none;
  text-align: center;
}

.pref__cancel,
.pref__save {
  .border-radius(0.25rem);
  .default-browser-focus;
  .font-family(medium);
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  margin: 0 auto;
  padding: 0.75rem 0 0.4375rem;
  width: 8.75rem;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
  });
}

.pref__cancel {
  .default-browser-focus;
  .font-family(medium);
  background-color: @scWhiteC;
  border: none;
  color: @gray-darker;
  cursor: pointer;
  font-size: 0.875rem;
  margin-right: 24px;
  width: auto;

  &:hover {
    color: @black;
    text-decoration: none;
  }
}

.pref__save {
  background-color: @scYellowC;

  .pref__edit-action-contents {
    .align-items(center);
    .flex-display(flex);
    .justify-content(center);

    .pref__edit__text,
    .pref__edit__icon-container {
      text-align: initial;
      width: auto;
    }
  }
}

.pref__email-container {
  margin-top: 2rem;
}

.pref__email-link {
  .font-family(medium);
  .right-arrow;
  font-size: 0.875rem;
}

.pref__delete-account {
  .default-browser-focus;
  .font-family(light);
  .default-browser-focus;
  background-color: @scWhiteC;
  border: none;
  color: @gray-darker;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 2rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });

  &:hover {
    color: @black;
    text-decoration: none;
  }
}
// END CONTROLS CONTAINER
.modal__delete-container {
  display: none;
}

.modal__delete-container--active {
  display: block;
}

.modal__delete {
  .border-radius(0.25rem;);
  .box-sizing(border-box);
  .transition(all 0.2s ease-in-out);
  background-color: @scWhiteC;
  border: @borderLightGray;
  display: inline-block;
  left: 0;
  margin: 0 auto;
  padding: 2.5rem 1.125rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 30%;
  width: 18.75rem;

  .respond-to(@bpTablet, {
    padding: 2.5rem 3.75rem;
    width: 25rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding: 5rem 10rem;
    width: 38rem;
  });
}

.modal__delete__title {
  .font-family(bold);
  display: block;
  font-size: 1.6em;
  margin-bottom: 1.25rem;
}

.modal__delete__message {
  color: @gray-darker;
  font-size: 1.3em;
  line-height: 1.6;
  margin: 0 0 1.25rem;
}

.modal__delete__cancel,
.modal__delete__submit {
  .border-radius(0.1875rem);
  .default-browser-focus;
  .font-family(medium);
  border: none;
  color: @gray-darker;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.875rem;
  margin-top: 0.8125rem;
  padding: 0.1875rem;
  position: relative;
  text-align: center;
  width: 7.813rem;
}

.modal__delete__cancel {
  background-color: @gray-light;

  .respond-to(@bpTablet, {
    margin-right: 0.75rem;
  });
}

.modal__delete__submit {
  background-color: @scYellowC;
}
// DELETE ACCOUNT MODAL

// TOOLTIPS
.pref__tooltip-container {
  display: none;

  .respond-to(@bpDesktopSmall, {
    display: block;
    margin-top: -0.3125rem;
    position: relative;
  });
}

.pref__tooltip-container--hidden {
  display: none;
}

.pref__tip {
  .border-radius(50%);
  background-color: @gray-38-bg;
  border: none;
  cursor: pointer;
  display: block;
  height: 1.25rem;
  margin: 0;
  padding: 0;
  position: relative;
  width: 1.25rem;

  &:hover {
    .pref__tooltip {
      display: block;
    }
  }
}

.pref__tip-image {
  .transform(translate(-50%, -50%));
  left: 50%;
  position: absolute;
  top: 50%;
}

.pref__tooltip {
  .transform(translateX(-50%));
  .font-family(medium);
  background-color: @gray-38-bg;
  color: @scWhiteC;
  display: none;
  font-size: 0.75rem;
  padding: 0.75rem 0.875rem 0.3125rem;
  position: absolute;
  text-align: center;
  top: -2.5rem;
  white-space: nowrap;

  &:after {
    .transform(translateX(0.125rem));
    content: '';
    border-bottom: none;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid @gray-38-bg;
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: 1.8125rem;
    width: 0;
  }
}
// END TOOLTIPS

// PREFERENCES EDIT MODE
.edit-mode {
  & .pref__studios__initial__subtext,
  & .pref__initial__select__button,
  & .pref__instructors__subtext,
  & .primary__selected-container,
  & .favorite__selected-container  {
    display: none;
  }

  & .pref__primarystudio__container {
    display: block;
  }

  & .pref__favoritestudios__container {
    display: block;
    &.pref__favoritestudios__container--hidden {
      display: none;
    }
  }

  & .pref__favorite__remove-button {
    display: block;
  }

  & .pref__favorites__item:last-child {
    display: block;
  }

  & .pref__favorites__item__add-container {
    display: inline-block;
  }

  & .pref__edit {
    display: none;
  }

  & .pref__handle-container {
    display: block;
  }

  & .pref__instructors__subtext {
    display: none;
  }
}
