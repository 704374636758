.promotion-offer-container {
  background-color: @gray-light;
  box-sizing: border-box;
  color: @black;
  min-width: 300px;
  padding: 16px 16px 32px;

  .respond-to(@bpDesktopSmall, {
    height: calc(~"100vh" - (@footerHeightDesktop + @headerHeight));
    min-height: 500px;
    padding: 0 30px 50px;
    white-space: nowrap;
  });

  .respond-to(@bpDesktopLarge, {
    min-height: 650px;
  });

  .promotion-offer-section {
    position: relative;
    white-space: normal;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      display: inline-block;
      height: 100%;
      vertical-align: top;
      width: 55%;

      &.promotion-offer-section-offer {
        width: 45%;
      }
    });

    .respond-to(@bpDesktopLarge, {
      width: 50%;

      &.promotion-offer-section-offer {
        width: 50%;
      }
    });
  }

  .promotion-offer-image {
    display: block;
    height: auto;
    margin: 0 auto 16px;
    max-width: 586px;
    width: 100%;

    .respond-to(@bpTablet, {
      margin: 0 auto 24px;
    });

    .respond-to(@bpDesktopSmall, {
      height: auto;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    });

    .respond-to(@bpDesktopLarge, {
        max-width: 690px;
    });

  }

  .promotion-offer-details {
    margin: 0 auto;

    .respond-to(@bpTablet, {
      margin-top: 0;
      min-width: 346px;
    });

    .respond-to(@bpDesktopSmall, {
      left: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    });

    .offer-details-container {
      margin: 0 auto;
      max-width: 385px;
      width: auto;

      .respond-to(@bpDesktopSmall, {
        margin: 0;
        max-width: none;
        width: 385px;
      });

      .respond-to(@bpDesktopLarge, {
        width: 555px;
      });


      .offer-box {
        background: @scWhiteC;
        padding: 32px 24px;

        .respond-to(@bpDesktopLarge, {
          padding: 56px;
        });

        .offer {
          h1 {
            .font-family(heavy);
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 16px;

            .respond-to(@bpDesktopLarge, {
              font-size: 40px;
              line-height: 48px;
            });
          }

          h3 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 32px;

            .respond-to(@bpDesktopLarge, {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 48px;
            });
          }

          .product-title {
            .font-family(bold);
            font-size: 24px;
            margin-bottom: 16px;

            .respond-to(@bpDesktopLarge, {
              font-size: 28px;
            });
          }

          .checkout {
            .font-family(medium);
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 24px;
            padding: 13px;
          }

          .disclaimer {
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .bottom-info {
        margin-top: 32px;
        width: 100%;

        .respond-to(@bpDesktopSmall, {
          position: absolute;
        });

        .reject-offer {
          margin: 0;
          text-align: center;

          a {
            border-bottom: 1px solid @black;
            font-size: 20px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
