.corporate_packages{
  .corporate-packages-container{
    .intl-tel-input{
      .flag-container{
        top: 0;
      }
    }
  }
}

.checkout_landing {
  .form-signup {
    .intl-tel-input {
      width: 100%;
      .flag-container {
        top: 0;
      }
    }
  }
}

.riderreferral_landing {
  .block-form-join-now {
    .intl-tel-input {
      .flag-container {
        top: 0;
      }
    }
  }
}

.intl-tel-input {
  .country-list {
    z-index: 15;
  }
}
