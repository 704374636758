.stream-signin-error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 2.5rem auto 20rem auto;
    padding: 1.5rem;

    .respond-to(@bpDesktopSmall, {
        width: 54rem;
    });

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    p {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .stream-btn-container {
        display: flex;
        flex-direction: column;
        
        .respond-to(@bpTablet, {
            flex-direction: row;
        });

        a {
            font-size: 0.875rem;
            height: 3rem;
            margin: 1rem 0.3rem 1rem 0.3rem;
            width: 15rem;
        }

        a.ghost-button {
            padding: 1rem;
        }

        a.yellow-button {
            padding: 0.65rem;
        }
    }
}
