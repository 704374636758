/*doc
---
title: SC Icons
name: sc_icons
category: icons
---

You can drop the class into the html.
You can also add the icon class directly to the css file.

```html_example
<span class="icon-tote-small bg-icons"></span>
<div class="mobile-nav-icon icon-hamburger"></div>

```

```css_example
.bookmarked {
  .bookmark {
    .icon-wheel-yellow;
  }
}
```

*/

// main sprite
.soul-con{
  position: absolute;
  background-image: url("/assets/images/spritesheet.svg");
  background-repeat: no-repeat;
  background-size: 17.5em 16.125em;
  display: inline-block;
  .no-svg & {
    background-image: url("/assets/images/spritesheet.png");
  }
}

//new bookmark icons
.bookmark {
  cursor: pointer;
  height: 23px;
  position: absolute;
  right: 115px;
  top: -1px;
  width: 17px;
}

// main sprite positioning
.icon-mastercard{
  background-position: -0.625em -0.625em;
  width: 4em;
  height: 2.5em;
}
.icon-visa{
  background-position: -0.625em -3.75em;
  width: 3.9375em;
  height: 2.5em;
}
.icon-wheel-yellow-footer{
  background-position: -9.875em -0.625em;
  width: 3.125em;
  height: 3.125em;
}
.icon-wheel-buy-series{
  background-position: -0.625em -6.875em;
  width: 2.125em;
  height: 3.0625em;
}
.icon-check-yellow-big{
  background-position: -5.1875em -3.75em;
  width: 2.875em;
  height: 2.1875em;
}
.icon-fan{
  background-position: -9.875em -4.375em;
  width: 2.75em;
  height: 1.5em;
}
.icon-wheel-grey{
  background-position: -3.375em -6.875em;
  width: 2.5625em;
  height: 2.5625em;
}
.icon-wheel-yellow{
  background-position: -6.5625em -6.875em;
  width: 2.5em;
  height: 2.5em;
}
.icon-pin-large{
  background-position: -9.6875em -6.875em;
  width: 1.9375em;
  height: 2.375em;
}
.icon-x-white-large{
  background-position: -0.625em -10.5625em;
  width: 1.75em;
  height: 1.8125em;
}
.icon-arrow-left-white-large{
  background-position: -3em -10.5625em;
  width: 1.0625em;
  height: 1.8125em;
}
.icon-insta-grey-large{
  background-position: -4.6875em -10.5625em;
  width: 1.75em;
  height: 1.75em;
}
.icon-twitter-circle{
  background-position: -7.0625em -10.5625em;
  width: 1.75em;
  height: 1.6875em;
}
.icon-pin{
  background-position: -9.4375em -10.5625em;
  width: 1.3125em;
  height: 1.625em;
}
.icon-tote-small{
  background-position: -11.375em -10.5625em;
  width: 1.5625em;
  height: 1.5625em;
}
.icon-info-dark{
  background-position: -13.625em -0.625em;
  width: 1.5em;
  height: 1.5625em;
}
.icon-info-light{
  background-position: -13.625em -2.8125em;
  width: 1.5em;
  height: 1.5625em;
}
.icon-hamburger{
  background-position: -0.625em -13em;
  width: 1.5625em;
  height: 1.1875em;
}
.icon-search-dark{
  background-position: -13.625em -5em;
  width: 1.3125em;
  height: 1.3125em;
}
.icon-spotify{
  background-position: -13.625em -6.9375em;
  width: 1.3125em;
  height: 1.3125em;
}
.icon-search-light{
  background-position: -13.625em -8.875em;
  width: 1.3125em;
  height: 1.3125em;
}
.icon-google-plus{
  background-position: -13.625em -10.8125em;
  width: 1.3125em;
  height: 1.1875em;
}
.icon-twitter-dark{
  background-position: -2.8125em -13em;
  width: 1.3125em;
  height: 1em;
}
.icon-insta-dark-small{
  background-position: -15.75em -0.625em;
  width: 1.125em;
  height: 1.1875em;
}
.icon-insta-white-small{
  background-position: -15.75em -2.4375em;
  width: 1.125em;
  height: 1.125em;
}
.icon-alert{
  background-position: -15.75em -4.1875em;
  width: 1.125em;
  height: 1.125em;
}
.icon-pinterest{
  background-position: -15.75em -5.9375em;
  width: 0.9375em;
  height: 1.125em;
}
.icon-facebook-dark{
  background-position: -15.75em -7.6875em;
  width: 0.625em;
  height: 1.125em;
}
.icon-move{
  background-position: -15.75em -9.4375em;
  width: 0.5em;
  height: 1.125em;
}
.icon-check-dark-small{
  background-position: -15.75em -11.1875em;
  width: 1em;
  height: 0.75em;
}
.icon-x-light-small{
  background-position: -15.75em -12.5625em;
  width: 0.9375em;
  height: 0.9375em;
}
.icon-x-yellow-small{
  background-position: -4.75em -13em;
  width: 0.9375em;
  height: 0.9375em;
}
.icon-twitter-light{
  background-position: -6.3125em -13em;
  width: 0.9375em;
  height: 0.875em;
}
.icon-heart-off-small{
  background-position: -7.875em -13em;
  width: 0.9375em;
  height: 0.8125em;
}
.icon-check-yellow-small{
  background-position: -9.4375em -13em;
  width: 0.9375em;
  height: 0.75em;
}
.icon-heart-on-small{
  background-position: -11em -13em;
  width: 0.9375em;
  height: 0.75em;
}
.icon-arrow-right-dark-small{
  background-position: -12.25em -6.875em;
  width: 0.625em;
  height: 0.9375em;
}
.icon-arrow-down-light-small{
  background-position: -12.5625em -13em;
  width: 0.9375em;
  height: 0.5625em;
}

.icon-arrow-down-dark-small {
  background-position: -12.25em -6.875em;
  width: 0.625em;
  height: 0.9375em;
}

.icon-email{
  background-position: -0.625em -14.8125em;
  width: 0.875em;
  height: 0.6875em;
}
.icon-facebook-light{
  background-position: -8.6875em -3.75em;
  width: 0.4375em;
  height: 0.875em;
}
.icon-carat-yellow-small{
  background-position: -8.6875em -5.25em;
  width: 0.5625em;
  height: 0.375em;
}

// main sprite - additional styles
.icon-wheel-yellow-footer {
  .soul-con;
  color: @sc-yellow;
  font-size: 1.3em;
}
.icon-check-yellow-big {
  .soul-con;
  color: @sc-yellow;
  font-size: .7em;
}
.icon-wheel-buy-series{
  .soul-con;
  color: #eee;
  font-size: 1.4em;
}
.icon-wheel-yellow {
  .soul-con;
  color: @sc-yellow;
  font-size: 1.3em;
  top: -1px;
  left: -1px;
}
.icon-wheel-grey {
  .soul-con;
  color: #eee;
  font-size: 1.3em;
  top: -1px;
  left: -1px;
}
.icon-tote-small {
  .soul-con;
  color: #ccc;
  font-size: 0.4em;
}
.icon-check-dark-small {
  .soul-con;
  color: #333;
  font-size: 0.4em;
}
.icon-x-yellow-small {
  .soul-con;
  color: @sc-yellow;
  font-size: .4em;
}
.icon-x-light-small {
  .soul-con;
  color: #ccc;
  font-size: 1.1em;
}
.icon-x-white-large {
  .soul-con;
  color: #fff;
  font-size: .6em;
}
.icon-info-light {
  .soul-con;
  color: #ccc;
  font-size: 1.4em;
}
.icon-hamburger {
  .soul-con;
  color: #ccc;
  font-size: 1.7em;
}
.icon-search-light {
  .soul-con;
  color: #ccc;
  font-size: 1.7em;
}
.icon-heart-off-small {
  .soul-con;
  color: @gray-darker;
  font-size: 2.2em;
  margin: 20px;
}
.icon-heart-on-small {
  .soul-con;
  color: @sc-yellow;
  font-size: 2.2em;
  margin: 20px;
}
.icon-arrow-right-dark-small {
  .soul-con;
  color: #333;
  font-size: 1em;
}
.icon-arrow-left-dark-small {
  .icon-arrow-right-dark-small;
  .flip-horizontally;
  font-size: 1em;
}
.icon-arrow-down-light-small {
  .soul-con;
  color: #ccc;
  font-size: .85em;
}

.icon-arrow-down-dark-small {
  .soul-con;
  font-size: .85em;
  .rotate(90deg);
}

.icon-arrow-left-light-small {
  .icon-arrow-down-light-small;
  .rotate(90deg);
}
.icon-carat-yellow-small {
  .soul-con;
  color: @sc-yellow;
  font-size: 2em;
  margin: 20px;
}

// share icon (hand drawn versions)
.share-icons {
  .share-icon-link {
    display: inline-block;
    height: 40px;
    margin: 0 10px 0 0;
    overflow: hidden;
    position: relative;
    width: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    .share-icon-small,
    .share-icon{
      left: 0;
      position: absolute;
      z-index: 1;
    }

    .share-icon-title {
      .vertical-align();
      display: inline-block;
      font-size: 9px;
      text-align: center;
    }
  }

  &.bottom-article {
    .share-icon-link {
      height: 60px;
      width: 60px;

      .share-icon-title {
        font-size: 10px;
        margin: 0 0 0 5px;

        .respond-to(@bpDesktopSmall, {
          font-size: 9px;
          margin: 0;
        });
      }

      .respond-to(@bpDesktopSmall, {
        height: 40px;
        width: 40px;
      });
    }
  }
}

.follow-us-container,
.scroll-share-btns {
  .share-icons {
    .share-icon-link {
      height: 60px;
      width: 60px;

      .share-icon-title {
        .vertical-align();
        font-size: 10px;
        margin: 0 0 0 5px;
      }
    }
  }
}

.scroll-share-btns {
  .share-icons {
    .share-icon-link {
      display: block;
    }
  }
}

.share-icon,
.share-icon-medium {
  display: inline-block;
  margin: 0px 10px 0px 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url("/assets/images/share-sprite.png");
  background-size: 553px 160px;
  background-repeat: no-repeat;
  &.share-fb {
    background-position: -3px -2px;
    &:hover {
      background-position: -3px -55px;
    }
  }
  &.share-twitter {
    background-position: -73px -2px;
    &:hover {
      background-position: -73px -55px;
    }
  }
  &.share-url.not-copied {
    background-position: -213px -1px;
    &:hover {
      background-position: -213px -54px;
    }
  }
  &.share-url.copy-background {
    background-position: -213px -107px;
  }
  &.share-email {
    background-position: -143px -1px;
    &:hover {
      background-position: -143px -54px;
    }
  }
  &.share-instagram {
    background-position: -491px -2px;
    &:hover {
      background-position: -491px -57px;
    }
  }
  &.share-spotify {
    background-position: -422px -2px;
    &:hover {
      background-position: -422px -56px;
    }
  }
}
.share-icon-small {
  display: inline-block;
  margin: 0px 10px 0px 0;
  background: url("/assets/images/share-sprite.png");
  background-repeat: no-repeat;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-size: 452px 131px;
  &.share-fb {
    background-position: -3px -2px;
    &:hover {
      background-position: -3px -45px;
    }
  }
  &.share-twitter {
    background-position: -60px -2px;
    &:hover {
      background-position: -60px -45px;
    }
  }
  &.share-url.not-copied {
    background-position: -175px -2px;
    &:hover {
      background-position: -175px -45px;
    }
  }
  &.share-url.copy-background {
    background-position: -175px -88px;
  }
  &.share-email {
    background-position: -117px -2px;
    &:hover {
      background-position: -117px -45px;
    }
  }
  &.share-instagram {
    background-position: -402px -2px;
    &:hover {
      background-position: -402px -47px;
    }
  }
  &.share-spotify {
    background-position: -345px -2px;
    &:hover {
      background-position: -345px -46px;
    }
  }
}
.share-icon-large {
  display: inline-block;
  background: url("/assets/images/share-sprite.png");
  background-size: 604px 175px;
  background-repeat: no-repeat;
  margin: 0px 10px 0px 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  &.share-fb {
    background-position: 0px 0px;
    &:hover {
      background-position: 0px -58px;
    }
  }
  &.share-twitter {
    background-position: -78px 0px;
    &:hover {
      background-position: -78px -58px;
    }
  }
  &.share-url.not-copied {
    background-position: -231px 0px;
    &:hover {
      background-position: -231px -58px;
    }
  }
  &.share-url.copy-background {
    background-position: -231px -116px;
  }
  &.share-email {
    background-position: -153px 0;
    &:hover {
      background-position: -153px -58px;
    }
  }
  &.share-instagram {
    background-position: -534px 1px;
    &:hover {
      background-position: -534px -59px;
    }
  }
  &.share-spotify {
    background-position: -455px 0;
    &:hover {
      background-position: -455px -59px;
    }
  }
}

//Handwritten alphabet sprites
.sc-alphabet {
  background-image: url('/assets/images/schandwriting-sprite-small.png');
  background-repeat: no-repeat;
  .respond-to(@bpDesktopSmall, {
    background-image: url('/assets/images/schandwriting-sprite-large.png');
    background-repeat: no-repeat;
  });

  /* combine these classes with the larger sprite classes you created below */
  &.sc-A, &.sc-a {
    .stitches-sprite(-5px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-5px, -5px, 95px, 95px);
    });
  }

  &.sc-B, &.sc-b {
    .stitches-sprite(-75px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-110px, -5px, 95px, 95px);
    });
  }

  &.sc-C, &.sc-c {
    .stitches-sprite(-145px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-215px, -5px, 95px, 95px);
    });
  }

  &.sc-D, &.sc-d {
    .stitches-sprite(-215px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-320px, -5px, 95px, 95px);
    });
  }

  &.sc-E, &.sc-e {
    .stitches-sprite(-285px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-425px, -5px, 95px, 95px);
    });
  }

  &.sc-F, &.sc-f {
    .stitches-sprite(-5px, -75px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-5px, -110px, 95px, 95px);
    });
  }

  &.sc-G, &.sc-g {
    .stitches-sprite(-75px, -75px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-110px, -110px, 95px, 95px);
    });
  }

  &.sc-H, &.sc-h {
    .stitches-sprite(-145px, -75px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-215px, -110px, 95px, 95px);
    });
  }

  &.sc-I, &.sc-i {
    .stitches-sprite(-215px, -75px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-320px, -110px, 95px, 95px);
    });
  }

  &.sc-J, &.sc-j {
    .stitches-sprite(-285px, -75px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-425px, -110px, 95px, 95px);
    });
  }

  &.sc-K, &.sc-k {
    .stitches-sprite(-5px, -145px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-5px, -215px, 95px, 95px);
    });
  }

  &.sc-L, &.sc-l {
    .stitches-sprite(-75px, -145px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-110px, -215px, 95px, 95px);
    });
  }

  &.sc-M, &.sc-m {
    .stitches-sprite(-145px, -145px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-215px, -215px, 95px, 95px);
    });
  }

  &.sc-N, &.sc-n {
    .stitches-sprite(-215px, -145px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-320px, -215px, 95px, 95px);
    });
  }

  &.sc-O, &.sc-o {
    .stitches-sprite(-285px, -145px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-425px, -215px, 95px, 95px);
    });
  }

  &.sc-P, &.sc-p {
    .stitches-sprite(-5px, -215px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-5px, -320px, 95px, 95px);
    });
  }

  &.sc-Q, &.sc-q {
    .stitches-sprite(-75px, -215px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-110px, -320px, 95px, 95px);
    });
  }

  &.sc-R, &.sc-r {
    .stitches-sprite(-145px, -215px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-215px, -320px, 95px, 95px);
    });
  }

  &.sc-S, &.sc-s {
    .stitches-sprite(-215px, -215px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-320px, -320px, 95px, 95px);
    });
  }

  &.sc-T, &.sc-t {
    .stitches-sprite(-285px, -215px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-425px, -320px, 95px, 95px);
    });
  }

  &.sc-U, &.sc-u {
    .stitches-sprite(-5px, -285px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-5px, -425px, 95px, 95px);
    });
  }

  &.sc-V, &.sc-v {
    .stitches-sprite(-75px, -285px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-110px, -425px, 95px, 95px);
    });
  }

  &.sc-W, &.sc-w {
    .stitches-sprite(-145px, -285px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-215px, -425px, 95px, 95px);
    });
  }

  &.sc-X, &.sc-x {
    .stitches-sprite(-215px, -285px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-320px, -425px, 95px, 95px);
    });
  }

  &.sc-Y, &.sc-y {
    .stitches-sprite(-285px, -285px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-425px, -425px, 95px, 95px);
    });
  }

  &.sc-Z, &.sc-z {
    .stitches-sprite(-355px, -5px, 60px, 60px);
    .respond-to(@bpDesktopSmall, {
      .stitches-sprite-large(-530px, -5px, 95px, 95px);
    });
  }
}

// hand drawn country flags
.country-flags {
  display: inline-block;
  object-fit: cover;

  &::before {
    color: @gray-medium;
    content: attr(alt);
    display: inline-block;
    font-size: 12px;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 48px;
  }

  &:hover {
    color: @gray-darker;
  }
}
.flag-ca {
  height: 28px;
  object-position: -10px 1px;
  width: 35px;
}
.flag-us {
  height: 20px;
  object-position: -36px 0;
  width: 35px;
}
