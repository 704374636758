.ponce_city_market__banner {
  .flex-display(flex);
  color: @white;
  flex-direction: column;
  justify-content: space-between;

  &-background {
    background: @black;
    padding-bottom: 2.75rem;
    
    .respond-to(@bpTablet, {
      padding-bottom: 3.75rem;
    });
  }

  .banner__content {
    margin: 1.4rem 5% 0;
    transition: height 1s ease;

    .respond-to(@bpTablet, {
      margin: 5.5rem 13.5% 0;
      width: 37.5rem;
    });

    .respond-to(@bpDesktopLarge, {
      margin: 4.6rem 11% 0 15.5%; 
      width: auto;
    });
  }

  .banner__title {
    .font-family(bold);
    padding-bottom: 0.875rem;

    h1 {
      font-size: 3rem;
      line-height: 64px;

      .respond-to(@bpTablet, {
        font-size: 4rem;
      });

      .respond-to(@bpDesktopLarge, {
        font-size: 8rem;
        line-height: 8.25rem;
        letter-spacing: -1px;
      });
    }

    .yellow {
      color: @sc-yellow;
    }
  }
  .banner__date,
  .banner__address {
    font-size: 1rem;
    letter-spacing: 1.57px;
    line-height: 1.25rem;
    padding: 0.75rem 0;
    text-transform: uppercase;

    .respond-to(@bpTablet, {
      line-height: 0; 
      left: 0; 
      padding: 1.25rem 0;
    });
  }
  .banner__address {
    letter-spacing: 0;
    .respond-to(@bpTablet, {
      font-size: 1.5rem;
    });
  }
}
