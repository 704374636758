/* GLOBAL - ALL PAGES SHARE THESE COMMON CLASSES
-----------------------------------------------------------------------------*/
.visually-hidden {
  // use this when you want to visually hide something but still want it in the dom
  // and in the visual spot you place it
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // use this when you want a focusable visually-hidden element to appear
  &.focusable:focus{
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// element specific
&.skip-content {
  background: #fff;
  clip: auto;
  height: auto;
  left: 0;
  padding: 15px;
  top: 7px;
  transition-duration: 0.2s;
  transform: translateX(-100%);
  width: auto;

  &.focusable:focus {
    position: absolute;
    transform: translateX(0);
    z-index: 2000;
  }
}

.skip-content {
  .font-family(medium);
  font-size: 0.875rem;
}

.main-container {
  outline: none;
  overflow: hidden;
  position: relative;
  clear: both;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  top: 59px;
}

.adjust-main-content {
  top: 0;
}

.soul-container {
  width: 100%;
  height: 100%;
  -webkit-transition: margin 0.3s ease-in-out;
  -moz-transition: margin 0.3s ease-in-out;
  transition: margin 0.3s ease-in-out;

  &.auth-open {

    #sc_content{
      -webkit-transition: padding 0.2s;
      -moz-transition: padding 0.2s;
      transition: padding 0.2s;
    }

    #soul-modal {
      margin-top: 217px;
    }

    .authentication {
      visibility: visible;
      height: 100%;
      overflow-y: scroll;
      .auth-content-container {
        opacity: 1;
      }
    }

    .global-header, .modal-white-bg {
      top: 150px;
    }

    .main-container {
      top: 330px;
    }

    .global-header {
      position: fixed !important;
    }

    .fixed-secondary-nav {
      position: fixed;
      top: 330px;
    }
  }

  &.confirmation-open {
    #soul-modal {
      margin-top: 112px;
    }
  }
}

#sc_content{
  display:block;

  -webkit-transition: padding 0.2s;
  -moz-transition: padding 0.2s;
  transition: padding 0.2s;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

.page_main_content{
  padding-top:20px;
  position:relative
}

#soul-modal {
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  display: none;
  margin-top: 62px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.throbber {
  position: absolute;
  top:20px;
  left:20px;
  font-size:60px;
  color:#000000;
  z-index: 9999;
  display:none;
}

.modal-white-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  top: 0;
}

.white-overlay,
.white-overlay-opaque {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 60;
  opacity: 0;
  display: none;
}

.white-overlay-opaque {
  background-color: rgba(255, 255, 255, 1);
  z-index: 100;
  overflow:scroll;
}


/////////////////////////////////////////
// SC Icon Sprite Background Positions //
/////////////////////////////////////////


.arrow-down-grey {
  .icon-arrow-down-light-small;
  position: absolute;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 2px;
  margin-left: 25px;

  &.up {
    .transform(rotate(180deg));
    height: 0.08em;
    width: 0.30em;
  }
}
.carat-yellow {
  .icon-carat-yellow-small;
}
.search-icon {
  .icon-search-light;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.swiper-prev-icon,
.swiper-next-icon,
.swiper-prev-icon-dark,
.swiper-next-icon-dark {
  background-size: 11px 17px;
  width: 11px;
  height: 17px;
  background-repeat: no-repeat;
}
.swiper-prev-icon {
  background-image: url('/assets/images/swiper-prev.svg');
}
.swiper-next-icon {
  background-image: url('/assets/images/swiper-next.svg');
}
.swiper-prev-icon-dark {
  background-image: url('/assets/images/swiper-prev-dark.png');
}
.swiper-next-icon-dark {
  background-image: url('/assets/images/swiper-next-dark.png');
}

/* START: Subnav Styles */
.subnav-dropdown {
  width: 100%;
  background-color: @gray-main;
  position: fixed;
  top: 60px;
  z-index: 100;
  font-size: 1.8em;
  .font-family(medium);
  .subnav-title {
    background-color: @gray-main;
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 10;
    padding: 27px 0 24px 0;
    text-align: center;
    border-bottom: 1px solid @gray-light;
  }
  .arrow-down-grey {
    .icon-arrow-down-light-small;
    display: block;
    margin: 10px auto 0 auto;
    padding: 0;
    position: relative;
    .transition(transform, 400ms);
  }
  .subnav-filters-container {
    background-color: @gray-main;
    overflow: hidden;
    position: absolute;
    top: 100%;
    transition: all 1s;
    .transition(transform, 400ms);
    .transform(translate(0, -100%));
    width: 100%;
  }
  &.subnav-open {
    .arrow-down-grey {
      .transform(rotate(-180deg));
    }
    .subnav-filters-container {
      .transform(translate(0, 0));
    }
  }
  .subnav-filters {
    font-size: .8em;
    line-height: 24px;
    padding: 20px 0;

    li {
      padding: 0 15px 15px;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        color: @gray-54;

        &.active {
          color: @gray-87;
        }

        &:hover {
          color: @gray-87;
        }
      }
    }
  }
}
.auth-open {
  .subnav-dropdown {
    position: relative;
    top: 0;
    margin-bottom: -71px;
    top: 25px;
  }
  .subnav-filters-container {
    display: none;
    background-color: @gray-main;
    position: relative;
    z-index: 10;
  }
  .subnav-filters {
    padding-top: 20px;
    font-size: .8em;
    .font-family(medium);
    &:last-child {
      padding-bottom: 15px;
    }
    li {
      padding: 12px 30px;
      &.active a {
        color: @gray-darker;
      }
      a {
        color: @gray-medium;
        &:hover {
          color: @gray-darker;
        }
      }
    }
  }
}
.auth-open {
  .subnav-dropdown {
    position: relative;
    top: 0;
    margin-bottom: -71px;
  }
}
.about_rider_tips {
  .subnav-dropdown {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    .font-family(light);
    .subnav-title {
      padding: 20px 0;
    }
    .arrow-down-grey {
      display: block;
      margin: 10px auto 0 auto;
      padding: 0;
      position: relative;
      top: 0;
      .transition(transform, 400ms);
      &.rotate {
        .transform(rotate(-180deg));
      }
    }
    .subnav-filters {
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 24px;
      padding: 20px 0;
      .font-family(light);
      li {
        padding: 0 15px 15px;
        &:last-child {
          padding-bottom: 0;
        }
        &.active {
          a {
            color: black;
          }
        }
        a {
          color: @gray-38;
        }
      }
    }
  }
}

/* START: Region Dropdown Styles */
#region_button {
  &:focus {
    outline: 1px dotted #212121;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.region-dropdown {
  border-bottom: @borderLightGray;
  height: auto;
  position: fixed;
  top: @headerHeight;
  width: 100%;
  z-index: @zIndexRegionDropdown;

  .region-dropdown-list {
    background-color: @gray-main;
    display: none;
    height: 100%;
    overflow: scroll;
    position: relative;
    z-index: @zIndexDropdownList;
  }

  .region-title {
    .font-family(medium);
    background-color: @gray-main;
    border: none;
    cursor: pointer;
    display: block;
    font-size: 1.7em;
    padding: 16px 0;
    text-align: center;
    width: 100%;

    .title {
      position: relative;
    }
  }
  .region-select-row {
    padding: 35px 20px;
    li {
      padding: 5px 16px;
      background-color: @gray-main;
      font-size: 21px;
      a {
        &.active:after {
          background-image: none;
        }
        .font-family(medium);
      }
    }
  }
}
// specific to instructors pages and studios pages
.instructors_index,
.studio_list {
  .main-container {
    overflow: visible;
    z-index: 10;
  }
  .region-dropdown .region-select-row {
    padding: 15px 0;
    margin: 0px 20px 150px;
    .respond-to(@bpMobileLarge, {
      margin:0;
    });
  }
}
.full-height {
  height: 100%;
}
/* END: Region Dropdown Styles */

/* Accessibility Styles */
.visibly-hidden {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

// Vegas Pre-Booking Banner
.vegas-banner-container {
  background: @gray-annex;
  line-height: 2rem;
  position: fixed;
  text-align: center;
  top: 3.6875rem;
  width: 100%;
  z-index: 31;

  .vegas-banner__message {
    color: @scWhiteC;
    display: inline-block;
    .font-family(light);
    font-size: 0.8125rem;
    letter-spacing: 0;
    margin: 0.5rem 0 0.25rem;

    .respond-to(@bpMobileLarge, {
      .font-family(bold);
      font-size: 0.9375rem;
      letter-spacing: 0.125rem;
    });
  }

  .close-banner-btn {
    background-color: transparent;
    border: none;
    display: inline-block;
    position: absolute;
    right: 0.1rem;
    top: 0.60rem;

    svg {
      height: 1.25rem;
      fill: @scWhiteC;
      width: 1.25rem;
    }

    .respond-to(@bpMobileLarge, {
      position: absolute;
      right: 0.1rem;

    });
  }
}
// End of Vegas Banner

// opt-in/opt-out checkbox on signup pages
#optin_optout_checkbox {
  display: none;
  position: relative;

  input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
  }

  label {
      font-size: 0.875rem;
      left: 2rem;
      position: relative
  }

  .label-text {
      .font-family(medium);
      font-size: 0.9rem;
      margin-left: 36px;
  }

  .checkbox-custom {
      .transition(all, 0.3s, ease-out);
      background-color: transparent;
      border: 1px solid #979797;
      height: 18px;
      left: 0px;
      position: absolute;
      top: 0px;
      width: 18px;
  }

  input:checked ~ .checkbox-custom {
      .transform(rotate(0deg) scale(1));
      background-color: #FFFFFF;
      border-radius: 2px;
      opacity: 1;
  }

  .checkbox-custom::after {
      .transform(rotate(0deg) scale(0));
      .transition(all, 0.3s, ease-out);
      border: solid #979797;
      border-width: 0 3px 3px 0;
      content: "";
      height: 0px;
      left: 12px;
      opacity:1;
      position: absolute;
      top: 12px;
      width: 0px;
  }

  input:checked ~ .checkbox-custom::after {
      .transform(rotate(45deg) scale(1));
      background-color: transparent;
      border: solid #000000;
      border-radius: 0;
      border-width: 0 2px 2px 0;
      height: 12px;
      left: 5px;
      opacity: 1;
      top: 0px;
      width: 6px;
  }
}

  /* MEDIA QUERIES - GLOBAL STYLES
  -----------------------------------------------------------------------------*/

  // 600px < css width (px) < 767px
  @media only screen and (min-width: 600px) {
    /* START: Region Dropdown Styles */
    .region-dropdown {
      height: auto;
      overflow: initial;
      .region-dropdown-list {
        height: auto;
        overflow: initial;
      }
      .region-select-row {
        overflow: hidden;
        padding: 40px 0;
        width: 100%;
        li {
          float: left;
          padding: 5px 0;
          width: 50%;
          a {
            padding-left: 50px;
            &.active:after {
              background-image: none;
            }
          }
        }
      }
    }

    /* END: Region Dropdown Styles */
  }

  // 768px < css width (px) < 1023px
  @media only screen and (min-width: 768px) {
    .soul-container {
      &.auth-open {
        .global-header, .modal-white-bg {
          top: 450px;
        }
      }
    }

    /* START: Region Dropdown Styles */
    .region-dropdown {
      .region-select-row {
        padding: 55px 0;
        li {
          background-color: @gray-main;
          float: left;
          font-size: 18px;
          padding: 5px 0;
          width: 33.3%;
        }
      }
    }

    /* END: Region Dropdown Styles */
  }

  // 1024px < css width (px) < 1439px
  @media only screen and (min-width: 1024px) {
    .main-container {
      .box-sizing(border-box);
    }

    .soul-container {

      &.auth-open {
        .authentication {
          height: 269px;
        }
        .global-header, .modal-white-bg {
          top: 270px;
        }
      }
    }

    .main-container {
      background-color: #fff;
    }

    /* START: Subnav Styles */
    .subnav-dropdown {
      text-align: center;
      .subnav-title {
        display: none;
      }
      .subnav-filters-container {
        height: auto;
        position: relative;
      }
      .subnav-filters-container {
        .transform(translate(0, 0));
      }
      .subnav-filters {
        li {
          display: inline-block;
          padding: 12px 0;
          padding-left: 1.75%;
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
  }

  // css width (px) >= 1440
  @media only screen and (min-width: 1440px) {
    /* START: Region Dropdown Styles */
    .region-dropdown {
      .region-select-row {
        li {
          font-size: 21px;
        }
      }
    }

    /* END: Region Dropdown Styles */
  }
