/*
New To Soul/Experience/classes Page Styles
*/

.new-to-soul__section--classes {
  .flex-display(flex);
}

.new-to-soul__section__classes-content-wrapper {
  .flex-display(flex);
  .flex-wrap(wrap);
  .justify-content(center);
  background-color: @black;
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    .justify-content(flex-end);
  });
}

.new-to-soul__section__classes-background {
  background-position: center center;
  background-size: cover;
  height: 28rem;
  position: relative;
  width: 100%;

  &:after {
    background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0) 30%, rgba(0,0,0,0.65) 75%);
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      background: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0) 46%);
    });
  }

  .respond-to(@bpDesktopSmall, {
    height: 45.625rem;
  });
}

.new-to-soul__section__classes-description {
  .align-self(flex-end);
  .flex-direction(column);
  .flex-display(flex);
  color: @scWhiteC;
  font-size: 0.973rem;
  line-height: 1.5;
  margin-top: -9rem;
  max-width: 23.4375rem;
  padding: 0 1.5rem 3rem;
  z-index: 2;

  h3 {
    font-size: 2.5rem;
  }

  p {
    .font-family(roman);
    max-width: inherit;
  }

  .respond-to(@bpMobileLarge, {
    max-width: 31.5rem;
    padding: 0 0 3rem;
  });

  .respond-to(@bpTablet, {
    margin-top: -12rem;
  });

  .respond-to(@bpDesktopSmall, {
    .align-self(center);
    font-size: 1.125rem;
    margin: 0 3rem 0 0;
    max-width: 26.25rem;
    padding: 0;
    position: absolute;
    right: 0;

    h3 {
      font-size: 3.12rem;
    }
  });

  .respond-to(@bpDesktopLarge, {
    max-width: 30rem;
  });

  &.is-old-browser {
    left: 0;

    .respond-to(@bpDesktopSmall, {
      left: auto;
      top: 15rem;
    });
  }
}

.new-to-soul__section__classes-description-button-wrapper {
  .align-items(baseline);
  .flex-display(flex);
  .justify-content(flex-start);
  width: 9.37rem;
}

.new-to-soul__section__classes-description-right-arrow {
  font-size: 0.8rem;
  margin-left: 0.625rem;
  position: static;
  transform: rotate(270deg);
}
