// Buttons
.button__primary {
  .mixin-button();
}

.button__secondary {
  .mixin-button-secondary();
}

.button__tertiary {
  .mixin-button-tertiary();
}

.button__text-link {
  .mixin-button-link();
}

.button__text__icon-link {
  .mixin-text-icon-link();
}

.button__icon {
  .mixin-button-icon();
}
