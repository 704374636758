.profile_rewards {
  .profile-sections-container {
    overflow: visible;

    .respond-to(@bpTablet, {
      min-width: 100%;
    });

    .respond-to(@bpDesktopSmall, {
      min-width: 862px; // revert to default
    })
  }
}

.profile__rewards-container {
  margin: 0 auto;
  max-width: 66em;
  width: 75%;
}

.profile__rewards-inner {
  margin-top: 4em;

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });
}

.rewards__header {
  text-align: center;
}

.rewards__title {
  .font-family(heavy);
  font-size: 1rem;
  margin-bottom: 2em;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.25rem;
  });
}

p.rewards__info {
  .font-family(light);
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 2em;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.188rem;
  });
}

.rewards__tracker {
  margin-bottom: 6em;
  position: relative;
}

.rewards__tracker-inner {
  background-color: @gray-light;
  line-height: 0;
  margin: 0 auto;
  position: relative;
  width: 20em;
}

.rewards__yellow-fill {
  background-color: @sc-yellow;
  bottom: 0;
  height: 0;
  position: absolute;
  width: 100%;
}

.rewards__wheel__image {
  position: relative;
  width: 100%;
}

.rewards__progress {
  .font-family(bold);
  font-size: 3.8em;
  left: 1.9em;
  position: absolute;
  top: 2.5em;
}

.rewards__earned-container {
  .font-family(bold);
  display: none;
  font-size: 3em;
  left: 0;
  line-height: 3em;
  position: absolute;
  top: 1em;
  width: 100%;

  .yellow-button {
    margin-top: 6em;
  }
}

.rewards__earned {
  position: relative;
  width: 100%;
}

.rewards__redeemable {
  font-size: 2.4em;
  left: 0.215em;
  line-height: 1.5em;
  position: absolute;
  top: 0;
}

.rewards__redeemable-text {
  line-height: 1em;
  position: absolute;
  right: 0.725em;
  text-align: left;
  top: 0.6em;
}

.rewards__counts{
  .font-family(bold);
  margin: 2em auto;
  text-align: left;
  white-space: nowrap;
  width: 25em;

  .respond-to(@bpTablet, {
    bottom: -2em;
    position: absolute;
    right: -1em;
    width: 19em;
  });

  .respond-to(@bpDesktopSmall, {
    right: 2em;
  });
}

.rewards_counts__grey-circle {
  .border-radius(50%);
  background-color: @gray-light;
  display: inline-block;
  height: 1.2em;
  line-height: 1.3em;
  margin-right: 1.5em;
  padding: 0.9em 0.7em;
  text-align: center;
  width: 1.5em;
}

.rewards__counts__classes,
.rewards__counts__referred {
  font-size: 1.4em;
  margin-bottom: 1em;
}

.rewards__referral {
  margin-bottom: 2em;
  text-align: center;
}

.rewards__referral__title,
.rewards__tracker__title {
  .font-family(heavy);
  color: @black;
  font-size: 0.8125rem;
  margin-bottom: 1em;
}

.rewards__copy-container {
  display: inline-block;
  margin-bottom: 1em;
  position: relative;
}

input.rewards__copy__input {
  margin-bottom:0;
  margin-right: 0;
  width: 21em;

  .respond-to(@bpTablet, {
    width: 30em;
  });
}

// defaults.less line 41 needs override (global a target)
a.rewards__copy__link {
  color: @scWhiteC;
  font-size: 1.2em;
  line-height: 1em !important;
  margin-top: 1em;;
  padding: 1.5em 1.2em 1em !important;
  width: 21em;
  z-index: 10;

  &:hover {
    color: @scWhiteC;
  }

  .respond-to(@bpTablet, {
    margin: 0;
    padding: 0.7em 1.2em 0.5em !important;
    position: absolute;
    right: 1em;
    top: 0.7em;
    width: 10em;
  });
}

.rewards__copy__success {
  .box-sizing(border-box);
  .font-family(bold);
  background-color: @gray-main;
  display: none;
  font-size: 1.4em;
  left: 0;
  margin: 0 auto;
  padding-left: 4em;
  padding-top: 1.5em;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  z-index: 5;
  width: 21em;

  .respond-to(@bpTablet, {
    padding-left: 1.5em;
    width: auto;
  });
}

.rewards__copy__check-icon {
  &:extend(.icon-check-yellow-big);
  margin-left: 1em;
  margin-top: -0.5em;
}

.rewards__share {
  text-align: center;
}

.profile__rewards__image-container {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -5em;
  position: relative;
  right: 50%;
  width: 100vw;
  z-index: -1;

  &:after {
    // gradient built off http://www.colorzilla.com/gradient-editor/
    background-image: -moz-linear-gradient(top, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    background-image: -webkit-linear-gradient(top, rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
    background-image: linear-gradient(to bottom, rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
  }

  .respond-to(@bpDesktopSmall, {
    margin-top: -20em;
  })
}

.profile__rewards__image {
  width: 100%;
}

.spread__login-container {
  margin-bottom: 2em;
  text-align: center;

  .spread__title {
    font-size: 1rem;
    margin-bottom: 1em;
  }

  .spread__login__button {
    height: 50px;
    width: 300px;
  }
}
