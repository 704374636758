/* RIDER REFERRAL STYLES
-----------------------------------------------------------------------------*/

.riderreferral_landing,
.riderreferral_confirm {
  .rr-background {
    img {
      display: none;
      .respond-to(@bpTablet, {
        display: block;
        width: 190%;
        height: auto;
      });
      .respond-to(@bpDesktopSmall, {
        width: 140%;
      });
      .respond-to(@bpDesktopLarge, {
        width: 120%;
      });
    }
  }
  .rr-container {
    .respond-to(@bpTablet, { top: 130px; });
    .respond-to(@bpDesktopSmall, { top: 170px; });

    // TODO: this should be global
    font-size: 14px;
    line-height: 1.6em;
    margin-bottom: @gutterMobile;
    input,
    input[type=text],
    input[type=tel],
    input[type=password],
    input[type=email],
    input[type=submit] {
      width: 280px;
      background-color: @gray-main;
      margin: 0 0 10px 0;
      .respond-to(@bpMobileLarge, {
        width: 376px;
      });
      input:focus {
        background-color: @scWhiteC;
      }
    }
    input[type=checkbox] {
      width: initial;
      margin-right: 10px;
      .respond-to(@bpMobileLarge, {
        width: initial;
      });
    }
    form .field-row .field-error,
    form .section-row .field-error,
    form .field-error input,
    form .form-error,
    form .field-error .error-message {
      float: none;
      display: inline-block;
      text-align: left;
    }
    form .field-error .error-message,
    form .form-error {
      display: block;
    }
    form .field-error {
      max-width: 280px !important;
      .respond-to(@bpMobileLarge, { max-width: 376px !important; });
    }
    .form-label {
      font-size: 18px;
      .font-family(bold);
    }
    h1, .sc-h1 {
      .font-family(bold);
      font-size: 28px;
      .respond-to(@bpMobileLarge, { font-size: 32px; });
      .respond-to(@bpTablet, { font-size: 40px; });
      .respond-to(@bpDesktopSmall, { font-size: 48px; });
    }
    h2, .sc-h2 {
      margin-bottom: 20px;
      .font-family(bold);
      font-size: 24px;
      .respond-to(@bpMobileLarge, { font-size: 28px; line-height: 32px; });
      .respond-to(@bpTablet, { font-size: 36px; line-height: 38px; });
    }
    h4 {
      .font-family(bold);
      font-size: 18px;
      line-height: 22px;
    }
    h5.global__input__text {
      margin-bottom: 1.5rem;
      text-align: left;
    }
    // TODO end. this should be global

    .form-snippet {
      margin-bottom: 28px;
      font-size: 18px;
      line-height: 28px;
      .respond-to(@bpTablet, {
        font-size: 20px;
        width: 530px;
        display: inline-block;
      });
      .respond-to(@bpDesktopSmall, { width: 600px; });
    }
    &.fake-modal {
      .respond-to(@bpTablet, { width: 680px; });
    }

    .form-terms {
      .font-family(light);
      font-size: 0.75rem;
      
      h1 {
        .font-family(medium);
        font-size: 2em;
      }

      h2 {
        .font-family(medium);
        font-size: 1rem;
        line-height: 1.125rem;
      }

      h3 { 
        .font-family(bold);
        font-size: .75rem;
        line-height: 0.93rem;
      }
    }
  }
}

.riderreferral_landing,
.riderreferral_confirm {
  .sc-h2 {
    .respond-to(@bpTablet, {
      line-height: 47px;
      width: 440px;
      display: inline-block;
    });
    .respond-to(@bpDesktopSmall, { width: 650px; });
  }

  .rr-authentication {
    .field-row {
      display: inline-block;
      position: relative;

      .frm-label {
        .transition(all, 0.1s, linear);
        font-size: 14px;
        left: 1.2em;
        position: absolute;
        top: 1.1em;
        z-index: 11;
      }

      .intl-tel-input {
        &+ .frm-label {
          z-index: -1;

          &.show {
            left: 0;
            top: -1.4em;
          }
        }
      }

      input {
        margin-bottom: 20px;

        &:focus {
          &+ .frm-label {
            left: 0;
            top: -1.4em;
          }
        }

        &+ .frm-label {
          &.show {
            left: 0;
            top: -1.4em;
          }
        }
      }
    }

    #frm-dob_placeholder,
    #frm-location_placeholder {
      display: none;
    }

    .dob_input {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0 0 0;

      .global__input-select-dob_group {
        width: 31%;

        select {
          width: 100%;
        }

        label {
          left: 0.45rem;
          text-transform: initial;
          top: -1.4rem;
        }
      }
    }

    .location_input {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0 0 0;

      .global__input-select-location_group {
        width: 48.5%;

        select {
          width: 100%;
        }

        label {
          left: 0.45rem;
          text-transform: initial;
          top: -1.4rem;
        }
      }
    }
  }

  .rr-authentication,
  .rr-confirmation {
    .yellow-button {
      width: 280px;
      margin-bottom: 20px;
      .respond-to(@bpMobileLarge, {
        width: 376px;
      });
    }
  }
  .rr-sign-up-container {
    fieldset {
      width: 280px;
      margin: 0 auto;
    }
    .terms-agreement {
      text-align: left;
      .font-family(bold);
      margin-top: 30px;
      margin-bottom: 6px;

      a {
        text-decoration: underline;
      }
    }
    .account-creation__form__opt-out {
      .label-text {
        vertical-align: text-top;
      }
    }
  }
  .rr-confirmation {
    margin-top: 20px;
    .yellow-button {
      margin-top: 20px;
    }
    .sc-h1 {
      .respond-to(@bpMobileLarge, { display: inline; });
      &.second {
        .respond-to(@bpMobileLarge, { margin-left: 5px; });
        .respond-to(@bpDesktopLarge, { margin-left: 10px; });
      }
    }
    .form-snippet {
      .respond-to(@bpDesktopSmall, { width: 650px; });
    }
  }
  .rr-info {
    .respond-to(@bpMobileLarge, { padding: 20px 0; });
  }
  .rr-more-info {
    .respond-to(@bpMobileLarge, { margin-top: 10px; });
    .respond-to(@bpTablet, { font-size: 18px; });
    p {
      margin-bottom: 0;
      .respond-to(@bpMobileLarge, { display: inline; });
    }
  }
}
