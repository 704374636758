/* BIKE INFO PAGE
-----------------------------------------------------------------------------*/

.bike-container {
  display: none;
  width: 100%;
  font-size: 1.5em;
  .headline-blurb {
    line-height: 2em;
  }
  .bike-image,
  .bike-image-main {
    max-width: 280px;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
  }
  .bike-exclusive {
    font-size: .7em;
    .font-family(bold);
    padding: 5px 0;
  }
  .bike-title {
    padding: 5px 0 10px;
  }
  .bike-cost {
    font-size: 1.8em;
    .font-family(bold);
  }
  .section {
    .headline {
      .font-family(bold);
      padding: 20px 0 15px;
    }
    .row {
      padding-left: 20px;
    }
    .copy {
      font-size: .9em;
      line-height: 1.7em;
    }
    .spec-citation {
      font-size: .9em;
      padding: 20px 0 25px;
    }
  }
  .contact-link-container {
    .yellow-button {
      width: 280px;
    }
  }
  .bike-btn-container {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.bike-container-soldout {
  width: 100%;
  font-size: 15px;
  .bike-title-alt {
    margin-top: 25px;
  }
  .headline-blurb {
    line-height: 30px;
  }
  .bike-image,
  .bike-image-main {
    max-width: 280px;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
  }
  .section {
    .row {
      width: initial;
      padding: 0 @bpMobileSmallGutter;
    }
    .row-content {
      width: initial;
    }
  }
}

/* MEDIA QUERIES - BIKE INFO PAGE
-----------------------------------------------------------------------------*/
// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
  .bike-container-soldout {
    .section,
    .section-top {
      .row {
        padding: 0 @bpMobileLargeGutter;
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width : 768px) {
  .bike-container {
    .section,
    .section-top {
      width: 100%;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid @gray-light;
      overflow: hidden;
      .row {
        width: 50%;
        padding-left: 0;
        border: none;
        float: left;
      }
    }
    .section-main {
      width: 50%;
      float: left;
      .row {
        width: 100%;
      }
    }
    .section-top {
      .section {
        border: none;
      }
    }
    .bike-image {
      height: 200px;
    }
    .bike-image-main {
      margin: 0;
      max-width: 360px;
      float: left;
    }
    .bike-btn-container {
      text-align: left;
    }
  }
  .bike-container-soldout {
    .section,
    .section-top {
      border-bottom: 1px solid @gray-light;
      overflow: hidden;
      .row {
        padding-left: 0;
        border: none;
        float: left;
      }
    }
    .section-top {
      padding: 20px 40px;
    }
    .section-main {
      .row {
        width: 100%;
      }
    }
    .section-top {
      .section {
        border: none;
        margin-top: 10%;
      }
    }
    .bike-image {
      height: 200px;
    }
    .bike-image-main {
      margin: 0;
      max-width: 360px;
      float: left;
    }
    .bike-btn-container {
      text-align: left;
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width : 1024px) {
  .bike-container {
    overflow: hidden;
    padding-bottom: 80px;
    .section {
      border: none;
      overflow: initial;
      padding-top: 0;
      padding-bottom: 0;
      .row {
        width: 33%;
        height: 420px;
      }
    }
    .section-top {
      border-bottom: 1px solid @gray-light;
    }
    .section-main {
      width: 50%;
      .row {
        height: initial;
        width: 100%;
      }
    }
    .bike-image-main {
      margin: 0;
      max-width: 480px;
      float: left;
    }
  }
  .bike-container-soldout {
    font-size: 20px;
    overflow: hidden;
    .headline-blurb {
      line-height: 40px;
    }
    .section-top {
      border: none;
    }
    .section {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      .row {
        width: 33%;
        height: 420px;
      }
    }
    .section-main {
      .row {
        height: initial;
        width: 100%;
      }
    }
    .bike-image-main {
      margin: 0;
      max-width: 480px;
      float: left;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width : 1440px) {
  .bike-container {
    max-width: 1220px;
    margin: 0 auto;
    .bike-image-main {
      max-width: 580px;
    }
  }
  .bike-container-soldout {
    max-width: 1560px;
    font-size: 24px;
    margin: 0 auto;
    .headline-blurb {
      line-height: 48px;
    }
    .section {
      padding: 20px 40px;
    }
    .bike-image-main {
      max-width: 580px;
    }
  }
}