/* STUDIO GRID STYLES
-----------------------------------------------------------------------------*/

.studio_list {
  .region-dropdown {
    .region-title {
      padding: 26px 0;
      font-size: 1.8em;
    }
  }
  .studio-list {
    margin-top: 47px;
    position: relative;
    overflow: hidden;
    background: #fff;
  }
}
.studio_list {
  .studios-elements {
    background: @gray-main;
    .region-header {
      .font-family(medium);
      border-bottom: @borderLightGray;
      background: @gray-main;
      position: static;
      font-size: 12px;
      .respond-to(@bpMobileLarge, {
        font-size: 18px;
      });
      .respond-to(@bpTablet, {
        border-top: @borderLightGray;
        clear: both;
      });
      .respond-to(@bpDesktopLarge, {
        padding-top: 5px;
        font-size: 30px;
      });
      .region-section-title {
        padding: 18px;
        display: block;
        .respond-to(@bpMobileLarge, {
          padding-left: 30px;
        });
      }
    }
    .studio-detail {
      height: 180px;
      text-align: center;
      background: #fff;
      border-bottom: 1px solid @gray-light;
      overflow: hidden;
      .studio-link {
        display: block;
        height: 100%;
      }
    }
    .fav-class {
      position: relative;
      z-index: 10;
      .fav-studio-icon {
        height: 15px;
        margin: 15px;
        object-fit: none;
        object-position: -126px -208px;
        width: 20px;
      }
      .fav {
        -webkit-backface-visibility: hidden;
        .transform(scale(1.4));
        .transition(transform, 0.3s, ease-in-out);
        position: absolute;
        right: 5px;
        text-indent: -9999px;
        top: 5px;
        visibility: visible;
        z-index: 1;
        &:hover {
          .transform(scale(1.6));
        }
      }
      &.faved {
        .fav {
          object-position: -176px -207px;
        }
      }
    }
    .map {
      position: relative !important;
      background-size: 636px 100%;
      height: 266px;
      font-size: 14px;
      color: #595A5C;
      overflow: hidden;
      position: relative;
      top: 70px;
      background-color: #ECECEC;
      #studios-map-canvas {
        height: 100%;
        width: 100%;
        .map-info-window {
          line-height: 1.35;
          overflow: hidden;
          white-space: nowrap;
          color: @gray-darker;
          h3 {
            .font-family(bold);
            font-size: 1.6em;
            margin: 20px 10px 10px 10px;
          }
          div {
            margin-left: 10px;
            width: 35%;
            line-height: 1.6em;
          }
          p {
            .font-family(bold);
            font-size: .9em;
            margin-left: 10px;
          }
        }
      }
    }
    /* .map */
    .studios-complete-list {
      .font-family(medium);
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 0.9em;
      margin-top: 25px;
      margin-left: 15px;
      position: relative;

      &:after {
        content: url(/assets/images/link-arrow-light.png);
        position: absolute;
        right: -13px;
        top: 0px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .studio-wrapper {
      margin-left: 0px;
      font-size: 14px;
      position: relative;
      background-color: #fff;
      height: 100%;

      &:hover {

        .short-name{
          color: @sc-yellow;
        }
      }
      #studios-map-canvas img {
        opacity: 1;
        transition: none;
      }

      //Keep position and size separate for compatibility with Safari
      .short-name {
        .font-family(heavy);
        color: @gray-main;
        font-size: 7em;

        &:hover {
          color: @sc-yellow;
        }
      }

      .new-tag {
        .font-family(bold);
        font-size: 14px;
        left: 20px;
        letter-spacing: 3.5px;
        position: absolute;
        text-align: left;
        top: 38px;
      }

      .info-cc {
        line-height: 22px;
        text-align: left;
        position: absolute;
        top: 45px;
        left: 20px;
        width: 100%;

        .street {
          width: 75%;
        }

        .address{
          max-width: 80%;
        }

        .name {
          .font-family(heavy);
          display: block;
          font-size: 20px;
          margin-bottom: 4px;
          max-width: 80%;
        }

        .phone {
          margin-bottom: 8px;
          display: block;
        }
        .studio-schedule-link {
          font-size: 13px;
          margin: 10px 15px 0 0;
          display: inline-block;
          .yellow-3d-button;
          line-height: 33px;
          float: left;
          padding: 0 10px;
          margin-right: 20px;
        }
        .more-info {
          font-size: 12px;
          margin-top: 12px;
          float: left;
          text-transform: uppercase;
        }
      }
    }
  }
}


/* MEDIA QUERIES - STUDIO GRID STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
  .studio_list {
    .region-dropdown {
      height: auto;
    }
    .studios-elements {

      .fav-class {

        .fav {
          right: 11px;
          top: 45px;
        }
      }

      .map {
        height: 395px;
      }

      .studio-detail {
        height: 255px;
      }

      .studio-wrapper {

        .short-name {
          font-size: 10.4em;
        }

        .new-tag {
          left: 30px;
          top: 44px;
        }

        .info-cc {
          left: 30px;
          top: 52px;

          .name {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 16px;
          }

          .street,
          .phone {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .studio_list {

    .studios-elements {
      background: #fff;

      .fav-class {

        .fav {
          top: 44px;
        }
      }

      .studio-detail {
        display: block;
        float: left;
        height: 215px;
        width: 50%;
      }

      .studio-wrapper {
        border-right: 1px solid @gray-light;

        .short-name {
          font-size: 120px;
        }

        .new-tag {
          top: 38px;
        }

        .info-cc {
          top: 45px;

          .name {
            font-size: 21px;
          }
        }
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .studio_list {

    .region-dropdown {

      .region-select-row {
        padding-left: 8%;

        li {
          width: 30%;
        }
      }
    }
    .studios-elements {

      .map {
        height: 417px;
      }

      .studio-detail {
        width: 33.3%;
      }

      .studio-wrapper {

        .short-name {
          font-size: 105px;
        }
      }
    }

    .studios-container {
      padding-bottom: @footerHeightDesktop;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width : 1440px) {
  .studio_list {

    .region-dropdown {

      .region-select-row {

        li {
          width: 22%;
        }
      }
    }
    .studios-elements {

      .fav-class {

        .fav {
          top: 62px;
        }
      }

      .studio-detail {
        height: 270px;
      }

      .studio-wrapper {

        .short-name {
          font-size: 144px;
        }

        .new-tag {
          top: 48px;
        }

        .info-cc {
          top: 55px;

          .name {
            font-size: 32px;
            line-height: 32px;
          }

          .street,
          .phone {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
