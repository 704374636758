.card-series-container {
  .flex(1);
  .flex-display(flex);
  .box-sizing(border-box);
  padding: 2.5rem 1rem 2.5rem 1.5rem;
  position: relative;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    padding: 3.5625rem 2.5rem 2.0625rem;
  });
}

.card-series-container--fixed {
  .box-shadow(@single-card-shadow);

  .respond-to(@bpMobileLarge, {
    max-width: 16.5rem;
  });

  .respond-to(@bpDesktopLarge, {
    max-width: 18.25rem;
  });
}

.card-series__inner-container {
  .flex(1);
  position: relative;

  .respond-to(@bpMobileLarge, {
    height: 13.75rem;
  });
}

.card-series__callout {
  background-color: @gray-darker;
  box-sizing: border-box;
  color: @scWhiteC;
  font-size: 0.6875rem;
  left: 0;
  letter-spacing: 0.125rem;
  line-height: 0.9375rem;
  padding: 0.4rem 0 0.3438rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
}

.card-series__title,
.card-series__size {
  .font-family(heavy);
  font-size: 1.125rem;
  letter-spacing: -0.03125rem;
  line-height: 1.3125rem;

  .respond-to(@bpMobileLarge, {
    font-size: 1.25rem;
    letter-spacing: -0.0175rem;
    line-height: 1.5rem;
  });
}

.card-series__size {
  .font-family(medium);
  color: @gray-category;
}
.card-series__price {
  .font-family(light);
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0.3125rem 0 1rem;

  .respond-to(@bpMobileLarge, {
    font-size: 1.125rem;
    line-height: 0.75rem;
    margin: 0.5rem 0 3.25rem;
  });
}

.card-series__expiration {
  .font-family(light);
  font-size: 0.75rem;
  line-height: 0.75rem;

  .respond-to(@bpMobileLarge, {
    bottom: 3.875rem;
    font-size: 0.875rem;
    position: absolute;
  });
}

.card-series__button-container {
  .transform(translateY(-50%));
  position: absolute;
  right: 0;
  top: 50%;

  .respond-to(@bpMobileLarge, {
    .transform(translateY(0));
    bottom: 0;
    left: 0;
    margin-top: 1.25rem;
    position: absolute;
    top: auto;
  });
}

.card-series__more-info-icon {
  position: absolute;
  right: 0.875rem;
  top: 1.9rem;
  width: 1.625rem;
  z-index: 15;

  & .button__icon {
    .mixin-button-icon(
      @background: none,
      @border: none
    );
    width: inherit;
  }
}

.card-series__credits-additional-info {
  background-color: @scWhiteC;
  bottom: 0;
  display: none;
  height: 84%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  .respond-to(@bpMobileLarge, {
    height: 90%;
  });
}

.card-series__close-more-info {
  margin: 2.5rem 0.687rem;
  z-index: 11;

  .respond-to(@bpMobileLarge, {
    margin: 2rem 0.687rem;
  });
}

.card-series__credits-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.85rem 1.5rem;

  .respond-to(@bpMobileLarge, {
    padding: 1.625rem 2.5rem;
  })
}

.card-series__close-button {
  background: none;
  border: unset;
  cursor: pointer;
  display: block;
  height: 0.8125rem;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1.25rem;
  top: 2.5rem;
  width: 0.8125rem;

  button {
    width: inherit;
  }
}
