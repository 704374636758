.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  &.active {
    visibility: visible;
    opacity: 1;
    .modal {
      -webkit-transform: scale(1);
      visibility: visible;
      opacity: 1;
    }
  }
  .modal {
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    width: 300px;
    background-color: white;
    border: 1px solid @gray-light;
    border-radius: 4px;
    padding: 40px 18px;
    -webkit-transform: scale(1.25);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    .title {
      display: block;
      .font-family(bold);
      font-size: 1.6em;
      margin-bottom: 20px;
    }

    .message {
      font-size: 1.3em;
      line-height: 1.6;
      color: @gray-darker;
      margin: 0 0 20px;
    }

    .button, .button-cancel, .button-right {
      width: 125px;
      float: left;
      margin-top: 13px;
      padding: 3px;
      &:first-child {
        margin-right: 12px;
      }
    }
  }
}

&.rider-class-region-conflict-modal {
  .modal {
    .button, .button-cancel {
      float: none;
    }
    .button-cancel:first-child {
      margin-right: 0;
    }
  }
}

/* MEDIA QUERIES - OUR STORY STYLES
-----------------------------------------------------------------------------*/

// 600px > css width (px) < 767px
@media only screen and (min-width: 600px) {
  .confirmation-modal {
    .modal {
      padding: 40px 60px;
      width: 400px;
    }
    .buttons-container {
      padding-left: 10px;
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {

}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {

}

@media only screen and (min-width: 1440px) {
  .confirmation-modal {
    .modal {
      padding: 80px 160px;
      width: 600px;
    }
    .buttons-container {
      padding-left: 10px;
      .button, .button-cancel, .button-right {
        padding: 5px;
      }
    }
  }
}