.supersoul__section__footer {
  background-color: @mineShaftGray;
  color: @white;
  padding: 17.6% 21.87%;

  .respond-to(@bpTablet, {
    padding: 4.86% 16.8% 8.17%;
  });

  &-content {
    .flex-direction(column);
    .flex-display(flex);
    .font-family(heavy);
    color: @white;
    font-size: 0.75rem;
    letter-spacing: 2.5px;
    margin: auto;
    text-align: center;

    a {
      .flex(1);
      color: @white;

      &:hover,
      &:focus {
        color: @white;
        text-decoration: underline;
      }
    }

    h2 {
      .flex(1);
      .font-family(heavy);
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
  }

  &-mailto {
    .font-family(light);
    font-size: 1rem;
    letter-spacing: 0;
    padding: 25px 0 40px;

    .respond-to(@bpTablet, {
      padding: 30px 0 20px;
    });
  }

  &-sitelink {
    font-weight: bold;
    text-transform: uppercase;
  }
}
