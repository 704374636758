.supersoul__section__banner {
  height: 250px;

  .respond-to(@bpTablet, {height: 600px;});

  &-background {
    .align-items(center);
    .flex-display(flex);
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  &-title {
    .flex(1);
    .flex-direction(column);
    .font-family(bold);
    .justify-content(center);
    overflow-wrap: break-word;
    text-align: center;
    width: 100%;

    &-soulcycle {
      color: @white;
      display: block;
      font-size: 1.5rem;
      letter-spacing: -1.5px;

      .respond-to(@bpTablet, {font-size: 3.5rem; letter-spacing: -3px;});
    }

    &-supersoul {
      color: @schoolBusYellow;
      display: block;
      font-size: 3rem;
      letter-spacing: 1.5px;
      padding-top: 7.5px;

      .respond-to(
        @bpTablet,
        {font-size: 7rem; letter-spacing: 3px; padding-top: 15px;}
      );
    }
  }
}
