.supersoul__section__signup {
  .flex-direction(column);
  .flex-display(flex);
  min-height: 550px;

  .respond-to(@bpTablet, {
    .flex-direction(row);
  });

  &-image-container {
    .flex(0 0 auto);
    background-color: @black;
    background-position: center;
    background-size: cover;
    height: 320px;

    .respond-to(@bpTablet, {
      .flex(0 0 50%);
      height: initial;
    });
  }

  &-content {
    .flex(0 1 auto);
    .font-family(roman);
    color: @armadilloGray;
    letter-spacing: 0.5px;
    padding: 19.47% 9.33% 13.87%;

    .respond-to(@bpTablet, {
      .flex(0 1 50%);
      padding: 6.94% 8.4%;
    });

    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin: 0;
      padding: 55px 0 50px 7px;

      .respond-to(@bpTablet, {
        padding: 13.88% 0;
      });
    }
  }
}
