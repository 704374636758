.about {

    .subnav-dropdown {
        display: none;
    }

    .info-detail-container {
        padding: 10px;

        .respond-to(@bpDesktopSmall, {
            margin: 0 0 @footerHeightDesktop 0;
            padding: 20px 50px;
        });

        .respond-to(@bpDesktopMedium, {
            padding: 20px 75px;
        });

        .respond-to(@bpDesktopLarge, {
            padding: 20px 100px;
        });

        .respond-to(@bpDesktopXL, {
            padding: 20px 150px;
        });
    }

    .menu-container {

        .respond-to(@bpDesktopSmall, {
            margin-top: 28px;
        });

        .main-menu-container,
        .sub-menu-container {
            border: 1px solid @gray-12;
            padding: 16px 20px;

            .respond-to(@bpDesktopSmall, {
                border: none;
                padding: 0;
            });
        }

        .top-category-label {
            color: @gray-38;
            cursor: pointer;
            display: inline-block;
            font-size: 18px;
            letter-spacing: 0.5px;
            line-height: 20px;
            width: 100%;

            .font-family(light);

            &.active {
                color: @gray-87;
            }

            .respond-to(@bpDesktopSmall, {

                &:hover {
                    color: @gray-87;
                }
            });
        }

        .sub-category-label {
            color: @gray-38;
            display: inline-block;
            font-size: 12px;
            letter-spacing: 0.5px;
            width: 100%;

            .font-family(roman);

            &.active {
                color: @gray-87;
            }

            .respond-to(@bpDesktopSmall, {

                &:hover {
                    color: @gray-87;
                }
            });
        }

        .main-menu-container {
            line-height: 0.5;

            .category-toggle {
                cursor: pointer;
                text-align: center;

                .respond-to(@bpDesktopSmall, {
                    display: none;
                });

                &.expanded {

                    .category-toggle--icon {

                        &:before {

                            .transform(rotate(0deg));
                        }
                    }
                }

                .category-toggle--icon {

                    &:before {

                        .transform(rotate(180deg));
                    }
                }
            }

            .top-category-label {
                display: inline;
            }

            .category-toggle--icon {

                &:before {
                    background-image: url(/assets/images/rider-tips/icons/ic-arrow-up.png);
                    background-repeat: no-repeat;
                    content: '';
                    display: inline-block;
                    height: 7px;
                    margin-left: 15px;
                    vertical-align: middle;
                    width: 11px;

                    .transition(transform, 350ms);
                }
            }
        }

        .sub-menu-container {
            border-top: none;
            display: none;

            .respond-to(@bpDesktopSmall, {
                display: block;
            });

            .top-menu-list,
            .sub-menu-list {
                list-style: none;
            }

            .top-menu-item {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .sub-menu-list {
                display: none;
                margin: 16px 0 0 20px;
            }

            .sub-menu-item {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .content-container {
        margin-top: 16px;
        position: relative;

        .respond-to(@bpDesktopSmall, {
            margin-top: 0;
        });

        .navigation-container {
            margin-bottom: 16px;
            overflow: hidden;

            .topic {
                color: @gray-38;
                font-size: 10px;
                letter-spacing: 2.36px;
                text-transform: uppercase;

                .font-family(bold);

                &.active {
                    color: @gray-87;

                    &:before,
                    &:after {
                        background-color: @gray-87;
                    }
                }
            }

            .previous-topic {
                float: left;

                &:before {
                    background-color: @gray-38;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin-right: 5px;
                    vertical-align: middle;
                    width: 17px;

                    .mask-image('/assets/images/rider-tips/icons/ic-arrow-left.svg');
                }
            }

            .next-topic {
                float: right;

                &:after {
                    background-color: @gray-38;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin-left: 5px;
                    vertical-align: middle;
                    width: 17px;

                    .mask-image('/assets/images/rider-tips/icons/ic-arrow-right.svg');
                }
            }
        }

        .hero-image-container {
            border: 1px solid @gray-12;
            line-height: 0;
            min-width: auto;
            padding: 0;

            .hero-image {

                &.hidden {
                    position: absolute;
                    visibility: hidden;
                }
            }
        }

        .hero-video-container {
            background-color: black;
            display: none;
            line-height: 0;

            .hero-video {
                width: 100%;
            }
        }

        .topic-content-container {
            margin-top: 16px;

            .respond-to(@bpDesktopSmall, {
                margin-top: 32px;
            });

            .topic-title {
                color: @gray-darker;
                display: block;
                font-size: 30px;
                margin-bottom: 16px;

                .font-family(light);

                .respond-to(@bpDesktopSmall, {
                    font-size: 40px;
                    margin-bottom: 32px;
                });

                &:empty {
                    margin-bottom: 0;
                }
            }

            .topic-description {
                color: @gray-45;
                display: block;
                font-size: 15px;
                line-height: 20px;

                .font-family(light);

                .respond-to(@bpDesktopSmall, {
                    font-size: 20px;
                    line-height: 25px;
                });

                p {
                    margin: 0;
                }
            }
        }

        .more-topics-container {

            &:before {
                background-color: @gray-38;
                content: '';
                display: block;
                height: 1px;
                margin: 32px 0;
                width: 100%;
            }

            .more-topics-title {
                color: @gray-darker;
                font-size: 12px;
                letter-spacing: 2.25px;
                text-transform: uppercase;

                .font-family(bold);

                .respond-to(@bpDesktopSmall, {
                    font-size: 14px;
                    letter-spacing: 3px;
                });
            }

            .more-topics-list {

                .more-topic-item {
                    margin-top: 16px;

                    .align-items(center);

                    .flex-display;

                    &.asset-type--video {

                        .more-topic-item--image {
                            position: relative;

                            &:after {
                                background-color: @gray-38;
                                background-image: url(/assets/images/rider-tips/icons/ic-play-button.png);
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: 45px;
                                content: '';
                                height: 75px;
                                left: 0;
                                position: absolute;
                                top: 0;
                                width: 125px;

                                .respond-to(@bpDesktopSmall, {
                                    background-size: 60px;
                                    height: 150px;
                                    width: 250px;
                                });
                            }
                        }
                    }
                }

                .more-topic-item--image {
                    border: 1px solid @gray-12;
                }

                .more-topic-item--image--url {
                    display: block;
                    height: 75px;
                    min-height: 75px;
                    min-width: 125px;
                    object-fit: cover;
                    width: 125px;

                    .respond-to(@bpDesktopSmall, {
                        height: 150px;
                        min-height: 150px;
                        min-width: 250px;
                        width: 250px;
                    });
                }

                .more-topic-item--info {
                    margin-left: 16px;
                }

                .more-topic-item--info--title {
                    color: @gray-darker;
                    display: block;
                    font-size: 18px;

                    .font-family(light);

                    .respond-to(@bpDesktopSmall, {
                        font-size: 24px;
                    });
                }

                .more-topic-item--info--description {
                    color: @gray-54;
                    display: block;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 8px;
                    max-height: 50px;
                    padding-top: 1px;

                    .font-family(roman);
                    .line-clamp(2);

                    .respond-to(@bpDesktopSmall, {
                        font-size: 16px;
                        line-height: 22px;
                        max-height: 100px;
                    });

                    p {
                        overflow: hidden;
                        margin-bottom: 30px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
