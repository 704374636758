/*doc
---
title: Intro
name: index
category: basics
---

This is the style guide for soul-cycle.com

*/
@import "global.less";
@import "confirmation-modal.less";
@import "vars.less";
@import "fonts.less";
@import "mixins.less";
@import "defaults.less";
@import "global-grid.less";
// shared components
@import "shared/sc-buttons.less";
@import "shared/sc-containers.less";
@import "shared/sc-forms.less";
@import "shared/sc-lists.less";
@import "shared/sc-login-component.less";
@import "shared/sc-text.less";
@import "shared/sc-inputs.less";
@import "shared/sc-class-list.less";
@import "shared/sc-giftcard-form.less";
@import "shared/sc-icons.less";
@import "shared/sc-tooltip.less";
@import "confirmation.less";
@import "header.less";
@import "footer.less";
@import "home.less";
@import "confirm_dialog.less";
@import "studio_list.less";
@import "studio.less";
@import "info-detail.less";
@import "info-detail-bike.less";
@import "shop.less";
@import "shop-detail.less";
@import "shop-nav.less";
@import "shop-gift-card.less";
@import "find-a-class.less";
@import "select-bike.less";
@import "shopping-bag.less";
@import "checkout.less";
@import "errors.less";
@import "careers.less";
@import "corporate_packages.less";
@import "footer-page.less";
@import "series_purchase.less";
@import "contact-us.less";
@import "press.less";
@import "philanthropy.less";
@import "tenth.less";
@import "siya.less";
@import "rider_referral.less";
@import "terms_landing.less";
@import "waiver_landing.less";
@import "comingsoon.less";
@import "plugins/intl-tel-input.less";
@import "custom-intl-tel-input.less";
@import "3-pack-landing-page.less";
@import "rider-tips.less";
@import "promo_codes.less";
@import "promotions.less";
@import "newrider/main.less";
@import "pro-tip-modal.less";
@import "success-dialog.less";
@import "supersoul/main.less";
@import "email-capture.less";
@import "cookie-banner.less";
@import "stream-signin-error.less";
// Happiness Challenge
@import "happiness-challenge.less";
// Ponce City Market Opening
@import "ponce-city-market/main.less";
// London Studios Opening
@import "london_studios/main.less";
// Smart Profile
@import "profile/main.less";
@import "campaign.less";
// SoulCup
@import "soulcup/main.less";
// Checkout for IOS App
@import "checkout-web-view.less";
// Styleguide
@import "styleguide/main.less";
@import "blocks/ui-components/cards/series.less";
@import "blocks/ui-components/buttons.less";


/**
STUPID QUIRKS
------------
RENAMED CSS TO LESS FILES IN ORDER TO COMPILE
*/
@import url("plugins/swiper.css.less");
@import url("plugins/featherlight.less");
@import url("plugins/featherlight.gallery.less");
@import url("plugins/odometer-theme-default.less");

// JIRA issue collector

#atlwdg-trigger {
  background: #fff000 !important;
  color: #000 !important;
  display: none !important;
}
