.profile-souljourney-container {
  margin: 0 auto;
  position: relative;

  .respond-to(@bpDesktopSmall, {
    padding-bottom: 60px;
  });

  .respond-to(@bpDesktopLarge, {
    width: 85%;
  });
}

// Empty state (classes < 10 )
.souljourney-empty-container {
  background: rgba(255,255,255,.95);
  height: 100%;
  position: absolute;
  top: -50px;
  width: 100%;
  z-index: 20;
}

.souljourney-empty__modal {
  background: @scWhiteC;
  padding-top: 50px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 101;

  .respond-to(@bpMobileLarge, {
    padding: 50px 20px;
  });

  .respond-to(@bpTablet, {
    border: 1px solid #eee;
    left: 50%;
    margin-left: -340px;
    top: 80px;
    width: 640px;
  });

  .respond-to(@bpDesktopSmall, {
    margin-left: -445px;
    top: 150px;
    width: 855px;
  });
}

.soljourney-empty__message{
  line-height: 24px;
  margin: 0 auto;
  padding: 0 20px 15px;
  text-align: left;

  .respond-to(@bpMobileLarge, {
    line-height: 35px;
    width: 540px;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 33px;
    line-height: 40px;
    width: 715px;
  });
}

.souljourney-empty__message,
.souljourney-empty__progress {
  .font-family(bold);
  font-size: 20px;

  .respond-to(@bpMobileLarge, {
    font-size: 28px;
  });
}

.souljourney-empty__background {
  background-color: @gray-light;
  line-height: 0;
  margin-bottom: 10px;
  position: relative;

  .respond-to(@bpMobileLarge, {
    margin: 0 auto;
    width: 500px;
  });

  .respond-to(@bpDesktopSmall, {
    width: 580px;
  });
}

.souljourney-empty__logo-mask {
  position: relative;
  width: 100%;
}

.souljourney-empty__yellow-fill {
  background-color: @sc-yellow;
  bottom: 0;
  height: 0%;
  position:absolute;
  width: 100%;
}

.souljourney-empty__button{
  .box-sizing(border-box);
  .font-family(medium);
  background-color: @scYellowC;
  border: 0;
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin: 20px auto 0;
  padding: 0 34px;
  position: relative;
}
// END EMPTY STATE STYLES

// TENTH CONTAINER STYLES
.souljourney-container{
  .font-family(bold);
  font-size: 16px;
  margin: 0 auto;
  min-height: 600px;
  position: relative;

  .respond-to(@bpTablet, {
    min-height: 800px;
  });

  .respond-to(@bpDesktopSmall, {
    padding-bottom: 60px;
  });

  .respond-to(@bpDesktopMedium, {
    padding-bottom: @footerHeightDesktop;
  });

  & .share-icons {
    & .share-icon {
      cursor: pointer;
      margin: 15px 6px;
      z-index: 1;

      .respond-to(@bpMobileLarge, {
        .share-icon-large;
      });

      .respond-to(@bpDesktopSmall, {
        .share-icon-small;
      });

      .share-icon-title {
        font-size: 8px;
      }
    }

    & .share-icon-link {
      height: 65px;
      width: 60px;

      .respond-to(@bpDesktopSmall, {
        height: 40px;
        width: 40px;
      });
    }
  }

  .share-container {
    padding-left: 0;
    text-align: center;

    h3 {
      .respond-to(@bpDesktopMedium, {
        font-size: 20px;
      });
    }

    .respond-to(@bpMobileLarge, {
      border-top: none;
      padding-top: 0;
    });

    .respond-to(@bpDesktopSmall, {
      border-bottom: none;
      padding-top: 30px;
      padding-bottom: 0;
      right: 23px;
      text-align: right;
    });

    .respond-to(@bpDesktopMedium, {
      margin: 0 auto;
      padding-right: 0;
      right: 0;
      width: 1100px;
    });

    .small-divider {
      display: none;

      .respond-to(@bpMobileLarge, {
        border-top: @borderDarkGray;
        display: inline-block;
        margin: 25px 0 35px;
        width: 100px;
      });

      .respond-to(@bpDesktopSmall, {
        display: none;
      });
    }
  }
}

.souljourney__section {
  border-bottom: @borderDarkGray;
  overflow: hidden;
  padding: 35px 15px;
  position: relative;

  .respond-to(@bpMobileLarge, {
    padding: 50px 40px;
  });

  .respond-to(@bpDesktopMedium, {
    padding-bottom: 20px;
    padding-top: 20px;
  });

  &.souljourney__bar-chart-container,
  &.souljourney__line-chart-container {
    padding: 50px 0 30px 0;

    .respond-to(@bpMobileLarge, {
      padding-bottom: 30px;
    });

    .respond-to(@bpTablet, {
      padding-bottom: 40px;
    });
  }

  &.first {
    line-height: 1;
    min-height: 60px;
    overflow: initial;

    .respond-to(@bpMobileLarge, {
      border-bottom: 2px solid @gray-darker;
      min-height: 85px;
    });

    .respond-to(@bpTablet, {
      min-height: 70px;
    });

    .respond-to(@bpDesktopSmall, {
      border-bottom: none;
      min-height: 60px;
      padding-left: 20px;
    });

    .respond-to(@bpDesktopMedium, {
      margin: 0 auto;
      min-height: 85px;
      padding-left: 0;
      padding-right: 0;
      width: 1100px;
    });
  }
}

.souljourney__logo {
  position: absolute;
  right: 50px;
  top: 20px;

  .respond-to(@bpDesktopSmall, {
    right: 20px;
    top: 61px;
  });

  .respond-to(@bpDesktopMedium, {
    right: 0;
    top: -7px;
  });
}

.souljourney__logo__label {
  display: inline-block;
  font-size: 14px;
  margin-right: 40px;
  padding-top: 10px;

  .respond-to(@bpMobileLarge, {
    font-size: 20px;
    padding-top: 15px;
  });

  .respond-to(@bpTablet, {
    font-size: 18px;
  });

  .respond-to(@bpDesktopSmall, {
    margin-right: 5px;
    padding-top: 0;
  });

  .respond-to(@bpDesktopMedium, {
    margin-right: 0;
    font-size: 26px;
  });
}

.souljourney__logo__img {
  position: absolute;
  right: 0;
  top: 7px;
  width: 32px;

  .respond-to(@bpMobileLarge, {
    top: 11px;
  });

  .respond-to(@bpDesktopSmall, {
    top: 24px;
    width: 136px;
  });

  .respond-to(@bpDesktopMedium, {
    width: 195px;
  });
}

.souljourney__rider-container {
  bottom: -14px;
  position: absolute;
  z-index: 10;

  .respond-to(@bpMobileLarge, {
    bottom: -23px;
  });

  .respond-to(@bpDesktopSmall, {
    bottom: -32px;
  });

  .respond-to(@bpDesktopMedium, {
    bottom: -41px;
  });
}

.souljourney__ridername  {
  font-size: 33px;
  letter-spacing: -.92px;

  .respond-to(@bpMobileLarge, {
    font-size: 60px;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 48px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 64px;
  });
}

.souljourney__souliversary-vert {
  color: @gray-darker;
  display: none;
  font-size: 34px;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
    float: left;
  });

  .respond-to(@bpDesktopSmall, {
    display: block;
    float: none;
    margin: 0 auto;
    text-align: center;
    width: 100px;
  });
}

.souljourney__title  {
  font-size: 49px;
  letter-spacing: -1.36px;

  .respond-to(@bpMobileLarge, {
    font-size: 80px;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 112px;
    letter-spacing: -2px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 144px;
    letter-spacing: -4.36px;
  });
}

.souljourney__desktop-container {
  .respond-to(@bpDesktopSmall, {
    border: 3px solid @gray-darker;
    height: 660px;
    margin: 0 20px;
    overflow: hidden;
  });

  .respond-to(@bpDesktopMedium, {
    height: 709px;
    margin: 0 auto;
    width: 1100px;
  });
}

.souljourney__classes-cities {
  .respond-to(@bpDesktopSmall, {
    float: left;
    overflow: hidden;
    width: 33.33%;
  });
}

.souljourney__souliversary-container {
  clear: both;

  .respond-to(@bpDesktopSmall, {
    float: left;
    overflow: hidden;
    width: 33.33%;
  });

  .souljourney__section {
    .respond-to(@bpDesktopSmall, {
      border-bottom: @borderDarkerGray;
      border-left: @borderDarkerGray;
      box-sizing: border-box;
      height: 331px;
      width: 100%;
      padding: 20px 20px;
    });

    .respond-to(@bpDesktopMedium, {
      height: 355px;
      padding: 25px 20px;
    });
  }
}


.souljourney__cities-container {
  .respond-to(@bpDesktopSmall, {
    float: left;
    overflow: hidden;
    width: 50%;
  });

  .souljourney__section {
    .respond-to(@bpDesktopSmall, {
      border-bottom: @borderDarkerGray;
      border-left: @borderDarkerGray;
      box-sizing: border-box;
      height: 331px;
      width: 100%;
      padding: 20px 20px;
    });

    .respond-to(@bpDesktopMedium, {
      height: 355px;
      padding: 25px 20px;
    });
  }
}

.souljourney__cities-container,
.souljourney__souliversary-container {
  height: 100%;
  overflow: visible;
  position: relative;
}

.souljourney__cities-front,
.souljourney__cities-back,
.souljourney__souliversary-container-front,
.souljourney__souliversary-container-back {
  .respond-to(@bpDesktopSmall, {
    background-color: #fff;
    position: absolute;
  });
}

.souljourney__cities-front,
.souljourney__souliversary-container-front {
  .respond-to(@bpDesktopSmall, {
    .transition(all, .2s, ease-in-out);
    background-color: #fff;
    opacity: 1;
    z-index: 2;
  });
}

.souljourney__cities-front:hover,
.souljourney__souliversary-container-front:hover {
  .respond-to(@bpDesktopSmall, {
    .transition(translate(0, -70px));
    .transform(translate);
    opacity: 0;
  });
}

.souljourney__souliversary-container-front {
  .respond-to(@bpMobileSmall, {
    border-top: @borderDarkGray;
  });

  .respond-to(@bpDesktopSmall, {
    border-top: none;
  });

  .souljourney__year-stats {
    .respond-to(@bpDesktopSmall, {
      display: none;
    });
  }
}

.souljourney__cities-back,
.souljourney__souliversary-container-back {
    display: none;
}

.souljourney__cities-back {
  .respond-to(@bpDesktopSmall, {
    display: block;
    z-index: 1;
  });

  .souljourney__fav-studio {
    color: @gray-medium;
    font-size: 83px;
    line-height: 63px;
    margin-top: 5px;
    text-align: center;
    width: 80px;
  }
}

.souljourney__souliversary-container-back {
  .respond-to(@bpDesktopSmall, {
    display: block;
    z-index: 1;
  });

  ul.souljourney__year-stats {
    .respond-to(@bpDesktopSmall, {
      font-size: 26px;
      margin-left: 0;
      margin-top: 3px;
      width: 100%;
    });

    li {
      .respond-to(@bpDesktopSmall, {
        line-height: 20px;
        margin-right: 15px;
        padding-left: 0;
      });

      &:nth-child(2) {
        .respond-to(@bpDesktopSmall, {
          margin-top: 5px;
        });
      }
    }

    li.souljourney__souliversary-horiz {
      color: @gray-darker;

      .respond-to(@bpDesktopSmall, {
        border-bottom: none;
        display: initial;
      });
    }
  }
}

.souljourney__chart-container {
  padding-left: 0;

  .respond-to(@bpMobileLarge, {
    width: 50%;
    float: left;
    box-sizing: border-box;
  });

  .respond-to(@bpDesktopSmall, {
    float: left;
    overflow: hidden;
    width: 33.33%;
  });

  .souljourney__section {
    .respond-to(@bpDesktopSmall, {
      border-bottom: @borderDarkerGray;
      border-left: @borderDarkerGray;
      box-sizing: border-box;
      height: 331px;
      width: 100%;
      padding: 20px 20px;
    });

    .respond-to(@bpDesktopMedium, {
      height: 355px;
      padding: 25px 20px;
    });
  }
}

.souljourney__chart-container--last {
  .respond-to(@bpMobileLarge, {
    border-left: @borderMediumGray;
  });
}

// bar graph styles
#bar-chart,
#line-chart {
  margin: 20px 0 0;

  .respond-to(@bpMobileLarge, {
    height: 180px;
  });

  .respond-to(@bpDesktopSmall, {
    margin-top: 0;
  });
}

.souljourney__bar-chart,
.souljourney__line-chart {
  margin: 0 auto;
  padding-bottom: 20px;
  width: 260px;

  .respond-to(@bpMobileLarge, {
    width: 240px;
  });

  .respond-to(@bpTablet, {
    width: 280px;
  });

  .respond-to(@bpDesktopSmall, {
    margin: 0 auto;
    width: 245px;
  });

  .respond-to(@bpDesktopMedium, {
    width: 278px;
  });
}

.souljourney__line-chart {
  padding-bottom: 0;

  .respond-to(@bpMobileLarge, {
    width: 258px;
  });

  .respond-to(@bpTablet, {
    width: 300px;
  });
}

.souljourney__bar-chart-container,
.souljourney__line-chart-container {
  padding-left: 0;

  .respond-to(@bpMobileLarge, {
    border-bottom: none;
  });

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderDarkGray;
  });

  .souljourney__section-title {
    padding-left: 15px;

    .respond-to(@bpMobileLarge, {
      padding-left: 40px;
    });

    .respond-to(@bpTablet, {
      padding-left: 80px;
    });

    .respond-to(@bpDesktopSmall, {
      padding-left: 0;
    });
  }
}

.souljourney__line-chart-container {
  .respond-to(@bpTablet, {
    padding-bottom: 60px;
  });

  .souljourney__line-chart {
    .respond-to(@bpDesktopSmall, {
      padding-left: 0;
      width: 265px;
    });

    .respond-to(@bpDesktopMedium, {
      width: 295px;
      margin: 0 auto;
    });
  }
}

.souljourney__line-chart-label {
  .font-family(medium);
  .transform(rotate(-90deg));
  display: inline-block;
  font-size: 11px;
  letter-spacing: 1.3px;
  margin-left: -35px;
  position: absolute;
  top: 50%;

  .respond-to(@bpMobileLarge, {
    font-size: 14px;
    margin-left: -38px;
  });

  .respond-to(@bpTablet, {
    margin-left: -43px;
  });
}

.souljourney__section-title {
  padding-bottom: 10px;

  .respond-to(@bpMobileLarge, {
    font-size: 20px;
  });

  .respond-to(@bpDesktopSmall, {
    .font-family(medium);
    font-size: 18px;
    line-height: 20px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 20px;
  });
}

.souljourney__number-classes-container {
  padding-bottom: 0;

  .respond-to(@bpDesktopSmall, {
    float: left;
    overflow: hidden;
    width: 50%;
  });

  .souljourney__section {
    padding-bottom: 0;

    .respond-to(@bpMobileLarge, {
      padding-bottom: 50px;

    });

    .respond-to(@bpDesktopSmall, {
      border-bottom: @borderDarkerGray;
      border-left: @borderDarkerGray;
      box-sizing: border-box;
      height: 331px;
      width: 100%;
      padding: 20px 20px;
    });

    .respond-to(@bpDesktopMedium, {
      height: 355px;
      padding: 25px 20px;
    });
  }
}

.souljourney__number-classes {
  font-size: 167px;
  display: inline-block;
  position: relative;
  text-align: center;

  .respond-to(@bpMobileLarge, {
    float: left;
    font-size: 144px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 5.875rem;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 4.875rem;
    width: 100%;
  });
}

.souljourney__number-classes--medium {
  font-size: 127px;

  .respond-to(@bpMobileLarge, {
    font-size: 144px;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 50px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 70px;
  });
}

.souljourney__number-classes--small {
  font-size: 127px;

  .respond-to(@bpMobileLarge, {
    font-size: 144px;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 35px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 40px;
  });
}

.souljourney__odometer-background {
  background-color: transparent;

  .respond-to(@bpMobileLarge, {
    background-color: @scYellowC;
    height: 80px;
    padding: 5px 15px 30px 5px;
  });

  .respond-to(@bpDesktopSmall, {
    height: 60px;
    padding: 15px 10px 20px;
  });
}

.souljourney__soul-bike {
  display: none;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
    float: left;
    margin-left: 30px;
    margin-top: 10px;
    width: 125px;
  });

  .respond-to(@bpDesktopSmall, {
    margin-left: 0;
    margin-top: 40px;
    width: 85%;
  });

  .respond-to(@bpDesktopMedium, {
    width: 142px;
  });
}

.souljourney__cities {
  .font-family(condensed-black);
  font-size: 29px;
  list-style-type: none;

  .respond-to(@bpMobileLarge, {
    font-size: 60px;
    line-height: 50px;
    width: 440px;
  });

  .respond-to(@bpTablet, {
    padding-right: 100px;
    width: initial;
  });

  .respond-to(@bpDesktopSmall, {
    padding-right: 0;
    margin-top: -3px;
  });

  &.cities-large {
    .respond-to(@bpDesktopSmall, {
      font-size: 32px;
      line-height: 28px;
    });

    .respond-to(@bpDesktopMedium, {
      font-size: 38px;
      line-height: 31px;
    });
  }

  &.cities-medium {
    .respond-to(@bpDesktopSmall, {
      font-size: 30px;
      line-height: 25px;
    });
    .respond-to(@bpDesktopMedium, {
      font-size: 33px;
      line-height: 28px;
    });
  }

  &.cities-small {
    .respond-to(@bpDesktopSmall, {
      font-size: 26px;
      line-height: 22px;
    });
    .respond-to(@bpDesktopMedium, {
      font-size: 31px;
      line-height: 26px;
    });
  }
}

.souljourney__city {
  float: left;
  margin-right: 10px;

  .respond-to(@bpDesktopSmall, {
    float: none;
  });

  &.selected {
    color: @sc-yellow;
  }
}

.souljourney__year-stats {
  .font-family(condensed-black);
  color: @gray-medium;
  font-size: 25px;
  list-style-type: none;

  .respond-to(@bpMobileLarge, {
    float: left;
    font-size: 34px;
    margin-left: 30px;
    margin-top: 10px;
    width: 70%;
  });
}

.souljourney__year-stats-title,
.souljourney__year-stats-item {
  border-bottom: @borderLightGray;
  letter-spacing: -.13px;
  margin-right: 35px;
  padding: 10px 0 5px;

  .respond-to(@bpMobileLarge, {
    letter-spacing: 0.08px;
    line-height: 27px;
    padding-left: 15px;
  });
}

.souljourney__year-stats-title {
  color: @gray-darker;
}

.souljourney__souliversary-horiz {
  color: @gray-darker;
  letter-spacing: initial;

  .respond-to(@bpMobileLarge, {
    display: none;
  });
}

.souliversary-vert__box {
  background-color: @scYellowC;
  border-top: @borderDarkerGray;
  padding-right: 30px;

  &:first-child {
    border-top: none;
  }

  .respond-to(@bpMobileLarge, {
    .font-family(condensed-black);
    font-size: 4.375rem;
    padding: 0.625rem;
  });

  .respond-to(@bpDesktopSmall, {
    background-color: transparent;
  });
}

.souliversary-horiz__box {
  background-color: @scYellowC;
  padding-right: 30px;
  font-size: 26px;
}

.souljourney__soulsquad-container {
  .respond-to(@bpDesktopSmall, {
    width: 66.67%;
    float: right;
    overflow: hidden;
    position: relative;
  });
}

.souljourney__soulsquad-inner-container {
  background-position: 100% 15%;
  background-repeat: no-repeat;
  background-size: 130%;
  border-bottom: @borderDarkGray;
  border-top: none;
  min-height: 280px;
  padding: 50px 40px;
  position: relative;

  .respond-to(@bpMobileLarge, {
    background-size: 100%;
    min-height: 420px;
  });

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderDarkerGray;
    border-left: @borderDarkerGray;
    box-sizing: border-box;
    height: 331px;
    width: 100%;
    padding: 20px 20px;
  });

  .respond-to(@bpDesktopMedium, {
    height: 355px;
    padding: 25px 20px;
  });
}

.souljourney__soulsquad-missing-instructor {
  background-position: 100%;
  background-size: 250px;
  padding-right: 0;

  .respond-to(@bpMobileLarge, {
    background-size: 70%;
  });

  .respond-to(@bpTablet, {
    background-size: 50%;
  });

  .respond-to(@bpDesktopSmall, {
    background-position: 100% 10%;
    background-size: 300px;
  });

  .respond-to(@bpDesktopMedium, {
    background-position: 100% -20%;
    background-size: 370px;
  });
}

.souljourney__soulsquad-title {
  .respond-to(@bpMobileLarge, {
    font-size: 68px;
    letter-spacing: -2.08px;
  });

  .respond-to(@bpDesktopSmall, {
    color: @gray-medium;
    font-size: 60px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 72px;
  });
}

.souljourney__soulsquad-rider {
  display: inline-block;
  padding: 15px 0;

  .respond-to(@bpMobileLarge, {
    padding: 0 0 15px;
  });

  .respond-to(@bpDesktopSmall, {
    padding-bottom: 0;
  });
}
.soulsquad-rider__image-container {
  background-color: @gray-light;
  border-radius: 50%;
  display: inline-block;
  height: 45px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  width: 45px;

  .respond-to(@bpMobileLarge, {
    height: 90px;
    width: 90px;
  });

  .respond-to(@bpTablet, {
    height: 115px;
    width: 115px;
  });

  .respond-to(@bpDesktopSmall, {
    height: 104px;
    width: 104px;
  });
}

.souljourney__soulsquad-image {
  position: absolute;
  top: 0;
  height: 60px;
  right: -15px;

  .respond-to(@bpMobileLarge, {
    height: 120px;
    right: -35px;
  });

  .respond-to(@bpTablet, {
    height: 140px;
  });

  .respond-to(@bpDesktopSmall, {
    right: -40px;
  });
}

.souljourney__soulsquad-image--missing {
    background-color: transparent;
    background-position: center;
    background-size: 130%;
}

.souljourney__soulsquad-gray-button.gray-button {
  display: block;
  margin: 0;
  padding: 0;
  width: 85px;

  .respond-to(@bpMobileLarge, {
    width: 166px;
  });

  .respond-to(@bpDesktopSmall, {
    background-color: rgba(255,255,255,0.85);
    display: none;
    left: 60px;
    position: absolute;
    top: 140px;
    width: 250px;
  });

  .respond-to(@bpDesktopMedium, {
    left: 60px;
    top: 154px;
  });

  .small {
    .respond-to(@bpMobileLarge, {
      display: none;
    });
  }

  .large {
    display: none;

    .respond-to(@bpMobileLarge, {
      display: block;
    });
  }
}

.souljourney__spotify-container {
  padding: 15px;
  text-align: center;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    border-bottom: none;
    padding-top: 40px;
  });

  .respond-to(@bpDesktopSmall, {
    border-top: none;
    position: absolute;
    text-align: left;
    top: 200px;
  });

  .respond-to(@bpDesktopMedium, {
    top: 234px;
  });
}

.souljourney__spotify-cta {
  .box-sizing(border-box);
  .font-family(medium);
  background-color: @scYellowC;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin-right: 30px;
  padding: 15px 15px 15px 48px;
  position: relative;
  text-align: left;

  .respond-to(@bpMobileLarge, {
    text-align: center;
    width: 95%;
  });

  .respond-to(@bpTablet, {
    width: 85%;
  });

  .respond-to(@bpDesktopSmall, {
    padding: 15px 5px 15px 48px;
    width: 525px;
  });
}

.souljourney__spotify-logo {
  left: 15px;
  position: absolute;
  top: 13px;
  width: 25px;

  .respond-to(@bpMobileLarge, {
    left: 12px;
    top: 8px;
    width: 30px;
  });

  .respond-to(@bpTablet, {
    left: 40px;
    width: 35px;
  });

  .respond-to(@bpDesktopSmall, {
    left: 25px;
    top: 6px;
    width: 32px;
  });
}
// END TENTH CONTAINER STYLES

// SOUL SQUAD MODAL
.souljourney__soulsquad {
  background-color: rgba(0,0,0,0.85);
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 20;

  .respond-to(@bpDesktopSmall, {
    top: -50px;
  });
}

.soulsquad__close-modal {
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 15px;
  margin-right: 5px;
  margin-top: 15px;
  position: relative;
  width: 15px;
  z-index: 1;

  .respond-to(@bpDesktopMedium, {
    height: 20px;
    margin-right: -5px;
    margin-top: 15px;
    width: 20px;
  });
}

.soulsquad__container {
  background-color: #fff;
  margin: 10px 20px;
  overflow: hidden;
  position: relative;

  .respond-to(@bpMobileLarge, {
    margin: 40px 30px;
  });

  .respond-to(@bpDesktopSmall, {
    margin: 60px 30px;
  });

  .respond-to(@bpDesktopMedium, {
    width: 1140px;
    margin: 60px auto;
  });
}

.soulsquad__header {
  border-bottom: 2px solid @gray-darker;
  padding: 2px 10px 8px;

  .respond-to(@bpMobileLarge, {
    padding: 5px 15px 24px;
  });

  .respond-to(@bpTablet, {
    padding: 6px 15px 16px;
  });

  .respond-to(@bpDesktopSmall, {
    padding-bottom: 7px;
  });

  .respond-to(@bpDesktopMedium, {
    padding: 0px 30px 12px;
  });
}

.soulsquad__header__text {
  .font-family(bold);
  display: inline-block;
  font-size: 28px;

  .respond-to(@bpMobileLarge, {
    font-size: 55px;
    line-height: 31px;
  });

  .respond-to(@bpTablet, {
    font-size: 60px;
    line-height: 40px;
  });

  .respond-to(@bpDesktopMedium, {
    font-size: 70px;
  });
}

.soulsquad__yellow-highlight {
  background-color: @scYellowC;
  display: inline-block;
  height: 15px;
  margin-top: 10px;
  padding-left: 5px;
  width: 220px;

  .respond-to(@bpMobileLarge, {
    padding-left: 15px;
    padding-top: 20px;
    width: 440px;
  });
  .respond-to(@bpTablet, {
    height: 21px;
    width: 480px;
  });
  .respond-to(@bpDesktopMedium, {
    width: 550px;
  });
}

.soulsquad__instructor {
  border-bottom: @borderDarkGray;
  height: 220px;
  overflow: hidden;
  padding: 15px 0 0;
  position: relative;

  .respond-to(@bpMobileLarge, {
    border-left: @borderDarkGray;
    box-sizing: border-box;
    float: left;
    height: 200px;
    width: 50%;
  });

  .respond-to(@bpTablet, {
    height: 260px;
  });

  .respond-to(@bpDesktopSmall, {
    height: 245px;
    width: 33.33%;
  });

  .respond-to(@bpDesktopMedium, {
    height: 275px;
  });
}

.soulsquad__instructor__name,
.soulsquad__percentage {
  .font-family(bold);
  font-size: 20px;
  line-height: 29px;
  margin-left: 20px;
  position: relative;
  z-index: 2;

  .respond-to(@bpTablet, {
    margin-left: 30px;
  });
}

.soulsquad__instructor__name {
  .respond-to(@bpMobileLarge, {
    font-size: 16px;
  });

  .respond-to(@bpTablet, {
    font-size: 20px;
    margin-bottom: 3px;
  });
}

.soulsquad__percentage {
  .respond-to(@bpMobileLarge, {
    font-size: 14px;
    line-height: 20px;
  });

  .respond-to(@bpTablet, {
    font-size: 18px;
    line-height: 28px;
  });

}
.soulsquad__percentage__yellow-highlight {
  background-color: @scYellowC;
  display: inline-block;
  height: 10px;
  width: 47px;
  padding-left: 5px;

  .respond-to(@bpMobileLarge, {
    height: 7px;
    width: 30px;
  });

  .respond-to(@bpTablet, {
    height: 9px;
    width: 47px;
    padding-left: 7px;
  });
}

.soulsquad__instructor-pic {
  left: 10%;
  line-height: 0;
  position: absolute;
  top: 0;

  .respond-to(@bpMobileLarge, {
    left: 50%;
    margin-left: -180px;
  });
}

.soulsquad__missing-image,
.soulsquad__instructor-image{
  width: 314px;

  .respond-to(@bpMobileLarge, {
    width: 300px;
  });

  .respond-to(@bpTablet, {
    width: 338px;
  });

  .respond-to(@bpDesktopMedium, {
    width: 358px;
  });
}

.soulsquad__missing-image {
  margin-left: 20px;
  margin-top: -22px;
  width: 280px;

  .respond-to(@bpMobileLarge, {
    margin-left: 40px;
    margin-top: -29px;
    width: 250px;
  });

  .respond-to(@bpTablet, {
    margin-left: 20px;
    margin-top: -22px;
    width: 300px;
  });

  .respond-to(@bpDesktopSmall, {
    margin-top: -30px;
  });

  .respond-to(@bpDesktopMedium, {
    margin-top: -15px;
  });
}

.soulsquad__share {
  overflow: hidden;
  text-align: center;

  .respond-to(@bpTablet, {
    text-align: right;
  });

  .respond-to(@bpDesktopSmall, {
    border-bottom: none;
    position: absolute;
    right: 80px;
    top: 10px;
  });

  .respond-to(@bpDesktopMedium, {
    margin: 0 auto;
    right: initial;
    width: 1140px;
  });

  & .share-icons {
    .respond-to(@bpTablet, {
      display: inline;
      margin-right: 20px;
    });

    .respond-to(@bpDesktopMedium, {
      margin-right: 60px;
    });

    & .share-icon {
      cursor: pointer;
      margin: 15px 6px;
      z-index: 1;

      .respond-to(@bpMobileLarge, {
        .share-icon-large;
      });

      .respond-to(@bpTablet, {
        .share-icon-medium;
      });

      .respond-to(@bpDesktopSmall, {
        .share-icon-small;
      });
    }

    & .share-icon-link {
      width: 65px;
      height: 70px;

      .respond-to(@bpTablet, {
        height: 50px;
        width: 50px;
      });

      .respond-to(@bpDesktopSmall, {
        height: 40px;
        width: 40px;
      });
    }

    & .share-icon-title {
      z-index: 0;
    }
  }
}

.soulsquad__share-hide{
  .font-family(medium);
  font-size: 18px;

  .respond-to(@bpTablet, {
    display: inline;
    float: none;
    right: 5px;
    top: -17px;
  });

  .respond-to(@bpDesktopSmall, {
    .font-family(bold);
    font-size: 14px;
  });
}

.soulsquad__share-hide-text {
  .respond-to(@bpDesktopSmall, {
    display: none;
  });
}
// END SOUL SQUD MODAL
