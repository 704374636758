.new_rider_forgot_password {
  .forgot-password-container {
    position: relative;
    width: 100%;

    .respond-to(@bpMobileSmall, {
      margin: 3rem auto;
    });

    .respond-to(@bpMobileLarge, {
      margin: 5rem auto;
    });

    .respond-to(@bpDesktopMedium, {
      width: 70%;
      margin: 7.5rem auto;
    });

    label.field-error {
      text-align: left;

      span.error-message {
        width: 100%;
      }
    }
  }

  .forgot-password__form {
    margin: 0 auto;
    width: 85%;

    .respond-to(@bpMobileLarge, {
      width: 67%;
    });

    .respond-to(@bpTablet, {
      width: 28.75rem;
    });
  }

  .forgot-password__header {
    text-align: center;
  }

  .forgot-password__header__title {
    .font-family(medium);
    font-size: 1.5rem;
    line-height: 2.25rem;

    .respond-to(@bpMobileLarge, {
      font-size: 1.75rem;
    });
  }

  .forgot-password__form__wrapper__field-error {
    color: @red-error;
    font-size: 0.75rem;
  }

  .forgot-password__title-container {
    margin-top: 0.3125rem;

    .respond-to(@bpMobileLarge, {
      margin-top: 1.3125rem;
    });
  }

  .forgot-password__title {
    .font-family(medium);
    color: @gray-darker;
    font-size: 0.6875rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
    line-height: 0.9375rem;
    text-align: center;
    text-transform: uppercase;

    .respond-to(@bpDesktopSmall, {
      font-size: 0.6875rem;
      line-height: 0.9375rem;
    });
  }

  .forgot-password__form__container  {
    align-items: center;
    background: @scWhiteC;
    display: block;
    flex-shrink: 0;
    font-size: 1.125rem;
    height: 100%;
    justify-content: center;
    text-align: left;
    width: 100%;
  }

  .forgot-password__buttons {
    margin-top: 1.75rem;
    text-align: center;
  }

  .forgot-password__buttons__login {
    &.forgot-password__buttons__login--link {
      font-size: 0.8125rem;
      line-height: 0.9375rem;
      text-decoration: underline;
    }
  }

  .forgot-password__buttons__button {
    border-radius: 0.3125rem;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8125rem;
    height: 2.75rem;
    line-height: 0.9375rem;
    margin: 0.3125rem 0.625rem 0.625rem;
    width: 8.125rem;
  }

  .forgot-password__buttons__button--primary {
    background: @sc-yellow;
  }

  .forgot-password__form__wrapper {
    display: inline-block;
    height: 3.688rem;
    margin-top: 1.8rem;
    position: relative;
    width: 100%;

    &.has-field-error {
      .forgot-password__form__wrapper__input {
        border-color: @red-error;
      }
    }
  }

  .forgot-password__form__wrapper__input {
    border: none;  /* This need to be first to remove default border style */
    background-color: @transparent-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0.0625rem solid @gray-darker;
    font-size: 1.625rem;
    margin: 0.625rem 0 0.9375rem 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 2;

    .respond-to(@bpMobileSmall, {
      margin-top: 1.6rem;
      padding-top: 0;
    });

    &:focus {
      &+ .forgot-password__form__wrapper__label {
        color: @gray-darker;
        font-size: 0.75rem;
        left: 0;
        top: 0.2rem;
        z-index: 1;
      }
    }

    &+ .forgot-password__form__wrapper__label {
      &.show {
        color: @gray-darker;
        font-size: 0.75rem;
        left: 0;
        top: 0.2rem;
        z-index: 1;
      }
    }
  }

  .forgot-password__form__wrapper__label {
    .transition(all, 0.1s, linear);
    color: @gray-lighter;
    cursor: auto;
    font-size: 1.625rem;
    left: 0;
    letter-spacing: 0;
    position: absolute;
    text-align: left;
    top: 1.7rem;
    z-index: 1;
  }
}
