.waiver-landing-container {
  .font-family(medium);
  padding: 0 @bpMobileSmallGutter;
  margin-top: 1rem;

  .respond-to(@bpTablet, {
    padding: 0 @bpMobileLargeGutter;
    margin-top: 1.75rem;
  });
  .respond-to(@bpDesktopSmall, {
    padding: 0 @bpTabletGutter;
    margin-bottom: 3.5rem;
  });
  .respond-to(@bpDesktopLarge, {
    padding: 0 @bpDesktopSmallGutter;
  });

  .form-streaming-waiver {
    border: 1px solid #EEE;
    border-radius: 0.28em;
    box-sizing: border-box;
    .font-family(light);
    font-size: 0.75rem;
    height: 23rem;
    margin-bottom: 1rem;
    max-width: 100%;
    overflow-x: auto;
    padding: 0.8rem;
    width: initial;

    .respond-to(@bpTablet, {
      height: 35rem;
      padding: 0.925rem;
      margin-bottom: 1.875rem;
    });
    .respond-to(@bpDesktopSmall, {
      height: 31rem;
      padding: 0.925rem 1.25rem;
    });
    .respond-to(@bpDesktopLarge, {
      padding: 0.925rem 1.875rem;
    });
  
    h1 {
      .font-family(medium);
      font-size: 2em;
      padding-bottom: .5em;
      text-decoration: underline;
    }
  
    h2 {
      .font-family(medium);
      font-size: 1rem;
      line-height: 1.125rem;
      margin: 0.875rem auto 0.25rem auto;
      text-decoration: underline;
    }
  
    h3 { 
      .font-family(bold);
      font-size: .75rem;
      line-height: 0.93rem;
      margin: 0.625rem 0 0;
      text-decoration: underline;
    }
  
    ul {
      padding-left: 2.5rem;
    }
  
    p {
      padding-bottom: 0.3rem;
      line-height: 1rem;
  
      a { 
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .waiver-agreement {
    font-size: 1rem;
    margin: 0 0 0.625rem;

    .respond-to(@bpTablet, {
      font-size: 1.1rem;
      margin-bottom: 1rem;
    });

    a {
      text-decoration: underline;
    }
  }

  .create-account-button {
    font-size: 1rem;
    margin-bottom: 2.5rem;
    padding: 0.75rem 0.9rem;
    width: 18rem;
  }
}
