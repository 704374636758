/* FIND A CLASS STYLES
-----------------------------------------------------------------------------*/

.find-class-container {
  border-bottom: @borderLightGray;
  position: relative;
  width: 100%;
  .font-family(light);

  .days-of-week {
    position: relative;
    z-index: 10;
    .today {
      .font-family(medium);
      color: @gray-darker;
    }
  }
  .classes-days {
    z-index: 10;
    &:first-child {
      .font-family(bold);
      color: @gray-darker;
    }
  }

  .truncate {
    display: inline-block;
    letter-spacing: 14px;
    overflow: hidden;
    white-space: nowrap;
    width: 10px;
  }
  .monday,
  .tuesday,
  .wednesday,
  .friday {
    padding-left: 2px;
  }
  .thursday,
  .saturday,
  .sunday {
    letter-spacing: 1.5px;
    width: 15px;
  }

  .days-of-week-container {
    position: relative;
    width: 100%;
    z-index: 100;
    background: @gray-main;
    top: 0;
    padding-bottom: 30px;
  }

  .fix-days-of-week {
    position: fixed;
    top: 59px;
  }

  .prepend-month {
    display: none;
  }

  .studio-title-short,
  .studio-title-watermark {
    .font-family(heavy);
    .transition(opacity, 400ms, ease-in-out);
    color: @gray-light;
    font-size: 9.5em;
    text-align: center;

    &.active {
      opacity: 1;
    }
  }

  .studio-title-watermark {
    font-size: 7em;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .studio-info-container {
    background-color: @scWhiteC;
    cursor: pointer;
    display: block;
    height: 130px;
    text-align: center;

    &:hover {
      .studio-title-short {
        color: @sc-yellow;
      }

      .studio-details-link {
        color: @black;
        font-weight: bold;

        .next-icon {
          background: url('/assets/images/swiper-next-dark.png') no-repeat;
          background-size: 0.6875rem 1.0625rem;
        }
      }
    }

    .studio-details-link {
      color: @gray-67;
      display: block;
      font-size: 0.875rem;
      margin: 0.3rem 0 1.25rem .6875rem;
      text-align: center;

      .respond-to(@bpTablet, {
        font-size: 1rem;
        margin: 1.5625rem 0 1.25rem
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 1.1rem;
        margin: 1.5rem 0 1.25rem
      });

      .respond-to(@bpDesktopLarge, {
        font-size: 1.2rem;
        margin: 1.25rem 0 1.25rem
      });

      .next-icon {
        background: url('/assets/images/swiper-next.svg') no-repeat;
        background-size: 0.6875rem 1.0625rem;
        display: inline-block;
        height: 1.125rem;
        margin-bottom: -0.375rem;
        margin-left: 0.3125rem;
        width: 0.75rem;

        .respond-to(@bpDesktopSmall, {
          margin-bottom: -0.25rem;
        });
      }
    }

    .studio-info {
      .font-family(light);
      color: #151515;
      letter-spacing: -0.5px;
      margin-top: -66px;
    }

    .studio-title {
      .font-family(heavy);
      color: @gray-darker;
      font-size: 1.8em;
      position: relative;
    }

    .studio-address,
    .studio-phone {
      font-size: 1.4em;
      padding-top: 4px;
    }

  }

  .small-nav-container {
    .center-container();
    clear: both;
    font-size: 1.4em;
    padding: 20px 0 15px 0;
    position: relative;
    text-align: center;
    width: 305px;
  }

  .week-info {
    color: @gray-darker;
    letter-spacing: 0;
    line-height: 11px;
    position: relative;
    visibility: hidden;
  }

  .change-week {
    cursor: pointer;
    display: block;
    position: relative;
    top: -23px;
    z-index: 20;

    img {
      .transform(scale(0.9));
      font-size: 0;
      height: 15px;
      object-fit: none;
      object-position: -196px -110px;

      &:before {
        background-color: @gray-main;
        content: attr(alt);
        font-size: 13px;
        height: 24px;
        left: -3px;
        position: absolute;
        top: -7px;
        width: 60px;
      }
    }

    &.prev-week-small {
      .flip-horizontally;
      float: left;
      margin: 20px 25px 20px 15px;

      img:before {
        .flip-horizontally;
      }
    }
    &.next-week-small {
      float: right;
      margin: 20px 15px 20px 25px;
    }
  }

  .days-of-week ul {
    .font-family(roman);
    color: @gray-medium;
    display: table;
    float: left;
    font-size: 1.2em;
    list-style: none;
    margin-bottom: -4px;
    margin-top: -15px;
    padding-left: 6%;
    width: 90%;
    li {
      display: table-cell;
      width: 100px;
      text-align: center;
    }
  }

  .classes-days {
    display: table;
    width: 90%;
    padding-left: 6%;
    .column-header {
      display: table-cell;
      width: 20px;
      text-align: center;
      cursor: pointer;
    }
  }

  .selected-day {
    background-color: @gray-darker;
    border-radius: 50%;
    color: @scWhiteC;
    display: inline-block;
    height: 30px;
    margin-left: -16px;
    margin-top: 4px;
    position: absolute;
    top: 0;
    width: 30px;
    .date {
      margin-top: -4px;
    }
  }

  .active-day {
    display: inline-block;
  }

  .active-day.today .date {
    margin-top: -19px;
    padding: 0;
  }

  .active-day.today {
    background-color: @gray-darker;
    color: @gray-darker;
    display: none;
    height: 4px;
    margin-left: -11px;
    margin-top: 32px;
    position: absolute;
    width: 22px;
    &.selected-day {
      border-radius: 50%;
      display: inline-block;
      height: 32px;
      margin-left: -16px;
      margin-top: 5px;
      width: 32px;
      .date {
        color: #fff;
        margin-top: 10px;
      }
    }
  }


  .classes-week-cols,
  .classes-days {
    clear: both;
    position: relative;

    .column-day {
      &.today {
        display: block;
        .column-sessions {
          width: 100%;
          left: 0;
        }
      }
    }

    .column-header {
      .font-family(bold);
      white-space: nowrap;
      &.today {
        .font-family(bold);
        .active-day {
          display: inline-block;
          top: 0;
        }
      }
    }

    .column-day {
      transition: width .4s;
      -moz-transition: width .4s; /* Firefox 4 */
      -webkit-transition: width .4s; /* Safari and Chrome */
      display: none;

      &:first-child {
        margin-left: 0;
      }
    }

    .day {
      font-size: 2em;
    }

    .date {
      display: block;
      font-size: 1.4em;
      padding-top: 13px;
      position: relative;
      z-index: 2;
    }

    .new-tag {
      .font-family(bold);
      font-size: 12px;
      letter-spacing: 4.29px;
      line-height: 12px;
      position: absolute;
      top: 24px;
      left: 50px;
    }

    a {
      .session-btn {
        border-radius: 5px;
        color: @gray-darker;
        line-height: 30px;
        position: absolute;
        right: 16px;
        top: 55px;
        z-index: 10;
      }
      &.reserve {
        .session-btn;
      }
      &.waitlist {
        .gray-button;
        .session-btn;
        background: @gray-main;
        border: 1px solid #575757;
        display: none;

        &:hover {
          background: @gray-light;
        }
      }

      &.supersoul {
        .font-family(medium);
        .gray-button;
        .session-btn;
        background: none;
        color: @gray-lighter;
        display: none;
        padding: 0;
      }
    }


    .column-sessions {
      clear: both;
      font-size: 11px;

      .session {
        overflow: hidden;
        position: relative;
        padding-top: 45px;
        height: 90px;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        text-align: left;

        .studio-name,
        .session-heading-2 {
          .opacity-hover(@opacity-70);
          font-size: 14px;
        }

        .bookmark {
          left: 16px;
        }

        &.no-sessions {
          margin-bottom: 4px;
          span {
            color: @gray-medium;
          }
        }

        a.reserve {
          &:focus {
            clip: auto;
            height: auto;
            width: auto;
          }
        }

        .reserved-text,
        .waitlisted-text {
          .font-family(heavy);
          color: @gray-darker;
          display: none;
          font-size: 60px;
          margin-left: auto;
          margin-right: auto;
          position: relative;
          text-align: center;
        }
        &:first-child {
          margin-top: 0;
        }

        &.expired {
          color: #cfcfcf;

          .bookmark {
            visibility: hidden !important;
          }

          a.reserved,
          a.supersoul,
          a.waitlist,
          a.reserve {
            display: none !important;
          }

          span.instructor a,
          span.class-type a,
          span.studio-name,
          span.cost,
          span.session-heading-2,
          a {
            color: #cfcfcf;
          }

          &.waitlisted {
            .bookmark {
              visibility: hidden;
            }

            .waitlist,
            .reserved-text {
              display: none;
            }

            .waitlisted-text {
              display: block;
            }
          }

          &.enrolled {
            background-color: @scYellowC;

            .reserved-text {
              display: block;
            }
          }
        }

        &.full {
          background: @gray-main;

          .waitlist {
            display: inline-block;
          }

          .reserve {
            display: none;
          }

          .supersoul {
            display: none;
          }
        }

        &.supersoul {
            &.full {
              .supersoul {
                background: none;
              }
            }

            .supersoul {
              .opacity-hover(@opacity-70);
              display: inline-block;
              opacity: 1; // REMOVING OPACITY CHANGE ON HOVER TO REFLECT LATEST DESIGN NEEDS
            }

            .reserve,
            .waitlist {
              display: none !important;
            }
        }

        &.enrolled {
          background: @sc-yellow;

          .reserve {
            .gray-button;
            background-color: #fff;
            top: 55px;
            right: 16px;
            position: absolute;

            &:hover {
              background: @white-70;
            }
          }
          .bookmark {
            visibility: hidden;
          }
          .reserved-text {
            display: block;
          }
          .studio-name,
          .session-heading-2 {
            color: @gray-darker;
          }
          &.full {
            background-color: @scYellowC;

            .reserve {
              visibility: hidden;
            }

            .waitlist {
              background: @scWhiteC;
              display: block;
              opacity: 1;

              &:hover {
                background: @white-70;
              }
            }
          }

          &.waitlisted {
            .waitlisted-text {
              display: none;
            }
          }
        }


        &.waitlisted {
          .bookmark {
            visibility: hidden;
          }

          .waitlisted-text {
            display: block;
          }
        }

        &:focus {
          z-index: 99;
        }
      }

      .time,
      .instructor,
      .studio-name,
      .cost,
      .session-heading-2,
      .class-type {
        display: block;
        padding-left: 50px;
        padding-right: 10px;
        text-align: left;
        width: 50%;
      }

      .instructor,
      .studio-name,
      .class-type {
        .font-family(medium);
        font-size: 14px;
        margin-top: 2px;
        position: relative;
        &.favorited:after {
          content: url('/assets/images/find-a-class-heart.png');
          position: absolute;
          padding-left: 8px;
          top: 0;
          width: 13px;
          text-align: right;
          line-height: 1;
        }
      }

      .studio-name {
        margin-bottom: 4px;
      }

      .class-type {
        display: inline-block;
        margin-top: 8px;
        margin-bottom: 4px;

      }

      .studio-name,
      .studio-name a,
      .cost,
      .session-heading-2,
      .session-heading-2 a {
        .font-family(medium);
        color: #8B8A8D;
        margin-bottom: 2px;
      }

      .cost {
        font-size: 0.9em;

        &:before {
          vertical-align: middle;
          margin: 2px;
          line-height: 0.6em;
          font-size: 0.6em;
          content: "\2605";
          position: relative;
        }
      }
    }
  }


  /* Remove fav icon if expired */
  .expired {
    .instructor,
    .studio-name
    .class-type {
      &.favorited:after {
        display:none;
      }
    }
  }

}

// Instructor Schedule ONLY classes
.instructor_schedule {
  padding-left: 0 !important;

  .modal-white-bg {
    display: none;
  }

  .studio-title-watermark {
    font-size: 7em;
    left: 0;
  }

  .studio-title-short {
    font-size: 13em;
    white-space: nowrap;
  }
  .studio-info-container {
    width: 100%;

    .studio-info {
      .font-family(bold);
      margin-left: 20px;
      margin-top: -80px;
      text-align: left;
    }

    .instructor-name {
      position: relative;
      font-size: 2.5em;
    }

    .instructor-type {
      color: @gray-annex;
      font-size: 14px;
      letter-spacing: 3px;
      margin-top: 4px;
    }

    .instructor-image {
      width: 240px;
      position: absolute;
      top: 12px;
      right: -65px;
    }

    .instructor-bio {
      display: none;
    }
  }

}


/* MEDIA QUERIES - FIND A CLASS STYLES
-----------------------------------------------------------------------------*/

// css width (px) < 599px
@media only screen and (max-width : 599px) {
  .find-class-container  {
    .column-sessions {
      .reserved-text,
      .waitlisted-text {
        top: -9px;
        width: 264px;
      }
    }
  }
}

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
  .find-class-container  {
      .classes-week-cols,
      .classes-days {
        a.reserve,
        a.supersoul,
        a.waitlist,
        .column-sessions .session.enrolled a.reserve {
          right: 78px;
          top: 45px;
        }

        a.reserve {
          clip: auto;
          height: auto;
          width: auto;
        }

        .new-tag {
          top: 16px;
        }

      .column-sessions {
        .reserved-text,
        .waitlisted-text {
          font-size: 67px;
          top: -39px;
          width: 264px;
        }
        .session {
          padding-top: 35px;
          height: 80px;

          .bookmark {
            left: 24px;
          }
        }
        .classes-week-cols {
          .time,
          .instructor,
          .studio-name,
          .cost,
          .session-heading-2
          .class-type {
            padding-left: 76px;
          }
        }
      }
    }
  }

   // Instructor Schedule ONLY classes
  .instructor_schedule {
    .studio-title-watermark {
      font-size: 8em;
    }
    .studio-title-short {
      font-size: 14em;
    }
    .studio-info-container {
      .studio-info {
        margin-left: 0;
        margin-top: -84px;
        text-align: center;
      }
      .instructor-image {
        width: 400px;
        position: absolute;
        top: -5px;
        right: -100px;
      }
    }
  }

}

// 768px < css width (px) < 1023px
@media only screen and (min-width : 768px) {
  .find-class-container  {
    .truncate {
      letter-spacing: normal;
      width: 24px;
    }
    .tuesday {
      width: 19px;
    }
    .thursday {
      width: 20px;
    }
    .friday {
      width: 13px;
    }
    .saturday {
      width: 18px;
    }
    .sunday {
      width: 21px;
    }
    .studio-info-container {
      .studio-info {
        margin-top: -80px;
      }
      .studio-title,
      .studio-address,
      .studio-phone {
        background-color: transparent;
        display: inline;
        letter-spacing: 0;
      }
      .studio-title {
        margin: 5px 20px 0 0;
      }
      .studio-title-short {
        font-size: 13em;
      }
      .studio-phone {
        margin-left: 20px;
      }
    }
    .classes-week-cols,
    .classes-days {

      .new-tag {
        left: 96px;
        top: 24px;
      }

      a.reserve,
      a.supersoul,
      a.waitlist {
        right: 96px;
      }
      a.reserve,
      a.waitlist,
      a.supersoul {
        top: 52px;
        right: 40px;
      }
      .column-sessions {
        .session {
          padding-top: 45px;
          height: 91px;

          .bookmark {
            left: 32px;
          }

          &.enrolled {
            a.reserve,
            a.waitlist {
              top: 52px;
              right: 40px;
            }
          }
        }
        .time,
        .instructor,
        .studio-name,
        .cost,
        .session-heading-2,
        .class-type {
          padding-left: 97px;
        }

        .reserved-text, .waitlisted-text {
          top: -28px;
          width: 264px;
        }
      }
    }
  }

   // Instructor Schedule ONLY classes
  .instructor_schedule {
    .studio-info-container {
      .studio-info {
        margin-left: 0;
        margin-top: -90px;
      }

      .instructor-name {
        font-size: 3.5em;
      }

      .studio-title-short {
        font-size: 14em;
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width : 1024px) {
  .find-class-container {
    border-bottom: none;
    overflow-x: hidden;
    padding-bottom: @footerHeightDesktop;

    .active-day.today {
      border-radius: 0;
      width: 22px;
      height: 4px;
      margin-top: 40px;
      margin-left: -11px;
    }
    .active-day.today {
      &.selected-day {
        border-radius: 0;
        height: 4px;
        margin-left: -11px;
        margin-top: 32px;
        width: 22px;
        .date {
          color: @gray-darker;
          margin-top: -20px;
        }
      }
    }
    .active-day{
      &.selected-day {
        border-radius: 0;
        width: 0;
        height: 0;
        margin-top: 0;
        margin-left: -11px;
        .date {
          color: @gray-darker;
          margin-top: 0;
        }
      }
    }

    .studio-info-container {
      .studio-address, .studio-phone {
        letter-spacing: -1px;
      }
      .studio-title,
      .studio-address,
      .studio-phone {
        font-size: 2.1em;
      }
    }

    .days-of-week ul,
    .classes-days {
      width: 100%;
      padding-left: 0;
      .column-header:first-child {
        .prepend-month {
          display: inline;
        }
      }
    }
    .classes-week-cols,
    .classes-days {

      .column-header {
        pointer-events: none;
        &.today {
          color: @gray-darker;
        }
      }
      a.reserve,
      a.waitlist,
      a.supersoul {
        bottom: 20px;
        left: 20px;
        right: auto;
        top: auto;
      }
      .column-day {
        display: block !important;
      }
      .column-sessions,
      .column-day.today .column-sessions {
        display: block;
        clear: none;
        float: left;
        width: 12.5%;
        .session {
          height: 171px;
          padding-right: 2px;
          padding-top: 65px;
          border: 1px solid @gray-main;

          .bookmark {
            left: 16px;
          }

          .new-tag {
            left: 20px;
            top: 48px;
          }

          &.enrolled {
            a.reserve,
            a.waitlist {
              bottom: 35px;
              left: 20px;
              top: auto;
              right: auto;
            }
          }

          .reserved-text, .waitlisted-text {
            font-size: 24px;
            top: 88px;
            width: 118px;
          }
        }
        .time,
        .instructor,
        .studio-name,
        .cost,
        .session-heading-2,
        .class-type {
          padding-left: 20px;
          width: initial;
        }
      }
    }
  }

  // Instructor Schedule ONLY classes
  .instructor_schedule {
    .studio-title-watermark {
      font-size: 8em;
    }

    .studio-info-container {
      .studio-info {
        margin-left: 0;
      }

      .instructor-image {
        right: 0;
      }
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width : 1440px) {
  .find-class-container {
    .truncate {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      letter-spacing: normal;
      width: initial;
    }
    .thursday, .saturday, .sunday {
      letter-spacing: normal;
      width: initial;
    }
    .studio-info-container {
      .studio-info {
        margin-top: -85px;
      }
      .studio-title-short {
        font-size: 14em;
      }
      .studio-title,
      .studio-address{
        font-size: 2.8em;
      }
      .studio-phone {
        font-size: 2.8em;
      }
    }

    .studio-title-watermark {
      font-size: 11.5em;
      margin: 0 auto;
    }

    .classes-week-cols,
    .classes-days {
      .column-sessions,
      .column-day.today .column-sessions {
        .session {
          height: 140px;
        }
      }
      .column-sessions {
        .session {
          .reserved-text, .waitlisted-text {
            font-size: 30px;
            top: 51px;
            width: 134px;
          }

          .waitlisted-text {
            width: 147px;
          }

          a.reserve {
            clip: rect(0 0 0 0);
            height: 1px;
            overflow: hidden;
            width: 1px;
          }

          a.waitlist,
          a.supersoul {
            display: none;
          }

          &.full {
            a.waitlist {
              display: none;
            }

            &:hover {
              .waitlist {
                display: block;
              }
            }
          }
          &.open {
            &:hover {
              .reserve {
                clip: auto;
                height: auto;
                width: auto;
              }
            }
          }

          &.supersoul {
            .supersoul {
              display: none;
            }

            &:hover {
              .supersoul {
                display: block;
              }

              a.reserve {
                display: none;
              }
            }
          }
          &.enrolled {
            &.open, &.full {
              .reserve,
              .waitlist {
                display: none;
              }
            }

            &.open:hover {
              .reserve {
                display: block;
              }
            }

            &.full:hover, &.full.waitlisted:hover {
              .waitlist {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  // Instructor Schedule ONLY classes
  .instructor_schedule {
    .studio-title-watermark {
      font-size: 11.5em;
      left: 0;
    }
    .studio-info-container {
      .instructor-bio {
        display: block;
        position: absolute;
        font-size: 2em;
        left: 100px;
        top: 57px;
        width: 320px;
        height: 50px;
      }
    }
  }
}
