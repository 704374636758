/*
New To Soul/Experience/instructors Page Styles
*/

.new-to-soul__section--instructors {
  .flex-display(flex);
  height: auto;
}

.new-to-soul__section__instructors-content-wrapper {
  .flex-display(flex);
  .flex-wrap(wrap);
  .justify-content(center);
  background-color: @black;
  width: 100%;
}

.new-to-soul__section__instructors-image-list {
  .flex-display(flex);
  height: 27.75rem;
  width: 100%;
  list-style: none;
}

#new-to-soul__section-special-img {
  .flex(1);
  .flex-display(flex);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.new-to-soul__section__instructors-list-item {
  display: none;

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    display: block;
    height: 100%;
    width: 25%;

    .new-to-soul__section-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  });

  &.is-old-browser {
    background-position: center center;
    background-size: cover;
  }
}

.new-to-soul__section__instructors-description {
  .flex-direction(column);
  .flex-display(flex);
  color: @scWhiteC;
  font-size: 0.937rem;
  line-height: 1.5;
  margin-top: -35%;
  max-width: 23.4375rem;
  padding: 3rem 1.5rem 3.5rem;
  z-index: 2;

  h3 {
    font-size: 2.5rem;
    max-width: max-content;
  }

  p {
    .font-family(roman);
    max-width: inherit;
  }

  .respond-to(@bpMobileMedium, {
    margin-top: -24%;

    h3 {
      font-size: 2.4rem;
    }
  });

  .respond-to(@bpMobileLarge, {
    margin-top: -20%;
    max-width: 31.5rem;
    padding: 3rem 0 3.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    .align-items(center);
    font-size: 1.125rem;
    margin-top: -11%;
    max-width: 32rem;
    padding: 5rem 0 6rem;

    p {
      text-align: center;
    }

    h3 {
      font-size: 3.12rem;
      max-width: none;
    }
  });

  .respond-to(@bpDesktopLarge, {
    margin-top: -9%;
  });

  @media only screen and (min-width: 1920px) {
    margin-top: -6%;
  }
}

.new-to-soul__section__instructors-description-button-wrapper {
  .align-items(baseline);
  .flex-display(flex);
  .justify-content(flex-start);
  width: 12.5rem;

  .respond-to(@bpDesktopSmall, {
    .justify-content(center);
  });
}

.new-to-soul__section__instructors-description-right-arrow {
  font-size: 0.8rem;
  margin-left: 0.625rem;
  position: static;
  transform: rotate(270deg);
}

.new-to-soul__section__instructors-black-gradient {
  background: linear-gradient(180deg, @gray-0 0%, @black 61.06%, @black 100%);
  height: 100%;
  position: absolute;
  width: 100%;

  @media only screen and (max-width: 480px) {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0, @black 70%, @black 100%);
  }
}
