// New To Soul? (Experience) Page Nave
.new-to-soul__nav-container {
  display: none;
  height: calc(~"100vh" - (@footerHeightDesktop + @headerHeight));
  left: 1rem;
  pointer-events: none;
  position: fixed;
  z-index: 1;

  .respond-to(@bpDesktopSmall, {
    &.new-to-soul__nav-container--active {
      .align-items(center);
      .flex-display(flex);
    }
  });
}

.new-to-soul__nav {
  .flex-display(flex);
  height: auto;
  padding: 2rem;
  pointer-events: auto;
}

.new-to-soul__nav__link-container {
  border-left: 0.0625rem solid @scWhiteC;
}

.new-to-soul__nav__item {
  list-style: none;
  position: relative;
}

.new-to-soul__nav__item--active {
  &:before {
    border-left: .3125rem solid @scWhiteC;
    content: '';
    height: 100%;
    left: -0.1875rem;
    position: absolute;
  }

  .new-to-soul__nav__link {
    .font-family(bold);
  }
}

// Include element tag to increase specificity.
a.new-to-soul__nav__link {
  .font-family(roman);
  color: @scWhiteC;
  display: block;
  font-size: 0.75rem;
  padding: 0.75rem;

  &:hover {
    .font-family(bold);
    color: @scWhiteC;
  }
}

/*
 * New Soul Variation B Styles
 */
.new-to-soul-container--variation-find-a-class-cta {
  .new-to-soul__find-a-class {
    bottom: 1rem;
    opacity: 0;
    position: fixed;
    text-align: center;
    transition: all 200ms ease;
    visibility: hidden;
    width: 100%;
    z-index: 3;

    .respond-to(@bpMobileMedium, {
      bottom: 4rem;
      left: 2rem;
      width: auto;
      z-index: 3;
    });

    .respond-to(@bpTablet, {
      bottom: 4rem;
    });

    .respond-to(@bpDesktopSmall, {
      bottom: 7rem;
    });

    .find-a-class-fixed-button {
      height: 2.5rem;
      line-height: 2.5rem;

      transition: width 200ms ease;
      width: 7.5rem;

      .respond-to(@bpMobileSmall, {
        &.new-to-soul__find-a-class-wide {
          width: 90%;
        }
      });

      .respond-to(@bpMobileMedium, {
        &.new-to-soul__find-a-class-wide {
          width: 7.5rem;
        }
      });
    }

    &.new-to-soul__find-a-class--active {
      opacity: 1;
      visibility: visible;
    }
  }
}
