/* 3 PACK LANDING PAGE STYLES
-----------------------------------------------------------------------------*/

.three-pack-landing-page {

  .three-pack-header {

    .logo-tier {
      background: url(/assets/images/threepack/logo.png);
      background-size: 76px 10px;
      height: 10px;
      margin: 15px auto;
      text-align: center;
      width: 76px;
      .respond-to(@bpDesktopSmall, {
        background-size: 150px 21px;
        height: 21px;
        margin: 32px auto;
        width: 150px;
      });
    }

    .tier1 {
      background: @gray-strong-dark;
    }

    .header-cover-image {
      .container-width;
      background: url(/assets/images/threepack/header-mobile.png);
      background-color: @gray-0;
      background-position: right top;
      background-repeat: no-repeat;
      height: 115px;
      max-width: none;
      padding-bottom: 38px;
      padding-top: 145px;
      .respond-to(@bpMobileLarge, {
        background: url(/assets/images/threepack/header-desktop.png);
        background-size: cover;
      });
      .respond-to(@bpDesktopSmall, {
        background-position: left bottom;
        min-height: 274px;
        padding-bottom: 163px;
        padding-top: 147px ;
      });

      p {
        .font-family(light);
        color: @scWhiteC;
        font-size: 14px;
        line-height: 18px;
        margin-top: 24px;
        .respond-to(@bpDesktopSmall, {
          .font-family(roman);
          line-height: 20px;  
        });
      }
    }

    .tier2 {
      height: 440px;
      overflow: hidden;
      padding-bottom: 0;
      padding-top: 35px;
      position: relative;
      .respond-to(@bpMobileLarge, {
        height: 390px;
      });
      .respond-to(@bpDesktopSmall, {
        height: auto;
        padding-top: 58px;
      });

      p {
        .font-family(roman);
        color: @gray-darker;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 19px;
        margin-top: 0;
        .respond-to(@bpDesktopSmall, {
          .font-family(light);
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 50px;
        });
        .respond-to(@bpDesktopMedium, {
          margin-bottom: 47px;
        });
      }

      .three-pack-container {
        overflow: hidden;
      }
    }

    h1 {
      .font-family(light);
      color: @scWhiteC;
      font-size: 20px;
      letter-spacing: 0.42px;
      line-height: 24px;
      margin-bottom: -9px;
      .respond-to(@bpDesktopSmall, {
        font-size: 36px;
        line-height: 37px;
      });
    }

    h2 {
      .font-family(bold);
      color: @scWhiteC;
      font-size: 10px;
      letter-spacing: 2.14px;
      line-height: 23px;
      margin-top: 12px;
      .respond-to(@bpDesktopSmall, {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 23px;
      });

    }

    .find-your-soul {
      .font-family(light);
      color: @gray-darker;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 18px;
      .respond-to(@bpDesktopSmall, {
        float: right;
        max-width: 563px;
        width: 53%;
      });
      .respond-to(@bpDesktopMedium, {
        max-width: none;
        width: 64%;
      });

      .soul-heading {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 15px;
        .respond-to(@bpDesktopSmall, { 
          font-size: 36px;
          letter-spacing: 0.5px; 
          line-height: 38px;
          margin-bottom: 6px;
          margin-bottom: 6px;
        });
      }

      .soul {
        display: none;
        .respond-to(@bpDesktopSmall, {
          display: inline-block;  
        });
      }
    }

    .breakthrough-video-content {
      background-color: black;
      bottom: 0;
      height: 214px;
      left: 0;
      position: absolute;
      width: 100%;
      .respond-to(@bpDesktopSmall, {
        background: none;
        float: right;
        height: 260px;
        margin-right: 27px;
        position: static;
        width: 354px;
      });
      .respond-to(@bpDesktopMedium, {
        height: 205px;
      });
      .respond-to(@bpDesktopLarge, {
        width: 361px;
      });

      .video {
        background: black url(/assets/images/threepack/video.png) no-repeat center/contain; 
        display: block;
        height: 100%;
        margin: auto;
        max-width: 100%;
        .respond-to(@bpDesktopSmall, {
          height: 100%;
        });
      }
    }

    .lightbox-video {
      background-color: @gray-50;
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      transition: visibility 2s linear 2s;
      visibility: hidden;
      width: 100%;
      z-index: 3;
    
      .video-frame {
        bottom: 0;
        height: 50%;
        left: 0;
        margin: auto;
        overflow: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 87%;
        .respond-to(@bpDesktopSmall, {
          width: 50%;
        });
      }
    
      &.active,
      .active {
        visibility: visible;
      }
    }
    
    .first-ride {
      .font-family(bold);
      border: 1px solid @gray-38;
      display: inline-block;
      font-size: 14px;
      height: 32px;
      letter-spacing: 1.4px;
      margin-bottom: 40px;
      text-align: center;
      width: 100%;
      .respond-to(@bpDesktopSmall, {
        float: left;
        font-size: 14px;
        height: 34px;
        letter-spacing: 2px;
        margin-bottom: 63px;
        width: 255px;
      });

      span {
        background: @scWhiteC;
        color: @gray-darker;
        display: inline-block;
        margin: 9px auto;
      }
    }
    
    .get-3pack-content, 
    .get-the-3pack {
      height: 28px;
    }
    
    .get-the-3pack {
      .font-family(medium);
      border-radius: 0;
      font-size: 10px;
      line-height: 30px;
      margin-top: 17px;
      width: 160px;
      z-index: 2;
      .respond-to(@bpDesktopSmall, {
        font-size: 14px;
        height: 34px;
        letter-spacing: 3px;
        line-height: 38px;
        margin-top: 49px;
        width: 225px;
      });
    
      &.affix {
        box-shadow: 0 2px 4px 0 @gray-38;
        font-size: 18px;
        height: 40px;
        line-height: 45px;
        margin-bottom: 15px;
        max-width: 292px;
        padding: 0;
        width: 100%;
        .respond-to(@bpTablet, {
          position: static;
        });
      }
    }
    
    .link-box {
      display: inline-block;
    }
    
    .break-line {
      display: block;
      width: 100%;
    }
    
    .affix {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
    }
  }
  
  .three-pack-footer {
  
    .tier-image {
      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }
  }
  
  .community-section {
    .box-sizing(border-box);
    .font-family(light);
    .gradient-custom(~"-5deg, @{white-gray-08} 57%, @{white-gray-bg} 82%");
    height: 100vh;
    max-height: 515px;
    padding-top: 34px;
    position: relative;
    .respond-to(@bpDesktopSmall, {
      background-color: @white-gray-bg;
      background-image: url(/assets/images/threepack/groupshot.jpg);
      background-size: cover;
      height: 394px;
      padding-top: 0;
    });
    .respond-to(@bpDesktopLarge, {
      background-color: @white-gray-bg;
      background-image: url(/assets/images/threepack/groupshot.jpg);
      background-size: cover;
      height: 515px;
      padding-top: 0;
    });
  
    h1 {
      color: @gray-darker;
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 28px;
      .respond-to(@bpDesktopSmall, {
        font-size: 33px;
        line-height: 43px;
      });      
      .respond-to(@bpDesktopMedium, {
         font-size: 36px;
      });
    }
  
    p {
      color: @gray-darker;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 17px;
      margin-top: 16px;
      .respond-to(@bpDesktopSmall, {
        .font-family(light);
        font-size: 17px;
        letter-spacing: 0.5px;
        line-height: 26px;
        margin-bottom: 27px;
      });
     .respond-to(@bpDesktopMedium, {
        color: @gray-87;
        font-size: 20px;
      });
    }
  
    .content-text {
      .respond-to(@bpDesktopSmall, {
        float: left;
        padding-top: 45px;
        width: 50%;
      });
      .respond-to(@bpDesktopLarge, {
        max-width: 542px;
        padding-top: 153px;
        width: 100%;
      });
    }
  
    .find-your-soul {
      .font-family(bold);
      border: 1px solid @gray-38;
      display: none;
      font-size: 14px;
      height: 32px;
      letter-spacing: 1.4px;
      line-height: 32px;
      margin-bottom: 40px;
      text-align: center;
      width: 100%;
      .respond-to(@bpDesktopSmall, {
        .font-family(bold);
        display: inline-block;
        height: 34px;
        letter-spacing: 3.5px;
        line-height: 38px;
        width: 218px;
      });
    }
  
    .content-image {
      bottom: 0;
      left: 0;
      margin: auto;
      max-height: 100%;
      position: absolute;
      right: 0;
      z-index: -1;
      .respond-to(@bpDesktopSmall, {
        display: none;
        float: right;
        margin-right: -70px;
        position: static;
        width: 55%;
      });
      .respond-to(@bpDesktopMedium, {
        width: 50%;
      });
      .respond-to(@bpDesktopLarge, {
        height: 100%;
        width: auto;
      });
  
      img {
        margin-bottom: -3px;
        min-width: 100%;
        width: 100%;
        .respond-to(@bpDesktopLarge, {
          height: 100%;
          margin: auto;
          min-height: 100%;
          width: auto;
        });
      }
    }
  
    .three-pack-container {
      .container-width;
      height: 100%;
      overflow: hidden;
      padding-bottom: 24px;
      .respond-to(@bpDesktopSmall, {
        padding-bottom: 0;
      });
    }
  }
  
  .take-your-journey {
    background-color: black;
    height: 449px;    
    .respond-to(@bpDesktopSmall, {
      height: 768px;
    });
  
    h1 {
      .font-family(bold);
      color: @scWhiteC;
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 23px;
  
      .title-highlight {
        .font-family(light);
        color: @scYellowC;
        display: block;
        font-size: 36px;
        letter-spacing: 0.4px;
        margin-top: 8px;
        width: 100%;
        .respond-to(@bpDesktopSmall, {
          font-size: 48px;
          line-height: 48px;
        });
      }
    }
  
    p {
      .font-family(roman);
      color: @scWhiteC;
      display: inline-block; 
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 18px;
      margin-top: 21px;
      text-align: left;
      width: 174px;  
      .respond-to(@bpMobileLarge, {
        width: 260px; 
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
        line-height: 24px;
        margin-top: 31px;
        width: 360px;
      });
    }
  
    .swiper-wrapper {
      position: relative;
    }
  
    .swiper-slide {
      background-color: inherit;
      background-size: cover;
      display: block;
      height: 100%;
      text-align: left;
    }
  
    .mantra {
      background-size: cover;
      display: block;
      height: 31px;
      margin-bottom: 20px;
      width: 292px;
      .respond-to(@bpDesktopSmall, {
        height: 81px;
        margin-bottom: 42px;
        width: 750px;
      });
    } 
  
    .bg-image {
      background-position-x: right;
      background-repeat: no-repeat;
      background-size: cover;
      height: 350px;
      position: absolute;
      width: 100%;
      z-index: -2;
     .respond-to(@bpTablet, {
        height: 100%;
        left: 0;
        right: 0;
      });
    }
  
    .threepack_item_1,
    .threepack_item_2,
    .threepack_item_3 {
      .bg-image {
        background-position-x: right;
        bottom: 0;
        .respond-to(0, @bpTablet, {
          background-position-x: left;
        });
      }
    }
  
    .threepack_item_1 .bg-image {
      .respond-to(0px, 440px, {
        background-position-x: -33px;
      });
    }
  
    .threepack_item_2 .bg-image {
      .respond-to(0px, 440px, {
        background-position-x: -63px;
      });
    }
  
    .threepack_item_3 .bg-image {
      .respond-to(440px, @bpTablet, {
        background-position-x: 62px;
      });
    }
  
    .three-pack-container {
      padding-bottom: 24px;
      padding-top: 107px;
      .container-width;
    }
  
    .slider-content {
      .box-sizing(border-box);
      height: 100%;
      .respond-to(@bpDesktopSmall, {
        padding-top: 240px;
      });
    }
  
    .swiper-pagination {
      .box-sizing(border-box);
      bottom: 0;
      left: 0;
      padding-bottom: 22px;
      position: absolute;
      right: 0;
      text-align: left;
      .respond-to(@bpMobileLarge, {
        padding-bottom: 88px;
      });      
      .respond-to(@bpDesktopSmall, {
        padding-bottom: 122px;
      });
    }
  
    .swiper-pagination-custom {
      cursor: pointer;
      display: inline-block;
      height: 16px;
      margin-right: 7px;
      padding-top: 15px;
      width: 40px; 
      .respond-to(@bpDesktopSmall, {
        margin-right: 17px;
        padding-bottom: 15px;
        width: 68px;
      });
  
      &:after {
        background: @white-30;
        content: '';
        display: inline-block;
        height: 4px;
        margin-top: 6px;
        width: 100%;
      }
    }
  
    .swiper-pagination-custom-active {
  
      &:after {
        background: @scYellowC;
      }
    }
  }
  
  .join-the-pack {
    margin: auto;
    margin-top: 32px;
  
    .title-join-pack {
      .font-family(light);
      text-align: center;
  
      h1 {
        color: @gray-darker;
        font-size: 36px;
        letter-spacing: 0.5px;
      }
  
      h2 {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 8px;
        .respond-to (@bpDesktopMedium, {
          margin-top: 16px;
        });
      }
    }
  
    .box-join-pack {
      .box-sizing(border-box);
      .container-width;
      display: block;
      margin-top: 27px;
      max-width: none;
      overflow: hidden;
      width: 100%;
      .respond-to(@bpDesktopSmall, {
        margin-top: 32px;
        padding-left: 70px;
        padding-right: 70px;
      });
      .respond-to (@bpDesktopMedium, {
        margin-bottom: 25px;
      });
    }
  
    .tier-size {
      .box-sizing(border-box);
      display: block;
      height: 234px;
      margin-bottom: 8px;
      position: relative;
      width: 100%;
      .respond-to(@bpTablet, {
        padding-left: 70px;
        padding-right: 70px;
      });
      .respond-to(@bpDesktopSmall, {
        float: left;
        height: 302px;
        padding: 0;
        width: 33.333333%;
      });
      .respond-to (@bpDesktopMedium, {
        height: 566px;
      });
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .tier1,
    .tier2,
    .tier3 {
      .box-sizing(boder-box);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
     .respond-to(@bpDesktopSmall, {
      line-height: 0;
    });      
    }
  
    .tier1 {
       background-image: url(/assets/images/threepack/find-nearst-studio-m.png); 
      .respond-to(@bpDesktopSmall, {
        background-image: url(/assets/images/threepack/find-nearst-studio.png);
      });
    }
  
    .tier2 {
      background-image: url(/assets/images/threepack/book-a-bike-m.png);
      margin: auto;
      .respond-to(@bpDesktopSmall, {
        background-image: url(/assets/images/threepack/book-a-bike.png);
      });
    }
  
    .tier3 {
      background-image: url(/assets/images/threepack/first-ride-m.png);
      .respond-to(@bpDesktopSmall, {
        background-image: url(/assets/images/threepack/first-ride.png);
      });
    }
  
    .content1,
    .content2,
    .content3 {
      height: 88px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 146px;
      width: 290px;
      .respond-to(@bpDesktopSmall, {
        font-size: 32px;
        height: auto;
        letter-spacing: 0.5px;
        top: 110px;
        width: 243px;
      });
      .respond-to (@bpDesktopMedium, {
        top: 234px;
      });
      .respond-to (@bpMobileLarge, @bpDesktopMedium, {
        left: 0;
        margin: auto;
        right: 0;
      });   
    }
  
    .find-nearest, 
    .book-bike, 
    .take-first-ride {
      .font-family(light);
      color: @scWhiteC;
      display: block;
      font-size: 20px;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
      .respond-to(@bpDesktopSmall, {
        font-size: 32px;
        height: 64px;
        line-height: 32px;
        margin-bottom: 13px;
      });
    }
  
    .view-studios-btn, 
    .get-started-btn, 
    .view-tips-btn {
      .font-family(bold);
      border-radius: 0;
      color: @gray-54;
      font-size: 10px;
      height: 24px;
      letter-spacing: 3px;
      line-height: 27px;
    }
  
    .yellow-button {
  
      &:hover {
        .respond-to(@bpDesktopSmall, {
          color: @gray-54;
        });
      }
    }
  }
  // Container settings
  .container-padding {
    padding-left: 14px;
    padding-right: 14px;
    .respond-to(@bpDesktopSmall, {
        padding-left: 70px;
        padding-right: 70px;
    });
  }
  
  .container-width {
    margin: auto;
    max-width: 320px;
    width: 100%;
    .respond-to(@bpMobileLarge, {
      max-width: @widthMobile;
    });
    .respond-to(@bpTablet, {
        max-width: @widthTablet;
    });
    .respond-to(@bpDesktopSmall, {
        max-width: @widthDesktopSmall;
    });
    .respond-to(@bpDesktopMedium, {
        max-width: @widthDesktopLarge;
    });    
    .respond-to(@bpDesktopLarge, {
        max-width: 1280px;
    });
  }
  
  .three-pack-container {
    .box-sizing(border-box);
    .container-padding;
    .container-width;
  }
  
  .mobile-full-container {
    .three-pack-container;
    overflow: hidden;
    .respond-to(0px, @bpDesktopSmall, {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    });
  }
  
  .break-line {
    height: 0;
    line-height: 0;
    width: 100%;
  }
  
  .desktop-break-line {
    .break-line;
    width: 0;
    .respond-to(@bpDesktopSmall, {
      display: block;
      width: 100%; 
    });
  }

  .mobile-break-line {
    .break-line;
    display: block;
    .respond-to(@bpDesktopSmall, {
      display: none;
    });
  }
}
