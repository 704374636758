/**
  SHOP NAVIGATION
 **/
.shop-nav {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;

  .respond-to(@bpDesktopSmall, {
    position: static;
  });

  // fix position on scroll
  &.fix-retail-nav {
    position: fixed;

    &.open {
      height: 100%;
      overflow-y:scroll;

      .respond-to(@bpDesktopSmall, {
        height: auto;
        overflow: visible;
      });
    }
  }

  // fix position when the auth is open
  &.fixed-secondary-nav {
      position: fixed;
      top: 330px;
      z-index: 1000;
  }

  // this is a temporary override restoring the outline
  // to the default value.  this should be unecessary when
  // and if the default outline gets reset globally
  & button:focus {
    outline: rgb(94, 158, 215) auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }

  & .nav-list {
    list-style: none;
  }

  .shop-counter {
    position: relative;
    text-align: center;
    margin-right: 25px;
    padding-top: 10px;
    font-size: .3em;
    color: #fff;
    letter-spacing: 0;

    .bag-quantity {
      font-size: 11px;
      margin-top: 4px;
      position: absolute;
    }
  }
  .shop-counter.mobile {
    margin-left: 55px;
    vertical-align: sub;

    .bag-quantity {
      left: 10px;
      top: -3px;
    }
  }
}

.bike-container,
.bike-container-soldout,
.gift-card-container,
.product-container,
.shop-retail-products{
  margin-top: 65px;

  .respond-to(@bpDesktopSmall, {
    margin-top: 0;
  });
}

.shop-nav-header {
  .font-family(medium);
  background-color: @gray-main;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 2em;
  padding: 25px;
  text-align: center;
  width: 100%;

  & .arrow-down-grey {
    padding: 0;

    .respond-to(0px, @bpDesktopSmall, {
      margin-top: 6px;
    });
  }

  &.open {
    > .arrow-down-grey {
      .transform(rotate(180deg));
    }
  }

  .respond-to(@bpDesktopSmall, {
    display: none;
  });

}

// Primary Navigation
.shop-nav-primary {
  background-color: @gray-main;
  font-size: 1.5em;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.3s ease-out;

  a {
    color: @gray-medium;

    &:hover {
      color: @gray-darker;
    }
  }

  .respond-to(@bpDesktopSmall, {
    display: flex;
    height: auto;
    justify-content: space-around;
    overflow: visible;
    z-index: 5;
  });
}

.shop-nav-primary-item {
  .font-family(bold);
  border-top: 1px solid @gray-light;
  font-size: 1.3em;
  position: relative;
  width: 100%;

  & > a {
    .box-sizing(border-box);
    display: block;
    padding: 30px 25px 25px 25px;
  }

  &.active > a {
    color: @gray-darker;
  }
  &.counter {
    display: none;
    .respond-to(@bpDesktopSmall, {
      display: block;
    });
  }

  & .arrow-down-grey {
    padding: 0;

    .respond-to(0px, @bpDesktopSmall, {
      margin-top: 5px;
    })
  }

  &.open {
    .arrow-down-grey {
      .transform(rotate(180deg));
    }
    position: relative;
  }

  .respond-to(@bpDesktopSmall, {
    .font-family(medium);
    border: 0;
    font-size: 1em;

    & > a {
      .align-items(center);
      .flex-display(flex);
      .justify-content(center);
      height: 100%;
      padding: 20px 0;
      width: 90%;
    }

    &.hide-desktop {
      display: none;
    }

    & .arrow-down-grey {
      margin-left: 48%;
      margin-top: 2px;
    }
  });
}

// Secondary Navigation
.shop-nav-secondary {
  .box-sizing(border-box);
  .font-family(light);
  background-color: @gray-main;
  font-size: 0.825em;
  height: 0px;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  top: -15px;
  transition:height 0.3s ease-out;

  .respond-to(@bpDesktopSmall, {
    .font-family(medium);
    background-color: @gray-main;
    font-size: 1.1em;
    position: absolute;
    text-align: left;
    top: auto;
    width: inherit;
    z-index: 11;
  });
}

.shop-nav-secondary-item {
  padding: 13px;

  &.active > a {
    color: @gray-darker;
  }
}
