.grid-gutter(@position) when (@position = left) {
	margin-left: @gutter;
}
.grid-gutter(@position) when (@position = right) {
	margin-left: @gutter;
}

.grid-row {
	&:after{
		content: ".";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
}

.grid-wrapper {
	float: left;
	position: relative;

	.grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8 {
		// background-color: #ccc;

		&:first-child {
			margin-left: 0;
		}

		.grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8 {
			// background-color: #aaa; // hightlight
		}
	}

	// WHEN A GRID-WRAPPER IS FOR A FEATURED BOX SPANNING FULL WIDTH OF THE GRID,
	// TAKE AWAY BOTTOM MARGIN SINCE THE
	&.featured {
		margin-bottom: 0;
	}
}

.grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-1-2 {
	margin-bottom: @gutter; // 12px = grid gutter size!!!
	float: left;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-left: @gutter;
	height: @colWidth;
	float: left;

	&.float-left {
		float: left;
	}

	&.float-right {
		float: right;
	}

	&.grid-wrapper {
		height: auto; // AUTO HEIGHT SO THAT NESTED GRID ELEMENTS DETERMINE HEIGHT OF THE WRAPPER
		margin-left: 0;
	}
}

.grid-1 {
	width: @colWidth;
	// height: @colWidth; // height would be defined in a page's specific LESS file

	@width: (@colWidth * 1) + (@gutter * 1);
	&.grid-wrapper {
		// @width: (@colWidth * 1) + (@gutter * 1);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-1 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-2 {
	width: 312px;
	// height: 312px;

	@width: (@colWidth * 2) + (@gutter * 2);
	&.grid-wrapper {
		// @width: (@colWidth * 2) + (@gutter * 2);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-2 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-3 {
	width: 474px;
	// height: 312px;

	@width: (@colWidth * 3) + (@gutter * 3);
	&.grid-wrapper {
		// @width: (@colWidth * 3) + (@gutter * 3);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-3 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-4 {
	width: 636px;
	// height: 312px;

	@width: (@colWidth * 4) + (@gutter * 4);
	&.grid-wrapper {
		// @width: (@colWidth * 4) + (@gutter * 4);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-4 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-5 {
	width: 798px;
	// height: 312px;

	@width: (@colWidth * 5) + (@gutter * 5);
	&.grid-wrapper {
		// @width: (@colWidth * 5) + (@gutter * 5);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-5 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-6 {
	width: 960px;
	// height: 312px;

	@width: (@colWidth * 6) + (@gutter * 6);
	&.grid-wrapper {
		// @width: (@colWidth * 6) + (@gutter * 6);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-6 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-7 {
	width: 1122px;
	// height: 312px;

	@width: (@colWidth * 7) + (@gutter * 7);
	&.grid-wrapper {
		// @width: (@colWidth * 7) + (@gutter * 7);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-7 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-8 {
	width: 1284px;
	// height: 312px;

	@width: (@colWidth * 8) + (@gutter * 8);
	&.grid-wrapper {
		// @width: (@colWidth * 8) + (@gutter * 8);
		width: @width;
		background: none;

		&:first-child {
			width: @width - @gutter;
		}
	}

	.grid-8 {
		float: right;
		width: @width - @gutter;
	}
}

.grid-1-2 {
	padding-left: @gutter * 2.5;
	padding-right: @gutter * 2.5;
	width: 50%;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}

@media only screen
and (min-width : 1346px) { // 1284 grid max-width PLUS 60px padding PLUS 12px gutter = 1346px
	.grid-wrapper {
			&.into4 {
				@width: (@colWidth * 4) + (@gutter * 4);
				width: @width;

				&:first-child {
					width: @width - @gutter;
				}
			}

			.into4 {
				@width: (@colWidth * 4) + (@gutter * 4);
				width: @width - @gutter;
			}

			&.into5 {
				@width: (@colWidth * 5) + (@gutter * 5);
				width: @width;

				&:first-child {
					width: @width - @gutter;
				}
			}

			.into5 {
				@width: (@colWidth * 5) + (@gutter * 5);
				width: @width - @gutter;
			}

			&.into6 {
				@width: (@colWidth * 6) + (@gutter * 6);
				width: @width;

				&:first-child {
					width: @width - @gutter;
				}
			}

			.into6 {
				@width: (@colWidth * 6) + (@gutter * 6);
				width: @width - @gutter;

				&.grid-4 {
					width: @width - @gutter;

					&:first-child{
						width: @width - @gutter;
					}
				}
			}

			&.into7 {
				@width: (@colWidth * 7) + (@gutter * 7);
				width: @width;

				&:first-child {
					width: @width - @gutter;
				}
			}

			.into7 {
				@width: (@colWidth * 7) + (@gutter * 7);
				width: @width - @gutter;
			}

			&.into8 {
				@width: (@colWidth * 8) + (@gutter * 8);
				width: @width;

				&:first-child {
					width: @width - @gutter;
				}

				&.grid-6 {
					width: @width;

					&:first-child{
						width: @width - @gutter;
					}
				}
			}

			.into8 {
				@width: (@colWidth * 8) + (@gutter * 8);
				width: @width - @gutter;

				&:first-child {
					width: @width - @gutter;
				}

				&.grid-6 {
					width: @width;

					&:first-child {
						width: @width - @gutter;
						float: none;
					}
				}
			}
	}
}

.grid-1x1 {
	.block(1,1);
}

.grid-2x1 {
	.block(2,1);
}

.grid-3x4 {
	.block(3,4);
}

.grid-4x3 {
	.block(4,3);
}

.grid-2x3 {
	.block(2,3);
}

/*doc
---
title: Grid v2.0
name: grid
category: grid
---

Updated version of the SoulCycle grid framework.

## How to Use

1. Define the row using the 'grid-row' class.

2. Define columns in the row using `sc-column` class.

3. Specify the width of the column using the `sc-column-{\d}-{\d}` class.

    Grids are generated using the `gridColumns` variable. The first digit in the class indicates the fraction of the total columns. The second digit is the total number of columns (i.e. the `gridColumns` variable).

    For example, to create a column that is 33% of the relative parent's width, the class used would be `sc-column-4-12`.

## Example

```html_example
<div class="grid-row">
    <div class="sc-column-4-12">
        <div class="sc-login-component">
        	<h2>sc-column-4-12</h2>

			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa asperiores vel in praesentium dolore tempore, dolor eius vitae ipsum, aperiam maxime rerum recusandae quisquam, dolorum libero ducimus! Cumque, eos fugit!<p>
        </div>
    </div>
    <div class="sc-column-2-12">
        <div class="sc-login-component">
        	<h2>sc-column 2-12</h2>

			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa asperiores vel in praesentium dolore tempore, dolor eius vitae ipsum, aperiam maxime rerum recusandae quisquam, dolorum libero ducimus! Cumque, eos fugit!<p>
        </div>
    </div>
    <div class="sc-column-2-12">
        <div class="sc-login-component">
        	<h2>sc-column 2-12</h2>

			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa asperiores vel in praesentium dolore tempore, dolor eius vitae ipsum, aperiam maxime rerum recusandae quisquam, dolorum libero ducimus! Cumque, eos fugit!<p>
        </div>
    </div>
    <div class="sc-column-4-12">
        <div class="sc-login-component">
        	<h2>sc-column-4-12</h2>

			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa asperiores vel in praesentium dolore tempore, dolor eius vitae ipsum, aperiam maxime rerum recusandae quisquam, dolorum libero ducimus! Cumque, eos fugit!<p>
        </div>
    </div>
</div>

*/

.generate-sc-columns(@n, @i: 1) when (@i =< @n) {
  .sc-column-@{i}-@{gridColumns} {
    width: (@i * 100% / @n);
  }

  .generate-sc-columns(@n, (@i + 1));
}

.generate-sc-columns(@gridColumns);

[class*='sc-column'] {
	float: left;
	.box-sizing(border-box);
	padding-left: @gutter * 2.5;
	padding-right: @gutter * 2.5;
	position: relative;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}
