/*doc
---
title: SC Tooltips
name: sc_tooltips
category: tooltips
---

When a user hovers over the icon, the tooltip shows

```html_example
    <div class="sc-tooltip">
    	<span class="icon-info">?</span>
        <span class="tooltip">Here is some info on the item you hovered over</span>
    </div>
```

*/

.sc-tooltip {
	cursor: pointer;
	display: inline-block;
	position: relative;

	&:hover {
		.tooltip {
			display: block;
		}
	}

	.info-icon {
		background: @gray-medium;
		border-radius: 50%;
		color: white;
		display: inline-block;
		.font-family(bold);
		font-size: 0.9em;
		height: 1.5em;
		line-height: 1.9em;
		text-align: center;
		width: 1.5em;
	}

	.tooltip {
		background: white;
		border: 1px solid @gray-medium-dark;
		display: none;
		padding: 1em;
		position: absolute;
		top: calc(~"100% + 0.5em");
		right: -0.4em;
		min-width: 1rem;

		&:before,
		&:after {
			background: white;
			border: 1px solid @gray-medium-dark;
			content: " ";
			height: 1em;
			position: absolute;
			top: 0;
			right: 0.95em;
			.transform(rotate(45deg) translate(0, -50%));
			width: 1em;
		}

		&:after {
			border: none;
			.transform(none);
			right: 0;
			width: 2em;
		}
	}
}

.ccv-info {
	cursor: pointer;
	display: inline-block;
	position: relative;
	.respond-to(@bpDesktopSmall, {
		&:hover {
			.tooltip {
				display: block;
			}
		}
	});
	img {
		position: relative;
		z-index: 10;
	}
	.icon {
		background: @gray-medium;
		border-radius: 50%;
		color: white;
		display: inline-block;
		height: 1.5em;
		line-height: 1.8em;
		text-align: center;
		width: 1.5em;
	}

	.tooltip {
		background: white;
		border: 1px solid @gray-medium-dark;
		display: none;
		padding: 0.5em;
		position: absolute;
		top: calc(~"100% + 0.5em");
		left: -0.5em;
		z-index: 10;
		.respond-to(@bpMobileLarge, {
			left: auto;
			right: -0.4em;
		});
		&:before,
		&:after {
			background: white;
			border: 1px solid @gray-medium-dark;
			content: " ";
			height: 1em;
			position: absolute;
			top: 0;
			left: 0.25em;
			.transform(rotate(45deg) translate(0, -50%));
			width: 1em;
			.respond-to(@bpMobileLarge, {
				left: auto;
				right: 0.95em;
			});
		}

		&:after {
			border: none;
			.transform(none);
			right: 0;
			width: 2em;
		}
	}
}