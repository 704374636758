/* ERROR PAGE STYLES
-----------------------------------------------------------------------------*/

#error_404 {
  font-size: 16px;
  text-align: center;
  min-height: 220px;
  .sc-text-watermark {
    .font-family(heavy);
    font-size: 10em;
    color: @gray-main;
    padding-top: 45px;
  }
  .error_info {
    font-size: .9em;
    margin-top: -125px;
    position: relative;
    .yellow-button {
      margin-top: 20px;
    }
  }
}

/* MEDIA QUERIES - ERROR PAGE STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
  #error_404 {
    font-size: 29px;
    min-height: 345px;
    .sc-text-watermark {
      padding-top: 80px;
    }
    .error_info {
      margin-top: -230px;
      .yellow-button {
        margin-top: 30px;
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  #error_404 {
    min-height: 415px;
    .sc-text-watermark {
      padding-top: 60px;
      font-size: 13em;
    }
    .error_info {
      margin-top: -285px;
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  #error_404 {
    font-size: 35px;
    min-height: 0;
    padding-bottom: 300px;
    .sc-text-watermark {
      padding-top: 110px;
      font-size: 15em;
    }
    .error_info {
      margin-top: -390px;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width : 1440px) {
  #error_404 {
    .sc-text-watermark {
      padding-top: 65px;
      font-size: 19em;
    }
    .error_info {
      margin-top: -490px;
    }
  }
}