.progress-goal-modal {
  background: @gray-38;
  bottom: 0;
  display: none;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: @zOverlay;

  &__overlay {
    height: 100%;
    position: fixed;
    width: 100%;
  }

  &__dialog-box {
    .border-radius(4px);
    background: @scWhiteC;
    color: @gray-darker;
    font-size: 0.875rem;
    left: 50%;
    margin: 0 auto 15px auto;
    overflow: hidden;
    padding: 36px 0 30px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    .respond-to(@bpMobileLarge, {
      margin: 30px auto;
      padding: 80px 0 30px;
      width: 535px;
    });

    .respond-to(@bpDesktopSmall, {
      width: 625px;
    });
  }

  &__dialog-close {
    background: url('/assets/images/confirm_dialog_close.png');
    background-size: cover;
    cursor: pointer;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;

    .respond-to(@bpMobileLarge, {
      right: 24px;
      top: 24px;
      height: 24px;
      width: 24px;
    });

    &:hover,
    &:active {
      background: url('/assets/images/confirm_dialog_close_hover.png');
      background-size: cover;
    }
  }

  &__header {
    border-bottom: 1px solid @gray-38;
    margin: 0 38px 17px;
    text-align: left;

    .respond-to(@bpMobileLarge, {
        margin: 0 auto 40px;
        width: 412px;
    });
  }

  &__header-goal-text {
    .font-family(heavy);
    font-size: 2.25rem;

    .respond-to(@bpMobileLarge, {
      font-size: 3.75rem;
    });
  }

  &__goal-type {
    .font-family(heavy);
    font-size: 0.75rem;
    letter-spacing: 2.14px;
    text-transform: uppercase;
    margin-bottom: 8px;

    .respond-to(@bpMobileLarge, {
      font-size: 0.875rem;
      letter-spacing: 2.5px;
      line-height: 1;
    });
  }

  &__text-main {
    line-height: 1.0625rem;
  }

  &__description {
    .font-family(light);
    font-size: 1.1875rem;
    line-height: 1;

    .respond-to(@bpMobileLarge, {
      font-size: 1.5rem;
      line-height: 1;
    });
  }

  &__prize-container {
    margin: 8px auto;
    text-align: center;
    width: 290px;

    .respond-to(@bpMobileLarge, {
      margin: 17px auto 40px;
      width: auto;
    });
  }

  &__prize-icons-group {
    align-content: center;
    display: flex;
    justify-content: center;
  }

  &__prize {
    box-sizing: border-box;
    display: inline-block;
    padding: 13px 6px;

    .respond-to(@bpDesktopSmall, {
      padding: 13px 9px;
    });
  }

  &__media {
    .border-radius(50%);
    border: none;
    color: @gray-87;
    height: 40px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 40px;

    .respond-to(@bpMobileLarge, {
      height: 44px;
      width: 44px;
    });

    > img {
      height: 100%;
    }
  }

  &__prize-description {
    color: @gray-darker;
    font-size: 0.75rem;
    line-height: 1.5;
    margin: 6px auto 0;
    max-width: 350px;

    .respond-to(@bpMobileLarge, {
      margin: 8px auto 0;
    });
  }

  &__disclaimer {
    .font-family(light);
    font-size: 0.6875rem;
    line-height: 0.75rem;
    margin: 14px auto 12px;
    max-width: 312px;

    .respond-to(@bpMobileLarge, {
      max-width: 500px;
    });
  }

  &__number-of-rides,
  &__remaining-number-of-rides {
    .font-family(heavy);
  }

  &__button {
    .border-radius(4px);
    .font-family(medium);
    .transition(background, .1s);
    border: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 40px;
    padding: 0 60px;
    display: none;
  }

  &__button-done {
    background: @gray-darker;
    color: @scWhiteC;

    &:hover,
    &:active {
      background: @sc-yellow;
    }
  }
}
