//////////////////////
//  CAREER STYLES   //
//////////////////////

.careers-page {
  font-size: 16px;
  overflow: hidden;
  .respond-to(@bpDesktopSmall, {
    padding-bottom: @footerHeightDesktop;
  });
  .font-family(normal);
  p {
    line-height: 20px;
    .respond-to(@bpMobileLarge, {
      line-height: 23px;
    });
  }
  h1 {
    line-height: 34px;
  }
  h2 {
    line-height: 24px;
    font-size: 18px;
    .font-family(medium);
  }
  .careers-hero {
    position: relative;
    line-height: 0;
    .career-title {
      .respond-to(@bpTablet, {
        font-size: 48px;
      });
    }
    img {
      width: 100%;
    }
  }
  .careers-header {
    font-size: 28px;
    .font-family(medium);
    .respond-to(@bpMobileLarge, {
      font-size: 36px;
      line-height: 50px;
    });
    .respond-to(@bpDesktopSmall, {
      font-size: 48px;
      line-height: 58px;
    });
  }
  .career-info {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: rgba(51, 51, 51, 0.5);
    width: 100%;
    height: 100%;
  }
  .career-title {
    color: #fff;
    .font-family(medium);
    font-size: 18px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  .career-btn-container {
    width: 100%;
    text-align: center;
    .respond-to(@bpMobileLarge, {
      text-align: left;
      width: initial;
    });
  }
}

.careers-page {
  .careers-about {
    text-align: center;
    font-size: 18px;
    padding: 30px 20px 15px;
    h1 {
      width: 250px;
      margin: 0 auto;
      .respond-to(@bpMobileLarge, {
        width: 100%;
        font-size: 32px;
      });
      .respond-to(@bpTablet, {
        font-size: 36px;
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 48px;
      });
    }
    p {
      width: 275px;
      margin: 10px auto;
      .respond-to(@bpMobileLarge, {
        width: 500px;
        font-size: 24px;
      });
      .respond-to(@bpTablet, {
        font-size: 28px;
        line-height: 34px;
        width: 600px;
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 36px;
        width: 800px;
        line-height: 43px;
        padding-top: 15px;
      });
    }
    .respond-to(@bpTablet, {
      padding: 50px 40px 35px;
    });
    .respond-to(@bpDesktopSmall, {
      padding: 90px 80px 80px;
    });
    .respond-to(@bpDesktopLarge, {
      padding: 120px 320px 110px
    });
  }
  .careers-tile {
    display: block;
    position: relative;
    line-height: 0;
    .respond-to(@bpTablet, {
      width: 33.33%;
      float: left;
    });
    .career-info {
      background-color: rgba(51, 51, 51, 0.7);
    }
    .career-title {
      .respond-to(@bpDesktopSmall, {
        font-size: 28px;
      });
    }
    img {
      width: 100%;
    }
  }
}

.careers-listing-page {
  .careers-list {
    padding: 0 20px 20px;
    .respond-to(@bpTablet, {
      padding: 0 40px 20px;
    });
    .respond-to(@bpDesktopSmall, {
      padding: 0 80px 20px;
    });
    .respond-to(@bpDesktopLarge, {
      padding: 0 320px 20px;
    });
    .careers-header {
      padding-top: 20px;
      padding-bottom: 10px;
      .respond-to(@bpMobileLarge, {
        padding-top: 30px;
        padding-bottom: 25px;
      });
      .respond-to(@bpTablet, {
        padding-top: 40px;
        font-size: 48px;
      });
      .respond-to(@bpDesktopSmall, {
        font-size: 48px;
        line-height: 50px;
      });
      border-bottom: 1px solid @gray-dark;
    }
    h2 {
      margin-top: 30px;
    }
    p {
      margin-top: 5px;
      font-size: 14px;
      .respond-to(@bpMobileLarge, {
        font-size: 16px;
      });
    }
    .careers-by-region {
      display: none;
      &.selected {
        display: block;
      }
    }
    .view-full-link {
      display: inline-block;
      margin-top: 10px;
    }
  }
}

// filter module
.filter-container {
  background-color: @gray-main;
  font-size: 18px;
  cursor: pointer;
  .font-family(medium);
  .filter-header {
    text-align: center;
    border-bottom: @borderLightGray;
    box-sizing: border-box;
    padding: 21px;
    .respond-to(@bpMobileLarge, {
      padding: 26px;
    });
  }
  .arrow-down-grey {
    right: initial;
    top: initial;
    margin-top: 3px;
  }
  .filters {
    padding: 20px 0;
    display: none;
    .filter {
      padding: 15px 20px;
      color: @gray-medium;
      &:hover,
      &.active {
        color: @gray-darker;
      }
    }
  }
}



