.email-capture-london-studios {

  .respond-to(@bpDesktopLarge, {
    margin: 1.6rem 11% 0 8.5%;});

  &__phrase {
    color: @gray-main;
    font-size: 14px;
    .font-family(medium);
    letter-spacing: 0.5px;
    line-height: 1.5em;
    max-height: 600px;
    margin: 0px 8% 9px;
    width: 312px;

    .respond-to(@bpTablet, {
      margin: 0px 7.5% 9px;
      width:600px;
    });

    .respond-to(@bpDesktopSmall, {
      font-size: 12px;
      .font-family(light);
      margin: 2.5rem 7.5% 0;
      width: 370px;
    });

    .respond-to(@bpDesktopMedium,{
      margin: 4.5rem 18.5% 0;
      width: 368px;
    });

    .respond-to(@bpDesktopLarge, {
        margin: 0px;
        position: relative;
        top: 25px;
    });
  }

  &__form-privacy-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 650px;
    .respond-to(@bpDesktopLarge, {flex-direction: column;});
  }

  &__form {
    display: flex;
    flex-direction: column;

    .respond-to(@bpTablet, {
      flex-direction: column;});

    .respond-to(@bpDesktopSmall, {
      flex-direction: column;
      overflow: initial;
      width: 650px;
    });

    .respond-to(@bpDesktopLarge, {
      flex-direction: column;
    });

    .input-field {
      display: flex;
      align-self: center;
      position: relative;
      margin: 1.4rem -1% -1px 4px;

      .respond-to(@bpMobileSmallPlus,{
        left: 0px;
      });

      .respond-to(@bpMobileMediumSmall,{
        margin: 1.4rem 7% -1px 4px;
      });

      .respond-to(@bpTablet, {
        align-self: flex-start;
        left: 44px;
        width: 344px;
      });

      .respond-to(@bpDesktopMedium,{
        left:117px
      });

      .respond-to(@bpDesktopLarge, {
        align-self: flex-start;
        left: 0px;
        top: 41px;
      });

    }

    &-error {
      color: @white;
      display: none;
      .font-family(roman);
      font-size: 0.875rem;
      height: 0;
      line-height: 1.5rem;
      margin: 0;
      padding-top: 5px;
      position: absolute;
      transition: all 0.5s ease-in-out;
    }

    &-error-expanded {
      height: auto;
      display: contents;
    }

    .frm-label {
      .font-family(medium);
      background: @black;
      color: @greenWhite;
      font-size: 16px;
      font-weight: normal;
      height: fit-content;
      left: 0.875rem;
      position: absolute;
      pointer-events: none;
      padding-left: 0.125rem;
      padding: 0 3px;
      top: 15px;
      transition: all 0.28s ease;
      width: fit-content;
      z-index: 1;
      -webkit-transition: all 0.28s ease;

      .respond-to(@bpTablet, {top: 14px;});
      .respond-to(@bpDesktopMedium, {top: 14px;});

      &.floated {
        color: @white;
        font-size: 0.875rem;
        top: -0.25em;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      // To make the autocomplete stay with the color black after vendor libs take over
      color: @white !important;
      -webkit-text-fill-color: @white !important;
      -webkit-box-shadow: 0 0 0 30px @black inset !important;
    }

    input {
      background-color: @black;
      border: 2px solid @greenWhite;
      border-radius: 2px;
      color: @white;
      display: block;
      float: none;
      height: 40px;
      width: 312px;
      .respond-to(@bpTablet, {width: 319px;});

      .respond-to(@bpDesktopMedium, {width: 360px});

      &:focus {
        background-color: @black !important;

        & + .frm-label {
          color: @greenWhite;
          top: -0.25em;
          font-size: 14px;
        }
      }

      &:hover {
        background-color: @black;
      }
    }
    &-input-error {
      border-color: @milanoRed;
    }

    .intl-tel-input {
      float: none;
    }

    &__submit {
      align-items: center;
      display: flex;

      &-button {
        .font-family(medium);
        background: @greenWhite;
        border: none;
        color: #000000;
        float: right;
        font-size: 14px;
        margin: 0 8% 9px;
        padding: 15px 20px 15px;
        text-align: center;
        position: relative;
        top: 12px;
        width: 312px;

        .respond-to(@bpTablet, {
          height: 40px;
          left: 320px;
          margin-top: 0px;
          position: relative;
          top: -48px;
          width: 160px;
        });

        .respond-to(@bpDesktopSmall,{
          left: 364px;
        });

        .respond-to(@bpDesktopMedium, {
          left: 521px;
          margin: 0px;
          padding: 12px;
          top: -48px;
          width: 150px;
        });

        .respond-to(@bpDesktopLarge, {
          left: 403px;
          top: -9px;
        });


        &-enabled {
          background: @schoolBusYellow;
          color: @black;
          cursor: pointer;

          &:hover {
            .linear-gradient(
                    @primary-button-gradient-start,
                    @primary-button-gradient-end
            );
          }
        }
      }
    }
  }

  &__privacy-policy {
    color: @greenWhite;
    .font-family(light);
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 8px 7% 9px;
    padding: 10px;
    width: 81%;

    .respond-to(@bpTablet, {
      bottom: 50px;
      position: relative;
      left: -15px;
      width: 528px;
    });

    .respond-to(@bpDesktopMedium, {
      bottom: 105px;
      font-size: 12px;
      left: 59%;
      position: relative;
      margin: 0px;
      width: 450px;
    });

    .respond-to(@bpDesktopSmall, {
      left: -30px;
    });

    .respond-to(@bpDesktopMedium, {
      bottom: 107px;
      left: 58%;
      width: 450px;
    });

    .respond-to(@bpDesktopLarge, {
      bottom: 64px;
      left: 51%;
      width: 576px;
    });

    a {
      color: @white;
      text-decoration: none;
      font-weight: bolder;
      &:hover {
        color: @white;
        cursor: pointer;
      }
    }
  }
  &__success-message {
    .font-family(medium);
    display: none;
    font-size: 1rem;
    margin: 1.75rem;
    position: relative;
    left: 0px;
    visibility: hidden;

    .respond-to(@bpMobileMedium,{
      left: 12px;
    });

    .respond-to(@bpTablet,{
      left: 9px;
    });
    .respond-to(@bpDesktopSmall,{
      left: 21px;
    });

    .respond-to(@bpDesktopMedium,{
      left: 95px;
    });

    .respond-to(@bpDesktopLarge,{
      left: 0px;
      margin: -1px;
    });

    .respond-to(@bpDesktopXL,{
      left: -44px;
    });

    a {
      color: @white;
      &:hover {
        color: @white;
        cursor: pointer;
      }
    }
  }
}
.email-capture-london-studios-completed {
  .email-capture-london-studios__phrase,
  .email-capture-london-studios__form-privacy-wrapper {
    color: @black;
    max-height: 0;
    opacity: 0;
    transition: max-height 1s ease, color 1s ease, visibility 1s, opacity 0.5s ease;
    visibility: hidden;
  }

  .email-capture-london-studios__success-message {
    transition: visibility 0.5s, opacity 0.5s ease;
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.map__svg {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;

  .respond-to(@bpTablet, {
    margin: 2.5rem 6.5% 0;
    padding: 0px;
  });

  .respond-to(@bpDesktopMedium, {
    height: 40px;
    margin: 14px 120px 0;
    padding-bottom: 10px;
    width: 300px;
  });

  .respond-to(@bpDesktopLarge, {
    margin: 0 0 0 -6px;
  });


  &-icon{
    display: none;

    .respond-to(@bpTablet, {
      background: center no-repeat url(/assets/images/london_studios/location.svg);
      display: flex;
      height: 30px;
      width: 30px;
    });

  }

  &-text{

    &-select{
      color: @gray-main;
      font-size: 16px;
      .font-family(medium);
      margin: 0.4rem 8% 0;

      .respond-to(@bpTablet, {
        display: none;
      });
    }

    &-choose{
      display: none;

      .respond-to(@bpTablet, {
        color: @gray-main;
        display: flex;
        font-size: 16px;
        .font-family(medium);
        height: 16px;
        padding: 10px;
        width: 300px;
      });
    }

    .respond-to(@bpDesktopLarge, {
      align-self: center;
      display: inline-block;
      padding: 10px;
    });
  }

}
