/* Override checkout styles for IOS web view */

.web-view-container {
    /* Classes to override here */
    padding-top: 3.6875rem;
    top: 0;

    .checkout-page-container {
        padding-top: 2.5rem;
    .sc-promo-code-form,
	.sc-giftcard-form {

      .expandable-title {
        span {
          &.arrow-down-grey {
            margin-left: 1.5625rem;
          }
        }
      }

      .expandable-content {
        margin-top: 0;
      }

      .applied-promo-message {
        padding: 0.5rem 0;
      }
    }

    .checkout-header {
      top: 0;
    }

    .cta-next,
    .cancel-button {
      display: none;
    }

    .footer-cta-next {
      padding: 0;

      .cta-next {
        display: block;
        margin: 0;
        padding-bottom: 0.5rem;
      }

      .form-button {
        max-width: 100%;
        width: 100%;
      }
    }

        .return-policy {
            h4 {
                .font-family(bold);
            }
            p {
                font-size: 0.75em;
                line-height: 1.667em;
            }
            .font-family(roman);
            font-size: 1em;
            line-height: 1.667em;
            max-width: 23.5em;
            margin-top: 2.1em;
            margin-bottom: 2.1em;
            width: 100%;
        }
        .shipping-note {
            .font-family(light);
            font-size: 0.875em;
            line-height: 1.667em;
            margin-bottom: 2.1em;
            span {
                .font-family(bold);
            }
        }

        .full-order-summary {
            margin-bottom: 4.125rem;
        }

        .footer-cta-next {
            background-image: linear-gradient(0deg, @scWhiteC 45%, @white-70 82%, @white-30 100%);
            bottom: -5.063rem;
            padding: 1rem 1rem 6rem 1rem;
            position: fixed;
            width: 100%;
        }

        #frm-save_cc {
            margin-left: 0;
        }
    }
}

.fix-fixed {
  position: absolute !important;
}
