.terms-landing-container {
  padding: 0 @bpMobileSmallGutter;
  margin-top: 20px;
  .respond-to(@bpTablet, {
    padding: 0 @bpMobileLargeGutter;
    margin-top: 28px;
  });
  .respond-to(@bpDesktopSmall, {
    padding: 0 @bpTabletGutter;
    margin-bottom: 56px;
  });
  .respond-to(@bpDesktopLarge, {
    padding: 0 @bpDesktopSmallGutter;
  });
  .font-family(medium);
  .terms-agreement {
    font-size: 16px;
    margin: 0 0 10px;
    .respond-to(@bpTablet, {
      font-size: 18px;
      margin-bottom: 15px;
    });

    a {
      text-decoration: underline;
    }
  }
  .create-account-button {
    margin-bottom: 40px;
    width: 280px;
    font-size: 16px;
    padding: 18px 18px 16px;
    .respond-to(@bpTablet, {
      width: 344px;
      font-size: 18px;
      padding: 21px 18px 19px;
    });
  }
  .form-terms {
    width: initial;
    height: 360px;
    max-width: 100%;
    padding: 10px 10px 10px 8px;
    font-size: 12px;
    margin-bottom: 15px;
    .respond-to(@bpTablet, {
      height: 565px;
      padding: 15px;
      margin-bottom: 30px;
    });
    .respond-to(@bpDesktopSmall, {
      height: 492px;
      padding: 15px 20px;
    });
    .respond-to(@bpDesktopLarge, {
      padding: 15px 30px;
    });
  }
}

// for all other terms and conditions
.form_terms_and_conditions {
  .category {
    display: none;
  }
  .footer-page-container {
    h3 {
      margin-bottom: 0;
      font-size: 14px;
      .font-family(bold);
      margin: 10px 0 0;
      line-height: 18px;
      .respond-to(@bpDesktopLarge, {
        font-size: 14px;
      });
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
    &.legal-container {
      margin: 0;
      padding: 0;
    }
    .category-list {
      margin: 0;
      padding: 0;
      .answer,
      .question {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

.form-terms {
  .font-family(light);
  font-size: 0.75rem;

  h1 {
    .font-family(medium);
    font-size: 2em;
    padding-bottom: .5em;
    text-decoration: underline;
  }

  h2 {
    .font-family(medium);
    font-size: 1rem;
    line-height: 1.125rem;
    margin: 0.875rem auto 0.25rem auto;
    text-decoration: underline;
  }

  h3 { 
    .font-family(bold);
    font-size: .75rem;
    line-height: 0.93rem;
    margin: 0.625rem 0 0;
    text-decoration: underline;
  }

  ul {
    padding-left: 2.5rem;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 0.875rem;
    padding: 0.3rem;
  }

  p {
    padding-bottom: 0.3rem;

    a { 
      color: -webkit-link;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  div.markdown-module {
    padding: 0.3rem 0 0.6rem 0;
  }
}

.sc-tc-table {
  tbody {
    tr {
      th[scope="row"] {
        font-weight: normal;
        text-align: left;
      }
    }
  }
}
