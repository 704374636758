/*
New To Soul/Experience/landing Page Styles
*/

.new-to-soul__section--landing {
  .flex-display();
  height: calc(~"100vh" - (@headerHeight));

  .respond-to(@bpDesktopSmall, {
    height: calc(~"100vh" - (@footerHeightDesktop + @headerHeight));
  });
}

.new-to-soul__section__landing-background {
  .flex-direction(column);
  .flex-display(flex);
  background-position: -10.5rem center;
  background-size: cover;
  height: 100%;
  width: 100%;

  &:after {
    background: rgba(0,0,0,0.2);
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .respond-to(@bpMobileLarge, {
    background-position: center center;
  });

  &.is-old-browser {
    background-position: center center;
  }
}

.new-to-soul__section__landing-your-journey {
  .align-items(center);
  .align-self(center);
  .flex(6);
  .flex-display(flex);
  color: @scWhiteC;
  font-size: 3.75rem;
  letter-spacing: 0.125rem;
  max-width: 15.62rem;
  text-align: center;
  z-index: 2;

  .respond-to(@bpTablet, {
    max-width: 21.875rem;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 5rem;
    max-width: none;
  });
}

.new-to-soul__section__landing-learn-more-container {
  .flex(1);
  .flex-display(flex);
  .justify-content(center);
  max-height: 8rem; // Fix for IE 11.
  min-height: 8rem;
  width: 100%;

  .respond-to(@bpMobileMedium, {
    max-height: 6rem; // Fix for IE 11.
    min-height: 6rem;
  });
}

.new-to-soul__section__landing-learn-more-wrapper {
  z-index: 2;
}

.new-to-soul__section__landing-description-button-wrapper {
  .justify-content(center);
}

.new-to-soul__section__landing-long-down-arrow {
  .align-items(center);
  .flex-direction(column);
  .flex-display(flex);
  margin-top: 1rem;
}
