.soulcup-landing-main {
  min-width: 320px;

  .register-btn {
    .font-family(bold);
    align-items: center;
    background-color: @goldenYellow;
    border-radius: 0px;
    color: @black;
    display: flex;
    font-size: 1rem;
    height: 56px;
    justify-content: center;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-top: 34px;
    text-align: center;
    width: 19.5rem;

    .respond-to(@bpTablet, {
      margin-top: 42px;
    });

    .respond-to(@bpDesktopLarge, {
      font-size: 1rem;
      margin: 2.5rem 0;
    });
  }

  .soulcup-section-container {
    padding: 3.125rem 1.5rem;
  }

  .soulcup-landing-hero {
    background: @black;
    color: @scWhiteC;
    position: relative;
    margin-top: 25px;

    .background {
      background-size: cover;
      background: url(/assets/images/turnitup/FallChallenge_Mobile-2.jpg) bottom center / cover no-repeat;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      margin-top: -7%;

      .respond-to(@bpTablet, {
        background: bottom center / cover no-repeat
        url(/assets/images/turnitup/2024TIU-Desktop-LP.jpg) @black;
        margin-top: 0%;
      });
    }

    .soulcup-landing-hero-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 130px;
      position: relative;
      z-index: 1;

      .respond-to(@bpTablet, {
        padding-top: 355px;
      });

      .respond-to(@bpDesktopLarge, {
        padding-top: 25.22rem;

      });

      .soulcup-logo {
        background: center center / 100% auto no-repeat
          url(/assets/images/turnitup/2024TIU-StudioSignage-07.png) @transparent-color;
        height: 100px;
        width: 300px;

        .respond-to(@bpTablet, {
          height: 150px;
          width: 450px;
        });
        .respond-to(@bpDesktopLarge, {
          height: 100px;
          width: 502px;
        });
      }

      .subtitle {
        .font-family(light);
        font-size: 1.125rem;
        margin-bottom: 16px;

        .respond-to(@bpTablet, {
          margin-bottom: 8px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 24px;
          margin-bottom: 16px;
        });
      }

      .date {
        .font-family(bold);
        color: @white;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        padding-top: 10px;
        text-align: center;
        text-transform: capitalize;

        .respond-to(@bpDesktopSmall, {
          font-size: 1rem;
        });
      }

      .description {
        .font-family(bold);
        color: @white;
        font-size: 1.5rem;
        letter-spacing: -0.24px;
        line-height: 28px;
        padding: 17px 0 10px 0;
        text-align: center;

        .respond-to(@bpMobileLarge, {
          font-size: 1.5rem;
          letter-spacing: -0.24px;
          line-height: 28px;
          white-space: normal;
        });
      }

      .more-info {
        color: @scWhiteC;
        display: none;
        z-index: 2;

        .respond-to(@bpDesktopLarge, {
          display: block;
          text-align: center;
          margin-top: 4.8rem;
          width: 100%;
        });

        .more-info-item {
          border-top: 1px solid @white-70;
          display: inline-block;
          padding-top: 16px;
          width: 190px;

          &:not(:last-child) {
            margin-right: 15px;
          }

          a {
            .font-family(heavy);
            color: #FFDE00;
            font-size: 0.875rem;
            line-height: 14px;
            letter-spacing: 2.5px;
            text-transform: uppercase;
            &:hover {
              color: @white-70;
            }
            &:active {
              color: @white-30;
            }
          }
        }
      }

      .arrow-down {
        .transition(all, 100ms);
        background: top left / contain no-repeat url(/assets/images/campaign/soultogether-landing-arrow.svg);
        height: 32px;
        margin: 49px 0 16px;
        width: 32px;
        z-index: 2;

        .respond-to(@bpDesktopLarge, {
          margin: 20px 0 40px;
        });

        &:hover {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
  .soulcup-landing-how-it-works {
    .respond-to(@bpTablet, {
      padding: 0;
    });

    .soulcup-section-container {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    h2 {
      .font-family(heavy);
      color: @gray-darker;
      font-size: 1.5rem;
      margin-bottom: 24px;
      text-align: center;
      width: 100%;
      .respond-to(@bpTablet, {
        font-size: 1.5rem;
        line-height: 1.8125rem;
      });
      .respond-to(@bpTablet, {
        font-size: 2.25rem;
        line-height: 2.6875rem;
      });
    }
    .description {
      font-size: 1rem;
      line-height: 20px;
      margin: 0 auto 32px auto;
      text-align: center;

      .respond-to(@bpMobileLarge, {
        margin-bottom: 24px;
      });

      .respond-to(@bpTablet, {
        margin-bottom: 32px;
        max-width: 615px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 1.25rem;
        line-height: 24px;
        max-width: 800px;
      });

      > span {
        white-space: nowrap;
      }
    }
    .how-it-works-steps {
      .respond-to(@bpTablet, {
        display: flex;
        justify-content: space-between;
        max-width: 1400px;
        width: 100%
      });

      &__row {
        display: flex;
        justify-content: center;
        padding-bottom: 16px;

        .respond-to(@bpTablet, {
          padding-bottom: 0;
          display: contents;
        });
      }

      .step {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: center;
        white-space: initial;
        width: 150px;

        .respond-to(@bpTablet, {
          .box-shadow(0 10px 15px 0 rgba(0,0,0,0.1));
          box-sizing: border-box;
          height: 150px;
          justify-content: center;
          width: 162px;
        });

        .respond-to(@bpDesktopLarge, {
          height: 235px;
          width: 303px;
        });

        .sign-up-step-image {
          background: center / contain no-repeat url(/assets/images/soulcup/hands_icon.png);
          height: 40px;
          width: 40px;

          .respond-to(@bpDesktopLarge, {
            height: 70px;
            width: 70px;
          });
        }

        &.ride-step .sign-up-step-image {
          background: center / contain no-repeat url(/assets/images/soulcup/heart_icon.png);
        }

        &.track-step .sign-up-step-image {
          background: center / contain no-repeat url(/assets/images/soulcup/star_icon.png);
        }

        &.prizes-step .sign-up-step-image {
          background: center / contain no-repeat url(/assets/images/soulcup/phone_icon.png);
        }

        .step-description {
          display: block;
          font-size: 0.875rem;
          line-height: 1.25rem;
          text-align: center;
          padding-top: 12px;

          .respond-to(@bpTablet, {
            font-size: 0.875rem;
            line-height: 1.125rem;
            max-width: 140px;
          });

          .respond-to(@bpDesktopLarge, {
            font-size: 1.25rem;
            line-height: 1.625rem;
            max-width: 240px;
          });

          > span {
            white-space: nowrap;
          }
        }
      }
    }
    .action-buttons {
      margin-bottom: 24px;
      text-align: center;
    }
    .soulcup-share-container {
      font-size: 18px;
      margin-top: 30px;
      text-align: center;
      .respond-to(@bpDesktopLarge, {
        margin-top: 60px;
      });

      h2 {
        .font-family(bold);
        color: @gray-87;
        font-size: 0.875rem;
        letter-spacing: 2.7px;
        line-height: 17px;
      }

      .soulcup-share-message {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 0.75rem;
        letter-spacing: 2.3px;
        margin: 10px auto auto;
        text-transform: uppercase;
      }
      .soulcup-share-links {
        cursor: pointer;
        display: inline-block;
        margin-top: 24px;

        a {
          border: none;
          display: inline-block;
          height: 21px;
          width: 40px;
          &.soulcup-share-icon {
            background: no-repeat 50%;
          }
          &.soulcup-share-icon-facebook {
            background-image: url("/assets/images/soulcup/share_icons/facebook-icon.svg");
            background-size: 20px;
          }
          &.soulcup-share-icon-twitter {
            background-image: url("/assets/images/soulcup/share_icons/twitter-icon.svg");
            background-size: 20px;
          }
          &.soulcup-share-icon-ig {
            background-image: url("/assets/images/soulcup/share_icons/Ig.png");
            background-size: 20px;
          }
          &.soulcup-share-icon-email {
            background-image: url("/assets/images/soulcup/share_icons/Mail.png");
            background-size: 20px;
          }
          &.soulcup-share-icon-link {
            background-image: url("/assets/images/soulcup/share_icons/Link.png");
            background-size: 20px;
          }
        }
      }
    }

    &__view-faqs {
      .font-family(bold);
      border-bottom: 1px solid @black;
      color: @black;
      font-size: 0.875rem;
      height: 16px;
      line-height: 1.25rem;
      margin: 20px auto 0;
      width: 74px;
    }
  }

  .soulcup-landing-ios {
    .flex-display(flex);
    background-color: @gray-darker;
    color: @white;
    height: 29.94rem;

    .respond-to(@bpTablet, {
      height: 28.94rem;
    });

    .respond-to(@bpDesktopLarge, {
      height: 45rem;
    });

    .respond-to(@bpDesktopLarge, {
      font-size: 1.25rem;
    });


    .soulcup-section-container {
      .flex-display(flex);
      flex-direction: column;
      padding: 41px 24px 0;
      width: 100%;
      .respond-to(@bpDesktopLarge, {
        padding: 0 0;
      });

      .soulcup-landing-ios-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        overflow: hidden;
        .respond-to(@bpTablet, {
          display: grid;
          grid-template-columns: 1fr 1fr;
        });
        h2 {
          margin-left: 0px;
        }
      }

      .soulcup-landing-ios-grid-with-image {
        display: grid;
        grid-template-columns: 1fr 0fr;
        height: 100%;
        overflow: hidden;
        .respond-to(@bpTablet, {
          display: grid;
          grid-template-columns: 1fr 1fr;
        });
      }

      h2 {
        .font-family(heavy);
        font-size: 1.5rem;
        grid-column: 1 / span 2;
        grid-row: 1;
        margin-bottom: 15px;
        width: 100%;
        .respond-to(@bpTablet, {
          grid-column: 2;
          grid-row: 1;
          margin: 76px 0 24px;
        });
        .respond-to(@bpDesktopLarge, {
          font-size: 2.25rem;
          line-height: 2.5rem;
          margin: 233px 20px 30px 80px;
          width: auto;
        });
      }

      p, li {
        .font-family(light);
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        text-align: left;

        .respond-to(@bpDesktopLarge, {
          font-size: 1.25rem;
          line-height: 1.625rem;
        });


      }

      .iphone {
        .flex(0 0 50%);
        background: center top / contain no-repeat url("/assets/images/turnitup/918_TurnItUp_WebAssets-Phone.png");
        margin-right: 16px;
        .respond-to(@bpTablet, {
          grid-column: 1;
          grid-row: 1 /span2;
          height: 422px;
        });
        .respond-to(@bpDesktopLarge, {
          height: 166%;
        });


      }
      .description-container {
        text-align: left;
        font-size: 1.3rem;
        line-height: 24px;
        font-weight: lighter;
        font-family: 'HelveticaNeueLTStd-Lt', serif;
        .flex(0 0 50%);
        flex-direction: row;
        .respond-to(@bpTablet, {
          grid-column: 2;
          grid-row: 1;
          margin-top: 45%;
        });
        .respond-to(@bpDesktopLarge, {
          grid-column: 2;
          grid-row: 2;
          margin: -50px 20px 30px 2px;
        });

        .soulcup-share-icon-ios {
          background: center / cover no-repeat url("/assets/images/soulcup/share_icons/ios-appstore.png");
          display: inline-block;
          height: 36px;
          margin-top: 0px;
          margin-left: 60%;
          width: 111px;

          .respond-to(@bpTablet, {
            margin-top: 75%;
            margin-left: 20%;
          });
          .respond-to(@bpDesktopLarge, {
            height: 48px;
            margin-top: 24px;
            width: 148px;
            margin-left: 20%;
          });
        }
      }

      .team-module-left {
        background: url(/assets/images/turnitup/2024TIU-Desktop-DashboardSolo.jpg) bottom center / cover no-repeat;
        background-size: cover;
        margin-top: -35%;
        .respond-to(@bpTablet, {
          grid-column: 1;
          grid-row: 1 /span2;
          margin-top: 0;
        });
        .respond-to(@bpDesktopLarge, {
          margin-top: 0;
        })
      }
    }
  }

  .soulcup-landing-prizes {
    .respond-to(@bpDesktopLarge, {
      background: linear-gradient(to right, @white 50%, @gray-main 50%);
      padding: 80px 0 48px;
    });

    .soulcup-section-container {
      padding-bottom: 15px;
      .respond-to(@bpTablet, {
        padding-top: 0;
      });
    }

    .soulcup-section-flex-container {
      .respond-to(@bpMobileLarge, {
        .flex-display();
        .flex-wrap(none);
        .align-items(flex-start);
      });

      > div {
        .respond-to(@bpMobileLarge, {
          .flex(1 50%);
        });
      }
    }

    .description {
      .respond-to(@bpMobileLarge,{
        box-sizing: border-box;
        padding: 40px 0 0 0;
        margin-right: 8px;
      });
      .respond-to(@bpTablet, {
        margin-right: 16px;
      });
      .respond-to(@bpDesktopLarge, {
        margin: 0;
        padding: 150px 80px 0 70px;
      });

      h2 {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 1.5rem;
        margin-bottom: 16px;
        width: 100%;

        .respond-to(@bpDesktopSmall, {
          font-size: 2.25rem;
        });
      }

      p {
        color: @gray-59;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 32px;

        .respond-to(@bpDesktopSmall, {
          font-size: 1.25rem;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 1.5rem;
          line-height: 30px;
        });
      }

      .register-btn {
        margin-bottom: 24px;
      }
    }
    .list {
      box-sizing: border-box;
      text-align: left;

      .respond-to(@bpMobileLarge, {
        margin: 0 0 0 8px;
        padding: 24px 16px;
      });

      .respond-to(@bpTablet, {
        margin-left: 16px;
        padding: 40px 32px 0;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 40px 48px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 40px 56px;
      });

      .item {
        .align-items(center);
        .flex-display();
        .flex-wrap(nowrap);
        border-bottom: 1px solid @gray-50;
        margin: 0;
        padding: 25px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }

        .amount {
          .flex(none);
          .font-family(heavy);
          color: @gray-darker;
          font-size: 1.5rem;
          text-align: left;
          width: 118px;

          .respond-to(@bpMobileLarge, {
            font-size: 1.25rem;
            width: 100px;
          });

          .respond-to(@bpDesktopSmall, {
            font-size: 1.875rem;
            width: 140px;
          });
        }
        .prize {
          text-align: left;
          margin-left: 1em;

          .flex-grow(33);
          font-size: 0.875rem;
          line-height: 16px;

          .respond-to(@bpMobileLarge, {
            font-size: 0.75rem;
            line-height: 15px;
          });

          .respond-to(@bpDesktopSmall, {
            font-size: 1.125rem;
            line-height: 24px;
          });

          > span {
            .respond-to(@bpMobileLarge, {
              white-space: nowrap;
            });
          }
        }
      }
    }
  }

  .soulcup-landing-team-prizes {
    .flex-display(flex);
    background-color: @gray-darker;
    color: @white;
    min-height: 24.875rem;

    .respond-to(@bpDesktopLarge, {
      min-height: 26.875rem;
    });

    .team-prizes-image {
      .flex(0 0 50%);
      background-image: url("/assets/images/soulcup/2022_unbeatable_rider.jpg");
      background-size: cover;
      display: none;

      .respond-to(@bpTablet, {
        display: inline-block;
      });
    }

    .soulcup-section-container {
      .flex-display(flex);
      padding-bottom: 22px;

      .respond-to(@bpTablet, {
        padding: 64px 25px 30px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 157px 80px 30px 120px;
      });

      .team-prizes-text {
        &-heading {
          font-size: 1.5rem;
          margin: 0;

          .respond-to(@bpTablet, {
            font-size: 1.5rem;
          });

          .respond-to(@bpDesktopLarge, {
            font-size: 2.25rem;
          });
        }

        &-content {
          font-size: 0.875rem;
          margin: 0;
          padding-top: 22px;

          .respond-to(@bpDesktopLarge, {
            font-size: 1.25rem;
          });
        }

        &-note {
          color: @greenWhite;
          font-size: 0.75rem;
          line-height: 0.875rem;
          padding-top: 44px;
          margin: 0;

          .respond-to(@bpTablet, {
            padding-top: 36px;
          });

          .respond-to(@bpDesktopLarge, {
            padding-top: 107px;
          });
        }
      }

      h2 {
        .font-family(heavy);
        font-size: 1.5rem;
        margin-bottom: 16px;
        width: 100%;

        .respond-to(@bpDesktopSmall, {
          font-size: 2.25;
        });
      }

      p {
        font-size: 0.875rem;
        line-height: 1.125rem;

        .respond-to(@bpDesktopSmall, {
          font-size: 1.25rem;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 1.5rem;
          line-height: 1.875rem;
        });
      }
    }
  }
}

.soulcup-register-main {
  min-width: 320px;
  text-align: center;

  .soulcup-register-section-container {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 16px;
    text-align: left;
    width: 100%;

    .respond-to(@bpMobileLarge, {
      padding: 0 24px;
    });

    .respond-to(@bpTablet, {
      margin: 0 auto;
      padding: 0;
      width: auto;
    });

    section {
      .respond-to(@bpTablet, {
        width: 720px;
      });

      .respond-to(@bpDesktopSmall, {
        width: 834px;
      });

      .respond-to(@bpDesktopLarge, {
        width: 864px;
      });
    }
  }

  .soulcup-register-header {
    padding-top: 40px;
    margin-bottom: 40px;

    .respond-to(@bpMobileLarge, {
      padding-top: 48px;
    });

    img {
      height: 80px;
      margin-bottom: 24px;
    }

    h3 {
      .font-family(light);
      font-size: 18px;
      margin-bottom: 8px;
    }

    .subtitle {
      .font-family(heavy);
      font-size: 14px;
      letter-spacing: 2.5px;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }

  .soulcup-register-form-section {
    margin-bottom: 64px;

    h3 {
      .font-family(heavy);
      font-size: 24px;
      margin-bottom: 16px;

      .respond-to(@bpMobileLarge, {
        font-size: 30px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 36px;
        margin-bottom: 24px;
      });
    }

    .divider {
      background: @scYellowC;
      height: 6px;
      margin-bottom: 16px;
      width: 150px;

      .respond-to(@bpDesktopSmall, {
        margin-bottom: 24px;
      });
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 48px;

      .respond-to(@bpMobileLarge, {
        margin-bottom: 56px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
      });
    }

    .step {
      h4 {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 18px;
        margin-bottom: 16px;

        .respond-to(@bpMobileLarge, {
          font-size: 20px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 24px;
        });
      }

      .help-text {
        .font-family(light);
        color: @gray-59;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 16px;

        .respond-to(@bpDesktopSmall, {
          font-size: 16px;
        });
      }
    }

    .goal-selection-content {
      .box-shadow(0 3px 8px 0 @gray-6);
      background: @scWhiteC;
      border: .5px solid @gray-12;
      margin-bottom: 48px;
      overflow: hidden;
      position: relative;
      width: 100%;

      .respond-to(@bpTablet, {
        display: inline-block;
      });

      .goal-selection-description {
        .font-family(heavy);
        color: @gray-38;
        font-size: 18px;
        margin: 48px 16px 16px;

        .respond-to(@bpMobileLarge, {
          font-size: 20px;
        });

        .respond-to(@bpTablet, {
          font-size: 24px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
          margin: 56px 40px 24px;
        });

        .respond-to(@bpDesktopLarge, {
          margin: 56px 56px 24px;
          white-space: nowrap;
        });

        .goal-select {
          .font-family(heavy);
          color: @gray-87;
          cursor: pointer;
          font-size: inherit;
          background: url(/assets/images/dropdown_arrow_down.png) no-repeat 96% center;
          background-size: 14px 8px;
          border: none;
          border-bottom: 2px solid;
          border-radius: 0;
          margin: 0;
          padding: 0 20px 0 0;
          text-indent: 0;

          .respond-to(@bpMobileLarge, {
            padding-right: 24px;
          });

          .respond-to(@bpTablet, {
            padding-right: 26px;
          });

          .respond-to(@bpDesktopSmall, {
            background-size: 20px 11px;
            padding-right: 40px;
          });

          &:hover {
            color: @gray-54;
          }
        }

        >span {
          display: none;

          .respond-to(@bpMobileLarge, {
            display: inline-block;
            white-space: pre;
          });
        }
      }
    }

    .recommended-goal-tip {
      .box-shadow(0 5px 10px 0 @gray-12);
      .transition(opacity, 500ms);
      left: 105px;
      opacity: 1;
      padding: 4px 14px 4px 7px;
      position: absolute;
      top: 6px;

      .respond-to(@bpMobileLarge, {
        left: 117px;
      });

      .respond-to(@bpTablet, {
        left: 132px;
      });

      .respond-to(@bpDesktopSmall, {
        left: 188px;
        top: 11px;
      });

      .respond-to(@bpDesktopLarge, {
        left: 208px;
      });

      &.recommended-goal-tip-hide {
        opacity: 0;
      }

      h5 {
        .font-family(bold);
        color: @gray-darker;
        font-size: 12px;
        margin-bottom: 3px;
      }

      p {
        .font-family(roman);
        color: @gray-59;
        font-size: 11px;
        margin: 0;
        white-space: nowrap;
      }

      &:before {
        border-top: 6px solid @scWhiteC;
        border-left: 5px solid transparent;
        border-right:5px solid transparent;
        bottom: -6px;
        content:'';
        display:block;
        height:0;
        left:14px;
        position:absolute;
        width:0;
      }

    }

    .goal-selection-boxes-wrapper {
      overflow: auto;
      position: relative;

      .respond-to(@bpTablet, {
        text-align: center;
      });

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .goal-selection-boxes {
      display: inline-block;
      padding: 0 16px;
      text-align: left;
      white-space: nowrap;

      .respond-to(@bpTablet, {
        padding: 0 8px;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 0 38px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 0 56px;
      });

      .goal-selection-box {
        border: 1px solid @gray-12;
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        height: 190px;
        padding: 16px;
        position: relative;
        vertical-align: top;
        width: 208px;

        .respond-to(@bpDesktopSmall, {
          height: 196px;
          width: 208px;
        });

        .respond-to(@bpDesktopLarge, {
          height: 232px;
          padding: 40px 24px;
          width: 224px;
        });

        &:not(:first-child) {
          margin-left: 8px;

          .respond-to(@bpDesktopSmall, {
            margin-left: 16px;
          });

          .respond-to(@bpDesktopLarge, {
            margin-left: 32px;
          });
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.selected-goal {
          border-color: @gray-87;
        }

        .radio-box {
          position: absolute;
          top: 8px;
          right: 8px;

          .respond-to(@bpDesktopLarge, {
            top: 16px;
            right: 16px;
          });

          input {
            cursor: pointer;
            margin: 0;
          }
        }

        .goal-count {
          .font-family(bold);
          border-bottom: 1px solid;
          color: @gray-darker;
          display: inline-block;
          font-size: 24px;
          margin-bottom: 16px;

          .respond-to(@bpDesktopSmall, {
            font-size: 30px;
          });
        }

        .rider-prize {
          .font-family(light);
          color: @gray-darker;
          font-size: 14px;
          margin: 0;

          .rider-prize-item {
            display: block;
            line-height: 14px;
            margin-bottom: 8px;
            white-space: normal;

            > span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .prize {
      overflow: hidden;
      margin: 16px;

      .respond-to(@bpDesktopSmall, {
        margin: 16px 38px;
      });

      .respond-to(@bpDesktopLarge, {
        margin: 24px 56px;
      });

      p {
        .font-family(heavy);
        color: @gray-59;
        font-size: 11px;
        letter-spacing: 2px;
        line-height: 14px;
        position: relative;
        text-transform: uppercase;
        z-index: 2;

        .respond-to(@bpMobileLarge, {
          font-size: 12px;
          line-height: normal;
          width: auto;
          max-width: none;
        });
      }

      .golden-disclaimer {
        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    .pickup-studio-selection {
      margin-bottom: 48px;

      select {
        margin: 0;
      }
    }

    .confirm-selection {

      label {
        .flex-display();
        .flex-wrap(nowrap);

        .respond-to(@bpDesktopSmall, {
          .align-items(center);
        });

        input {
          .flex(none);
        }

        span {
          .font-family(medium);
          color: @gray-darker;
          display: inline-block;
          float: left;
          font-size: 14px;
          line-height: 16px;
          padding-left: 8px;
          width: 475px;

          .respond-to(@bpDesktopSmall, {
            width: auto;
          });
        }
      }

      p {
        .font-family(medium);
        color: @gray-darker;
        font-size: 14px;
        line-height: 16px;
        margin: 16px 0;

        a {
          text-decoration: underline;
        }
      }

      .checkbox {
        float: left;
      }
    }

    .submit-section {
      button {
        padding: 5px 53px;
      }
    }

    .pickup-studio {
      background: url(/assets/images/dropdown_arrow_down.png) no-repeat 96% center;
      background-size: 16px 9px;
      border: 1px solid @gray-59;
      border-radius: 5px;
      max-width: 520px;
      width: 100%;

      &.field-error {
        border-color: @error;
      }
    }
  }

  .soulcup-register-footer {
    background: @gray-6;
    padding: 40px 16px;

    .respond-to(@bpMobileLarge, {
      padding: 40px 32px;
    });

    .respond-to(@bpTablet, {
      padding: 56px 0;
    });

    .respond-to(@bpDesktopSmall, {
        margin-bottom: @footerHeightDesktop;
    });

    p {
      .font-family(medium);
      color: @gray-darker;
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      a {
        text-decoration: underline;
      }
    }
  }

  .soultogether-checkbox {
    .appearance(none);
    border: 1px solid @gray-medium;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    margin: 0 10px 0 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 24px;

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/spritesheet.png) -148px -202px no-repeat;
      color: @yellow-light;
      content: '';
      font-size: 14px;
      height: 24px;
      left: 0;
      position: absolute;
      width: 24px;
    }
  }

  .soultogether-radio {
    .appearance(none);
    border: 1px solid @gray-54;
    border-radius: 50%;
    height: 20px;
    padding: 0;
    position: relative;
    width: 20px;

    .respond-to(@bpDesktopLarge, {
      height: 24px;
      width: 24px;
    });

    &:checked {
      background-color: @yellow-light;
      border: none;
    }

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/spritesheet.png) -250px -175px no-repeat;
      color: @yellow-light;
      content: '';
      display: block;
      height: 100%;
      width: 100%;

      .respond-to(@bpDesktopLarge, {
        background-size: 313px 297px;
        background-position: -278px -201px;
      });
    }
  }
}
