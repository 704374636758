.gift-card-container {
  .respond-to(@bpDesktopSmall, {
    padding-bottom: 60px;
  });
  .gift-card-background {
    text-align: center;
  }
  .gift-card-subheader {
    position: relative;
    height: 100px;
    p {
      padding: 0 0 0 0;
    }
  }
  .gift-card-header {
    width: 245px;
    font-size: 4em;
    padding-top: 40px;
    padding-bottom: 20px;
    margin: 0 auto;
  }
  font-size: 1em;
  input,
  textarea {
	width: 280px;
    margin-bottom: 10px;
    margin-right: 0px;
  }
  select {
    border: @borderLightGray;
    padding: 10px;
    height: 50px;
    margin-right: 5px;
    min-width: 60px;
  }
  .gift-card-content {
    p {
      padding: 0;
    }
  }
  #frm-delivery_month {
    width: 122px;
  }
  #frm-delivery_day {
    width: 60px;
  }
  #frm-delivery_year {
    width: 82px;
    margin-right: 0;
  }

  .yellow-button {
    width: 280px;
    margin: 20px 0 20px;
    padding: 0;
    line-height: 50px;
    font-size: 14px
  }
  .sub-title {
    padding: 30px 0 10px 0;
  }
  .label {
    font-size: 1.4em;
  }
  .gift-card-disabled-message {
    display: block;
    font-size: 16px;
    line-height: normal;
    margin: 174px 16px 100px;
    text-align: center;

    .respond-to(@bpDesktopSmall, {
      font-size: 24px;
    });
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}

.gift-card-content-container {
  .center-container();
  width: 280px;
}

.recaptcha-message {
  display: block;
  p {
    font-size: 16px;
    line-height: 24px;
  }

  p > a {
    color: @blue-main;
  }

}

.gift-card-container {
  .gift-card-content-container {
    p.gift-card-faq {
      padding-top: 20px;
      font-size: 14px;
      .font-family(bold);
      text-align: center;
    }
  }
}

/* MEDIA QUERIES - SHOP GIFT CARD
-----------------------------------------------------------------------------*/

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .gift-card-container {
    .gift-card-header {
      width: 100%;
      font-size: 5em;
      padding-bottom: 60px;
    }
    .gift-card-subheader {
      height: 30px;
      .label {
        position: absolute;
        top: -10px;
        right: 0;
      }
    }
    .gift-card-card,
    .delivery_recipient_email,
    .gift-card-message {
      width: 280px;
      float: left;
    }
    .gift-card-card {
      margin-right: 10px;
    }
    .purchase-gc-form {
      textarea {
        min-height: 111px;
        margin-bottom: 30px;
      }
    }
    .yellow-button {
      margin-top: 11px;
    }
    .gift_card_form {
      clear: both;
    }
    .sub-title,
    .label {
      display: inline;
    }
    .label {
      display: inline;
      float: left;
    }
    .email-to-me {
      .label {
        float: left;
        padding: 3px 10px 0 0;
      }
    }
    .gift-card-content {
      clear: both;
      margin-top: 30px;
    }
    .delivery-date {
      display: inline-block;
      clear: both;
      float: left;
    }
    .add-to-bag {
      margin-top: 42px;
      margin-left: 10px;
    }
  }
  .gift-card-content-container {
    width: 570px;
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width : 1024px) {
  .gift-card-container {
    .gift-card-header {
      font-size: 6.5em;
      margin: 40px 0 20px 0;
    }
    .purchase-gc-form {
      textarea {
        margin-bottom: 10px;
      }
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .gift-card-container {
    input,
    textarea {
      width: 344px;
      margin-bottom: 15px;
    }
    select {
      margin-right: 15px;
    }
    .gift-card-header {
      font-size: 6.5em;
      margin: 40px 0 20px 0;
    }
    .sub-title {
      font-size: 2.6em;
    }
    .gift-card-subheader {
      .label {
        top: -5px;
      }
    }
    .purchase-gc-form {
      textarea {
        min-height: 117px;
        margin-bottom: 10px;
      }
    }
    .email-to-me, .email-to-recipient {
      .label {
        clear: both;
      }
    }
    #frm-delivery_month {
      width: 134px;
    }
    #frm-delivery_day {
      width: 80px;
    }
    #frm-delivery_year {
      width: 94px;
    }
    #frm-delivery_year {
      width: 94px;
    }
    .yellow-button {
      width: 344px;
    }
    .add-to-bag {
      margin-left: 15px;
    }
    .gift-card-card {
      width: 344px;
      margin-right: 15px;
    }
  }
    .gift-card-content-container {
      width: 703px;
    }
}
