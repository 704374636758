body.styleguide {
  .sg-header {
    .box-sizing(border-box);
    .flex-direction(row);
    .flex-display(flex);
    background-color: @black;
    padding: 1rem 1rem 0.625rem;
    width: 100%;
  }

  .sg-header__logo {
    .align-self(center);
    .font-family(bold);
    color: @scWhiteC;
    font-size: 1.5rem;
    margin-right: 1rem;
    text-transform: uppercase;
  }

  .sg-header__nav {
    align-self: center;
  }

  .sg-header__nav__list {
    list-style: none;
  }

  .sg-header__nav__item {
    // tbd
  }

  .sg-header__nav__link {
    .font-family(roman);
    // Unfortunately there is a global style on a tags
    // rendering the need for !important here.
    color: @scWhiteC !important;
    font-size: 0.625rem;
  }

  .sg-container {
    .flex-direction(column);
    .flex-display(flex);
    padding: 1rem;

    .respond-to(@bpDesktopSmall, {
      .flex-direction(row);
      margin: 3rem auto;
      padding: 2rem;
    });
  }

  .sg-left__column {
    .respond-to(@bpDesktopSmall, {
      .flex(15%);
      border-right: @borderMediumGray;
      padding-right: 2rem;
    });
  }

  .sg-right__column {
    .respond-to(@bpDesktopSmall, {
      .flex(85%);
      padding: 0 2rem 1rem;
    });
  }

  .sg-list {
    list-style: none;
  }

  .sg-sublist {
    list-style: none;
    margin: 0.3125rem 0 0 1.25rem;
  }

  .sg-list__item,
  .sg-sublist__item {
    margin-bottom: 0.3125rem;
  }

  // All components main nav
  .sg-components__nav {
    display: block;
    margin: 3rem auto;
    width: 75%;
  }

  .sg-components__list {
    list-style: none;
  }

  .sg-components__link {
    .font-family(medium);
    font-size: 1.125rem;
  }

  // UI components nav
  .sg-ui-components__nav {
    display: block;
    width: 75%;
  }

  .sg-ui-components__list {
    list-style: none;
  }

  .sg-ui-components__item {
    border-bottom: @borderBlack;
    margin-bottom: 1rem;
    padding-bottom: 0.3125rem;
  }

  .sg-ui-components__link {
    .font-family(medium);
    font-size: 1.125rem;
  }

  // Display blocks
  .sg-hr {
    margin: 2rem auto;
  }

  .sg-display__block__title {
    .font-family(heavy);
    border-bottom: @borderBlack;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .sg-display__block__subtitle {
    .font-family(heavy);
    font-size: 1.5rem;
  }

  .sg-block,
  .sg-display__block {
    margin-bottom: 2rem;
  }

  .sg-block {
    .font-family(roman);
    font-size: 1.125rem;
  }

  .sg-display__block {
    .align-items(center);
    .flex-display(flex);
    .flex-wrap(wrap);
    .justify-content(space-evenly);
    margin: 1rem auto;
    padding: 1rem 2rem 1rem;
  }

  .sg-display__block button,
  .sg-display__block a {
    margin-bottom: 1rem
  }

  .sg-display__card__block {
    margin: 1rem auto;
    padding: 1rem 0;
  }

  .sg-display__form__block {
    margin: 1rem auto;
    padding: 1rem 0;
  }

  .sg-code__block {
    .font-family(roman);
    background: @black;
    color: @scWhiteC;
    display: block;
    font-size: 0.8125rem;
    margin: 1rem;
    padding: 1rem;
  }
}
