// HISTORY TAB-SPECIFIC STYLES
.profile-history-container {
  .profile-day__title {
    padding-left: 1rem;

    .respond-to(@bpDesktopSmall, {
      padding-left: 0;
    });
  }

  .profile-class-container {
    border-bottom: @borderGrayHex97;
    padding: 1.375rem 1rem;

    .respond-to(@bpDesktopSmall, {
      border-bottom: @borderLightGray;
      padding: 1.5rem 0;
    });
  }
}

// HISTORY-SPECIFIC STYLES
.profile-content-container--history {
  .respond-to(@bpMobileLarge, {
    margin: 0 auto;
    max-width: 25rem;
  });

  .respond-to(@bpTablet, {
    padding: 0.5rem 0 6rem;
  });

  .respond-to(@bpDesktopSmall, {
    max-width: 41.25rem;
    padding-top: 2rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding: 2.625rem 0 6rem;
  });
}

.profile-history-header {
  .font-family(heavy);
  font-size: 1rem;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.25rem;
    padding-bottom: 0.75rem;
  });
}

.profile-loading-message {
  .font-family(medium);
  font-size: 0.875rem;
  text-align: center;
  padding: 2rem;
}

// PURCHASE-TAB SPECIFIC STYLES
.profile-purchase-container {
  .respond-to(@bpDesktopSmall, {
    display: table;
    width: 100%;
  });
}

.profile-purchase-header {
  .font-family(medium);
  display: none;
  font-size: 0.8125rem;

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderGray38-thick;
    display: table-header-group;
  });
}

.profile-purchase-header__column {
  display: table-cell;

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderGray38-thick;
    padding: 1rem 0 1rem 0.875rem;
  });
}

.profile-purchase-header__column--order {
  .respond-to(@bpDesktopSmall, {
    padding-left: 0;
  });
}

.profile-purchase-header__column--series {
  min-width: 6.25rem;
}

.profile-purchase-history-item {
  border-bottom: @borderGray38-thick;
  padding: 1rem 2.75rem;

  .respond-to(@bpMobileLarge, {
    padding: 1rem;
  });

  .respond-to(@bpTablet, {
    padding: 1rem 0;
  });

  .respond-to(@bpDesktopSmall, {
    border-bottom: none;
    border-spacing: 0.875rem 1.5rem;
    display: table-row;
  });
}

.profile-purchase-history-item__column {
  .flex-display(flex);
  line-height: 1.125rem;

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderGray38-thick;
    display: table-cell;
  });
}

.profile-purchase-history-item__column--order {
  .respond-to(@bpDesktopSmall, {
    border-spacing: 0;
  });
}

.profile-purchase-history__item__column {
  display: block;
}

.profile-purchase-history-item__column--date {
  margin-bottom: 1.125rem;

  .respond-to(@bpDesktopSmall, {
    margin-bottom: 0;
  });
}

.profile-purchase-history-item__column__title {
  .font-family(medium);
  color: @gray-darker;
  font-size: 0.875rem;
  margin-right: 0.25rem;

  .respond-to(@bpDesktopSmall, {
    .visually-hidden();
  });
}

.profile-purchase-history-item__column__value {
  .font-family(light);
  color: @gray-darker;
  font-size: 0.875rem;

  .respond-to(@bpDesktopSmall, {
    .font-family(medium);
    display: table-cell;
    font-size: 0.8125rem;
  });
}

.profile-purchase-history-item__column__value--order {
  .font-family(medium);
  color: @gray-38;
  font-size: 0.875rem;

  .respond-to(@bpDesktopSmall, {
    color: @gray-darker;
    display: table-cell;
  });
}

// PROFILE HISTORY - AVAIlABLE SERIES MENU STYLES
.profile-series-container {
  margin-bottom: 3.375rem;
}

.profile-series-menu__label-container {
  border-bottom: @borderLightGray;
  padding: 0 2.75rem;

  .respond-to(@bpMobileLarge, {
    padding: 0 1rem;
  });

  .respond-to(@bpTablet, {
    padding: 0;
  });
}

.profile-series-menu__label {
  .align-items(center);
  .flex-display(flex);
  width: 100%;
  padding: 1.5rem 0;
}

.profile-series-menu__label__type {
  .flex(1);
  .flex-display(flex);
  .flex-wrap(wrap);
}

.profile-series-menu__label__type__title {
  .font-family(bold);
  color: @gray-darker;
  font-size: 1rem;
  width: 100%;
}

.profile-series-menu__label__type__region {
  .font-family(light);
  color: @gray-darker;
  font-size: 0.875rem;
  padding-top: 0.25rem;
}

.profile-series-menu__label__type__regions {
  .font-family(light);
  color: @gray-medium;
  font-size: 0.875rem;
  max-width: 15.5rem;
  padding-top: 0.25rem;

  .respond-to(@bpMobileLarge, {
    max-width: 17.5rem;
  });

  .respond-to(@bpTablet, {
    max-width: 22rem;
  });
}

.profile-series-menu__label__type__ww-date {
  display: inline-block;
  font-weight: bold;
  padding-top: 6px;
}

// Series Transfer Styles
.profile-series-menu-transfer__switch-container {
  .flex-display(flex);
  padding: 2rem 0;
}

.profile-series-menu-transfer__switch {
  .align-items(center);
  .flex-display(flex);
  .justify-content(flex-start);
  background: @gray-darker;
  border-radius: 0.625rem;
  cursor: pointer;
  height: 1rem;
  min-width: 2rem;
  padding: 0.125rem;
  width: 2rem;
}

.profile-series-menu-transfer__switch--active {
  .justify-content(flex-end);
}

.profile-series-menu-transfer__switch__knob {
  background-color: @gray-light;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.profile-series-menu-transfer__switch__knob--active {
  background-color: @scYellowC;
}

.profile-series-menu-transfer__switch__message {
  .flex(1);
  margin: 0 1rem;
}

.profile-series-menu-transfer__switch__message__title {
  .font-family(bold);
  color: @gray-darker;
  font-size: 1rem;
  line-height: 1.25rem;
}

.profile-series-menu-transfer__switch__message__body {
  .font-family(light);
  color: @gray-darker;
  font-size: 0.875rem;
  line-height: 1.375rem;

  .respond-to(@bpMobileLarge, {
    max-width: 17.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    max-width: 31.5rem;
  });
}

// Available Series Credits Styles
.profile-series-menu__credits {
  .align-items(center);
  .flex-display(flex);
  .font-family(medium);
  .justify-content(center);
  border-radius: 0.9375rem;
  font-size: 0.75rem;
  height: 1.875rem;
  width: 1.875rem;
}

.profile-series-menu__credits--cycle {
  background-color: @scYellowC;
  color: @gray-darker;
}

.profile-series-menu__credits--unusable {
  background-color: @gray-light;
  color: @gray-70;
}

.profile-series-menu-transfer__credits {
  .align-self(center);
}

.profile-series-menu-transfer__credits--hidden {
  display: none;
}

.profile-history-header--no-series {
  padding-bottom: 1.25rem;
}

.profile-no-classes__button--no-series {
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
  width: 10.375rem;
}

.profile-no-classes__message--purchases {
  padding-bottom: 0;
}
