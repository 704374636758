.success-dialog-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: @zOverlay;
}

.success-dialog-container__overlay {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
}

.success-dialog-container__flex {
  .align-items(center);
  .flex-display(flex);
  .justify-content(center);
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}

.success-dialog {
  .align-items(center);
  .flex-display(flex);
  background-color: @scWhiteC;
  margin: 1rem;
  max-height: 95%;
  max-width: 95%;
  pointer-events: auto;
  position: relative;

  .respond-to(@bpMobileLarge, {
    height: auto;
    width: 33.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    height: 32.25rem;
    width: 40rem;
  });
}

.success-dialog__close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;

  svg {
    height: 1rem;
    fill: @scWhiteC;
    width: 1rem;
  }

  &:hover,
  &:active {
    svg {
      fill: #9B9B9B;
    }
  }

  .respond-to(@bpDesktopSmall, {
    right: 2rem;
    top: 2rem;

    svg {
      fill: @black;
      height: 1.5rem;
      width: 1.5rem;
    }
  });
}

.success-dialog__banner {
  .flex(2);
  background: url(/assets/images/newrider/success_banner.jpg) top left -4rem no-repeat;
  background-size: 240%;
  display: none;
  height: 100%;
  position: relative;

  &:after {
    background: linear-gradient(to top, #000 32%, rgba(0, 0, 0, 0) 35%);
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .respond-to(@bpDesktopSmall, {
    display: block;
  });
}

.success-dialog__main {
  .respond-to(@bpDesktopSmall, {
    .flex(3);
    padding: 2.5rem;
  });
}

.success-dialog__header {
  .align-items(flex-end);
  .flex-display(flex);
  background: url(/assets/images/newrider/success_banner.jpg) top left 4rem / cover no-repeat;
  height: 10.25rem;
  padding: 1rem;
  position: relative;

  &:after {
    background: linear-gradient(to right, #000 16%, rgba(0,0,0,0.2) 35%);
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .respond-to(@bpMobileLarge, {
    background-position: top left 11.25rem;
    background-size: 75%;
    height: 11rem;
    padding: 1.5rem 4rem;

    &:after {
      background: linear-gradient(to right, #000 33%, rgba(0,0,0,0) 50%);
    }
  });

  .respond-to(@bpDesktopSmall, {
    background: none;
    height: auto;
    padding: 0;

    &:after {
      content: none;
    }
  });
}

.success-dialog__title {
  .font-family(light);
  color: @scWhiteC;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 1;

  .respond-to(@bpMobileLarge, {
    font-size: 2.5rem;
    line-height: 2.75rem;
  });

  .respond-to(@bpDesktopSmall, {
    .font-family(bold);
    color: @gray-darker;
    font-size: 1.75rem;
    line-height: 2.25rem;
  });
}

.success-dialog__body {
  padding: 1rem;

  .respond-to(@bpMobileLarge, {
    padding: 2rem 4rem 2.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    padding: 0;
  });
}

.success-dialog__text {
  .font-family(roman);
  color: @gray-darker;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0 1.5rem;

  .respond-to(@bpDesktopSmall, {
    padding: 1.5rem 0 3rem;
  });

  a {
    text-decoration: underline;

    &:hover {
      color: @gray-59;
    }
  }
}

.success-dialog__button {
  .font-family(medium);
  background-color: @scYellowC;
  border: none;
  border-radius: 0.3125rem;
  color: @gray-darker;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 0 auto;
  max-width: 20rem;
  padding: 1rem;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    width: 9rem;
  });

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });

  &:hover {
    background: -moz-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(254,215,3,1)), color-stop(80%,rgba(254,224,0,1)));
    background: -webkit-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -o-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -ms-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: linear-gradient(to bottom, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
  }
}
