.page-link-style() {
  color: #0D52E3;
  font-size: 20px;
  line-height: 42px;
}
/* SERIES PURCHASE PAGE
-----------------------------------------------------------------------------*/
.series_purchase {
  .credits-page {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
  }

  .quick-links {
    li {
      list-style-type: none;
        a {
        .page-link-style()
      }
    }
  }

  .giftcard-link {
    .page-link-style();
    display: block;
    margin: 1rem 0;
  }

  .class-packages,
  .giftcard-packages,
  .renewable-packages {
    grid-template-columns: repeat(auto-fill,minmax(28%,1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    border-top: none;
    border-bottom: none;
    display: grid;
    max-width: 894px;
  }

  .row {
    border-bottom: none;
    text-align: center;
    background-color: #F6F6F6;
    min-height: 265px;

    &.super-soul-series-16,
    &.super-soul-series-25,
    &.super-soul-series-50 {
      color: white;
      background-color: black;
      border-style: solid;
      border-width: 10px 10px 10px 10px;
      -moz-border-image: url(/assets/images/MAF_MOBILE.jpeg) 5 5 5 5 stretch stretch;
      -webkit-border-image: url(/assets/images/MAF_MOBILE.jpeg) 5 5 5 5 stretch stretch;
      -o-border-image: url(/assets/images/MAF_MOBILE.jpeg) 5 5 5 5 stretch stretch;
      border-image: url(/assets/images/MAF_MOBILE.jpeg) 5 5 5 5 stretch stretch;

      .credits-additional-info-desktop {
        color: black;

      }
    }
    &.soul-renew-ma{
      color: white;
      background-color: black;
      border-style: solid;
    }
    &.pride-package{
      color: black;
      background-color: white;
      border-style: solid;
      border-width: 10px 10px 10px 10px;
      -moz-border-image: url(/assets/images/series_box_pride.jpg) 5 5 5 5 stretch stretch;
      -webkit-border-image: url(/assets/images/series_box_pride.jpg) 5 5 5 5 stretch stretch;
      -o-border-image: url(/assets/images/series_box_pride.jpg) 5 5 5 5 stretch stretch;
      border-image: url(/assets/images/series_box_pride.jpg) 5 5 5 5 stretch stretch;

      .credits-additional-info-desktop {
        color: black;

      }
    }

    img {
      width: 100%;
    }

    &.unstyled {
      background-color: transparent;

      a {
        .font-family(bold);
        background-color: @goldenYellow;
        display: block;
        font-size: 14px;
        height: 40px;
        letter-spacing: 0;
        line-height: 42px;
        text-align: center;
      }
    }
  }

  .series-type-divider {
    margin: 2rem 0 0;
  }

  .series-title-header {
    position: relative;
    .learn-more-link {
      .page-link-style();
      position: absolute;
      right: 0px;
      top: 0.75rem;
    }
  }


  button.change-region-series {
    padding-left: 0;
    padding-top: 1rem;
    background: transparent;
    border: none;

    .region {
      display: inline-block;
      font-size: 1.875rem;
      width: 13rem;
      word-break: break-word;

      .respond-to(@bpMobileSmallPlus, {
        width: 16rem;
      });

      .respond-to(@bpMobileMedium, {
        width: auto;
      });
    }
  }

  .section-row-container {
    width: 76%;
  }

  .region-cc {
    border-bottom: @borderLightGray;
    border-left: @borderLightGray;
    border-right: @borderLightGray;
    display: none;
    margin-top: -2px;
    position: absolute;
    width: 90%;
    z-index: 12;

    .region-select-row li {
      margin-bottom: 3px;
      padding: 0 16px;

      a:focus {
        color: @gray-70;
      }

      a.active {
        background: none;
      }
    }

    .region-select-row li:first-child {
      display: none;
    }

    .find-a-class-row,
    .region-select-row {
      overflow-y: scroll;
      padding-bottom: 0;
    }
  }

  .series-tabs-header {
    background: @gray-main;
    // border-top: @borderLightGray;
    padding-top: 32px;
    text-align: center;

    &.inactive {
      display: none;
    }
  }

  .series-tabs {
    display: inline-block;
    font-size: 0; // Fix for preventing whitespace around inline block
    margin: 0 auto;
    position: relative;
    text-align: center;

    .active-tab {
      border: none;
      border-bottom: 2px solid @gray-darker;
      cursor: pointer;
      display: block;
      position: absolute;
      top:48px;
      width: 40px;
      .transition(margin, 0.25s, ease);

      &.cycle-active {
        margin-left: 55px;
      }
    }
  }

  .series-tab-header {
    .font-family(bold);
    background: @gray-main;
    border: @borderLightGray;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 38px;
    letter-spacing: 1.5px;
    line-height: 12px;
    padding-top: 30px;
    position: relative;
    width: 150px;

    span {
      display: block;
      text-align: center;
    }

    .header-new {
      .font-family(bold);
      color: @gray-darker;
      font-size: 11px;
      letter-spacing: 2.1px;
      line-height: 11px;
      display: inline-block;
      left: 60px;
      position: absolute;
      top: 12px;
    }

    &.active {
      background: @scWhiteC;
      border: none;
      border-bottom: 1px solid @scWhiteC;
    }
  }

  .cycle-series-container {
    display: none;

    &.active {
      display: block;
    }
  }

  .series-type-header {
    .font-family(bold);
    line-height: 31px;
    padding: 24px 0;
    font-size: 24px;
  }

  .series-type-description {
    font-size: 20px;
    line-height: 28px;
    margin: 0.5rem 0;
  }

  .first-time-ride-series-1 {
    background-color: @goldenYellow;

    .yellow-button {
      background: black;
      color: white;
      border-radius: 0px;
    }
  }

  .hamptons-super-soul-series-50,
  .series-3 {
    background-color: @gray-main;

    // Canadian background hacks to deal with $50 online limit
    &.canadian-series {
      background-color: white;

      &.unpurchasable {
        background-color: @gray-main;
      }
    }
  }

  .newrider-pack {
    background: @scYellowC;

    .yellow-button {
      background: @black;
      color: @scWhiteC;

      &:hover {
        background: @gray-54 !important;
        color: @scWhiteC;
      }
    }
  }

  .unpurchasable {
    background-color: @gray-main;
  }

  .buy-classes-container {
    font-size: 1.2em;
    line-height: 1.9;

    .row {
      padding: 34px 0 10px;
      position: relative;
      width: auto;
      &.soul-renew-ma {
        padding: 0 0 10px;
      }
      &.warrior-wk.more-info.series-4 {
        border: 10px solid #fde001;
        padding-top: 24px;
      }
    }

    &.ten-pack-pride-month {
      .buy-series-close-button {
        right: 13px;
        top: 55px;
      }
    }

    .is-pride-month {
      margin-top: 18px;
    }

    .credits-tc {
      padding-top: 15px;
    }

    .series-header-container {
      padding: 36px 20px;
      width: 24%;
    }

    .series-header {
      margin: 0 auto;
    }

    .transfer-info {
      width: 100%;
    }

    .also-usable-in {
      .title {
        .font-family(medium);
      }
    }

    .change-region-series {
      .font-family(bold);
      padding: 1rem 3.3rem 1rem 0;
      cursor: pointer;
      position: relative;
      width: 100%;
    }

    .change-region-series-arrow {
      background-image: url('/assets/images/arrow-down-yellow.png');
      background-size: 12px 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 27px;
      width: 12px;
    }
    .credits-header-renew {
      background-color: @goldenYellow;
      color: black;
      line-height: 60px;
      .font-family(bold);
      display: block;
      font-size: 21px;
      letter-spacing: 0;
    }
    .add-series-form {
      width: 100%;

      .respond-to(@bpTablet, {
        padding-top: 10px;
      });
    }

    .credits-value {
      .font-family(bold);
      display: inline-block;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 34px;
      padding-top: 4px;
    }

    .credits-current {
      font-weight: bold;
    }

    .credits-original {
      text-decoration: underline;
      text-underline-offset: -8px;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 2px;
      opacity: 0.75;
    }

    .credits-expire {
      display: block;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      padding: 0;
      position: relative;
      width: 100%;
        &.credits-expire-renew {
            font-size: 10px;
            line-height: 10px;
            padding: 12px 0 12px;
        }
    }

    .credits-header-container {
      min-height: 80px;
    }
    .credits-promo-renew {
      span {
        .font-family(medium);
        background-color: #515650;
        display: inline-block;
        font-size: 14px;
        letter-spacing: -1px;
        line-height: 28px;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 3px;
      }
    }
    .canada-unpurchasable-message {
      bottom: 0;
      font-size: 12px;
      font-weight: 600;
      margin-top: 70px;
      position: absolute;
      padding-left: 32px;
      text-align: center;
    }

    .yellow-button,
    .black-border-button {
      .font-family(medium);
      font-size: 12px;
      letter-spacing: 0;
      position: absolute;
      right: 16px;
      top: 20px;
    }

    .yellow-button {
      height: 32px;
      line-height: 32px;
      width: 90px;
    }

    .black-border-button {
      background: transparent;
      cursor: default;
      height: 30px;
      line-height: 30px;
      padding: 0;
      width: 88px;
    }

    .credits-info {
      padding: 20px 32px 0 36px;
      position: relative;
    }

    .new-tag {
      .font-family(bold);
      font-size: 14px;
      left: 36px;
      letter-spacing: 5px;
      line-height: 14px;
      position: absolute;
      top: -8px;
    }

    .credits-additional-info {
      display: block;
      padding: 16px 20px 24px 36px;
    }

    .credits-additional-info-desktop {
      display: none;
      opacity: 0;
      position: absolute;
      z-index: 0;
    }

    .credits-special-head,
    .credits-amount,
    .credits-head,
    .series-activity-title {
      .font-family(heavy);
      display: block;
      font-size: 26px;
      letter-spacing: 0;
      line-height: 32px;
      // max-width: 67%;
    }

    .credits-amount {
      line-height: 24px;
    }

    .series-activity-title {
      display: block;
    }

    .credits-special-info a,
    .credits-exclude-hamptons a {
      .font-family(bold);
    }

    .more-info-icon {
      .icon-info-light;
      cursor: pointer;
      display: none;
      margin: 8px;
      right: 4px;
      top: 4px;
    }

    .buy-cycle-link-container {
      padding: 32px 22px 40px;
      text-align: center;

      span {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 14px;

        a {
          border-bottom: @borderDarkerGray;

          &:hover {
            opacity: 0.54;
          }
        }
      }
    }

    .booking-info {
      padding: 1.25rem 2.25rem 0;
      position: relative;

      .respond-to(@bpTablet, {
        opacity: 1;
        padding: 0 2rem 0;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 0 1.875rem;
      });

      .booking-description {
        font-size: 1rem;
        line-height: 1.15rem;
        padding: .5rem 0 0;

        .respond-to(@bpMobileLarge, {
          font-size: 1.2rem;
          line-height: 1.3rem;
        });

        a {
          text-decoration: underline;
        }
      }
    }

    .corp-booking-container {
      padding-top: 1rem;

      .corp-booking-image {
        margin-bottom: 1.3rem;
        position: relative;

        .respond-to(@bpMobileLarge, {
          margin-bottom: 1.8rem;
        });

        img {
          height: 9.5rem;
          object-fit: cover;
          width: 100%;
        }

        span {
          .font-family(heavy);
          color: @scWhiteC;
          display: block;
          font-size: 1.4rem;
          margin-top: 3.6rem;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }
      }

      .corp-booking__learn-more__button {
        .mixin-text-icon-link(
          @fontFamily: light;
          @fontSize: 1.1rem;
        );

        display: block;
        margin: 1.8rem auto;

        .respond-to(@bpMobileLarge, {
          margin: 0.4rem auto 0;
        });

        span {
          padding-right: 1rem;
        }
      }
    }

    .group-booking-container {
      .series-activity-title {
        margin-bottom: 2.5rem;
      }

      .booking-description {
        margin-bottom: 2.2rem;

        .respond-to(@bpMobileLarge, {
          margin-bottom: 0;
        });
      }

      .group-booking__email-us__button {
        .mixin-button-secondary(
          @textTransform: lowercase;
        );
        padding: 0.7rem 3rem;
        position: absolute;
        right: 1.5rem;
        top: 0;

        .respond-to(@bpMobileLarge, {
          margin-top: 2.2rem;
          position: inherit;
          right: auto;
          top: auto;
        });
      }
    }
  }
}

.shoe-tool-tip {
  display: none;

  &.visible {
    .font-family(medium);
    color: @scCoolGray7C;
    display: block;
    padding: 0 0 1rem 2.2rem;
    text-align: left;
    width: 100%;

    .respond-to(@bpMobileLarge, {
      color: @black;
      font-size: 0.937rem;
      padding: 1rem 0 1rem;
    });

    .respond-to(@bpDesktopLarge, {
      padding: 1rem 0 1rem;
    });
  }
}

/* MEDIA QUERIES - SERIES PURCHASE PAGE
-----------------------------------------------------------------------------*/
@media only screen and (max-width: 768px) {
  .series_purchase {
    .credits-page {
      flex-direction: column;

      .row {
        &.super-soul-series-16,
        &.super-soul-series-25,
        &.super-soul-series-50 {
          background-size: 100% 100%;
          background-position: top;
        }
      }

      .giftcard-link {
        position: relative;
        display: block;
        padding: 0.25rem;
        text-align: center;
        border: solid 2px #0d52E3;
        margin: 1.1rem 0 2rem;
      }
    }

    .section-row-container {
      width: 100%;
    }

    .buy-classes-container .group-booking-container .group-booking__email-us__button {
      display: none;
    }

    .class-packages,
    .giftcard-packages,
    .renewable-packages {
      grid-template-columns: repeat(auto-fill,minmax(95%,1fr));
    }

    .quick-links {
      display: flex;
      justify-content: space-between;
      margin: 2em 0 -2rem;

      li a {
        font-size: 14px;
        padding: 0 1rem;
      }
      li:last-child {
        padding-right: 0;
        a {
          border-right: none;
        }
      }
    }

    .series-container {
      margin: 1rem;
    }

    .series-title-header .learn-more-link {
      position: relative;
      display: block;
      padding: 0.25rem;
      text-align: center;
      border: solid 2px #0d52E3;
      margin: 1.1rem 0 2rem;
    }

    .row {
      margin-bottom: 1rem;
    }

    .buy-classes-container .series-header-container {
      width: 100%;
      padding: 0
    }

    .buy-classes-container .series-header h3 {
      text-align: center;
    }

    .buy-classes-container .series-header {
      padding: 1rem;
    }
    .buy-classes-container {
      .yellow-button,
      .black-border-button {
        left: 0;
        font-family: "HelveticaNeueLTStd-Md","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        position: relative;
        margin: auto auto 2rem;
      }

      button.change-region-series {
        padding: 1rem 0;
        text-align: center;
      }
    }

    hr {
      display: none;
    }
  }
}

// 300px < css width (px) < 599px
@media only screen and (min-width : 300px) {
  .series_purchase {
    .region-cc {
      .region-select-row {
        background-color: @scWhiteC;
        height: 300px;
      }
    }
  }
}

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
  .series_purchase {
    .region-cc {
      background-color: @scWhiteC;
      width: 335px;

      .region-select-row {
        height: 300px;
      }
    }

    .buy-classes-container {
      .credits-header-container {
        min-height: 100px;
          &.credit-header-renew {
              padding-top: 10px;
          }
      }

      .section-row-container {
        overflow: hidden;
      }

      .row {
        box-sizing: border-box;
        overflow: hidden;

        &.warrior-wk.more-info.series-4 {
          padding: 58px 0 32px;
        }

        &.corp-booking-container {
          padding-bottom: 4rem;
          padding-top: 2.25rem;
        }

        hr,
        .credits-additional-info {
          border-top: none;
          display: none;
          margin: 0;
          padding: 0;
        }
      }

      .series-header-container {
        .series-header {
          margin: 0 auto;
          overflow: hidden;
        }
      }


      .series-tabs-header {
        padding-top: 48px;
      }

      .series-tabs {
        .active-tab {
          top:44px;
          width: 80px;

          &.cycle-active {
            margin-left: 95px;
          }
        }
      }

      .series-tab-header {
        font-size: 14px;
        height: 34px;
        letter-spacing: 3px;
        line-height: 14px;
        padding-top: 24px;
        width: 268px;
      }

      .header-new {
        background: @gray-darker;
        color: @scWhiteC;
        font-size: 12px;
        left: 205px;
        line-height: 12px;
        padding: 10px 8px 8px 9px;
        top: 14px;
      }

      .yellow-button.is-pride-month {
        margin-top: 18px;
      }

      .yellow-button,
      .black-border-button {
        font-size: 14px;
        margin: 20px 0 0;
        position: relative;
        right: 0;
        top: 0;
      }

      .yellow-button {
        height: 50px;
        line-height: 50px;
        width: 160px;
        border-radius: 0px;
      }

      .black-border-button {
        height: 48px;
        line-height: 48px;
        width: 158px;
      }

      .credits-info {
        opacity: 1;
        padding: 0 32px 0;
      }

      .new-tag {
        left: 32px;
        top: -24px;
      }

      .credits-special-head,
      .credits-amount,
      .credits-head,
      .series-activity-title {
        font-size: 20px;
        letter-spacing: -0.28px;
        line-height: 20px;
          &.credits-head-renew {
              font-size: 25px
          }
      }

      .credits-amount {
        line-height: 30px;
        max-width: 100%;
      }

      .credits-value {
        font-size: 18px;
        line-height: 22px;
        padding-top: 8px;
        &.credits-value-renew {
            .font-family(bold);
            padding-top: 30px;
        }
        &.credits-value-renew-unlimited {
            padding-bottom: 20px;
        }
      }

      .credits-expire {
        font-size: 14px;
        line-height: 14px;
        padding: 12px 0 0;
          &.credits-expire-renew {
              font-size: 10px;
              line-height: 10px;
              padding: 10px 0 10px;
          }
      }

      .credits-additional-info-desktop {
        background-color: @gray-main;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;

        .buy-series-close-button {
          right: 4px;
          top: 40px;
          z-index: 12;
        }

        .credits-special-head,
        .credits-head {
          margin-bottom: 5px;
        }

        .credits-info {
          padding-bottom: 34px;
          padding-top: 68px;
        }

        &.block {
          opacity: 1;
        }
      }

      .more-info-icon {
        display: block;
        z-index: 11;
      }

      .buy-cycle-link-container {
        padding: 40px 32px 56px;

        span {
          display: inline-block;
          font-size: 20px;
          letter-spacing: 3.33px;
          line-height: 20px;
        }
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .series_purchase {
    .region-cc {
      background-color: @scWhiteC;
      width: 444px;

      .region-select-row {
        height: 425px;
      }
    }

    .buy-classes-container {

      .series-header-container {
        padding: 4rem 2rem;
      }

      .buy-cycle-link-container {
        padding: 48px 22px 72px;
      }

      .credits-additional-info-desktop {
        .credits-special-head,
        .credits-head {
          padding-top: 10px;
        }
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width : 1024px) {
  .series_purchase {
    .region-cc {
      background-color: @scWhiteC;
      top: 133px;
      width: 358px;
    }

    .buy-classes-container {
      padding-bottom: @footerHeightDesktop;

      .series-header-container {
        .series-header {
          padding-bottom: 30px;
          padding-top: 15px;
        }
      }


      .change-region-series {
        display: inline-block;
        text-align: left;
      }

      .change-region-series-arrow {
        top: 29px;
      }

      .transfer-info {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width : 1440px) {
  .series_purchase {
    .region-cc {
      top: 134px;
      width: 678px;
    }

    .buy-classes-container {
      .row {
        &.warrior-wk.more-info.series-4 {
          padding-top: 70px;
        }
      }


      .change-region-series-arrow {
        right: 24px;
      }

      .series-type-header {
        padding: 48px 0 20px;
        font-size: 26px;
        letter-spacing: 0px;
        line-height: auto;
      }

      .credits-header-container {
        min-height: 112px;
          &.credit-header-renew {
              padding-top: 10px;
              min-height: 100px;
          }
      }

      .credits-special-head,
      .credits-amount,
      .credits-head,
      .series-activity-title {
        font-size: 24px;
        letter-spacing: -0.36px;
        line-height: 26px;
        width: 100%;
      }

      .credits-amount {
        line-height: 30px;
      }

      .credits-info {
        padding: 0 25px;
      }

      .new-tag {
        left: 30px;
      }

      .yellow-button.is-pride-month {
        margin-top: 18px;
      }

      .yellow-button,
      .black-border-button {
        margin: 20px 0 0;
      }

      .more-info-icon {
        right: 14px;
      }

      .canada-unpurchasable-message {
        bottom: 10px;
      }

      .credits-additional-info-desktop {
        width: 100%;

        .buy-series-close-button {
          right: 14px;
          top: 40px;
        }

        .credits-info {
          padding-bottom: 80px;
          padding-top: 80px;
        }
      }

      .buy-cycle-link-container {
        padding: 50px 32px 96px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1140px) {
  .series_purchase {
    .buy-classes-container {
      .series-header-container {
        width: auto;
      }
      .class-packages,
      .giftcard-packages,
      .renewable-packages {
        grid-template-columns: repeat(auto-fill,minmax(40%,1fr));
      }
    }
  }
  .series-header {
    .change-region-container {
      button {
        text-align: left;
      }
    }
  }
}

