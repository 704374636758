/* DEFAULTS - COMMONLY USED CLASSES
-----------------------------------------------------------------------------*/

/////////////////////////////////////
//////// GLOBAL RESET ///////////////
/////////////////////////////////////

body,
html {
  height: 100%;
}

html {
  overflow-y: scroll;
}

body {
  background-color: @gray-main;
  margin: 0;
  padding: 0;
  color: @gray-darker;
  font: 62.5%/1.5 "HelveticaNeueLTStd-Lt", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, li {
	padding: 0;
	margin: 0;
}

h1, h2, h3, h4, h5, h6 {
	position: relative;
	padding: 0;
	margin: 0;
	font-weight: normal;
	line-height: 1;
}

a:not(.default-link) {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
	text-decoration: none;
	color: @gray-darker;

	&:hover {
		color: #333;
	}
}

img {
	border: 0;
}

* { -webkit-tap-highlight-color:transparent; }

.clearfix {
	clear: both;

	&:after {
		clear: both;
		content: " ";
	}
}

.block {
	display: block;
}

.medium {
	.font-family(medium);
}
.font-thin {
  .font-family(thin);
}

.yellow-highlight {
  background-color: @sc-yellow;
}
.white-highlight {
  background-color: #fff;
}

.center-content {
  margin: 0 auto;
  text-align: center;
}

.sc-padding {
  padding: 40px 20px;
  .respond-to(@bpMobileLarge, {
    padding: 60px 40px;
  });
  .respond-to(@bpTablet, {
    padding: 80px 60px;
  });
  .respond-to(@bpDesktopSmall, {
    padding: 100px 100px;
  });
  .respond-to(@bpDesktopLarge, {
    padding: 100px 320px;
  });
}

.fake-modal {
  padding: 40px 20px;
  .respond-to(@bpMobileLarge, {
    padding: 60px 40px;
  });
  .respond-to(@bpTablet, {
    background-color: #fff;
    width: 650px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 60px 0;
  });
  .respond-to(@bpDesktopSmall, {
    width: 860px;
  });
}

/////////////////////////////////////
//////// REUSABLE CLASSES ///////////
/////////////////////////////////////

.full-height {
  height: 100%;
}

.row {
  border-top: 1px solid @gray-light;
  border-bottom: 1px solid @gray-light;
  width: 100%;
  padding: 20px 0;
}

.row-content {
  width: 90%
}

.sub-title {
  font-size: 2em;
  .font-family(bold);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix,
.clear {
  clear: both;
}

.inline_block {
  display: inline-block;
}

.block {
  display: block !important;
}

.hide {
  display: none !important;
}
.hidden {
  display: none;
}

.opaque {
  opacity: 1 !important;
  display: block !important;
}

.medium {
  .font-family(medium);
}

.bold {
  .font-family(bold);
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.static {
  position: static !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.unfixed{
  position:relative !important;
}

.space-29px {
  height: 29px;
}

.space-16px {
  height: 16px;
}

.mobile_only {
  display:block;
}

.desktop_only {
  display:none;
}


// white gradient, horizontal fade bottom to top
.gradient-white {
  position:absolute;
  z-index:2;
  right:0; bottom:0; left:0;
  height:150px;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(62%,rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%);
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%);
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%);
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

.horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid @gray-light;
  margin: 0;
  padding: 0;
  clear: both;
}

.series-circle {
  background-color: @sc-yellow;
  height: 19px;
  width: 27px;
  padding-top: 8px;
  .border-radius(50%);
  font-size: 12px;
  color: #000;
  .font-family(medium);
  text-align: center;
}

.grey-watermark {
  .font-family(heavy);
  color: @gray-main;
  font-size: 50px;
  text-align: center;
  .respond-to(@bpMobileLarge, {
    font-size: 96px;
    margin-left: -10px;
  });
  .respond-to(@bpTablet, {
    font-size: 120px;
  });
  .respond-to(@bpDesktopSmall, {
    font-size: 150px;
  });
}

.scalable-image {
  width: 100%;
  &.phil-graphic {
    display: block;
    margin: 0 auto;
    max-width: 900px;
  }
}

//////////////////////////
// PADDING AND MARGINS  //
//////////////////////////

.pt_2 {
  padding-top: 2px;
}
.pt_3 {
  padding-top: 3px;
}
.pt_5 {
  padding-top: 5px;
}
.pt_7 {
  padding-top: 7px;
}
.pt_10 {
  padding-top: 10px;
}
.pt_13 {
  padding-top: 13px;
}
.pt_15 {
  padding-top: 15px;
}
.pt_20 {
  padding-top: 20px;
}

.pl_5 {
  padding-left: 5px;
}
.pl_10 {
  padding-left: 10px;
}
.pl_20 {
  padding-left: 20px;
}
.pl_40 {
  padding-left: 40px;
}
.pl_50 {
  padding-left: 50px;
}
.pl_100 {
  padding-left: 100px;
}
.pl_110 {
  padding-left: 110px;
}
.pl_130 {
  padding-left: 130px;
}

.mup_5 {
  margin-top: -5px !important;
}
.mup_8 {
  margin-top: -8px !important;
}
.mup_10 {
  margin-top: -10px !important;
}
.mup_15 {
  margin-top: -15px !important;
}
.mup_20 {
  margin-top: -20px !important;
}
.mup_25 {
  margin-top: -25px !important;
}

//////////////////
// FONT SIZE    //
//////////////////

.font_xxsmall {
  font-size: .7em;
}
.font_xsmall {
  font-size: 1em;
}
.font_small {
  font-size: 1.5em;
}
.font_medium {
  font-size: 2em;
}
.font_large {
  font-size: 2.5em;
}
.font_xlarge {
  font-size: 3em;
}
.font_xxlarge {
  font-size: 3.5em;
}
.font_xxxlarge {
  font-size: 4em;
}

//////////////////
// FONT COLOR   //
//////////////////

.font_gray_darker {
  color: darken(@gray, 70%);
}
.font_gray_dark {
  color: darken(@gray, 40%);
}
.font-gray-main {
  color: @gray-main;
}
.font-gray-light {
  color: @gray-light;
}
.font-gray-med {
  color: @gray-medium;
}

////////////////////////
// BACKGROUND COLOR   //
////////////////////////

.background-gray-main {
  background: @gray-main;
}
.background-gray-light {
  background: @gray-light !important;
}

/* MEDIA QUERIES - DEFAULTS
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width : 1024px) {
  .header-position {
    position: fixed !important;
  }
  body {
    background-color: #fff;
  }
}
