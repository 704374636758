.london-studios__banner {
  .flex-display(flex);
  color: @white;
  flex-direction: column;
  justify-content: space-between;

  &-background {
    background: @black;
  }

  .banner__content {
    margin: 1.4rem 8% 0;
    transition: height 1s ease;

    .respond-to(@bpTablet, {margin: 2.5rem 4.5% 0;});

    .respond-to(@bpDesktopMedium, {margin: 102px 120px 0px;});
  }

  .banner__title {
    .font-family(bold);

    h1 {
      font-size: 26px;
      line-height: 33px;

      .respond-to(@bpTablet, {
        font-size: 47px;
        line-height: 52px;
      });

      .respond-to(
              @bpDesktopMedium,
      {
        font-size: 64px;
        height: 214px;
        letter-spacing: -1px;
        line-height: 70px;
        text-align: left;
        width: 888px;
      }

      );
    }
    .yellow {
      color: @sc-yellow;
    }
  }

  .banner__date {
    .font-family(heavy);
    color: @schoolBusYellow;
    font-size: 1rem;
    letter-spacing: 1.57px;
    padding: 0.75rem 0;

    .respond-to(@bpTablet,{
      padding: 1.25rem 0;
    });
  }
}
