.cookie-banner  {
  .box-sizing(border-box);
  background-color: @scWhiteC;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  opacity: 1;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 100;

  .respond-to(@bpDesktopSmall, {
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  });


  &__buttons-container {
    align-items: center;
    display: flex;
    margin-left: 1rem;

    .respond-to(@bpDesktopSmall, {
      flex: 1 1 auto;
      justify-content: flex-end;
    });
  }

  button {
    .font-family(heavy);
    background: @black;
    border: none;
    color: @white;
    cursor: pointer;
    height: 56px;
    font-size: 1rem;
    letter-spacing: 0.8px;
    line-height: 1.25rem;
    margin: 0 1rem 0 auto;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
    width: 96px;

    .respond-to(@bpDesktopSmall, {margin: 1rem;});
  }

  &__learn-more {
    color: @black;
    font-size: 1rem;
    cursor: pointer;
    line-height: 1.5rem;
    margin-right: 1rem;

    span, svg {
      display: inline-block;
    }

    svg {
      margin-right: 1rem;
      vertical-align: middle;
    }
  }

  &__message {
    color: @black;
    font-size: 0.75rem;
    line-height: 1rem;

    a {
      color: @blueRibbonBlue;
      &:hover {
        color: @blueRibbonBlue;
        text-decoration: underline;
      }
    }

    .respond-to(@bpDesktopSmall, {
        flex: 1 1 68%;
        margin: 0 0 0 2.5rem;
    });
  }
}
