/* SELECT BIKE STYLES
-----------------------------------------------------------------------------*/

.select-bike-container {
  position: relative;
  background: #fff;

  .select-bike-grid-container {
    position: relative;
    .center-container();
    padding-top: 10px;
    .class-type {
      margin-top: 30px;
      text-align: center;
      font-size: 1.7em;
      color: @gray-medium;
    }
    .name {
      .font-family(bold);
      color: @gray-dark;
    }

    .title {
      position: relative;
      .font-family(bold);
      font-size: 1.8em;
      margin-top: 10px;
      text-align: center;
      margin-bottom: 15px;

      a {
        &:hover {
          color: @gray-59;
        }
      }
    }

    .location {
      position: relative;
      .font-family(light);
      font-size: 2.1em;
      margin-top: 10px;
      color: @gray-default;
      text-align: center;
    }
    .seats {
      position: relative;
      .center-container();
      margin-top: 25px;
      width: 95%;
      padding-bottom: 100%;

      &.cols-8 {
        margin-bottom: 20px;
        padding-bottom: 95%;
        width: 85%;

        .instructor {
          width: 30%;
        }

        .seat {
          font-size: 10px;
          padding: 2.5%;
        }

        .seat_number {
          margin-top: 10%;
          width: 10px;
        }
      }
      &.cols-10 {
        .seat {
          padding: 2.1% 2% 1.9%;
          font-size: 10px;
        }
        .seat_number {
          width: 10px;
        }
      }
      &.cols-11, &.cols-12 {
        .seat {
          padding: 2% 1.9% 1.8%;
          font-size: 10px;
        }
        .seat_number {
          width: 10px;
        }
      }
      &.cols-13 {
        .seat {
          padding: 1.8% 1.7% 1.6%;
          font-size: 10px;
        }
        .seat_number {
          width: 10px;
        }
      }
      &.cols-14 {
        .seat {
          padding: 1.8% 1.6% 1.4%;
          font-size: 9px;
        }
        .seat_number {
          width: 9px;
        }
      }
      &.cols-15 {
        .seat {
          padding: 1.6% 1.5% 1.4%;
          font-size: 8px;
        }
        .seat_number {
          width: 8px;
        }
      }
      &.cols-16 {
        .seat {
          padding: 1.5% 1.4% 1.3%;
          font-size: 7px;
        }
        .seat_number {
          width: 7px;
        }
      }
      &.cols-17 {
        .seat {
          padding: 1.4% 1.3% 1.2%;
        }
      }
    }

    .seat {
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      z-index: 10;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      -khtml-border-radius: 50%;
      padding: 1.3% 1.2% 1.1%;
      text-align: center;
      color: @gray-darker;
      font-size: 6px;
      opacity: 0;
      cursor: pointer;
      .font-family(medium);

      &.taken {
        color: @gray-medium;
        border: 1px solid @gray-light;
        background-color: @gray-light;
        cursor: default;
        display: block;
      }

      &.open {
        border: 1px solid @gray-darker;

      }

      &.booked {
        background-color: @sc-yellow;
        border: 1px solid @sc-yellow;
      }

      &.bilt {
        background: url('/assets/images/bilt.svg') no-repeat center center;
        background-size: 10px 10px;
        text-indent: -9999px;
        cursor: default;
        width: 10px;
        height: 10px;
      }

      &.fan {
        background: url('/assets/images/booking-fan.png') no-repeat center center;
        background-size: 10px 10px;
        text-indent: -9999px;
        cursor: default;
        width: 10px;
        height: 10px;
      }

      &.door {
        background: url('/assets/images/booking-door.png') no-repeat center center;
        background-size: 31px 31px;
        text-indent: -9999px;
        cursor: default;
      }
      .seat_number {
        display: block;
        width: 6px;
        margin-top: 2%;
      }
      &.offset{
        padding-left: 4% !important;
      }
    }

    .instructor {
      position: absolute;
      z-index: 1;
      left: 50%;
      margin-top: -10px;
      margin-left: -7%;
      width: 20%;
      img {
        display: block;
        position: relative;
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
      }
    }
  }

}

/* MEDIA QUERIES - SELECT A BIKE STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .select-bike-container {
    .select-bike-grid-container {
      padding-top: 30px;
      .location {
        margin-top: 18px;
        margin-bottom: 10px;
        font-size: 3em;
      }
      .seats {
        margin-top: 40px;

        &.cols-8 {
          padding-bottom: 75%;

          .instructor {
            width: 25%;
          }
        }

        &.cols-10, &.cols-11, &.cols-12 {
          width: 80%;
          padding-bottom: 80%;
          .seat {
            padding: 2.4%;
          }
        }
        &.cols-13, &.cols-14 {
          .seat {
            padding: 1.8%;
            font-size: 11px;
          }
          .seat_number {
            width: 11px;
          }
        }
        &.cols-15, &.cols-16 {
          .seat {
            padding: 1.6%;
            font-size: 11px;
          }
          .seat_number {
            width: 11px;
          }
        }
        &.cols-17 {
          .seat {
            padding: 1.5%;
          }
        }
      }

      .seat {
        font-size: 10px;
        padding: 1.5%;
        .seat_number {
          width: 10px;
          margin-top: 14%;
        }
        &.fan {
          background-size: 15px 15px;
          width: 15px;
          height: 15px;
        }
      }
      .instructor {
        width: 15%;
      }
    }
  }

}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .select-bike-container {
    .select-bike-grid-container {
      .seats {

        &.cols-8 {
          padding-bottom: 430px;
          width: 430px;
        }

        &.cols-10 {
          width: 550px;
          padding-bottom: 550px;
          .seat {
            padding: 13px;
            font-size: 12px;
          }
          .seat_number {
            width: 12px;
          }
        }
        &.cols-11, &.cols-12 {
          width: 600px;
          padding-bottom: 600px;
          .seat {
            padding: 13px;
            font-size: 12px;
          }
          .seat_number {
            width: 12px;
          }
        }
        &.cols-13, &.cols-14 {
          .seat {
            padding: 13px;
            font-size: 12px;
          }
          .seat_number {
            width: 12px;
          }
        }
        &.cols-15, &.cols-16 {
          .seat {
            padding: 13px;
            font-size: 12px;
          }
          .seat_number {
            width: 12px;
          }
        }
        &.cols-17 {
          .seat {
            padding: 13px;
          }
        }
      }
      .seat {
        font-size: 12px;
        padding: 13px;
        .seat_number {
          width: 12px;
        }
        &.fan {
          background-size: 20px 20px;
          width: 20px;
          height: 20px;
        }
      }
      .instructor {
        width: auto;
      }
    }
  }

}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .select-bike-container {
    .select-bike-grid-container {
      .seats {
        width: 940px;
        padding-bottom: 940px;

        &.cols-8 {

          .seat {
            font-size: 12px;
            padding: 13px;
          }

          .seat_number {
            width: 12px;
          }
        }

        &.cols-13, &.cols-14 {
          width: 800px;
          padding-bottom: 800px;
          .seat {
            padding: 13px;
            font-size: 12px;
          }
          .seat_number {
            width: 12px;
          }
        }
        &.cols-15, &.cols-16 {
          width: 800px;
          padding-bottom: 800px;
        }
      }
    }
  }

}

@media only screen and (min-width: 1440px) {
  .select-bike-container {
    .select-bike-grid-container {
      .seats {
        .seat {
          &.open:hover {
            background-color: @sc-yellow;
          }
        }
      }
    }
  }
}
