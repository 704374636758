/* CONFIRMATION STYLES - MQ < 600px
-----------------------------------------------------------------------------*/
.soul-container {

  &.auth-open {
    .confirmation {
      top: 0;
      z-index: 99;
    }
  }

  .confirmation {
    background-color: @scYellowC;
    font-size: 14px;
    overflow: hidden;
    position: fixed;
    top: @headerHeight;
    transition: visibility 0s ease-in-out 0.5s, height 0.5s ease-in-out; /* reversed 'show' class transition (via delayed 'visibility' animation) */
    visibility: hidden;
    width: 100%;
    z-index: 99;

    .confirmation-content-container {
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; /* fix for overflow hidden on parent that has position: fixed */
      text-align: center;
      position: relative;
      padding: 20px 0;

      .confirmation-type-indicator {
        background: url('/assets/images/alert_icon.png') center center no-repeat transparent;
        width: 20px;
        height: 20px;
        margin: 10px auto 15px;
        display: none;
      }

      .confirmation-close-btn {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 0;
        outline: inherit;
        padding: 0;
        position: absolute;
        right: 6px;
        top: 8px;

        img {
          .transform(scale(0.55));
          height: 31px;
          object-fit: none;
          object-position: -10px -169px;

          &::before {
            .font-family(heavy);
            background-color: @scYellowC;
            color: @scWhiteC;
            content: attr(alt);
            display: inline-block;
            font-size: 25px;
            font-weight: 600;
            height: 20px;
            position: absolute;
            right: 0;
            top: 0;
            width: 70px;
          }
        }

        /* Flash Sale Banner Styles */
        &.flash-sale-btn-position {
          right: 5%;
        }

        .flash-sale-close-btn {
          height: 25px;
          margin-top: 9px;
          object-fit: unset;
          object-position: unset;
        }
        /* End of Flash Sale Banner Styles */
      }

      .confirmation-message {
        margin: 0 auto;
        display: block;
        position: relative;
        width: 75%;

        .message {
          font-size: 14px;
          line-height: 20px;
          color: @gray-darker;
          display: inline-block;
          .font-family(medium);
          text-transform: lowercase;

          .confirm-button {
            display: block;
            float: left;
            margin-top: 1em;
            margin-right: 1em;
            width: calc(~"50% - 0.5em");

            &:last-child {
              margin-right: 0;
            }
          }

          /* Flash Sale Banner Styles */
          .flash-sale-message {
            text-transform: none;
          }

          .flash-sale-big-sale {
            font-weight: bold;
          }
          /* End of Flash Sale Banner Styles */
        }
      }

      .confirmation-call-to-action {
        display: none;

        .buttons {
          display: none;
          padding: 10px 0 0;
          position: relative;
          margin: 0 5px;

          a {
            text-transform: lowercase;
            color: @scWhiteC;
            font-size: 12px;
            .font-family(bold);
            display: block;
            position: relative;
          }

          &.cancel-button {
            a {
              background-color: white;
              color: black;
            }

          }

          &.show {
            display: inline-block !important;
          }
        }

        &.show {
          display: block;
        }
      }
    }
    &.show, & * .show {
      transition: visibility 0s ease-in-out, height 0.5s ease-in-out;
    }
  }
}

/* MEDIA QUERIES - CONFIRMATION BOX STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .soul-container {
    .confirmation {
      .confirmation-content-container {
        padding: 20px 5%;
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .soul-container {
    &.auth-open {
      .confirmation {
        top: 330px;
      }
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .auth-open {
    .confirmation {
      &.confirmation-sticky {
        position: fixed;
        top: 0 !important;
      }
    }
  }

  .soul-container {
    .confirmation {
      .confirmation-content-container {
        .confirmation-close-btn {
          display: block;
        }
      }
    }
  }
}
