.soulcup_invite {
  .main-container {
    margin-bottom: 0px;
    top: 0px;
  }

  .global-footer {
    display: none;
  }
}

.soulcup-invite {
  .respond-to(@bpTablet, {
    align-items: center;
    background-color: @abbeyGray;
    display: flex;
    height: 100vh;
    justify-content: center;
  });

  &-hero {
    background-color: @black;
    max-width: 535px;
    position: relative;
    width: 100%;
    .respond-to(@bpTablet, {
      border-radius: 4px 4px 0 0;
    });

    &-background {
      background: bottom right / cover no-repeat url('/assets/images/soulcup/2023_turnitup_mobile.jpg');
      bottom: 120px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      .respond-to(@bpTablet, {
        border-radius: 4px 4px 0 0;
      });
    }

    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px 15px 0;

      &-x {
        z-index: 1;

        svg {
          height: 16px;
          width: 16px;

          g {
            fill: @white;
          }
        }
      }

      &-logo {
        height: 35px;
        width: auto;
        z-index: 1;
      }

      &-tooltip {
        z-index: 1;

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    &-container {
      align-items: center;
      color: @scWhiteC;
      display: flex;
      flex-direction: column;
      margin: auto;
      position: relative;

      &-description {
        .font-family(bold);
        color: @white;
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 0;
        max-width: 290px;
        padding: 16px 52px 62px;
        text-align: center;
      }

      &-team-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 260px;
        width: 100%;

        &-badge {
          align-items: center;
          background: center center / 100% auto no-repeat @transparent-color;
          display: flex;
          height: 136px;
          justify-content: center;
          width: 136px;

          &-initials {
            .font-family(bold);
            color: @goldenYellow;
            font-size: 46px; // this text is decorative and not meant to scale with relative units
            height: 34px;
            text-transform: uppercase;
          }
        }

        &-name {
          .font-family(heavy);
          font-size: 1.5rem;
          letter-spacing: -0.24px;
          margin: 0;
          padding: 24px 15px 12px;
          text-align: center;
        }

        &-members {
          .font-family(bold);
          font-size: 0.75rem;
          letter-spacing: 1.8px;
          line-height: 0.625rem;
          margin: 0;
          padding: 0 0 68px;
          text-transform: uppercase;
        }
      }

      &-cta-container {
        align-items: center;
        background-color: @white;
        border-radius: 4px 4px 0 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 31px 15px;
        width: 100%;

        &-heading {
          .font-family(bold);
          color: @black;
          font-size: 1.125rem;
          line-height: 1.5rem;
          margin: 0;
          text-align: center;
        }

        &-description {
          .font-family(roman);
          color: @abbeyGray;
          font-size: 1rem;
          line-height: 1.25rem;
          margin: 0;
          padding-top: 4px;
          text-align: center;

          &.small-text {
            .font-family(condensed);
            font-size: 0.75rem;
            line-height: 0.875rem;
            text-align: left;
          }
        }
      }
    }

    #soulcup-invite-hero-btn {
      .font-family(bold);
      align-items: center;
      background-color: @goldenYellow;
      border-radius: 4px;
      color: @black;
      display: flex;
      font-size: 0.875rem;
      height: 3.5rem;
      justify-content: center;
      line-height: 1.125rem;
      margin-top: 26px;
      max-width: 345px;
      width: 100%;

      &.soulcup-invite-hero-btn-variation {
        background-color: @white;
        border: @borderMediumGray;
      }
    }
  }
}
