.profile__nav-container {
  .box-sizing(border-box);
  background-color: @scWhiteC;
  font-size: 1.8em;
  left: 0;
  position: fixed;
  top: 3.3125em;
  width: 100%;
  z-index: 1000;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.5em;
    left: auto;
    margin: 0 auto 3em;
    min-width: 54rem;
    position: relative;
    top: auto;
    width: 60%;
    z-index: 1;
  });
}

.profile__nav__list {
  .box-shadow(0 0.5em 0.375em -0.375em @gray-12);
  display: flex;
  flex-direction: column;
  list-style: none;

  .respond-to(@bpDesktopSmall, {
    .box-shadow(none);
    border-top: 0.275em solid @black;
    flex-direction: row;
    justify-content: space-around;
  });
}

/**
  This class is used to target the menu items when the menu is expanded in mobile
**/
.profile__nav__list--expanded {
  & .profile__nav__item {
    display: block;
  }

  & .profile__nav__item--mobile {
    .respond-to(@bpDesktopSmall, {
      display: none;
    });
  }

  & .profile__nav__item--active {
    border-color: @black;

    &:after {
      .transform(rotate(-45deg));
      top: 0.1em;
    }

    .respond-to(@bpDesktopSmall, {
      border-color: transparent;
    });
  }
}

.profile__nav__item {
  border-left: 0.4em solid transparent;
  color: @gray-38;
  display: none;
  flex-grow: 1;
  padding: 1.5em;
  position: relative;
  text-align: left;

  .respond-to(@bpDesktopSmall, {
    display: block;
    margin-left: 0;
    text-align: center;
  });

  &:hover {
    border-left: 0.4em solid @gray-darker;

    .respond-to(@bpDesktopSmall, {
      border-color: transparent;
    });
  }
}

// Ruleset used for hiding links in desktop
.profile__nav__item--mobile {
  .respond-to(@bpDesktopSmall, {
    display: none;
  });
}

/**
  Active Menu Item
  Mobile: Displays a left yellow border in collapsed state and black when the menu is expanded.
          Additionally displays a down arrow  after the text in collapsed state and up arrow on expanded.
  Desktop(1024+) : Displays a filled circle above the link text
**/
.profile__nav__item--active {
  border-left: 0.4em solid @gray-darker;
  color: @gray-darker;
  cursor: pointer;
  display: block;
  margin-left: 0;

  // on mobile setup an arrow pointing down
  &:after {
    .transform(rotate(135deg));
    border-bottom: 0;
    border-left: 0;
    border-right: 0.1em solid @gray-darker;
    border-top: 0.1em solid @gray-darker;
    content: '';
    display: inline-block;
    height: 0.4em;
    margin-left: 0.5em;
    position: relative;
    top: -0.2em;
    width: 0.4em;
  }

  .respond-to(@bpDesktopSmall, {
    border-color: transparent;
    cursor: default;

    /**
      on desktop setup a circle over the link text
      the transform and top properties use important to override the mobile styles on expanded.
      this allows us to use minimal js to create overrides
    **/
    &:after {
      .border-radius(0.5em);
      .transform(translateX(-50%) rotate(0deg)) !important;
      background-color: @black;
      content: '';
      display: block;
      height: 0.825em;
      left: 50%;
      margin-left: 0;
      position: absolute;
      top: -0.5125em !important;
      width: 0.825em;
    }
  });

  & .profile__nav__link {
    .respond-to(@bpDesktopSmall, {
      cursor: default;
    });
  }
}

.profile__nav__link {
  .font-family(medium);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

// required to use the a tag to target this because it is being overriden elsewhere.
a.profile__nav__link {
  color: @gray-38;
}

button.profile__nav__link {
  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
}
