.go-shop-purchases {
  align-items: center;
  background-color: @white;
  border: 4px solid @black;
  bottom: 10px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
  display: none;
  justify-content: space-between;
  padding: 5px 10px;
  position: fixed;
  right: 10px;
  width: 85%;
  .respond-to(@bpMobileSmall, {
    display: flex;
  });
  .respond-to(@bpTablet, {
    display: none;
  });
}

.go-shop-purchases-small {
  align-items: center;
  background-color: @white;
  border: 4px solid @black;
  bottom: 10px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
  display: none;
  justify-content: space-between;
  padding: 5px 10px;
  position: fixed;
  right: 10px;
  width: 26px;
  .respond-to(@bpMobileSmall, {
    display: flex;
  });
  .respond-to(@bpTablet, {
    display: none;
  });
}

#go-shop-purchases-container {
  transition: width 0.5s;
}

.go-shop-purchases-text-button {
  .font-family(light);
  align-items: center;
  color: @black;
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  justify-content: space-between;
  letter-spacing: 0.5px;
  line-height: 1.5;
  text-align: center;
}

.go-shop-purchases-text-button-desktop {
  .font-family(light);
  color: @black;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;

  .respond-to(@bpTablet, {
    font-size: 0.875rem;
    line-height: 1.5;
  });
}

.profile-tabs-container {
  .respond-to(@bpTablet, {
    align-items: center;
    display: flex;
  });
}

.padding-30pc {
  .respond-to(@bpTablet, {
    padding-left: 30%;
  });
}

.go-shop-purchases-text-button-container {
  .respond-to(@bpMobileSmall, {
    display: none;
  });

  .respond-to(@bpTablet, {
    display: block !important;
    padding-right: 20px;
  });
  .respond-to(@bpDesktopSmall, {
    display: block !important;
    padding-right: 20%;
  });

}


.go-shop-purchases-text-button:hover {
  color: #0145d4 !important;
}

.go-shop-purchases-arrow-right-circle {
  .respond-to(@bpMobileSmall, {
    padding-bottom: 2px;
    padding-left: 5px;
    width: 18px;
  });
  .respond-to(@bpDesktopSmall, {
    padding-bottom: 5px;
    padding-left: 5px;
    width: 14px;
  });

}

.profile_history {
  .main-container {
    z-index: 99;
  }
}

.profile_class_history {
  .main-container {
    z-index: 99;
  }
}

.profile_purchase_history {
  .main-container {
    z-index: 99;
  }
}
