/*doc
---
title: Lists
name: lists
category: lists
---

This page contains SoulCycle List styles
*/

/*doc
---
title: Item Lists
name: item_lists
parent: lists
category: lists
---

This is a SoulCycle Item List style for products, but could apply to any list that contains an image thumbnail and description.

```html_example
<div class="sc-item-list">
	<header class="sc-item-list-header">
        <span class="list-col list-title list-col-main-info">Your Bag</span>
	</header>

	<div class="list-item">
    	<div class="item-thumb">
			<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
         </div>

        <div class="item-description main-description list-col">
        	<span class="item-name">Summer Stripe SOUL Cage Bra</span>
            <span class="item-spec">Color: BLACK/WHITE</span>
           	<span class="item-spec">Size: M</span>
        	<span class="item-single-price"><span class="label">Price: </span>$52.00</span>
        	<span class="item-quantity-price">$520.00</span>
        </div>

        <span class="item-remove">x</span>
    </div>

	<div class="list-item">
    	<div class="item-thumb">
			<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
         </div>

        <div class="item-description main-description list-col">
        	<span class="item-name">Summer Stripe SOUL Cage Bra</span>
            <span class="item-spec">Color: BLACK/WHITE</span>
           	<span class="item-spec">Size: M</span>
        	<span class="item-single-price"><span class="label">Price: </span>$52.00</span>
        	<span class="item-quantity-price">$520.00</span>
        </div>

        <span class="item-remove">x</span>
	</div>
</div>
```
*/

.sc-item-list {
	font-size: 1.125rem;

	.list-col {
		.respond-to(@bpTablet, {
			float: left;
		});

		&.item-description {
			.item-single-price,
			.item-spec {
				.respond-to(@bpDesktopSmall, {
					font-size: 16px;
				});

				.respond-to(@bpDesktopLarge, {
					font-size: 18px;
				});
			}
		}
	}

	.sc-item-list-header {
		display: inline-block;
		border-bottom: 1px solid @gray-light;
		padding-bottom: 0.5em;
		width: 100%;

		.list-col {
			display: none;

			.respond-to(@bpTablet, {
				display: inline-block;
				color: @gray-medium;
			});

			&.list-title {
				.box-sizing(border-box);
				color: @gray-darker;
				display: inline-block;
				margin: 0 @gutterMobile;

				.respond-to(@bpTablet, {
					margin-right: 5%;
					margin: 0;
					width: 43%;
				});

				.respond-to(@bpDesktopSmall, {
					margin: 0 @gutterMobile;
					width: calc(~"100% - 376px");
				});

				.respond-to(@bpDesktopLarge, {
					width: calc(~"100% - 590px");
				});
			}
		}
	}

	.list-item {
		.box-sizing(border-box);
		border-bottom: 1px solid @gray-light;
		.clearfix();
		display: inline-block;
		position: relative;
		padding: @gutterMobile 2.66em @gutterMobile 0;
		width: 100%;

		.respond-to(@bpTablet, {
			padding-right: 0;
		});

        .original-price {
            text-decoration: underline;
            text-underline-offset: -6px;
            text-decoration-skip-ink: none;
            text-decoration-thickness: 2px;
            opacity: 0.75;
            .respond-to(@bpDesktopSmall, {
                text-underline-offset: -8px;
            });
        }
	}

	.item-thumb {
		float: left;
		width: 4.5rem;
		margin-left: @gutterMobile;
		margin-right: 1.25rem;
		overflow: hidden;

		img {
			max-width: 100%;
		}

		.respond-to(@bpMobileLarge, {
			margin-right: 1.625rem;
		});

		.respond-to(@bpTablet, {
			margin-left: 0;
		});
	}

	.item-description {
		margin-left: 5.75rem + @gutterMobile;

		.respond-to(@bpTablet, {
			margin-left: 0;
			vertical-align: top;
		});

		&.main-description {
			float: none;

			.respond-to(@bpTablet, {
				margin-left: 4.75rem + @gutterMobile;
			});
		}

		[class*=item-] {
			display: block;
			font-size: 0.77em;
			line-height: 1.5;

			/* Reset styles for nested item elements */
			[class*=item-] {
				display: inline-block;
			}
		}

		.item-name {
			color: @gray-darker;
			.font-family(heavy);
			font-size: 1em;
		}

		.item-quantity {
			margin-top: 1.11em;
			position: relative;

			.respond-to(@bpTablet, {
				margin-top: -0.4em;
			});

			&.value-static {
				.respond-to(@bpTablet, {
					margin-top: 0;
					padding-left: 1em;
				});

				&:after {
					.respond-to(@bpDesktopSmall, {
						top: 0.4em;
					});
				}
			}

			&:after {
				.respond-to(@bpDesktopSmall, {
					content: "X";
					color: @gray-darker;
					display: block;
					font-size: 14px;
					position: absolute;
					top: 0.8em;
					left: -50%;
				});
			}

			.select-item-quantity {
				margin-top: 0;
				padding-left: 1.3em;
			}

			label {
				margin-right: 0.72em;

				.respond-to(@bpDesktopSmall, {
					display: none;
				});
			}
			.quantity-value-static {
				line-height: 1em;
				padding: 1.2em;
			}
		}

		.item-quantity-price {
			&.cross-text {
				text-decoration: line-through;
			}

			&.promo {
				.font-family(medium);
				color: @scGreen;
			}

			.font-family(medium);
			color: @gray-darker;
			font-size: 1em;
			margin-top: 0.83em;

			.respond-to(@bpTablet, {
				margin-top: 0;
			});

			.respond-to(@bpDesktopSmall, {
				font-size: 1.17em;
			});

			.respond-to(@bpDesktopLarge, {
				padding-left: 60px;
			});
		}
	}

	.item-remove {
		color: @gray-medium-dark;
		cursor: pointer;
		font-size: 1rem;
		position: absolute;
		top: 2rem;
		right: 1.75rem;
	}

/*doc
---
title: Item Lists - Order Summary
name: item_list_order_summary
parent: item_lists
category: lists
---

Style modifier for Order Summary version of item list

```html_example
<div class="order-summary sc-item-list">
	<header class="sc-item-list-header">
        <span class="list-col list-title list-col-main-info">Your Bag</span>
	</header>

	<div class="list-item">
    	<div class="item-thumb">
			<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
         </div>

        <div class="item-description main-description list-col">
        	<span class="item-name">Summer Stripe SOUL Cage Bra</span>
        	<span class="item-quantity-price">$520.00</span>
        </div>
    </div>

	<div class="list-item">
    	<div class="item-thumb">
			<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
         </div>

        <div class="item-description main-description list-col">
        	<span class="item-name">Summer Stripe SOUL Cage Bra</span>
        	<span class="item-quantity-price">$520.00</span>
        </div>
	</div>
</div>
```
*/

	&.order-summary {
		background: @gray-main;
		padding: 0;

		.list-item {
			border-bottom: 0;
			margin-bottom: 1.5em;
			padding: 2em 2em 0 2em;
		}

		.item-thumb {
			margin-left: 0;
		}

		.item-description {
			margin-left: 6.125rem;
		}

		.item-quantity-price {
			margin-top: 0;
		}
	}

/*doc
---
title: Item Lists - Table Modifier
parent: item_lists
category: lists
---

To create a table view in large viewports, add a `sc-item-table` class modifier.

The `.list-col` class is the equivilant of a table cell.

```html_example
<div class="bag-item-row-container sc-item-list sc-item-table">
	<header class="sc-item-list-header">
        <span class="list-col list-title list-col-main-info">Your Bag</span>
        <span class="list-col">Price</span>
        <span class="list-col">Quantity</span>
        <span class="list-col">Subtotal</span>
	</header>

	<div class="list-item">
        <div class="list-col-thumb-and-info">
        	<div class="item-thumb">
				<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
             </div>

            <div class="item-description main-description list-col">
            	<span class="item-name">Summer Stripe SOUL Cage Bra</span>
                <span class="item-spec">Color: BLACK/WHITE</span>
               	<span class="item-spec">Size: M</span>
            </div>
    	</div>

        <div class="item-description list-col">
        	<span class="item-single-price"><span class="label">Price: </span>$52.00</span>
        </div>

        <div class="item-description list-col">
            <label>Quantity</label>
            <span>10</span>
        </div>

        <div class="item-description list-col">
        	<span class="item-quantity-price">$520.00</span>
        </div>

        <span class="item-remove">x</span>
    </div>

	<div class="list-item">
        <div class="list-col-thumb-and-info">
        	<div class="item-thumb">
				<img src="https://www.soul-cycle.com/assets/images/cat.jpg" alt="">
             </div>

            <div class="item-description main-description list-col">
            	<span class="item-name">Soul Tank</span>
                <span class="item-spec">Color: Black</span>
               	<span class="item-spec">Size: S</span>
            </div>
    	</div>

        <div class="item-description list-col">
        	<span class="item-single-price"><span class="label">Price: </span>$10.00</span>
        </div>

        <div class="item-description list-col">
            <label>Quantity</label>
            <span>1</span>
        </div>

        <div class="item-description list-col">
        	<span class="item-quantity-price">$10.00</span>
        </div>

        <span class="item-remove">x</span>
	</div>
</div>
```
*/
	&.sc-item-table {
		.list-col {
			.respond-to(@bpTablet, {
				margin-right: 5%;
				width: 12.5%;
			});

			.respond-to(@bpDesktopSmall, {
				font-size: 18px;
				margin-right: 38px;
				width: 80px;
			});

			.respond-to(@bpDesktopLarge, {
				margin-right: 22px;
				width: 130px;

				&.subtotal {
					padding-left: 60px;
				}
			});

			label,
			.label {
				.respond-to(@bpTablet, {
					display: none;
				});
			}
		}

		.sc-item-list-header {
			.list-title {
				.respond-to(@bpMobileLarge, {
					margin: 0;
				});
				.respond-to(@bpTablet, {
					margin-right: 5%;
					margin: 0;
					width: 43%;
				});

				.respond-to(@bpDesktopSmall, {
					margin: 0 @gutterMobile;
					width: calc(~"100% - 436px");
				});

				.respond-to(@bpDesktopLarge, {
					width: calc(~"100% - 590px");
				});
			}
		}

		.list-col-thumb-and-info {
			.respond-to(@bpTablet, {
				.clearfix();
				float: left;
				margin-right: 5%;
				margin: 0;
				width: 43%;
			});

			.respond-to(@bpDesktopSmall, {
				margin: 0 @gutterMobile;
				width: calc(~"100% - 436px");

				.list-col {
					width: calc(~"42% - 4.5rem");
				}
			});

			.respond-to(@bpDesktopLarge, {
				width: calc(~"100% - 590px");
			});
		}

		.item-description {
			&.main-description {
				.promo {
					.font-family(medium);
					color: @scGreen;
				}
				.respond-to(@bpTablet, {
					width: auto;
				});
			}
		}
	}

	&.order-summary {
		.item-name {
			.font-family(light);
			word-wrap: break-word;


		}

		.item-name,
		.item-quantity-price {
			.respond-to(@bpMobileLarge, {
				font-size: 18px;
			});
		}
	}
}

/*doc
---
title: Summary Lists
name: summary_list
parent: lists
category: lists
---

List style for summary areas. Examples include checkout total summary or shopping bag summary.

```html_example
<div class="sc-list-summary">
	<div class="summary-container">
		<div class="totals">
	        <div class="summary-row" data-number-detail="tax">
	            <span class="label">Tax</span>
	            <span class="value">$0.00</span>
	        </div>

	        <div class="summary-row">
	            <span class="label">Shipping</span>
	            <span class="value">$10.00</span>
	        </div>

	        <div class="gift-card-list">
	            <ul class="checkout-aside-list">
	                <li class="summary-row gift-card-row">
	                    <span class="label">Gift Card #SOUL-5BD3-7B51</span>

	                    <span class="value"><span class="negative">-</span>$250.00</span>

	                    <a href="#" class="aside-list-remove delete-gift-card item-remove" id="gift-card-SOUL-5BD3-7B51" data-gift-code="SOUL-5BD3-7B51">X</a>
	                </li>
	                <li class="summary-row gift-card-row">
	                    <span class="label">Gift Card #SOUL-5F0D-23D2</span>

	                    <span class="value"><span class="negative">-</span>$165.00</span>

	                    <a href="#" class="aside-list-remove delete-gift-card item-remove" id="gift-card-SOUL-5F0D-23D2" data-gift-code="SOUL-5F0D-23D2">X</a>
	                </li>
	        	</ul>
	        </div>

	        <div class="summary-row estimated-total">
                <span class="label">Estimated Total</span>
                <span class="value">$0.00</span>
	        </div>
		</div>
	</div>
</div>
```
*/

.sc-list-summary {
	.box-sizing(border-box);
	border-bottom: @borderLightGray;
	font-size: 0.875rem;

	.respond-to(@bpDesktopLarge, {
		font-size: 1.125rem;
	});

	.summary-container {
		.box-sizing(border-box);
	}

	.summary-row {
		list-style: none;
		line-height: 1.4em;
		margin-bottom: 1.35em;
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: @gray-54;
			display: inline-block;
			font-size: 1em;
			padding: 0 1em 0 0;
			width: 48%;

			&.promo {
				.font-family(medium);
				color: @scGreen;
				> span {
					white-space: nowrap;
				}
			}

			.respond-to(@bpTablet, {
				padding-right: 5%;
				width: 48%;
			});

			.respond-to(@bpDesktopSmall, {
				font-size: 16px;
				padding-right: 2em;
				width: 41%;
			});

			.respond-to(@bpDesktopLarge, {
				font-size: 18px;
			});
		}

		.value {
			color: @gray-87;
			vertical-align: top;
			position: relative;

			.negative {
				position: absolute;
				font-size: 1em;
				left: -0.5em;
			}

			.remove-promo-code {
				background-color: transparent;
				border: none;
				cursor: pointer;
				margin-left: 3px;
				padding: 0;
			}
		}

		.item-remove {
			color: @gray-medium-dark;
			cursor: pointer;
			font-size: 14px;
			position: absolute;
			text-transform: lowercase;
			top: 0;
			right: 1.75rem;
		}

		&.summary-promotion {
			margin-bottom: 0.25em;
		}

		&.summary-promotion-message {
			margin-bottom: 0.35em;
		}
	}

	.estimated-total {
		margin-top: 2em;

		.respond-to(@bpDesktopSmall, {
			margin-top: 4.5em;
		});

		.value {
			color: @gray-darker;
			.font-family(medium);
			font-size: 18px;
			letter-spacing: -0.86px;
		}
	}
}

