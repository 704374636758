/* PHILANTHROPY PAGES STYLES
-----------------------------------------------------------------------------*/

.philanthropy_about,
.charity_rides,
.soul_scholarship {
  .info-detail-container .hero-image-container {
    min-width: 320px;
  }
}

.philanthropy_about {
  .info-detail-container {
    .respond-to(@bpMobileLarge, {
      padding-bottom: 60px;
    });
    .respond-to(@bpDesktopSmall, {
      padding-bottom: 100px;
    });
    .hero-image-container {
      padding-bottom: 0;
    }
    .hero-video-container {
      .hero-video {
        max-height: 810px;
      }
    }
  }
  .row {
    .respond-to(@bpMobileLarge, {
      padding-bottom: 0;
    });
    .respond-to(@bpTablet, {
      padding-bottom: 20px;
    });
  }
  .watermark-offset-title {
    .font-family(heavy);
    font-size: 25px;
    text-align: center;
    margin-top: -20px;
    .respond-to(@bpMobileLarge, {
      font-size: 45px;
      margin-top: -40px;
      margin-bottom: 30px;
    });
    .respond-to(@bpTablet, {
      font-size: 55px;
      margin-top: -50px;
    });
    .respond-to(@bpDesktopSmall, {
      font-size: 65px;
      margin-top: -60px;
      margin-bottom: 30px;
    });
    .respond-to(@bpDesktopLarge, {
      margin-bottom: 0px;
    });
  }
  .navigation-link {
    display: block;
    margin: 5px 10px 0 0;
    .font-family(medium);
    color: @gray-darker;
    img {
      margin-left: 10px;
    }
  }
}

.charity_rides,
.soul_scholarship {
  .info-detail-container.find-your-soul .row {
    .respond-to(@bpDesktopSmall, {
      width: initial;
      .page-title {
        font-size: 65px;
        .font-family(heavy);
      }
    });
    &.row-donate {
      padding-bottom: 60px;
      .respond-to(@bpDesktopSmall, {
        width: 100%;
        padding-top: 60px;
      });
    }
  }
}

.soul_scholarship {
  .row {
    border-bottom: 0;
    padding-bottom: 40px;
    .respond-to(@bpMobileLarge, {
      padding-bottom: 60px;
    });
  }
  .phil-row {
    background-color: @gray-main;
    padding-top: 0;
    .respond-to(@bpDesktopSmall, {
      padding-bottom: 0;
      display: -webkit-box !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: -webkit-flex !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      &:nth-child(even) {
        background-color: #fff;
      }
    });
    .row-content {
      padding-top: 20px;
      .respond-to(@bpTablet, {
        padding-top: 40px;
        padding-bottom: 30px;
      });
      .respond-to(@bpDesktopSmall, {
      padding-top: 0;
      padding-bottom: 0;
      width: 55%;
      position: relative;
      });
      h3, p {
        .respond-to(@bpDesktopSmall, {
          padding-left: 60px;
          padding-right: 60px;
        });
      }
    }
    .move-right {
      .respond-to(@bpDesktopSmall, {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
      });
    }
  }
  .phil-img {
    width: 100%;
    .respond-to(@bpDesktopSmall, {
      width: 45%;
    });
  }
}