/* SHOP STYLES
-----------------------------------------------------------------------------*/
// auth-open class added to .soul-container when clicking on login
.auth-open {
  .fix-retail-products {
    margin-top: 0;
  }
}

.shop-filter-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.checkbox {
  margin-left: 40%;
  position: relative;
  text-align: left;
}

.custom-shop-checkbox {
  visibility: hidden;
}

.custom-shop-checkbox + label::before {
  border-radius: 1px;
  border: 2px solid #5A5A5A;
  content: '';
  height: 18px;
  left: 0;
  margin-top: 2px;
  position: absolute;
  top: 0;
  width: 18px;
  z-index: 0;
}

.custom-shop-checkbox.filled-in + label::before, .custom-shop-checkbox.filled-in + label::after {
  content: '';
  left: 0;
  position: absolute;
  z-index: 1;
}

.custom-shop-checkbox.filled-in:not(:checked) + label::before {
  -webkit-transform-origin: 20% 40%;
  .transform(rotateZ(37deg));
  border: 3px solid transparent;
  height: 0;
  left: 6px;
  top: 10px;
  transform-origin: 100% 100%;
  width: 0;
}

.custom-shop-checkbox.filled-in + label::after {
  content: '';
  left: 0;
  position: absolute;
  z-index: 1;
}

.custom-shop-checkbox.filled-in:not(:checked) + label::after {
  background-color: transparent;
  border: 1px solid @gray-light;
  height: 10px;
  top: 0;
  width: 10px;
  z-index: 0;
}

.custom-shop-checkbox.filled-in + label::before, .custom-shop-checkbox.filled-in + label::after {
  content: '';
  left: 0;
  position: absolute;
  z-index: 1;
}

.custom-shop-checkbox.filled-in:checked + label::before {
  -webkit-transform-origin: 100% 100%;
  .transform(rotateZ(37deg));
  border-bottom: 2px solid @sc-yellow;
  border-left: 2px solid transparent;
  border-right: 2px solid @sc-yellow;
  border-top: 2px solid transparent;
  height: 6px;
  left: -1px;
  top: -2px;
  transform-origin: 100% 100%;
  width: 3px;
}

.custom-shop-checkbox.filled-in:checked + label::after {
  border: 1px solid @gray-light;
  height: 10px;
  top: 0;
  width: 10px;
  z-index: 0;
}

.shop_index, .shop_categories {
  .filters-panel {
    background-color: #fff;
    display: block;
    width: 100%;
    .all-colors {
      .checkbox {
        margin-left: 15%;
      }
    }
    .color-swatch {
      height: 13px;
      width: 13px;
      border-radius: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      margin-right: 8px;
      margin-left: 15%;
      float: left;

      &.green {
        background-color: #90f363;
      }
      &.blue {
        background-color: #4084f7;
      }
      &.red {
        background-color: #f2757d;
      }
      &.orange {
        background-color: #ffa500;
      }
      &.white {
        background-color: #f4f4f4;
      }
      &.purple {
        background-color: #2d0081;
      }
      &.pink {
        background-color: #ff879c;
      }
      &.yellow {
        background-color: #f4e545;
      }
      &.grey {
        background-color: #959595;
      }
      &.brown {
        background-color: #8b4513;
      }
      &.black {
        background-color: #0c0c0d;
      }
      &.multi {
        background: -webkit-linear-gradient(bottom, #f2757d, #f4e545, #4084f7); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(bottom, #f2757d, #f4e545, #4084f7); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(bottom, #f2757d, #f4e545, #4084f7); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to bottom, #f2757d, #f4e545, #4084f7); /* Standard syntax */
      }
    }
    .dd-opener,
    .sort-by-container {
      cursor: pointer;
      font-size: 12px;
      float: left;
      background-color: #ffffff;
      position: relative;
      height: 50px;
      white-space: nowrap;
      .dd-selection {
        border-left: @borderLightGray;
        border-radius: 3px;
        border: 1px solid @gray-medium;
        color: @gray-medium;
        cursor: pointer;
        height: 22px;
        list-style: none;
        margin: 14px auto 0 auto;
        overflow: hidden;
        padding-left: 8px;
        text-align: center;
        width: 180px;

        &.open {
          background: @gray-main;
          border: 1px solid @gray-main;
          border-radius: 3px 3px 0 0;
        }
        li {
          display: none;
          &.selected {
            display: inline-block;
            padding-top: 8px;
          }
        }
      }
      &.size .dd-selection li, &.color .dd-selection li[data-name="all"] {
        border-radius: 5px;
        padding: 6px 0 0 5px;
      }
      .size-dd {
        background: @scWhiteC;
        left: 0;
        margin: 0 auto;
        outline-offset: -2px;
        outline: 2px solid #FAFAFA;
        right: 0;
        text-align: center;
        top: 38px;
        width: 189px;

        ul {
          overflow: visible;
        }
      }
    }
    .dd {
      display: none;
      text-align: left;
      position: absolute;
      top: 50px;
      z-index: 100;
      font-size: 12px;
      background-color: @scWhiteC;
      width: 200%;
      color: @gray-medium;
      border-left: @borderLightGray;
      ul {
        list-style: none;
        overflow: hidden;
        border-top: @borderLightGray;
        border-bottom: @borderLightGray;
        border-right: @borderLightGray;
        li {
          &:hover {
            color: @gray-darker;
          }
        }
      }
      .dd-item {
        float: left;
        padding: 10px 0;
        width: 50%;
        .checked {
          color: @gray-darker;
        }
      }
      &.color-dd {
        width: 200%;
        margin-left: -100%;
        .dd-item {
          width: 33%;
        }
        .checkbox {
          float: left;
        }
      }
      &.filter-dd {
        width: 200%;
        margin-left: -200%;
        .sort-mode {
          width: 100%;
        }
        .selected {
          color: @gray-darker;
        }
      }
    }
    #sort-by-container {
      cursor: pointer;
      font-size: 12px;
      height: 50px;
      float: left;
      background-color: #ffffff;
      position: relative;
      color: @gray-darker;
      text-align: center;
      #sort-by-label {
        height: 100%;
        border-left: @borderLightGray;
        .sort-by-details {
          padding-top: 13px;
        }
      }
      #opts {
        list-style: none;
        text-align: center;
        li.sort-mode {
          cursor: pointer;
          padding: 20px 0;
        }
      }
    }
  }
  #no-products {
    text-align: center;
    font-size: 4em;
    .font-family(heavy);
    color: @gray-light;
    padding: 35% 5% 25%;
    display: none;
    box-sizing: border-box;
  }

  .no-products-text {
    .flex-display();
    .justify-content(center);
    .align-items(center);
    .font-family(medium);
    min-height: 20rem;
    width: 100%;
  }

  .shop-slider-container {
    position: relative;
    .clear-all();
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    .slides-controller {
      right: 20px;
      position: absolute;
      bottom: 20px;
      li {
        float: left;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        background-color: transparent;
        border: 2px solid #FFDB00;
        margin-left: 8px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        cursor: pointer;
        list-style-type: none;
        &.active {
          background-color: #FFDB00;
        }
      }
    }
    .single-slide {
      height: 500px;
      display: inline-block;
      background-repeat: no-repeat;
    }
  }
  .shop-hero-slides {
    position: relative;

    .single-slide {
      a {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
  
  .shop-filters {
    .font-family(medium);
    border-bottom: 1px solid @gray-light;
    border-top: 1px solid @gray-light;
    width: 50%;
  }
  
  .size-filters {
    width: 100%;
  }

  .shop-retail-products {
    background-color: #fff;
    position: relative;
    overflow: hidden;

    &.fix-retail-products {
      margin-top: 65px;
    }

    /* Flash Sale Styles */
    &.flash-sale-margin {
      margin-top: 62px;
    }
  }

  .single-item-name, .single-item-price {
    color: @gray-darker;
    display: block;
    font-size: 1.3em;
    .font-family(light);
  }
  .single-item-name {
    margin-top: 5px;
    line-height: 18px;
    width: 85%;
    .respond-to(@bpMobileLarge, {
      width: 100%;
    });
  }
  .single-item-price {
    letter-spacing: normal;
    margin-top: 5px;

    &.discounted-price {
      color: @red-error;
      display: inline;
    }

    &.is-discounted {
      color: @gray-user-info;
      display: inline-block;
      margin-right: 5px;
      position: relative;

      &:before {
        border-bottom: 1px solid @gray-user-info;;
        content: '';
        position: absolute;
        right: 0;
        top: calc(~'50% - 3px');
        width: 100%;
      }
    }
  }
  .single-item-colors {
    position: absolute;
    top: 20%;
    right: 10px;
    .single-item-color {
      display: block;
      width: 8px;
      height: 8px;
      margin: 0 1px 5px;
      background-color: #000;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid @gray-main;
    }
  }
  .shop-grid-elements {
    position: relative;
    .product {
      .box-sizing(border-box);
      float: left;
      margin: 0;
      position: relative;
      width: 50%;

      .product-details {
        padding: 15px 0 0 15px;
        .respond-to(@bpMobileLarge, {
          padding: 20px 0 0 20px;
        });
        .respond-to(@bpDesktopSmall, {
          padding: 0;
          max-width: 60%;
          margin: 0 auto;
        });
      }
      a {
        color: @gray-darker;
      }
      img {
        max-width: 70%;
        height: auto;
        .respond-to(@bpMobileLarge, {
          max-width: 75%;
        });
        .respond-to(@bpDesktopSmall, {
          max-width: 100%;
          padding-top: 10px;
        });
      }
      &.featured {
        height: 280px;

        &:hover {
          .view-item {
            text-decoration: underline;
          }
        }
        .item-name {
          display: block;
          .font-family(medium);
          color: @gray-darker;
          font-size: 1.4em;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-top: 12px;
        }
        .item-info {
          display: block;
          font-size: 1.4em;
          color: #919194;
          line-height: 1.6;
          margin-top: 5px;
        }
        .view-item {
          position: relative;
          display: inline-block;
          color: @gray-darker;
          letter-spacing: 1px;
          font-size: 1.2em;
          .font-family(medium);
          text-transform: uppercase;
          margin-top: 15px;
          &:after {
            content: url('/assets/images/link-arrow.png');
            position: absolute;
            right: -11px;
            top: -1px;
          }
        }
      }
      .badge-new {
        font-size: 1.3em;
        color: @gray-darker;
        .font-family(medium);
        padding-top: 5px;
      }
    }
  }

  /////////////////////
  // Carousel Styles //
  /////////////////////

  .swiper-container {
    .swiper-slide {
      z-index: 1;
      width: 100%;
      -webkit-justify-content: unset;
      justify-content: center;
      line-height: 0;
      font-size: 0;
      img {
        min-height: 320px;
        min-width: 320px;
        margin-left: -106px;
        opacity: 1;
      }
      .disabled-swiper-link {
        pointer-events: none;
      }
      .swiper-image {
        width: 100%;
        img {
          opacity: .3 !important;
        }
      }
    }
    .swiper-slide-active {
      z-index: 10;
      .swiper-image {
        img {
          opacity: 1 !important;
        }
      }
    }
    .swiper-slide-next {
      z-index: 5;
    }
  }
  .carat-yellow {
    right: 0;
  }

  .size-filters-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
    width: 180px;
  }

  .size-filter-icon {
    color: @gray-medium;
    display: block;
    float: right;
    font-size: 10px;
    margin-top: -15px;
  }

  span.open.size-filter-icon {
    .transform(rotate(180deg));
    margin-top: -18px;
  }

  .filter-by-size {
    background-color: @scWhiteC;
    color: @gray-medium;
    display: block;
    float: right;
    font-size: 12px;
    margin: -19px 0 0 34px;
    padding: 2px;
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 130px;
  }

  span.open.filter-by-size {
    visibility: visible;
  }
}


/* MEDIA QUERIES - SHOP STYLES
-----------------------------------------------------------------------------*/

// 375px < css width (px) < 599px (iPhone 6 Portrait)
@media only screen and (min-width: 375px) {
  // Carousel Styles //
  .shop_index, .shop_categories {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -95px;
        }
      }
    }
  }
}

.respond-to(0px, 599px, {
  .shop_index {
    .filters-panel {
      .dd-opener {
        .size-dd {
          border-bottom: 1px solid @gray-light;
          box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
          outline-offset: none;
          outline: none;
          width: 100%;

          ul {
            overflow: visible;
          }
        }

        .size-filters-container {
          width: 100%;
        }

        .dd-selection.open {
          background: @scWhiteC;
          border-color: @scWhiteC;
        }
      }

      .dd-item.dd-all-option {
        padding: 20px 0 10px 0;
        width: 100%;
      }

      .dd {
        ul {
          border: none;
          margin: 0 auto 0 auto;
          width: 50%;
        }
      }
    }

    .size-filter-icon {
      float: unset;
      margin-left: 164px;
      text-align: center;
    }

    .filter-by-size {
      float: unset;
      margin: -11px auto 0 auto;
      padding-left: 16px;
      position: relative;
      text-align: center;
      visibility: hidden;
    }
  }
});

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .shop_index, .shop_categories {
    .swiper-container {
      .swiper-slide {
        img {
          max-width: 600px;
          min-height: 0;
          margin-left: -200px;
        }
      }
    }
    .shop-grid-elements {
      .product {
        width: 33.3%;
        &.featured {
          height: 380px;
        }
        .badge-new {
          padding-top: 10px;
        }
      }
    }
    .filters-panel {
      .dd-opener,
      .sort-by-container {
        text-align: center;
      }
      #sort-by-container {
        #sort-by-label {
          .sort-by-details {
            padding-top: 18px;
          }
        }
        .carat-yellow {
          right: 0;
          left: 78%;
        }
      }
      .dd-item.dd-all-option {
        width: 100%;
        padding: 20px 0 10px 0;
      }
      .dd {
        .dd-item {
          width: 100%;
        }
      }

      .filter-by-size {
        background-color: @gray-main;
      }
    }
    .single-item-name {
      font-size: 1.8em;
      line-height: 24px;
    }
    .single-item-price {
      font-size: 1.8em;
    }
    .single-item-colors {
      top: 30%;
      right: 20px;
      .single-item-color {
        margin-bottom: 8px;
        width: 10px;
        height: 10px;
      }
    }
  }
  .dd-opener {
    .carat-yellow {
      right: 0;
      left: 70%;
    }
  }
  .sort-by-type {
    display: inline-block;
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .shop_index, .shop_categories {
    .swiper-container {
      .swiper-slide {
        img {
          max-width: 768px;
          max-height: 320px;
          margin-left: -255px;
        }
      }
    }
    .filters-panel {
      #sort-by-container {
        .carat-yellow {
          left: 73%;
        }
      }
      .dd-item.dd-all-option {
        width: 100%;
        padding: 20px 0 10px 0;
      }
    }
    #no-products {
      font-size: 8em;
      padding: 25% 5% 25%;
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .shop_index, .shop_categories {
    padding-bottom: @footerHeightDesktop;
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -180px;
        }
      }
    }
    .filters-panel {
      .dd {
        width: 100%;
        .dd-item {
          float: left;
          padding: 10px 0;
          width: 100%;
          .checked {
            color: @gray-darker;
          }
        }
        &.color-dd {
          width: 100%;
          margin-left: 0;
          .dd-item {
            width: 100%;
          }
          .checkbox {
            float: left;
          }
        }
        &.filter-dd {
          width: 100%;
          margin-left: 0;
          .sort-mode {
            width: 100%;
          }
          .selected {
            color: @gray-darker;
          }
        }
      }
      .dd-item.dd-all-option {
        padding: 20px 0 10px 0;
      }
    }
    .shop-grid-elements {
      .product {
        &.featured {
          height: 380px;
        }
      }
    }
    .shop-retail-products {
      clear: both;
      padding-bottom: 54px;
      .shop-filters{
        &.color{
          .scroller-wrap{
            display:block;
            overflow: auto;
            max-height: 25em;
            border-bottom: @borderLightGray;
          }
        }
      }
    }

    #no-products {
      text-align: center;

      .font-family(heavy);
      color: @gray-light;
      padding: 25% 5% 35%;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .shop_index, .shop_categories {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -100px;
        }
      }
    }

    .filters-panel {
      #sort-by-container {
        .carat-yellow {
          left: initial;
          right: initial;
          margin: 22px 0 0 75px;
        }
      }
    }
    .shop-grid-elements {
      .product {
        width: 25%;
        &.featured {
          height: 480px;
        }
        img {
          width: 255px;
        }
      }
    }
    .dd-opener {
      .carat-yellow {
        right: initial;
        left: initial;
        margin-left: 75px;
        margin-top: 22px;
      }
    }

    #no-products {
      text-align: center;
      .font-family(heavy);
      color: @gray-light;
      padding: 20% 5% 30%;
    }
  }
}

// css width (px) >= 2000
@media only screen and (min-width: 2000px) {
  .shop_index, .shop_categories {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: 0px;
        }
      }
    }
  }
}