/*doc
---
title: Containers
name: containers
category: containers
---

Main containers to wrap main content. This document may be conslidated into an overall layouts guide in the future.

```html_example
<div class="sc-container">
	<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vitae malesuada ligula. Quisque imperdiet massa molestie, viverra quam sit amet, ultrices ligula. Quisque non maximus nunc, non feugiat erat. Vestibulum tincidunt diam nibh, nec consectetur magna fermentum consectetur. Integer malesuada dui nec metus vestibulum, eu pharetra lectus tempus. Nam nec justo metus. Donec sagittis mollis eros at vulputate. Phasellus imperdiet faucibus massa vitae malesuada. Curabitur ac neque iaculis, lobortis nisl eu, varius odio. Nulla ultricies lacus ex, nec porta eros dapibus vitae. Nullam non blandit nisl. Suspendisse potenti. Nunc ornare orci tortor, sed placerat mauris imperdiet vel. Cras volutpat consectetur lorem, vitae mollis urna eleifend quis. Proin iaculis dapibus condimentum.</p>
</div>
```

```html_example
<div class="sc-container-medium">
	<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vitae malesuada ligula. Quisque imperdiet massa molestie, viverra quam sit amet, ultrices ligula. Quisque non maximus nunc, non feugiat erat. Vestibulum tincidunt diam nibh, nec consectetur magna fermentum consectetur. Integer malesuada dui nec metus vestibulum, eu pharetra lectus tempus. Nam nec justo metus. Donec sagittis mollis eros at vulputate. Phasellus imperdiet faucibus massa vitae malesuada. Curabitur ac neque iaculis, lobortis nisl eu, varius odio. Nulla ultricies lacus ex, nec porta eros dapibus vitae. Nullam non blandit nisl. Suspendisse potenti. Nunc ornare orci tortor, sed placerat mauris imperdiet vel. Cras volutpat consectetur lorem, vitae mollis urna eleifend quis. Proin iaculis dapibus condimentum.</p>
</div>
```

Apply the .sc-container-main class to a container that needs to adjust for main header and footer

```html_example
<div class="sc-container-medium sc-container-main">
	<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vitae malesuada ligula. Quisque imperdiet massa molestie, viverra quam sit amet, ultrices ligula. Quisque non maximus nunc, non feugiat erat. Vestibulum tincidunt diam nibh, nec consectetur magna fermentum consectetur. Integer malesuada dui nec metus vestibulum, eu pharetra lectus tempus. Nam nec justo metus. Donec sagittis mollis eros at vulputate. Phasellus imperdiet faucibus massa vitae malesuada. Curabitur ac neque iaculis, lobortis nisl eu, varius odio. Nulla ultricies lacus ex, nec porta eros dapibus vitae. Nullam non blandit nisl. Suspendisse potenti. Nunc ornare orci tortor, sed placerat mauris imperdiet vel. Cras volutpat consectetur lorem, vitae mollis urna eleifend quis. Proin iaculis dapibus condimentum.</p>
</div>
```
*/

[class*=sc-container] {
    .box-sizing(border-box);
	margin: 0 auto;
	max-width: 56.25rem;
	width: 100%;

	&:after {
		content: " ";
		display: block;
		clear: both;
	}

	&.sc-container-main {
		padding-top: @gutterTop;
		padding-bottom: 1rem;

		.respond-to(@bpDesktopSmall, {
			padding-bottom: @footerHeightDesktop;
		});
	}
}

.sc-container-default {
	.respond-to(@bpTablet, {
		max-width: @widthMobile;
	});

	.respond-to(@bpDesktopSmall, {
		max-width: @widthDesktopSmall;
	});

	.respond-to(@bpDesktopLarge, {
		max-width: @widthDesktopLarge;
	});
}

.sc-container-medium {
	max-width: @widthTablet; /* 768px */
}
