/* STUDIO PAGE STYLES
-----------------------------------------------------------------------------*/
.studio_detail {
  .main-container {
    p {
      margin: 0;
    }
  }

  .row {
    border-bottom: none;
    display: block;
    overflow: hidden;
  }

  .studio-header-container {
      .align-items(center);
      .flex-display(flex);
      .justify-content(space-between);
      border-bottom: @borderLightGray;
      overflow: hidden;
      padding: 2rem 2rem 2rem 1.5rem;
      position: relative;
      text-align: left;

    .studio-name-watermark {
      .flex-display(flex);
      .font-family(heavy);
      .justify-content(space-around);
      color: @gray-main;
      font-size: 10em;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      white-space: nowrap;
      z-index: 1;
    }

    .all-studios {
      display: block;
      font-size: 1.2em;
      margin-bottom: 1rem;
      z-index: 1;
    }

    .studio-name {
      .flex(1);
      .font-family(heavy);
      display: inline;
      font-size: 2rem;
      letter-spacing: -2px;
      position: relative;
      z-index: 10;
    }

    .studio-location-container {
      display: none;
      position: relative;
      text-align: left;
      z-index: 1;
    }
  }

  .upcoming-classes-subcontainer {
    height: 368px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .no-classes {
      font-size: 14px;
      padding: 32px 20px;
    }
  }

  .studio-info-container {
    overflow: hidden;
    position: relative;

    .studio-location-container {
      padding: 1.25rem 1.5rem;
      width: auto;
    }
  }

  .page-sub-header {
    .font-family(heavy);
    font-size: 2.1em;
    letter-spacing: -0.5px;
    padding: 10px 0;
  }

  .upcoming-classes-header {
    border-bottom: @borderLightGray;
    border-top: none;
    padding-bottom: 30px;
    padding-left: 24px;

    &:hover {
      color: @gray-54;
    }
  }

  .upcoming-classes-container {
    border-top: @borderLightGray;
  }

  .fav-it {
    .transition(transform, 0.3s, ease-in-out);
    .transform(scale(1.4));
    bottom: 1.25rem;
    cursor: pointer;
    height: 15px;
    object-fit: none;
    object-position: -126px -208px;
    position: absolute;
    right: -2rem;
    width: 20px;

    &.active {
      object-position: -176px -207px;
    }

    &:hover {
      .transform(scale(1.6));
    }
  }

  .swiper-container {
    .swiper-slide {
      font-size: 0;
      justify-content: center;
      -webkit-justify-content: unset;
      line-height: 0;
      width: 100%;
      z-index: 1;

      img {
        margin-left: -107px;
        max-height: 180px;
        max-width: 320px;
        opacity: 1;
      }

      .disabled-swiper-link {
        pointer-events: none;
      }

      .swiper-image {
        width: 100%;

        img {
          opacity: .3 !important;
        }
      }
    }

    .swiper-slide-active {
      z-index: 10;

      .swiper-image {
        img {
          opacity: 1 !important;
        }
      }
    }

    .swiper-slide-next {
      z-index: 5;
    }
  }

  .instructor-swiper {
    width: 100%;

    .swiper-image {
      img {
        margin-left: -30px;
        vertical-align: bottom;
        width: 200%;
      }
    }
  }

  .instructor-swiper-next,
  .instructor-swiper-prev {
    cursor: pointer;
    display: none;
    height: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    position: absolute;
    top: 40%;
    width: 30px;
    z-index: 100;
    .respond-to(@bpMobileLarge, {
      display: block;
    });
  }

  .instructor-swiper-prev {
    left: 30px;
  }

  .instructor-swiper-next {
    right: 30px;
  }

  .studio-container {
    position: relative;
  }

  .studio-name-container {
    .align-items(center);
    z-index: 1;
  }

  .studio-hero {
    margin: 0 auto;
    overflow: hidden;
    width: 1200px;
    z-index: 1;
  }

  .studio-hero-slides {
    position: relative;
  }

  .single-slide {
    background-position: center top;
    background-repeat: no-repeat;
    float: left;
    height: 500px;
    width: 100%;
  }

  .parking-instructions {
    overflow: hidden;
    padding: 48px 24px;
  }

  .icons {
    float: left;

    .icon-park {
      .font-family(bold);
      border: 2px solid @gray-darker;
      border-radius: 50%;
      display: inline-block;
      font-size: 1.3em;
      height: 25px;
      line-height: 29px;
      position: relative;
      text-align: center;
      width: 25px;
    }
  }

  .parking-instructions-info {
    float: left;
    font-size: 1.2em;
    line-height: 1.8;
    margin-top: 0;
    padding-left: 16px;
    width: 83%;
  }

  .studio-details {
    .align-items(center);
    .flex-display(flex);
  }

  .studio-longname {
    .font-family(medium);
    display: block;
    margin-right: 5px;
  }

  .studio-location {
    display: block;
    font-size: 1.6em;
    line-height: 1.7;
    padding-top: 5px;
  }

  .studio-content {
    display: block;
    overflow: hidden;

    .studio-instructors {
      border-bottom: @borderLightGray;
      border-top: @borderLightGray;
      position: relative;
      z-index: 10;

      .page-sub-header {
        background-color: @gray-main;
        padding: 24px;
      }

      .instructor-thumb {
        display: block;
        height: 100%;
        width: 100%;
      }

      .instructor-name {
        .font-family(medium);
        bottom: 15px;
        font-size: 0.7em;
        left: 25%;
        position: absolute;
      }

      .no-instructors {
        font-size: 14px;
        padding: 32px 20px;
        width: 65%;
      }
    }

    .upcoming-classes-link {
      display: inline-block;
      font-size: 1.2em;
      letter-spacing: 0;
      position: relative;
    }

    .upcoming-class {
      border-bottom: @borderLightGray;
      height: 100px;
      position: relative;
      width: 100%;

      .bookmark {
        left: 16px;
        right: auto;
      }

      .reserved,
      .waitlist,
      .supersoul,
      .not-open {
        cursor: default;
        display: none;
      }

      .reserved-text,
      .waitlisted-text {
        .font-family(heavy);
        color: @gray-darker;
        display: none;
        font-size: 6em;
        margin-top: -4px;
        text-align: center;
      }

      // TODO: move to global (duplicate code in find-a-class.less)
      a {
        right: 16px;
        top: 45px;

        &.reserve {
          position: absolute;
        }

        &.waitlist {
          .gray-button;
          opacity: 0;
          position: absolute;

          &:hover {
            background: @gray-really-light;
          }
        }

        &.supersoul,
        &.not-open {
          .gray-button;
          font-size: 12px;
          opacity: 0;
          position: absolute;

          &:hover {
            background: @gray-really-light;
          }
        }
      }

      &.session {
        height: 90px;
        overflow: hidden;
        padding-top: 32px;
        position: relative;

        .no-sessions {
          color: @gray-medium;
        }

        &:first-child {
          margin-top: 0;
        }

        &.expired {
          color: #cfcfcf;

          &.enrolled {
            color: #fff !important;

            .type,
            .instructor a {
              color: #fff;
            }

            .waitlist,
            .reserve {
              opacity: @opacity-54;
            }

            &.full {
              .waitlist {
                display: block;
                opacity: @opacity-54;
              }

              .reserve {
                display: none;
              }
            }
          }

          .supersoul,
          .not-open,
          .waitlist,
          .reserve {
            opacity: @opacity-54;
          }

          .supersoul,
          .not-open,
          .waitlist {
            display: none;
          }

          .instructor a {
            color: #cfcfcf;
          }

          .bookmark {
            visibility: hidden;
          }

          &.full {
            .waitlist {
              display: block;
              opacity: @opacity-54;
            }

            .reserve {
              display: none;
            }
          }
        }

        &.full {
          background: @gray-main;

          .waitlist {
            opacity: 1;
            z-index: 10;
          }

          .reserve,
          .supersoul,
          .not-open {
            display: none;
          }

          &.waitlisted {
            background: @gray-main;
          }
        }

        &.open {
          .waitlist,
          .supersoul,
          .not-open {
            display: none;
          }
        }

        &.supersoul,
        &.not-open {
          &.full,
          &.open {
            .waitlist,
            .reserve {
              display: none;
            }

            .supersoul,
            .not-open {
              display: block;
              opacity: 1;
              z-index: 10;
            }
          }

          &.enrolled {
            .not-open,
            .supersoul {
              background-color: #fff;
            }
          }

          .reserve {
            display: none;
          }
        }

        &.enrolled {
          background: @sc-yellow;

          .bookmark {
            visibility: hidden;
          }

          .reserve {
            .gray-button;
            background-color: #fff;
            position: absolute;
            right: 16px;
            top: 45px;

            &:hover {
              background: @white-70;
            }
          }

          .reserved-text {
            display: block;
          }

          .type {
            color: @gray-darker;
          }

          &.full {
            .reserve {
              visibility: hidden;
            }

            .waitlist {
              background: #fff;
              display: block;
              opacity: 1;
              z-index: 10;

              &:hover {
                background: @white-70;
              }
            }
          }

          &.waitlisted {
            background: @scYellowC;

            .waitlisted-text {
              display: none;
            }

            .reserved-text {
              display: block;
            }

            &.open {
              .reserve {
                background-color: @scWhiteC;

                &:hover {
                  background: @white-70;
                }
              }
            }
          }
        }

        &.waitlisted {
          background: @scWhiteC;
          color: inherit;

          .waitlisted-text {
            display: block;
          }

          &.open {
            .reserve {
              background-color: @scYellowC;
            }
          }
        }
      }

      // TODO: move to global (duplicate code in find-a-class.less)
      &.class_info_row {
        .tooltip {
          border-bottom: 4px solid @gray-darker;
          bottom: 0;
          display: none;
          height: 4px;
          position:absolute;
          width: 100%;
        }
      }

      .reserve_class_button {
        .form-button {
          margin-right: 0;
        }
      }

      .class_info {
        padding: 0 124px 0 55px;

        .datetime {
          color: @gray-default;
          font-size: 1.1em;
          letter-spacing: 0;
          margin-top: 5px;
        }

        .class-type,
        .instructor {
          .font-family(medium);
          display: block;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 15px;
          margin-top: 5px;
        }

        .class-type {
          color: @gray-darker;
        }

        .instructor {
          .opacity-hover(@opacity-70);
          color: @gray-medium-lighter;
          width: 95%;
        }
      }
    }

    .instructor-swiper {
      background-color: @gray-main;

      .swiper-slide {
        background: transparent;
        text-align: left;
      }
    }

    .studio-news {
      background: #fff;
      position: relative;
      z-index: 10;

      .page-sub-header {
        padding: 24px;
      }

      .row {
        padding-left: 24px;
        padding-right: 16px;
        width: inherit;

        &:nth-child(n+8) {
          display: none;
        }
      }

      .thumb {
        float: left;
        height: 50px;
        width: 90px;
      }

      .news-content {
        float: left;
        padding-top: 20px;
        width: 100%;
      }

      h3 {
        .font-family(medium);
        display: inline-block;
        font-size: 16px;
        line-height: 18px;
        padding-left: 16px;
        width: 60%;
      }

      p {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        margin: 0;
      }

      .read-more {
        .font-family(medium);
        color: @gray-darker;
        display: inline-block;
        font-size: 1.2em;
        letter-spacing: 1px;
        margin-top: 10px;
        position: relative;
        text-transform: uppercase;

        &:after {
          content: url(/assets/images/link-arrow.png);
          position: absolute;
          right: -11px;
          top: -1px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .studio-map {
    overflow: hidden;

    #studio-map-canvas {
      float: left;
      height: 320px !important;
      width: 100% !important;
    }

    p {
      color: #919194;
      float: left;
      font-size: 1.5em;
      line-height: 1.5;
      margin: 0 0 0 15px;
      width: 230px;
    }
  }

  .studio-map-full {
    width: 100% !important;
  }
}

/* MEDIA QUERIES - STUDIO PAGE STYLES
-----------------------------------------------------------------------------*/

// 375px < css width (px) < 413px ( Samsung Galaxy Portrait)
@media only screen and (min-width: 360px) {

  // Carousel Styles //
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -100px;
        }
      }
    }
  }
}

// 375px < css width (px) < 413px (iPhone 6 Portrait)
@media only screen and (min-width: 375px) {

  // Carousel Styles //
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -95px;
        }
      }
    }
  }
}

// 375px < css width (px) < 599px (iPhone 6+ Portrait)
@media only screen and (min-width: 414px) {

  // Carousel Styles //
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -87px;
        }
      }
    }
  }
}

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -200px;
          max-height: 360px;
          max-width: 600px;
        }
      }
    }

    .studio-header-container {
      background: none;
      padding: 2.5rem 3.5rem;

      .studio-name-watermark {
        font-size: 12em;
      }

      .all-studios {
        font-size: 14px;

        a {
          .opacity-hover(@opacity-54);
        }
      }

      .studio-location-container {
        display: block;
        margin-left: 2.5rem;
        min-width: 13.75rem;

        .studio-details {
          .studio-location {
            font-size: 14px;
            letter-spacing: 0;
          }
        }
      }
    }

    .studio-info-container {
      .studio-location-container {
        &.row {
          display: none;
        }
      }
    }

    .page-sub-header {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 21px;
    }

    .parking-instructions {
      padding: 54px;
    }

    .parking-instructions-info {
      font-size: 1.4em;
      line-height: 1.8;
      width: 90%;
    }

    .upcoming-classes-header {
      padding: 40px 54px 35px;
    }

    .upcoming-classes-subcontainer {
      height: 296px;

      .no-classes {
        font-size: 16px;
        padding: 54px;
        line-height: 22px;
      }
    }

    .studio-content {
      .instructor-swiper {
        .swiper-image {
          img {
            width: 160%;
          }
        }
      }

      .upcoming-class {

        a {
          &.reserve {
            right: 20px;
            top: 34px;
          }

          &.waitlist {
            .gray-button;
            position: absolute;
            right: 20px;
            top: 34px;
          }

          &.supersoul,
          &.not-open {
            right: 20px;
            top: 34px;
          }
        }

        .reserved-text,
        .waitlisted-text {
          margin-top: -20px;
        }

        &.session {
          height: 74px;
          padding-top: 24px;

          .class_info {
            padding-left: 100px;
          }

          &.enrolled {
            .reserve {
              right: 20px;
              top: 34px;
            }

            .waitlist {
              right: 20px;
              top: 34px;
            }

            .supersoul,
            .not-open {
              right: 20px;
              top: 34px;
            }
          }
        }

      }

      .studio-news,
      .studio-instructors {
        .row,
        .page-sub-header {
          padding: 35px 54px 35px;
        }

        .row {
          &:nth-child(n+8) {
            display: block;
          }
        }

        .news-content {
          padding-left: 20px;
          width: 75%;
        }
      }

      .studio-instructors {
        .instructor-name {
          bottom: 20px;
          left: 3%;
        }

        .no-instructors {
          font-size: 16px;
          line-height: 22px;
          padding-left: 54px;
        }
      }
    }
  }
}

// 600px < css width (px) < 736px (iPhone 6+ Landscape)
@media only screen and (min-width: 736px) {

  // Carousel Styles //
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -175px;
        }
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .studio_detail {
    .fav-it {
      bottom: 2.25rem;
      right: -2.375rem;
    }

    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -255px;
          max-height: 320px;
          max-width: 768px;
        }
      }
    }

    .studio-header-container {
      .studio-name {
        font-size: 48px;
        letter-spacing: -1.25px;
      }

      .studio-name-watermark {
        font-size: 16em;
      }

      .all-studios {
        font-size: 14px;
      }

      .studio-location-container {
        .studio-location {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 21px;
        }
      }
    }

    .page-sub-header {
      font-size: 18px;
      line-height: 21px;
      padding: 35px 0 8px 0;
    }

    .parking-instructions-info {
      font-size: 1.2em;
    }

    .upcoming-classes-header {
      border-bottom: none;
      display: inline-block;
      float: left;
      padding-bottom: 64px;
      padding-right: 25px;
      width: 15%;
    }

    .upcoming-classes-subcontainer {
      height: 209px;
      overflow: hidden;
      position: relative;

      .no-classes {
        border-bottom: none;
        padding-top: 70px;
        text-align: left;
      }
    }

    .upcoming-classes {
      padding: 0 20px;
    }

    .studio-content {
      border-bottom: @borderLightGray;
      padding-bottom: 32px;

      .upcoming-class {
        .bookmark {
          left: 32px;
        }

        &.session {
          border-bottom: none;
          border-left: 1px solid @gray-light;
          float: left;
          height: 185px;
          width: 33%;

          .class_info {
            padding: 35px 10px 0 32px;
          }

          .reserved-text,
          .waitlisted-text {
            font-size: 3em;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: 85px;
            width: 132px;
          }

          .waitlisted-text {
            width: 147px;
          }

          .reserve_class_button {
            .form-button {
              left: 32px;
              margin-top: 103px;
              right: initial;
            }
          }
        }
      }

      .instructor-swiper {
        padding-top: 30px;
        padding-left: 130px;

        .swiper-image {
          img {
            margin-left: 0;
            width: 100%;
          }
        }

        .swiper-slide {
          margin-right: -20px !important;
          padding-left: 0;
        }
      }
      .studio-news {
        .page-sub-header {
          border-bottom: 1px solid @gray-light;
        }

        h3 {
          min-height: 110px;
        }

        .row {
          border-top: none;
          display: inline-block;
          padding: 35px 0;
        }

        .news-content {
          float: none;
          padding-left: 0;
          width: auto;

        }
      }

      .studio-news-article-container {
        .flex-display(flex);
        .flex-wrap(wrap);
        padding: 0 54px;
        position: relative;

        .row {
          width: calc(~"50% - 27px");

          &:nth-child(even) {
            padding-left: 27px;
          }

          &:nth-child(odd) {
            padding-right: 27px;
          }

          &:nth-child(n+8) {
            display: inline-block;
          }
        }
      }

      .studio-instructors {
        .page-sub-header {
          position: absolute;
          top: 10px;
        }

        .no-instructors {
          padding-left: 15%;
          padding-top: 16px;
        }
      }
    }

    .studio-map {
      float: left;
      margin-top: 0;
      width: 50%;
    }

    .parking-instructions {
      margin: 0 54px 0 0;
      padding: 32px 0 32px 36px;
    }

    .parking-instructions-info {
      font-size: 14px;
      width: 84%;
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -210px;
        }
      }
    }
    .studio-container {
      padding-bottom: @footerHeightDesktop;
    }

    .studio-header-container {
      padding: 2.5rem 5rem;

      .studio-name-watermark {
        font-size: 15em;
      }

      .studio-location-container {
        min-width: 15.75rem;

        .studio-details {
          .studio-location {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }

    .upcoming-classes-container {
      position: relative;
      z-index: 30;
    }

    .studio-content {
      clear: both;

      .upcoming-classes-header {
        padding-left: 80px;

        .page-sub-header {
          max-width: 150px;
        }
      }
      .upcoming-class {
        &.session {
          width: 24.8%;
        }
      }

      .studio-instructors {
        .instructor-name {
          left: 5%;
        }

        .page-sub-header {
          padding-left: 80px;
        }
      }

      .studio-news {
        .page-sub-header {
          padding-left: 80px;
        }

        .studio-news-article-container {
          padding: 0 80px;
          position: relative;

          .row {
            &:nth-child(even) {
              padding-left: 40px;
            }

            &:nth-child(odd) {
              padding-right: 40px;
            }
          }
        }

        .row {
          padding: 35px 0;
          width: calc(~"50% - 40px");
        }

        h3 {
          min-height: 66px;
        }
      }
    }

    .upcoming-classes-header {
      padding-right: 0;
    }

    .studio-details {
      margin-top: 0;
    }

    .studio-location {
      font-size: 1.6em;
    }

    .parking-instructions {
      margin: 0 80px 0 0;
      padding: 32px 0 32px 48px;
    }

    .parking-instructions-info {
      width: 88%;
    }

    .studio-map #studio-map-canvas {
      height: 280px !important;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: -100px;
        }
      }
    }

    .studio-header-container {
      padding: 2.5rem 7.1875rem;

      .studio-name-watermark {
        font-size: 19em;
      }

      .studio-name {
        font-size: 76px;
      }

      .studio-location-container {
        min-width: 17.625rem;

        .studio-details {
          .studio-location {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }

    .parking-instructions {
      margin: 0 115px 0 0;
      padding: 40px 0 40px 54px;
    }

    .studio-details {
      font-size: 1.4em;
    }

    .parking-instructions-info {
      font-size: 1.4em;
    }

    .fav-it {
      bottom: 4rem;
    }

    .studio-content {
      padding-bottom: 0;

      .upcoming-classes-header {
        padding-left: 115px;
        width: 160px;
      }

      .upcoming-class {
        &.session {
          width: 193px;
        }
      }

      .studio-instructors {
        .page-sub-header {
          padding-left: 115px;
        }

        .no-instructors {
          padding-left: 200px;
        }
      }

      .instructor-swiper {
        .swiper-image {
          img {
            height: 280px;
            width: auto;
          }
        }
      }

      .instructor-swiper-next,
      .instructor-swiper-prev {
        display: block;

        &.swiper-button-disabled {
          display: none;
        }
      }
      .studio-news {
        .page-sub-header {
          padding-left: 115px;
        }

        .studio-news-article-container {
          padding: 0 115px;
          position: relative;

          .row {

            &:nth-child(even) {
              padding-left: 0;
              padding-right: 0;
            }

            &:nth-child(odd) {
              padding-left: 0;
              padding-right: 0;
            }

            &:nth-child(n) {
              padding-left: 0;
              padding-right: 54px;
              width: 33.33%; //Fallback
              width: calc(~"33.33% - 54px");
            }

            &:nth-child(3n+3) {
              padding-left: 0;
              padding-right:0;
            }

            &:nth-child(n+11) {
              display: none;
            }
          }

          p {
            width: 90%;
          }
        }
      }
    }

    .studio-map #studio-map-canvas {
      height: 240px !important;
    }
  }
}

// css width (px) >= 2000
@media only screen and (min-width: 2000px) {
  .studio_detail {
    .swiper-container {
      .swiper-slide {
        img {
          margin-left: 0;
        }
      }
    }
  }
}
