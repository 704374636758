/*
SuperSoul Page Styles
*/

/*
SUPERSOUL SHARED STYLES
Styles for: supersoul/supersoul.twig.html
*/

.supersoul-get-started-button {
  .font-family(heavy);
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2.5px;
  line-height: 0.75rem;
  padding: 19px 40px;
  text-transform: uppercase;
  width: 100%;

  .respond-to(@bpTablet, {
    width: initial;
  });
}
