.landing__info {
  .flex-direction(column);
  .flex-display(flex);

  .respond-to(@bpTablet, {
    .flex-direction(row);
    
    &--flipped {
      .flex-direction(row-reverse);
    }
  });
  
  &-image-container {
    .flex(0 0 auto);
    background-color: @black;
    background-position: center;
    background-size: cover;
    min-height: 360px;

    .respond-to(@bpTablet, {
      .flex(0 0 50%);
      height: initial;
    });
    
    .respond-to(@bpDesktopLarge, {
      min-height: 720px;
    });
  }

  &-content {
    .flex(0 1 auto);
    color: @gray-87;
    padding: 3.4rem 4.45%;

    .respond-to(@bpTablet, {
      line-height: 1.8125rem;
      padding: 5.25rem 4.56% 4.8rem;
    });

    .respond-to(@bpDesktopLarge, {
      letter-spacing: 0.45px;
      line-height: 1.5rem;
      padding: 15.875rem 6.39% 15.81rem;
    });

    h2 {
      .font-family(medium);
      font-size: 1.5rem;

      .respond-to(@bpDesktopLarge, {
        font-size: 2rem;
      });
    }

    p {
      .font-family(roman);
      font-size: 1rem;
      line-height: 1.5rem;
      
      .respond-to(@bpTablet, {
        padding-top: 0.44%;
      });

      .respond-to(@bpDesktopLarge, {
        padding-top: 0.92%;
      });
    }

    #view-now-button {
      .font-family(heavy);
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 0.75rem;
      padding: 0;
    }
  }
}
