/*doc
---
title: SC Text Styles
name: sc_text
category: text
---

SoulCycle text styles are mainly for header treatments, but can be applied where needed

```html_example
<div class="message-empty sc-text-watermark">Your bag is empty!</div>

*/

.sc-text-watermark {
	.font-family(light);
	color: @gray-light;
	font-size: 4.5rem;
	text-align: center;
}

.gray-caps {
	color: @gray-light;
	.font-family(bold);
	text-transform: uppercase;

	.alt {
		color: @scCoolGray8C;
	}

	h2& {
		font-size: 4.75em;
		height: 0.72em;
	}

	h3& {
		font-size: 2.25em;
	}
}
