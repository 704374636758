/*doc
---
title: Input Styles
name: inputs
category: inputs
---

SoulCycle styles for forms

*/

input:-ms-input-placeholder,
input::-moz-placeholder,
input:-moz-placeholder,
input::-webkit-input-placeholder {
  text-transform: lowercase;
}

input,
select,
textarea,
.form-terms {
  border: @borderLightGray;
  border-radius: 0.28em;
  box-sizing: border-box;
  display: inline-block;
  font-family: HelveticaNeueLTStd-Roman,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 0.875rem;
  margin: 0 0.75em 0.58em 0;
  padding: 1.14em;
}

input[type=tel],
input[type=text],
input[type=password],
input[type=email],
input[type=submit],
textarea {
  -webkit-appearance: none;
  background-color: @gray-main;
}

input[type=tel],
input[type=text],
input[type=password],
input[type=email],
textarea {
  &:focus {
  background-color: #fff !important;
  }
}

textarea {
  min-height: 150px;
  resize: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/assets/images/carat-yellow.png);
  background-color: white;
  background-repeat: no-repeat;
  background-size: 0.75em 0.56em;
  background-position: calc(~"100% - 0.75em") center;
  max-width: 100%;
  min-width: 5em;
  text-indent: 1px;
  text-overflow: '';
}

form {
  fieldset {
    padding: 0px;
    border-width: 0px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-before: 0em;
    -webkit-padding-start: 0em;
    -webkit-padding-end: 0em;
    -webkit-padding-after: 0em;
  }

  .form-error {
    float: left;
    clear: both;
    color: @error;
    font-size: 14px;
    padding-top: 0;
    padding-left: 11px;
  }

  & input[type="checkbox"].field-error {
    &.control {
      outline: 1px solid @error !important;
    }
  }

  input.field-error,
  select.field-error {
    border: 1px solid @error !important;
  }

  .field-error {
    color: @error;

    &.control {
      border: 1px solid @error !important;
    }

    input {
      float: left;
      margin-left: 0px !important;
      border: 1px solid @error !important;
    }

    &:first-child {
      margin-left: 0px;
    }

    .error-message {
      .font-family(medium);
      clear: both;
      float: left;
      font-size: 12px;
      line-height: 1.2;
      position: relative;
      top: -6px;
    }

    &.dropkick,
    &.file {
      .error-message {
        top: 6px;
      }
    }
  }

  .field-row, .section-row {
    .field-error {
      float: left;
    }
  }
}

.label {
  font-size: 1.1em;
  padding: 14px 0;
}

ul.form-error {
  color: @error;
  display: inline-block;
  list-style: none;
  margin: 2em 0 0;
  padding: 0;
  width: 100%;

  li {
    margin: 0 0 1em;

    &:last-child {
      margin-top: 0;
    }
  }
}

.checkbox-field {
  .clearfix();

  input[type=checkbox] {
    .box-sizing(border-box);
    background: @gray-light;
    border: 1px solid @gray-medium;
    border-radius: 0.3em;
    float: left;
    height: 1.5em;
    margin-right: 0.625em;
    margin-top: -0.1em;
    padding: 0;
    width: 1.5em;
  }

  span {
    font-size: 1em;
  }

  a {
    color: @gray-darker;
    font-weight: bold;
  }
}

.form-terms {
  background-color: #fff;
  display: block;
  height: 200px;
  line-height: 16px;
  margin: 0 auto 20px;
  max-width: 375px;
  overflow-y: scroll;
  padding: 10px;
  text-align: left;

  p {
    padding: 0;
    margin: 0;
  }
}

.create-account-button.disabled,
.submit.step-four.disabled {
  background-color: @gray-light;

  &:hover {
    background: @gray-light;
    color: #000;
  }
}

// NEW input styles
.global__input-wrapper {
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

    &.has-field-error,
    & .has-field-error {
      .global__input,
      .global__input:focus {
        border-bottom: 0.0625rem solid @red-error;
      }
    }

  .global__input {
    .font-family(light);
    border: none;  /* This need to be first to remove default border style */
    background-color: @transparent-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0.0625rem solid @gray-darker;
    font-size: 1.563rem;
    margin: 0.625rem 0 0.9375rem 0;
    padding: 0.2rem 0 0;
    position: relative;
    width: 100%;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
      // this is a hack for autofill...since no event is fired
      // we're monitoring the transitionend event to indicate
      // an autofill has been performed.
      transition: background-color 0.1s ease-in-out 0s;
    }

    &:focus {
      outline: none;
      border-bottom: 0.25rem solid @scYellowC;

      &+ .global__input-label {
        .font-family(medium);
        color: @gray-darker;
        font-size: 0.75rem;
        left: 0;
        top: -0.5625rem;
        z-index: 1;
      }
    }

    &+ .global__input-label {
      &.show {
        .font-family(medium);
        color: @gray-darker;
        font-size: 0.75rem;
        left: 0;
        top: -0.5625rem;
        z-index: 1;
      }
    }
  }

  // NETTE forms classes
  .intl-tel-input {
  width: 100%;

    .global__input {
      padding-left: 3rem;
      z-index: 0;

      &::placeholder {
        color: @gray-lighter;
      }
    }

    .flag-container {
      margin-top: 0;
      top: 0;
    }

    &+ .global__input-label {
      visibility: hidden;
      z-index: -1;

      &.show {
        color: @gray-darker;
        font-size: 0.75rem;
        left: 0;
        top: 0.9rem;
        visibility: visible;
        z-index: 1;

        .respond-to(@bpDesktopSmall, {
          top: -0.6rem;
        });
      }
    }
  }
}

.global__input-label {
  .transition(all, 0.1s, linear);
  color: @gray-lighter;
  cursor: auto;
  font-size: 1.625rem;
  left: 0;
  letter-spacing: 0;
  pointer-events: none;
  position: absolute;
  text-align: left;
  text-transform: capitalize;
  top: 1rem;
  z-index: 1;
}

.global__form__field-error {
  color: @red-error;
  font-size: 0.75rem;
}

.global__input__text {
  clear: both;
}
