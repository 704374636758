.happiness-challenge {

  .soul-container {
    .respond-to(@bpDesktopSmall, {
      background-color: #FFE200;
    });
  }

  .main-container {
    overflow: visible;
  }

  .global-footer, .footer-container {
    overflow: visible;
    z-index: 1;

    .respond-to(@bpDesktopSmall, {
      z-index: 2;
    });
  }

  .confirmation {
    background-color: #FF3232;

    .confirmation-content-container {
      .confirmation-message {
        .message {
          color: @scWhiteC;
          font-size: 1.125rem;
        }
      }
    }
  }
}

.happiness-challenge-offer-container {
  .flex-display(flex);
  .flex-flow(column);
  .justify-content(center);
  background-color: #FFE200;
  color: @scWhiteC;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: rgba(0,0,0,0.12);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.7);
  }
}

.happiness-challenge-offer-section {
  padding: 2.5rem 1.875rem 1.875rem;
  text-align: center;

  .respond-to(@bpMobileLarge, {
    min-height: 50.875rem;
    padding: 2.5rem 1.875rem 1.875rem;
  });

  .respond-to(@bpTablet, {
    min-height: 37.375rem;
    padding: 3.5rem 0.3125rem 1.875rem;
  });

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    .flex-direction(row);
    .flex-display();
    min-height: 28.5rem;
    margin-bottom: 3.125rem;
    padding: 6.875rem 0.3125rem 1.875rem;
  });

  .respond-to(@bpDesktopLarge, {
    .flex(1);
    .flex-direction(row);
    .flex-display();
    min-height: 33.5rem;
    padding: 6.875rem 0.3125rem 1.875rem;
  });

}

.happiness-challenge-offer-logo {
  margin-bottom: 2.1875rem;

  .respond-to(@bpMobileLarge, {
    margin-bottom: 2.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    margin: 0 3.75rem 0 0;
  });

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    margin: 0;
  });

  img {
    height: 12.5rem;

    .respond-to(@bpMobileLarge, {
      height: 20.625rem;
    });

    .respond-to(@bpDesktopSmall, {
      height: 24rem;
    });
    .respond-to(@bpDesktopLarge, {
      height: 29rem;
    });
  }
}

.happiness-challenge-offer-content {

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    margin: 0 3.75rem 0 0;
    text-align: left;
  });

  .happiness-challenge-offer-content-inner {
    .respond-to(@bpDesktopLarge, {
      max-width: 34.6875rem;
    });
  }

  h1 {
    .font-family(bold);
    color: @black;
    font-size: 1.875rem;
    margin-bottom: 1rem;

    .respond-to(@bpMobileLarge, {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    });

    .respond-to(@bpDesktopLarge, {
      font-size: 3.25rem;
      margin-bottom: 1.5rem;
    });
  }

  h3 {
    .font-family(bold);
    color: #53565A;
    font-size: 1rem;
    margin-bottom: 1.5rem;

    .respond-to(@bpMobileLarge, {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    });

    .respond-to(@bpDesktopSmall, {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    });

    .respond-to(@bpDesktopLarge, {
      font-size: 1.6875rem;
      margin-bottom: 2rem;
    });
  }
}

.happiness-challenge__region-dropdown {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  position: relative;
  width: 18.75rem;

  .respond-to(@bpMobileLarge, {
    width: 19.6875rem;
  });

  .respond-to(@bpDesktopSmall, {
    margin-left: 0;
    text-align: center;
  });
}

.happiness-challenge__region-dropdown__button {
  .border-radius(0.3125rem);
  .font-family(bold);
  background-color: @black;
  border: 0.062rem solid @black;
  color: @scWhiteC;
  cursor: pointer;
  display: none;
  font-size: 1.125rem;
  padding: 1rem 0;
  width: 100%;

  .respond-to(@bpTablet, {
    display: inline;
  });

  .happiness-challenge__region-dropdown__button__white-arrow {
    display: inherit;
    margin-left: 2rem;
  }
  .happiness-challenge__region-dropdown__button__black-arrow {
    display: none;
    margin-left: 2rem;
  }

  &.region-selected {
    background-color: @scWhiteC;
    border: 0.062rem solid @black;
    color: @black;

    .happiness-challenge__region-dropdown__button__white-arrow {
      display: none;
    }
    .happiness-challenge__region-dropdown__button__black-arrow {
      display: inherit;
    }
  }
}

.happiness-challenge__region-dropdown-native {
  .border-radius(0.3125rem);
  .font-family(bold);
  background-color: @black;
  background-image: url(/assets/images/filled-arrow-down-white.svg);
  background-position: 95% center;
  border: 0.062rem solid @black;
  color: @scWhiteC;
  cursor: pointer;
  display: block;
  font-size: 1.125rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
  text-align-last: center;
  width: 100%;

  option {
    background-color: @scWhiteC;
    color: @black;
  }

  &.region-selected {
    background-color: @scWhiteC;
    background-image: url(/assets/images/filled-arrow-down-black.svg);
    background-position: 85% center;
    border: 0.062rem solid @black;
    color: @black;
  }

  .respond-to(@bpTablet, {
    display: none;
  });
}

.happiness-challenge-form__selected-region-button {
  .border-radius(0.3125rem);
  .font-family(bold);
  background-color: @black;
  border: 0.062rem solid @black;
  color: @scWhiteC;
  cursor: pointer;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  width: 18.75rem;

  .respond-to(@bpMobileLarge, {
    width: 19.6875rem;
  });
}

.happiness-challenge__region-dropdown-list {
  .box-sizing(border-box);
  background-color: @gray-main;
  border-bottom: 0.062rem solid @black;
  border-left: 0.062rem solid @black;
  border-right: 0.062rem solid @black;
  display: none;
  max-height: 16.75rem;
  overflow-y: scroll;
  position: absolute;
  top: 98%;
  width: 100%;
  z-index: 1;

  .region-select-row {
    padding: 0;
  }
}

.happiness-challenge-form-container {
  margin: 0 auto;
  max-width: 33.25rem;

  .respond-to(@bpDesktopSmall, {
    margin-left: 0;
  });
}
.happiness-challenge__disclaimer{
  .font-family(bold);
  color: @black;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

.happiness-challenge__terms-and-conditions__container {
  border: 0.0625rem solid @black;
  margin: 0 auto 0.625rem;
  padding: 1rem 0;
}

.happiness-challenge__terms-and-conditions {
  background-color: transparent;
  color: @black;
  display: block;
  height: 6.375rem;
  line-height: 1rem;
  margin: 0;
  overflow-y: scroll;
  padding: 0 1rem;
  text-align: justify;

  .respond-to(@bpMobileLarge, {
    height: 5.5rem;
  });

  p {
    font-size: 0.875rem;
    margin: 0;
  }
}
