.campaign-landing-main {
    color: @gray-59;
    min-width: 320px;

    .respond-to(@bpDesktopSmall, {
        padding-bottom: @footerHeightDesktop;
    });

    .register-btn {
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .campaign-section-container {
        .respond-to(@bpMobileLarge, {
            margin: 0 auto;
            width: 536px;
        });

        .respond-to(@bpTablet, {
            width: 721px;
        });

        .respond-to(@bpDesktopSmall, {
            width: 961px;
        });

        .respond-to(@bpDesktopLarge, {
            width: 1280px;
        });
    }

    .campaign-landing-hero {
        background: @black;
        color: @scWhiteC;
        height: 498px;
        position: relative;

        .respond-to(@bpMobileSmall, {
            height: 420px;
        });

        .respond-to(@bpTablet, {
            height: 460px;
        });

        .respond-to(@bpDesktopSmall, {
            height: calc(~"100vh - @{bpTabletGutter} - @{footerHeightDesktop}");
            min-height: 600px;
        });

        .background {
            background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_new.jpg) top right / 100% auto no-repeat;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;

            .respond-to(@bpMobileLarge, {
                background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_new.jpg) / 100% auto no-repeat;
                background-position: top right 0px;
                background-size: cover;
                left: auto;
                width: 100%;
            });
            .respond-to(@bpMobileSmall, {
                background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_mobile_new.jpg) top / 100% auto no-repeat;
                max-width: 550px;
            });
            .respond-to(@bpTablet, {
                background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_mobile_new.jpg) center / 100% auto no-repeat;
                max-width: 550px;
            });

            .respond-to(@bpDesktopSmall, {
                background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_new.jpg) top right / 100% auto no-repeat;
                max-width: 700px;
                top: 9%;
            });

            .respond-to(@bpDesktopLarge, {
                background: @black url(/assets/images/campaign/warrior_week/WW_landing_page_header_new.jpg) top right / 100% auto no-repeat;
                max-width: 50%;
                top: 0;
            });

            &:before {
                background: linear-gradient(to bottom, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 50%);
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                .respond-to(@bpMobileLarge, {
                    background: linear-gradient(to right, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 30%);
                });
            }

            &:after {
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                .respond-to(@bpMobileLarge, {
                    background: linear-gradient(to top, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 40%);
                });
            }
        }

        .campaign-landing-hero-container {
            padding: 60px 16px 0 16px;
            position: relative;
            z-index: 1;
            max-width: 400px;

            .respond-to(@bpMobileLarge, {
                width: 55%;
                padding: 64px 0 0 30px;
            });

            .respond-to(@bpTablet, {
                padding-left: 24px;
                padding-top: 80px;
            });

            .respond-to(@bpDesktopSmall, {
                padding-left: 32px;
            });

            .respond-to(@bpDesktopLarge, {
                padding-left: 80px;
                width: 50%;
                max-width: 700px;
            });

            .campaign-logo {
                background: url(/assets/images/campaign/warrior_week/WW_white_logo_stacked_new.png) left / contain no-repeat;
                height: 90px;
                margin-bottom: 32px;

                .respond-to(@bpMobileLarge, {
                    margin-top: 15px;
                    height: 80px;
                    margin-bottom: 40px;
                });

                .respond-to(@bpMobileSmall, @bpMobileLarge, {
                    height: 60px;
                });

                .respond-to(@bpDesktopSmall, {
                    height: 224px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                });

                .respond-to(@bpDesktopMedium, {
                    margin-bottom: 0;
                    margin-top: 0;
                });

                .respond-to(@bpDesktopLarge, {
                    margin-bottom: 45px;
                    margin-top: 27px;
                });
            }
            .subtitle {
                .font-family(light);
                font-size: 18px;
                margin-bottom: 16px;

                .respond-to(@bpMobileSmall, {
                    font-size: 14px;
                });

                .respond-to(@bpTablet, {
                    margin-bottom: 8px;
                });

                .respond-to(@bpDesktopSmall, {
                    font-size: 23px;
                    margin-bottom: 0;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 38px;
                    margin-bottom: 0;
                });
            }
            .dates {
                .font-family(light);
                font-size: 24px;
                margin-bottom: 35px;
                letter-spacing: 1px;

                .respond-to(@bpDesktopSmall, {
                  font-size: 23px;
                  margin-bottom: 35px;
                  letter-spacing: 1px;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 38px;
                });
            }
            .description {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 24px;

                .respond-to(@bpMobileSmall, {
                    font-size: 18px;
                    letter-spacing: 1px;
                });

                .respond-to(@bpMobileLarge, {
                    font-size: 20px;
                });

                .respond-to(@bpDesktopSmall, {
                  .font-family(roman);
                  font-size: 23px;
                  line-height: 38px;
                  margin-bottom: 24px;
                  text-align: justify;
                  letter-spacing: normal;
                  width: 125%;
                });

                .respond-to(@bpDesktopLarge, {
                    width: 140%;
                    font-size: 34px;
                });
            }

        }

        .more-info {
            color: @scWhiteC;
            display: none;
            z-index: 2;

            .respond-to(@bpDesktopSmall, {
                bottom: 105px;
                display: block;
                position: absolute;
                text-align: center;
                width: 100%;
            });

            .more-info-item {
                border-top: 1px solid @white-70;
                display: inline-block;
                padding-top: 16px;
                width: 190px;

                &:not(:last-child) {
                    margin-right: 15px;
                }

                a {
                    .font-family(heavy);
                    color: @scWhiteC;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 2.5px;
                    text-transform: uppercase;
                    &:hover {
                        color: @white-70;
                    }
                    &:active {
                        color: @white-30;
                    }

                    .respond-to(@bpDesktopLarge, {

                    });
                }
            }
        }

        .arrow-down {
            .transition(all, 100ms);
            .translate(-50%);
            background: url(/assets/images/campaign/soultogether-landing-arrow.svg) top left / contain no-repeat;
            bottom: 56px;
            display: none;
            height: 32px;
            left: 50%;
            position: absolute;
            width: 32px;
            z-index: 2;

            .respond-to(@bpDesktopSmall, {

                display: block;
            });

            &:hover {
                .translate(-50%, 2px);
                height: 36px;
                width: 36px;
            }
        }


    }
    .campaign-landing-how-it-works {
        padding: 56px 16px 64px 16px;

        .respond-to(@bpMobileLarge, {
            padding: 64px 32px;
        });
        .respond-to(@bpMobileSmall, {
            padding: 10px 32px;
        });

        h2 {
            .font-family(heavy);
            color: @gray-darker;
            font-size: 24px;
            margin-bottom: 16px;
            text-align: center;
            width: 100%;

            .respond-to(@bpTablet, {
                margin-bottom: 24px;
            });

            &:after {
                background: @scYellowC;
                display: block;
                content: '';
                height: 6px;
                margin: 16px auto 0 auto;
                width: 75px;

                .respond-to(@bpMobileLarge, {
                    width: 150px;
                });

                .respond-to(@bpTablet, {
                    margin-top: 24px;
                });
            }
        }
        .description {
            font-size: 16px;
            line-height: 20px;
            margin: 0 auto 32px auto;
            text-align: center;

            .respond-to(@bpMobileLarge, {
                margin-bottom: 24px;
            });

            .respond-to(@bpTablet, {
                margin-bottom: 32px;
                max-width: 615px;
            });

            .respond-to(@bpDesktopSmall, {
                font-size: 20px;
                line-height: 24px;
                max-width: 615px;
            });

            > span {
                white-space: nowrap;
            }
        }
        .how-it-works-steps {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto 46px auto;
            max-width: 370px;
            text-align: center;

            .respond-to(@bpMobileLarge, {
                max-width: none;
                margin: 0 -10px 46px -10px;
                margin-bottom: 48px;
                white-space: nowrap;
            });

            .step {
                position: relative;
                text-align: center;
                vertical-align: top;
                white-space: initial;
                margin: 0 10px;
                width: 100%;

                .respond-to(@bpMobileLarge, {
                    .box-shadow(0 3px 8px 0 @gray-6);
                    border: solid .5px @gray-12;
                    box-sizing: border-box;
                    flex: 1 1 0px;
                    padding: 0 8px;
                });

                &:first-child {
                    margin-bottom: 24px;

                    .respond-to(@bpMobileLarge, {
                        margin-bottom: 0;
                    });
                }

                p {
                    font-size: 1rem;
                    margin-left: 20px;
                    margin-right: 20px;
                    span {
                        display: block;
                    }
                }


                .sign-up-step-image {
                    background: url(/assets/images/campaign/warrior_week/WW_icon_1_new.png) center no-repeat;
                    background-size: 90%;
                    height: 89px;
                    margin: 0 auto;
                    margin-bottom: 8px;
                    max-width: 270px;
                    padding-top: 15%;

                    .respond-to(@bpTablet, {
                        height: 160px;
                    });

                    .respond-to(@bpDesktopSmall, {
                        height: 160px;
                    });

                    .respond-to(@bpDesktopLarge, {
                        background-size: 90%;
                        height: 160px;
                    });
                }

                &.ride-step .sign-up-step-image {
                    background-image: url(/assets/images/campaign/warrior_week/WW_icon_2.png);
                    background-size: 50%;


                    .respond-to(@bpTablet, {
                        max-width: 400px;
                    });
                    .respond-to(@bpMobileSmall, {
                        max-width: 270px;
                    });

                }

                &.track-step .sign-up-step-image {
                    background-image: url(/assets/images/campaign/warrior_week/WW_icon_3_uni.png);
                    background-size: 40%;
                    padding-top: 15%;

                    .respond-to(@bpMobileLarge, {
                        background-size: 55%;
                    });
                    .respond-to(@bpMobileSmall, {
                        background-size: 40%;
                    });

                    .respond-to(@bpTablet, {
                        background-size: 60%;
                    });

                    .respond-to(@bpDesktopSmall, {
                        background-size: 65%;
                    });

                    .respond-to(@bpDesktopLarge, {
                        background-size: 60%;
                    });
                }

                &.prizes-step .sign-up-step-image {
                    background-image: url(/assets/images/campaign/step4.svg);
                    background-size: 17%;

                    .respond-to(@bpMobileLarge, {
                        background-size: 27%;
                    });

                    .respond-to(@bpTablet, {
                        background-size: 32%;
                    });

                    .respond-to(@bpDesktopSmall, {
                        background-size: 28%;
                    });

                    .respond-to(@bpDesktopLarge, {
                        background-size: 32%;
                    });
                }
                .step-description {
                    display: block;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;

                    .respond-to(@bpTablet, {
                        font-size: 14px;
                        line-height: 18px;
                    });

                    .respond-to(@bpDesktopSmall, {
                        font-size: 16px;
                        line-height: 24px;
                    });

                    .respond-to(@bpDesktopLarge, {
                        font-size: 20px;
                    });

                    > span {
                        white-space: nowrap;
                    }
                }
            }
        }
        .action-buttons {
            margin-bottom: 24px;
            text-align: center;
        }
        .campaign-share-container {
            font-size: 18px;
            margin-top: 30px;
            text-align: center;

            .campaign-share-message {
                .font-family(heavy);
                color: @gray-darker;
                font-size: 12px;
                letter-spacing: 2.3px;
                margin: 10px auto auto;
                text-transform: uppercase;
            }
            .campaign-share-links {
                display: inline-block;
                margin-top: 24px;

                a {
                    border: none;
                    display: inline-block;
                    height: 21px;
                    width: 40px;
                    &.campaign-share-icon {
                        background: no-repeat 50%;
                    }
                    &.campaign-share-icon-facebook {
                        background-image: url('/assets/images/campaign/share_icons/facebook-icon.svg');
                        background-size: 20px;
                    }
                    &.campaign-share-icon-twitter {
                        background-image: url('/assets/images/campaign/share_icons/twitter-icon.svg');
                        background-size: 20px;
                    }
                    &.campaign-share-icon-email {
                        background-image: url('/assets/images/campaign/share_icons/email-icon.svg');
                        background-size: 20px;
                    }
                    &.campaign-share-icon-link {
                        background-image: url('/assets/images/campaign/share_icons/link-icon.svg');
                        background-size: 20px;
                    }
                }
            }
        }
    }

    .campaign-landing-about {
        background: @white-blue-bg;

        .respond-to(@bpMobileLarge, {
            padding: 80px 0;
        });

        .respond-to(@bpDesktopSmall, {
            padding: 48px 0;
        });

        .respond-to(@bpDesktopLarge, {
            padding: 0;
        });

        .campaign-section-container {
            .respond-to(@bpMobileLarge, {
                .flex-display();
                .flex-wrap(nowrap);
            });

            .respond-to(@bpTablet, {
                .align-items(center)
            });

            .respond-to(@bpDesktopLarge, {
                width: 100%;
            });
        }

        .media {
            background: url(/assets/images/campaign/tiu-landing-about.jpg) 80% center / cover no-repeat;
            height: 375px;

            .respond-to(@bpMobileLarge, {
                .flex(none);
                height: 192px;
                width: 152px;
            });

            .respond-to(@bpTablet, {
                height: 347px;
                width: 328px;
            });

            .respond-to(@bpDesktopSmall, {
                height: 468px;
                width: 386px;
            });

            .respond-to(@bpDesktopLarge, {
                .flex(1 50%);
                height: 560px;
                margin: 80px 80px 80px 0;
            });
        }

        .description {
            box-sizing: border-box;
            padding: 48px 16px;

            .respond-to(@bpMobileLarge, {
                padding: 8px 0 0 16px;
            });

            .respond-to(@bpTablet, {
                padding: 0 0 0 24px;
            });

            .respond-to(@bpDesktopLarge, {
                .flex(1 50%);
            });

            .description-container {
                .respond-to(@bpDesktopLarge, {
                    margin: 0 auto;
                    max-width: 520px;
                });

                h2 {
                    .font-family(heavy);
                    color: @gray-darker;
                    font-size: 24px;
                    margin-bottom: 15px;
                    width: 100%;

                    .respond-to(@bpDesktopSmall, {
                        font-size: 36px;
                    });
                }
                p {
                    color: @gray-59;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0 0 24px 0;
                    padding: 0;
                    text-align: left;

                    .respond-to(@bpDesktopSmall, {
                        font-size: 20px;
                        line-height: 24px;
                    });

                    &.campaign-landing-about-disclaimer {
                        font-size: 14px;
                        line-height: normal;
                        margin-top: 24px;

                        a {
                            color: white;
                            cursor: pointer;
                            text-decoration: underline;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        &.campaign-landing-about-cont {
            background-color: @gray-darker;

            .media {
                background: url(/assets/images/campaign/tiu-landing-about.jpg) center / cover no-repeat;

                .respond-to(@bpMobileLarge, {
                    .flex-order(2);
                });
            }

            .description {
                .respond-to(@bpMobileLarge, {
                    .flex-order(1);
                    padding: 8px 16px 0 0;
                });

                .respond-to(@bpTablet, {
                    padding: 0 24px 0 0;
                });

                .description-container {
                    p,
                    h2 {
                        color: @scWhiteC;

                        .respond-to(@bpMobileLarge, {
                            > span {
                                white-space: nowrap;
                            }
                        });
                    }
                }
            }
        }
    }

    .campaign-landing-prizes {
        padding: 64px 16px 32px 16px;

        .respond-to(@bpMobileLarge, {
            padding: 60px 32px 0px 32px;
        });
        .respond-to(@bpTablet, {
            padding: 60px 32px 0px 32px;
        });

        .respond-to(@bpMobileSmall, {
            padding: 60px 32px 0px 32px;
        });
        .respond-to(@bpDesktopLarge, {
            padding: 80px 0 48px;
        });

        .campaign-section-flex-container {
            .respond-to(@bpMobileLarge, {
                .flex-display();
                .flex-wrap(none);
                .align-items(flex-start);
            });

            > div {
                .respond-to(@bpMobileLarge, {
                   .flex(1 50%);
                });
            }
        }
        .description {

            .respond-to(@bpMobileLarge, {
                box-sizing: border-box;
                padding: 40px 0 0 0;
                margin-right: 8px;
            });

            .respond-to(@bpTablet, {
                margin-right: 16px;
            });

            h2 {
                .font-family(heavy);
                color: @gray-darker;
                font-size: 24px;
                margin-bottom: 16px;
                width: 100%;

                .respond-to(@bpDesktopSmall, {
                    font-size: 36px;
                });
            }

            p {
                color: @gray-59;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;

                .respond-to(@bpDesktopSmall, {
                    font-size: 20px;
                });

                .respond-to(@bpDesktopLarge, {
                    font-size: 24px;
                    line-height: 30px;
                });
            }

            .register-btn {
                margin-bottom: 24px;
            }
        }
        .list {
            .box-shadow(0 3px 8px 0 @gray-6);
            border: 1px solid @gray-12;
            box-sizing: border-box;
            padding: 20px 24px;
            margin-top: 40px;
            text-align: center;

            .respond-to(@bpMobileLarge, {
                margin: 0 0 0 8px;
                padding: 24px 16px;
            });

            .respond-to(@bpTablet, {
                margin-left: 16px;
                padding: 40px 32px;
            });

            .respond-to(@bpDesktopSmall, {
                padding: 40px 48px;
            });

            .respond-to(@bpDesktopLarge, {
                padding: 40px 56px;
            });

            .item {
                .align-items(center);
                .flex-display();
                .flex-wrap(nowrap);
                border-bottom: 1px solid @gray-50;
                margin: 0;
                padding: 25px 0;

                .amount {
                    .flex(none);
                    .font-family(heavy);
                    color: @gray-darker;
                    font-size: 24px;
                    text-align: left;
                    width: 118px;

                    .respond-to(@bpMobileLarge, {
                        font-size: 20px;
                        width: 100px;
                    });

                    .respond-to(@bpDesktopSmall, {
                        font-size: 30px;
                        width: 140px;
                    });
                }
                .prize {
                    .flex-grow(33);
                    font-size: 14px;
                    line-height: 16px;
                    text-align: left;
                    ul {
                        padding-left: 20px;
                    }
                  .respond-to(@bpMobileLarge, {
                        font-size: 12px;
                        line-height: 15px;
                    });

                    .respond-to(@bpDesktopSmall, {
                        font-size: 18px;
                        line-height: 24px;
                    });

                    > span {
                        .respond-to(@bpMobileLarge, {
                            white-space: nowrap;
                        });
                    }
                    .prize-subtext {
                        font-size: 12px;
                        font-style: italic;
                        margin-top: 8px;
                    }
                }
            }

            .disclaimer {
                .font-family(bold);
                color: @gray-darker;
                font-size: 12px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-top: 32px;
                width: 100%;

                .respond-to(@bpTablet, {
                   font-size: 14px;
                });

                img {
                    display: block;
                    margin: 0 auto 20px;
                    max-width: 200px;
                }
            }
        }

        .golden-disclaimer {
            font-size: 14px;
            line-height: 16px;
            margin-top: 48px;
            text-align: center;

            a {
              color: inherit;
              text-decoration: underline;
            }
        }
    }
    .campaign-landing-footer {
        background: url(/assets/images/campaign/warrior_week/WW_landing_footer_desktop_new.jpg) center 32% / contain no-repeat;
        padding: 60px 0;
        position: relative;

        .respond-to(@bpMobileLarge, {
            padding: 56px 0;
        });

        .respond-to(@bpTablet, {
            padding: 72px 0;
            background-size: cover;
        });

        .respond-to(@bpDesktopSmall, {
            padding: 100px 0;
        });

        .respond-to(@bpDesktopLarge, {
            padding: 146px 0;
        });

        &:before {
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .media {
            background: url(/assets/images/campaign/warrior_week/WW_white_logo_horizontal.png) center / contain no-repeat;
            height: 14px;
            margin: 0 auto 16px auto;
            position: relative;
            z-index: 2;

            .respond-to(@bpMobileLarge, {
                height: 24px;
            });

            .respond-to(@bpTablet, {
                height: 30px;
                margin-bottom: 24px;
            });

            .respond-to(@bpDesktopSmall, {
                height: 40px;
            });

            .respond-to(@bpDesktopLarge, {
                height: 56px;
                margin-bottom: 32px;
            });
        }
        .subtitle {
            .font-family(heavy);
            color: @scWhiteC;
            font-size: 11px;
            position: relative;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;

            .respond-to(@bpMobileLarge, {
                font-size: 12px;
            });

            .respond-to(@bpTablet, {
                font-size: 14px;
                letter-spacing: 2.7px;
            });
        }
    }
}

.campaign-register-main {
  min-width: 320px;
  text-align: center;

  .campaign-register-section-container {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 16px;
    text-align: left;
    width: 100%;

    .respond-to(@bpMobileLarge, {
      padding: 0 24px;
    });

    .respond-to(@bpTablet, {
      margin: 0 auto;
      padding: 0;
      width: auto;
    });

    section {
      .respond-to(@bpTablet, {
        width: 720px;
      });

      .respond-to(@bpDesktopSmall, {
        width: 834px;
      });

      .respond-to(@bpDesktopLarge, {
        width: 864px;
      });
    }
  }

  .campaign-register-header {
    padding-top: 40px;
    margin-bottom: 40px;

    .respond-to(@bpMobileLarge, {
      padding-top: 48px;
    });

    img {
      height: 80px;
      margin-bottom: 24px;
    }

    h3 {
      .font-family(light);
      font-size: 18px;
      margin-bottom: 8px;
    }

    .subtitle {
      .font-family(heavy);
      font-size: 14px;
      letter-spacing: 2.5px;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }

  .campaign-register-form-section {
    margin-bottom: 64px;

    h3 {
      .font-family(heavy);
      font-size: 24px;
      margin-bottom: 16px;

      .respond-to(@bpMobileLarge, {
        font-size: 30px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 36px;
        margin-bottom: 24px;
      });
    }

    .divider {
      background: @scYellowC;
      height: 6px;
      margin-bottom: 16px;
      width: 150px;

      .respond-to(@bpDesktopSmall, {
        margin-bottom: 24px;
      });
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 48px;

      .respond-to(@bpMobileLarge, {
        margin-bottom: 56px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
      });
    }

    .step {

      h4 {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 18px;
        margin-bottom: 16px;

        .respond-to(@bpMobileLarge, {
          font-size: 20px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 24px;
        });
      }

      .help-text {
        .font-family(light);
        color: @gray-59;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 16px;

        .respond-to(@bpDesktopSmall, {
          font-size: 16px;
        });
      }
    }

    .goal-selection-content {
      .box-shadow(0 3px 8px 0 @gray-6);
      background: @scWhiteC;
      border: .5px solid @gray-12;
      margin-bottom: 48px;
      overflow: hidden;
      position: relative;
      width: 100%;

      .respond-to(@bpTablet, {
        display: inline-block;
      });

      .goal-selection-description {
        .font-family(heavy);
        color: @gray-38;
        font-size: 18px;
        margin: 48px 16px 16px;

        .respond-to(@bpMobileLarge, {
          font-size: 20px;
        });

        .respond-to(@bpTablet, {
          font-size: 24px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
          margin: 56px 40px 24px;
        });

        .respond-to(@bpDesktopLarge, {
          margin: 56px 56px 24px;
          white-space: nowrap;
        });

        .goal-select {
          .font-family(heavy);
          color: @gray-87;
          cursor: pointer;
          font-size: inherit;
          background: url(/assets/images/dropdown_arrow_down.png) 96% center no-repeat;
          background-size: 14px 8px;
          border: none;
          border-bottom: 2px solid;
          border-radius: 0;
          margin: 0;
          padding: 0 20px 0 0;
          text-indent: 0;

          .respond-to(@bpMobileLarge, {
            padding-right: 24px;
          });

          .respond-to(@bpTablet, {
            padding-right: 26px;
          });

          .respond-to(@bpDesktopSmall, {
            background-size: 20px 11px;
            padding-right: 40px;
          });

          &:hover {
            color: @gray-54;
          }
        }

        >span {
          display: none;

          .respond-to(@bpMobileLarge, {
            display: inline-block;
            white-space: pre;
          });
        }
      }
    }

    .recommended-goal-tip {
      .box-shadow(0 5px 10px 0 @gray-12);
      .transition(opacity, 500ms);
      left: 105px;
      opacity: 1;
      padding: 4px 14px 4px 7px;
      position: absolute;
      top: 6px;

      .respond-to(@bpMobileLarge, {
        left: 117px;
      });

      .respond-to(@bpTablet, {
        left: 132px;
      });

      .respond-to(@bpDesktopSmall, {
        left: 188px;
        top: 11px;
      });

      .respond-to(@bpDesktopLarge, {
        left: 208px;
      });

      &.recommended-goal-tip-hide {
        opacity: 0;
      }

      h5 {
        .font-family(bold);
        color: @gray-darker;
        font-size: 12px;
        margin-bottom: 3px;
      }

      p {
        .font-family(roman);
        color: @gray-59;
        font-size: 11px;
        margin: 0;
        white-space: nowrap;
      }

      &:before {
        border-top: 6px solid @scWhiteC;
        border-left: 5px solid transparent;
        border-right:5px solid transparent;
        bottom: -6px;
        content:'';
        display:block;
        height:0;
        left:14px;
        position:absolute;
        width:0;
      }

    }

    .goal-selection-boxes-wrapper {
      overflow: auto;
      position: relative;

      .respond-to(@bpTablet, {
        text-align: center;
      });

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .goal-selection-boxes {
      display: inline-block;
      padding: 0 16px;
      text-align: left;
      white-space: nowrap;

      .respond-to(@bpTablet, {
        padding: 0 8px;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 0 38px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 0 56px;
      });

      .goal-selection-box {
        border: 1px solid @gray-12;
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        height: 190px;
        padding: 16px;
        position: relative;
        vertical-align: top;
        width: 208px;

        .respond-to(@bpDesktopSmall, {
          height: 196px;
          width: 208px;
        });

        .respond-to(@bpDesktopLarge, {
          height: 232px;
          padding: 40px 24px;
          width: 224px;
        });

        &:not(:first-child) {
          margin-left: 8px;

          .respond-to(@bpDesktopSmall, {
            margin-left: 16px;
          });

          .respond-to(@bpDesktopLarge, {
            margin-left: 32px;
          });
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.selected-goal {
          border-color: @gray-87;
        }

        .radio-box {
          position: absolute;
          top: 8px;
          right: 8px;

          .respond-to(@bpDesktopLarge, {
            top: 16px;
            right: 16px;
          });

          input {
            cursor: pointer;
            margin: 0;
          }
        }

        .goal-count {
          .font-family(bold);
          border-bottom: 1px solid;
          color: @gray-darker;
          display: inline-block;
          font-size: 24px;
          margin-bottom: 16px;

          .respond-to(@bpDesktopSmall, {
            font-size: 30px;
          });
        }

        .rider-prize {
          .font-family(light);
          color: @gray-darker;
          font-size: 14px;
          margin: 0;

          .rider-prize-item {
            display: block;
            line-height: 14px;
            margin-bottom: 8px;
            white-space: normal;

            > span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .prize {
      overflow: hidden;
      margin: 16px;

      .respond-to(@bpDesktopSmall, {
        margin: 16px 38px;
      });

      .respond-to(@bpDesktopLarge, {
        margin: 24px 56px;
      });

      p {
        .font-family(heavy);
        color: @gray-59;
        font-size: 11px;
        letter-spacing: 2px;
        line-height: 14px;
        position: relative;
        text-transform: uppercase;
        z-index: 2;

        .respond-to(@bpMobileLarge, {
          font-size: 12px;
          line-height: normal;
          width: auto;
          max-width: none;
        });
      }

      .golden-disclaimer {
        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    .pickup-studio-selection {
      margin-bottom: 48px;

      select {
        margin: 0;
      }
    }

    .confirm-selection {

      label {
        .flex-display();
        .flex-wrap(nowrap);

        .respond-to(@bpDesktopSmall, {
          .align-items(center);
        });

        input {
          .flex(none);
        }

        span {
          .font-family(medium);
          color: @gray-darker;
          display: inline-block;
          float: left;
          font-size: 14px;
          line-height: 16px;
          padding-left: 8px;
          width: 475px;

          .respond-to(@bpDesktopSmall, {
            width: auto;
          });
        }
      }

      p {
        .font-family(medium);
        color: @gray-darker;
        font-size: 14px;
        line-height: 16px;
        margin: 16px 0;

        a {
          text-decoration: underline;
        }
      }

      .checkbox {
        float: left;
      }
    }

    .submit-section {
      button {
        padding: 5px 53px;
      }
    }

    .pickup-studio {
      background: url(/assets/images/dropdown_arrow_down.png) 96% center no-repeat;
      background-size: 16px 9px;
      border: 1px solid @gray-59;
      border-radius: 5px;
      max-width: 520px;
      width: 100%;

      &.field-error {
        border-color: @error;
      }
    }
  }

  .campaign-register-footer {
    background: @gray-6;
    padding: 40px 16px;

    .respond-to(@bpMobileLarge, {
      padding: 40px 32px;
    });

    .respond-to(@bpTablet, {
      padding: 56px 0;
    });

    .respond-to(@bpDesktopSmall, {
        margin-bottom: @footerHeightDesktop;
    });

    p {
      .font-family(medium);
      color: @gray-darker;
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      a {
        text-decoration: underline;
      }
    }
  }

  .soultogether-checkbox {
    .appearance(none);
    border: 1px solid @gray-medium;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    margin: 0 10px 0 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 24px;

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/spritesheet.png) -148px -202px no-repeat;
      color: @yellow-light;
      content: '';
      font-size: 14px;
      height: 24px;
      left: 0;
      position: absolute;
      width: 24px;
    }
  }

  .soultogether-radio {
    .appearance(none);
    border: 1px solid @gray-54;
    border-radius: 50%;
    height: 20px;
    padding: 0;
    position: relative;
    width: 20px;

    .respond-to(@bpDesktopLarge, {
      height: 24px;
      width: 24px;
    });

    &:checked {
      background-color: @yellow-light;
      border: none;
    }

    &:checked:after {
      background-size: 278px 257px;
      background: url(/assets/images/spritesheet.png) -250px -175px no-repeat;
      color: @yellow-light;
      content: '';
      display: block;
      height: 100%;
      width: 100%;

      .respond-to(@bpDesktopLarge, {
        background-size: 313px 297px;
        background-position: -278px -201px;
      });
    }
  }
}

.me_soultogether .profile-sections-container {
  min-width: 320px;
  width: 100%;
}

.campaign-dashboard {
  padding-bottom: 30px;

  &.campaign-share {
    .action-buttons {
      margin-top: 16px;

      .register-btn {
        height: 40px;
        line-height: 40px;
        width: 164px;
      }

      .take-me-to-dashboard-btn {
        height: 40px;
        line-height: 40px;
        width: 220px;
      }
    }
  }

  header {
    background: @black url('/assets/images/campaign/warrior_week/WW_landing_page_header.jpg') center / cover no-repeat;
    height: 330px;
    position: relative;
    width: 100%;

    .respond-to(@bpMobileLarge, {
      background-position: top right -55px;
      background-size: auto 100%;
      height: 320px;
    });

    .respond-to(@bpDesktopSmall, {
      height: 365px;
    });

    .respond-to(@bpDesktopSmall, {
      height: 365px;
    });

    .respond-to(@bpDesktopLarge, {
      height: 514px;
    });

    &.dashboard-header-background-completed {
      background-color: @scWhiteC;
      background-image: url('/assets/images/campaign/warrior_week/WW_landing_about.jpg');
      background-position: top right !important;
    }

    .background-gradient {
      background: linear-gradient(to top right, rgba(0,0,0,1) 33%, rgba(0,0,0,0) 56%);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .respond-to(@bpMobileLarge, {
        background: linear-gradient(to right, rgba(0,0,0,1) 33%, rgba(0,0,0,0) 56%);
        left: auto;
        width: 100%;
        max-width: 630px;
      });

      .respond-to(@bpDesktopSmall, {
        max-width: 800px;
      });

      .respond-to(@bpDesktopLarge, {
        max-width: 1150px;
      });

      &.bottom-gradient {
        display: none;
      }
    }

    .campaign-dashboard-header-content {
      color: @scWhiteC;
      position: absolute;
      padding: 40px 16px;

      .respond-to(@bpMobileLarge, {
        padding: 40px 30px;
      });

      .respond-to(@bpTablet, {
        padding: 40px 24px;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 56px 32px;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 56px 32px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 56px 80px;
      });

      .campaign-logo {
        height: 74px;
        margin-bottom: 36px;

        .respond-to(@bpTablet, {
          height: 80px;
        });

        .respond-to(@bpDesktopLarge, {
          height: 110px;
        });
      }

      .campaign-dashboard-subtitle {
        .font-family(light);
        font-size: 12px;
        margin-bottom: 8px;

        .respond-to(@bpMobileLarge, {
          font-size: 16px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 24px;
          margin-bottom: 16px;
        });
      }

      .campaign-dashboard-greeting {
        .font-family(heavy);
        font-size: 20px;
        margin-bottom: 8px;

        .respond-to(@bpMobileLarge, {
          font-size: 24px;
          margin-bottom: 16px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 36px;
          margin-bottom: 24px;
        });
      }

      .campaign-dashboard-header-divider {
        background: @scYellowC;
        height: 6px;
        margin-bottom: 16px;
        width: 160px;

        .respond-to(@bpDesktopLarge, {
          margin-bottom: 24px;
        });
      }

      .campaign-dashboard-countdown {
        .font-family(light);
        font-size: 16px;

        .respond-to(@bpDesktopSmall, {
          font-size: 18px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 20px;
        });
      }
    }
  }

  &.ended {
    header {
      background-position: right -180px top;

      .respond-to(@bpMobileLarge, {
        background-position: right -140px top;
      });

      .respond-to(@bpTablet, {
        background-position: right -70px top;
      });

      .respond-to(@bpDesktopSmall, {
        background-position: right -80px top;
      });

      .respond-to(@bpDesktopLarge, {
        background-position: right top;
      });

      .background-gradient {
        .gradient-custom(~"70deg, #fff 50%, rgba(255,255,255,0) 56%");
        width: 100%;

        .respond-to(@bpMobileLarge, {
          .gradient-custom(~"90deg, #fff 50%, rgba(255,255,255,0) 75%");
        });

        .respond-to(@bpTablet, {
          .gradient-custom(~"90deg, #fff 40%, rgba(255,255,255,0) 75%");
        });

        .respond-to(@bpDesktopSmall, {
          .gradient-custom(~"90deg, #fff 42%, rgba(255,255,255,0) 75%");
        });

        .respond-to(@bpDesktopLarge, {
          .gradient-custom(~"90deg, #fff 35%, rgba(255,255,255,0) 75%")
        });

        &.bottom-gradient {
          .gradient-custom(~"0deg, #fff 40%, rgba(255,255,255,0) 56%");
          display: block;

          .respond-to(@bpMobileLarge, {
            display: none;
          });
        }
      }
      .background-gradient-completed {
        .gradient-custom(~"70deg, #fff 61%, rgba(255,255,255,0) 67%");
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      .campaign-dashboard-header-content {
        color: @black;
        max-width: 100%;

        .respond-to(@bpMobileLarge, {
          max-width: 50%;
        });

        .respond-to(@bpDesktopSmall, {
          max-width: 55%;
        });

        .respond-to(@bpDesktopLarge, {
          max-width: 44%;
        });

        .campaign-logo {
          margin-bottom: 56px;

          .respond-to(@bpTablet, {
            height: 72px;
          });

          .respond-to(@bpDesktopLarge, {
            height: 92px;
          });
        }

        .campaign-dashboard-info-text {
          .font-family(light);
          font-size: 12px;
          line-height: 14px;
          width: 100%;

          .respond-to(@bpDesktopSmall, {
            font-size: 16px;
            line-height: 20px;
          });

          .respond-to(@bpDesktopLarge, {
            font-size: 20px;
            line-height: 24px;
          });
        }
      }
    }

    .campaign-dashboard-progress {
      .campaign-dashboard-subtext {
        .font-family(light);
        color: @gray-54;
        font-size: 12px;
        line-height: normal;

        > span {
          white-space: nowrap;
        }

        .respond-to(@bpMobileLarge, {
          font-size: 16px;
          margin-bottom: 48px;
          display: none;
        });

        .respond-to(@bpTablet, {
          margin-bottom: 64px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 18px;
          margin-bottom: 48px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 20px;
          margin-bottom: 56px;
        });
      }

      .campaign-dashboard-goal-container {

        .goal-achieved {
          .font-family(heavy);
          background-color: @scYellowC;
          color: @gray-87;
          font-size: 14px;
          letter-spacing: 2.1px;
          line-height: 14px;
          margin-bottom: 15px;
          padding: 8px 6px 6px 6px;
          text-align: center;
          text-transform: uppercase;
          width: 110px;
        }
      }

      .campaign-dashboard-progress-content {
        padding-top: 48px;

        .respond-to(@bpMobileLarge, {
          padding-top: 0;
        });

        .campaign-dashboard-subtext {
          display: none;

          .respond-to(@bpMobileLarge, {
            display: block;
            margin-bottom: 96px;
          });

          .respond-to(@bpDesktopSmall, {
            margin-bottom: 48px;
          });
        }
      }
    }
  }

  .campaign-dashboard-progress {
    .align-items(flex-start);
    .box-shadow(0 3px 8px 0 @gray-6);
    .flex-display();
    .flex-wrap(wrap);
    background: @scWhiteC;
    border: solid 1px @gray-12;
    margin: -70px 16px 0 16px;
    padding: 16px;
    position: relative;

    .respond-to(@bpMobileLarge, {
      margin: -40px 32px 0 32px;
      padding: 24px;
    });

    .respond-to(@bpTablet, {
      margin: -40px 24px 0 24px;
    });

    .respond-to(@bpDesktopSmall, {
      margin: -40px 32px 0 32px;
      padding: 32px;
    });

    .respond-to(@bpDesktopLarge, {
      margin: -128px 80px 0 80px;
    });

    .campaign-dashboard-goal-container {
      .flex(none);
      .font-family(bold);
      border: solid 1px @gray-12;
      border-radius: 5px;
      color: @gray-87;
      display: inline-block;
      .flex(none);
      font-size: 12px;
      letter-spacing: 1.62px;
      margin-right: 24px;
      padding: 37px 10px;
      text-align: left;
      text-transform: uppercase;
      white-space: nowrap;

      .respond-to(@bpMobileLarge, {
        font-size: 14px;
        padding:  60px 16px;
      });

      .respond-to(@bpDesktopSmall, {
        padding: 70px 16px;
        margin-right: 32px;
      });

      .respond-to(@bpDesktopLarge, {
        padding: 73px 32px;
      });

      .campaign-dashboard-goal {
        .font-family(heavy);
        font-size: 24px;
        letter-spacing: normal;
        margin-top: 8px;
        text-transform: none;

        .respond-to(@bpMobileLarge, {
          font-size: 27px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 38px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 50px;
        });
      }
    }

    .campaign-dashboard-progress-content {
      color: @gray-darker;
      padding-top: 16px;
      width: 100%;

      .respond-to(@bpMobileLarge, {
        .flex(1 99);
        padding-top: 0;
      });

      .campaign-dashboard-subtext {
        .font-family(light);
        color: @gray-54;
        font-size: 12px;
        line-height: normal;
        margin: 0 0 24px 0;

        > span {
          white-space: nowrap;
        }

        .respond-to(@bpMobileLarge, {
          font-size: 16px;
          margin-bottom: 48px;
        });

        .respond-to(@bpTablet, {
          margin-bottom: 64px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 18px;
          margin-bottom: 48px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 20px;
          margin-bottom: 56px;
        });
      }

      .campaign-dashboard-progress-message {
        .font-family(heavy);
        font-size: 20px;

        .respond-to(@bpTablet, {
          font-size: 24px;
        });

        .respond-to(@bpDesktopSmall, {
          font-size: 30px;
        });

        .respond-to(@bpDesktopLarge, {
          font-size: 36px;
        });
      }

      .campaign-dashboard-progress-bar {
        height: 16px;
        background: @gray-12;
        margin-bottom: 32px;
        margin-top: 16px;
        overflow: hidden;
        width: 90%;

        .respond-to(@bpDesktopSmall, {
          margin-top: 24px;
          margin-bottom: 56px;
        });

        .campaign-dashboard-progress-bar-fill {
          .transition(all, .5s);
          background: @scYellowC;
          height: 100%;
          width: 0%;
        }
      }

      .campaign-dashboard-share {

        .campaign-dashboard-share-divider {
          background: @gray-87;
          height: 2px;
          width: 60px;
        }

        .campaign-dashboard-share-message {
          .font-family(heavy);
          font-size: 14px;
          margin-top: 16px;
        }

        .campaign-dashboard-share-link {
          display: inline-block;
          margin-top: 16px;
          position: relative;

          input {
            margin: 0;
            max-width: 300px;
            padding-right: 112px;
            position: relative;
            width: 100%;

            .respond-to(@bpTablet, {
              max-width: none;
              width: 380px;
            });

            &:focus {
              background-color: @gray-main !important;
            }
          }

          button {
            .border-radius(5px);
            .font-family(medium);
            .translate(0, -50%);
            background: @gray-darker;
            border: none;
            color: @scWhiteC;
            cursor: pointer;
            font-size: 14px;
            line-height: normal;
            padding: 7px 23px;
            position: absolute;
            right: 7px;
            top: 50%;
          }
        }

        .campaign-share-container {
          text-align: left;

          .campaign-share-message {
            display: none;
          }

          .campaign-share-links {
            margin-top: 24px;

            a {
              background-position: left center;
              border: none;
              height: 21px;
              width: 44px;

              &.campaign-share-icon-facebook {
                background-size: 20px;
              }

              &.campaign-share-icon-twitter {
                background-size: 20px;
              }

              &.campaign-share-icon-email {
                background-size: 20px;
              }

              &.campaign-share-icon-link {
                display: none;
              }
            }
          }
        }

      }
    }
  }

  .campaign-dashboard-footer {
    font-size: 14px;
    padding: 80px 0;
    text-align: center;

    .campaign-dashboard-footer-message {
      margin: 0 auto;
      width: 300px;

      .respond-to(@bpMobileLarge, {
        width: auto;
      });

      > span {
        .font-family(heavy);
        display: block;
        margin-bottom: 8px;
      }

      > a {
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
      }

      .campaign-dashboard-footer-email {
        border-bottom: none;
      }
    }
  }

  .campaign-dashboard-content {

    .campaign-dashboard-content-nav {
      border-bottom: solid 1px @gray-20;
      border-top: solid 1px @gray-20;
      margin-top: 16px;
      white-space: nowrap;

      .respond-to(@bpMobileLarge, {
        margin-top: 48px;
      });

      .respond-to(@bpTablet, {
        margin-top: 56px;
      });

      .respond-to(@bpDesktopSmall, {
        margin-top: 48px;
      });

      .respond-to(@bpDesktopLarge, {
        margin-top: 56px;
      });

      .campaign-dashboard-content-nav-item {
        .font-family(heavy);
        box-sizing: border-box;
        color: @gray-38;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        padding: 24px 16px;
        position: relative;
        text-align: center;
        white-space: normal;
        width: 50%;

        .respond-to(@bpMobileLarge, {
          font-size: 16px;
          padding: 32px 16px;
        });

        .respond-to(@bpTablet, {
          font-size: 18px;
        });

        &:hover {
          color: @gray-12;
        }

        &.selected {
          color: @gray-87;

          &::before {
            .translate(-50%);
            background: @gray-darker;
            bottom: 0;
            content: '';
            display: block;
            height: 6px;
            left: 50%;
            position: absolute;
            width: 124px;
          }
        }
      }
    }

    .campaign-dashboard-content-section {
      display: none;
      padding: 50px 16px 0;
      text-align: center;

      .campaign-progress {
        margin-bottom: 40px;

        .respond-to(@bpMobileLarge, {
            margin-left: 110px;
        });

        .progress-container {
            padding-bottom: 50px;
            .action-button-container {
                margin-bottom: 30px;
            }
        }
      }

      .dashboard-progress-get-started {
        .flex-display();
        .flex-direction(column);
        .align-items(center);

        h2 {
            font-size: 20px;
            line-height: 20px;
        }

        .get-started-action {
            margin-top: 25px;

            a {
                font-size: 14px;
                line-height: 17px;
                padding: 15px 45px;
            }
        }
      }

      .campaign-community-main {
        text-align: center;

        .campaign-community-heading {

          h3 {
            .font-family(heavy);
            color: @gray-87;;
            font-size: 30px;
            letter-spacing: 0;
            line-height: 38px;
            text-align: center;

            .respond-to(@bpMobileLarge, {
              font-size: 36px;
            });
          }
        }

        .divider {
          background: @yellow-light;
          height: 6px;
          margin: 24px auto 0;
          width: 122px;

          .respond-to(@bpTablet, {
            width: 140px;
          });
        }

        .campaign-community-content {
          margin-top: 40px;
          padding-bottom: 80px;

          .campaign-stat {
            margin-bottom: 32px;
            clear: both;

            .respond-to(@bpMobileLarge, {
              margin-bottom: 40px;
            });

            .stat-value {
              .font-family(heavy);
              color: @gray-darker;
              font-size: 36px;
              letter-spacing: 0;
              text-align: center;

              .respond-to(@bpMobileLarge, @bpTablet, {
                font-size: 50px;
              });

              .respond-to(@bpMobileLarge, {
                float: left;
                text-align: right;
                width: 50%;
              });

              .respond-to(@bpTablet, {
                font-size: 64px;
              });

              span {
                .respond-to(@bpMobileLarge, {
                  display: inline-block;
                  padding-right: 10px;
                });
              }
            }

            .stat-description {
              .font-family(light);
              color: @gray-darker;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 24px;
              margin: 0 auto;
              text-align: center;
              width: 262px;

              .respond-to(@bpMobileLarge, {
                float: left;
                text-align: left;
                width: 50%;

              });

              .respond-to(@bpTablet, {
                font-size: 20px;
              });

              span {

                .respond-to(@bpMobileLarge, {
                  display: inline-block;
                  padding-left: 10px;
                  width: 272px;
                });
              }
            }
          }

          .campaign-community-scholars {
            margin: 62px auto 24px;
            width: 350px;

            .respond-to(@bpDesktopSmall, {
              font-size: 0;
              width: 100%;
            });

            .campaign-community-scholars-message {
              width: 100%;

              h5 {
                .font-family(heavy);
                color: @gray-87;
                font-size: 24px;
                letter-spacing: 0;
                margin: 24px auto 8px;
                max-width: 350px;
                text-align: center;

                .respond-to(@bpDesktopSmall, {
                  margin: 0;
                  text-align: left;
                  width: 385px;
                });
              }

              p {
                .font-family(light);
                color: @gray-87;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                margin: 0 auto;
                text-align: center;

                .respond-to(@bpDesktopSmall, {
                  text-align: left;
                });
              }

              a {
                color: black;
              }
            }

            .community-scholars {
              width: 262px;
            }

            .campaign-community-scholars-message,
            .community-scholars {
              height: 142px;
              margin: 0 auto;

              .respond-to(@bpDesktopSmall, {
                display: inline-block;
                height: auto;
                margin: 0 12px;
                width: 382px;
                vertical-align: middle;
              });

              .respond-to(@bpDesktopLarge, {
                width: 410px;
              });
            }
          }
        }
      }
    }
  }

  .campaign-dashboard-footer {
    border-top: solid 1px @gray-12;
    font-size: 14px;
    padding: 80px 0;
    text-align: center;
    margin-top: 48px;

    .campaign-dashboard-footer-message {
      margin: 0 auto;
      width: 300px;

      .respond-to(@bpMobileLarge, {
        width: auto;
      });

      > span {
        .font-family(heavy);
        display: block;
        margin-bottom: 8px;
      }

      > a {
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
      }

      .campaign-dashboard-footer-email {
        border-bottom: none;
      }
    }
  }
}

.campaign-share-container {
  font-size: 18px;
  text-align: center;

  .respond-to(@bpMobileLarge, {
    font-size: 18px;
  });

  .campaign-share-message {
    .font-family(heavy);
    font-size: 16px;
    margin: auto;
    max-width: 299px;
    padding: 0 6px;
  }

  .campaign-share-links {
    display: inline-block;
    letter-spacing: -5px;
    margin-top: 12px;

    a {
      border-right: 1px solid #333;
      display: inline-block;
      height: 50px;
      width: 64px;

      .respond-to(@bpMobileLarge, {
        height: 74px;
        width: 100px;
      });

      &:last-child {
        border-right: none;
      }

      &.campaign-share-icon {
          background: no-repeat 50%;
      }

      &.campaign-share-icon-facebook {
        background-image: url('/assets/images/campaign/share_icons/facebook-icon.svg');
        background-size: 24px;

        .respond-to(@bpMobileLarge, {
          background-size: 30px;
        });
      }

      &.campaign-share-icon-twitter {
        background-image: url('/assets/images/campaign/share_icons/twitter-icon.svg');
        background-size: 24px;

        .respond-to(@bpMobileLarge, {
          background-size: 30px;
        });
      }

      &.campaign-share-icon-email {
        background-image: url('/assets/images/campaign/share_icons/email-icon.svg');
        background-size: 25px;

        .respond-to(@bpMobileLarge, {
          background-size: 34px;
        });
      }

      &.campaign-share-icon-link {
        background-image: url('/assets/images/turnitup/share_icons/tiu_share_link.png');
        background-size: 23px;

        .respond-to(@bpMobileLarge, {
          background-size: 30px;
        });
      }
    }
  }
}

.invite_overlay_container {
  background: @gray-30;
  bottom: 0;
  box-sizing: border-box;
  display: none;
  left: 0;
  overflow: auto;
  padding: 0 16px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: @zOverlay;

  .respond-to(@bpMobileLarge, {
    padding: 0 32px;
  });

  .invite-modal {
    background: @scWhiteC;
    color: @gray-darker;
    margin: 40px auto;
    max-width: 795px;
    padding: 40px 16px;
    position: relative;
    text-align: center;

    .respond-to(@bpMobileLarge, {
      padding: 80px 16px;
    });

    .invite-close {
      background: url('/assets/images/confirm_dialog_close.png');
      background-size: cover;
      cursor: pointer;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 16px;

      .respond-to(@bpMobileLarge, {
        right: 16px;
        top: 16px;
      });

      &:hover,
      &:active {
        background: url('/assets/images/confirm_dialog_close_hover.png');
        background-size: cover;
      }
    }

    .invite-section {
      color: @gray-59;
      max-width: 492px;
      margin: 0 auto;

      h2 {
        .font-family(heavy);
        color: @gray-darker;
        font-size: 16px;
        margin-bottom: 8px;

        .respond-to(@bpMobileLarge, {
          font-size: 20px;
        });

        > span {
          white-space: nowrap;
        }
      }

      p {
        .font-family(light);
        font-size: 14px;
        line-height: 16px;
        letter-spacing: .5px;
        margin: 0 0 24px 0;
      }

      .campaign-dates {
        .font-family(heavy);
        color: @gray-87;
        font-size: 9px;
        letter-spacing: 1.7px;
        line-height: 13px;
        margin-bottom: 16px;
        text-transform: uppercase;

        .respond-to(@bpMobileLarge, {
          margin-bottom: 32px;
        });
      }

      form {
        max-width: 408px;
        margin: 0 auto;

        .field-container {
          margin-bottom: 16px;

          .field-error-message {
            .font-family(medium);
            color: @red-error;
            display: none;
            font-size: 14px;
            margin-left: 8px;
            margin-top: 8px;
            text-align: left;
          }

          &.field-error .field-error-message {
            display: block;
          }
        }

        input {
          background: @gray-main;
          display: block;
          width: 100%;
        }

        .invite-add-container {
          cursor: pointer;
          text-align: left;
          margin-bottom: 32px;

          > * {
            cursor: pointer;
            vertical-align: middle;
          }

          button {
            background: url('/assets/images/campaign/soultogether-invite-add.svg') center no-repeat;
            border: none;
            height: 29px;
            margin-right: 15px;
            width: 29px;
          }

          span {
            .font-family(medium);
            font-size: 14px;
          }

          &:hover {
            button {
              background-image: url('/assets/images/campaign/soultogether-invite-add-hover.svg');
            }

            span {
              text-decoration: underline;
            }
          }
        }

        .invite-send {
          font-size: 14px;
          line-height: normal;
          padding: 16px 58px;
          margin-bottom: 24px;

        }
      }

      .invite-dismiss {
        .font-family(light);
        color: @gray-87;
        cursor: pointer;
        display: block;
        font-size: 16px;
        line-height: 19px;

        &:hover {
          color: @gray-darker;
          text-decoration: underline;
        }

        .invite-dismiss-arrow {
          background: url('/assets/images/campaign/soultogether-invite-dismiss-arrow.svg') center no-repeat;
          display: inline-block;
          height: 16px;
          vertical-align: middle;
          width: 20px;
        }
      }
    }
  }
}

.campaign-faq {
  color: @gray-darker;
  font-size: 14px;
  line-height: 1.6em;
  max-width: 800px;

  .respond-to(@bpDesktopSmall, {
    padding-bottom: @footerHeightDesktop;
  });

  a {
    border-bottom: none;
    color: @gray-darker;
    white-space: nowrap;
  }

  .campaign-hero {
    margin-bottom: 0;
    padding: 0 20px;

    .respond-to(@bpMobileLarge, {
      padding: 0 40px;
    });

    .respond-to(@bpTablet, {
      padding: 0 60px;
    });

    .respond-to(@bpDesktopSmall, {
      padding: 0;
    });

    .campaign-main-logo {
      background: url('/assets/images/campaign/warrior_week/WW_black_logo_horizontal.png') left center no-repeat;
      background-size: 60%;
      height: 55px;
      max-width: 586px;
      width: 100%;

      .respond-to(@bpMobileMedium, {
        margin-bottom: 10px;
      });

      .respond-to(@bpMobileLarge, {
        margin-bottom: 15px;
      });
    }

    .campaign-header {
      margin-bottom: 10px;
    }
  }

  h2 {
    .respond-to(@bpMobileLarge, {
      font-size: 27px;
      line-height: 30px;
    });

    .respond-to(@bpTablet, {
      font-size: 32px;
      line-height: 34px;
    });

    .respond-to(@bpTablet, {
      font-size: 32px;
      line-height: 34px;
    });

    .respond-to(@bpDesktopSmall, {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
    });
  }

  .campaign-terms {
    padding: 0 20px;
    line-height: 26px;

    .respond-to(@bpMobileLarge, {
      padding: 0 40px;
    });

    .respond-to(@bpTablet, {
      padding: 0 60px;
    });

    .respond-to(@bpDesktopSmall, {
      padding: 0;
    });

    header {
      padding: 35px 0;

      .respond-to(@bpDesktopSmall, {
        border-top: @borderLightGray;
      });
    }

    .campaign-terms-question {
      .respond-to(@bpMobileLarge, {
        padding-bottom: 20px;
      });

      .respond-to(@bpDesktopSmall, {
        padding-bottom: 40px;
      });

      .respond-to(@bpDesktopLarge, {
        padding-bottom: 50px;
      });
    }

    h3 {
      .font-family(bold);
      font-size: 18px;
      line-height: 27px;
      text-align: left;

      .respond-to(@bpMobileLarge, {
        font-size: 20px;
        line-height: 30px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 24px;
        line-height: 30px;
      });
    }

    p {
      font-size: 16px;
      padding-top: 5px;

      .respond-to(@bpMobileLarge, {
        font-size: 18px;
        line-height: 26px;
      });

      .respond-to(@bpTablet, {
        line-height: 30px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
        line-height: 30px;
      });
    }

    li {
      font-size: 16px;
      margin-bottom: 8px;

      .respond-to(@bpMobileLarge, {
        font-size: 18px;
        line-height: 26px;
      });

      .respond-to(@bpTablet, {
        line-height: 30px;
      });

      .respond-to(@bpDesktopSmall, {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
      });
    }

    ul {
      list-style-position: inside;
      list-style-type: none;
    }

    .campaign-my-soultogether {
      text-align: center;

      .respond-to(@bpMobileLarge, {
        margin: 10px 0 20px 0;
      });

      .respond-to(@bpDesktopSmall, {
        text-align: left;
      });

      .yellow-button {
        width: 280px;
      }
    }
  }
}

.campaign-progress {
    .flex-display();
    .flex-direction(column);
    .align-items(center);
    margin-bottom: 100px;
    width: 100%;

    .respond-to(@bpMobileLarge, {
        padding: 0;
    });

    .header-action {
        background: @white-70;
        border-bottom: 1px solid @gray-soultogether;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        position: fixed;
        z-index: 2;

        a {
            .font-family(heavy);
            color: @gray-87;
            font-size: 16px;
            line-height: 20px;

            span {
                background: url(/assets/images/campaign/back-arrow.svg);
                display: block;
                float: left;
                margin-top: -2px;
                margin-right: 10px;
                height: 20px;
                width: 12px;
            }
        }
    }

    .title {
        .font-family(heavy);
        font-size: 14px;
        font-style: italic;
        line-height: 14px;
        margin: 100px 0 0 -140px;
        padding-left: 15px;
        padding-bottom: 55px;
        text-align: center;
    }

    .progress-container {
        margin-top: 15px;
        position: relative;
        text-align: left;

        .progress-line {
            background-color: @gray-soultogether;
            height: 100%;
            left: 36px;
            position: absolute;
            width: 2px;
            z-index: -1;

            &:before,
            &:after {
                .border-radius(50%);
                background-color: @gray-soultogether;
                bottom: 0px;
                content: '';
                height: 18px;
                left: -8px;
                position: absolute;
                width: 18px;
            }
            &:before {
                top: -20px;
            }
        }

        .campaign-progress-ride-item {
            margin: 40px 0;

            > div {
              font-size: 0;
              display: inline-block;
              vertical-align: middle;
            }

            .media {
                position: relative;

                .instructor-image {
                    border: 2px solid @scYellowC;
                    border-radius: 50%;
                }

                .ride-number {
                    .border-radius(50%);
                    .font-family(heavy);
                    background-color: @scWhiteC;
                    border: 2px solid @gray-soultogether;
                    color: @gray-soultogether;
                    font-size: 36px;
                    height: 69px;
                    line-height: 76px;
                    position: relative;
                    text-align: center;
                    width: 69px;
                }
            }

            .description {
                .ride-with {
                    .font-family(light);
                    font-size: 16px;
                    line-height: 30px;
                    margin: 0 0 0 20px;

                    .ride-info {

                        .ride-info-container {
                            .font-family(medium);
                        }

                        .is-up-next {
                            .flex(none);
                            .font-family(heavy);
                            background-color: @scYellowC;
                            box-sizing: border-box;
                            color: @gray-87;
                            display: inline-block;
                            font-size: 12px;
                            height: 18px;
                            letter-spacing: 2.14px;
                            line-height: 14px;
                            margin-left: 0;
                            padding: 3.5px;
                        }
                    }
                }

                .ride-at {
                    font-size: 12px;
                    line-height: 22px;
                    margin: 0 0 0 20px;
                    text-align: left;
                }

                .ride-placeholder {
                    .border-radius(2px);
                    height: 18px;
                    width: 180px;
                    background-color: @gray-soultogether;
                    margin: 10px 0 0 20px;

                    &.long-placeholder {
                        width: 203px;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 200px;
            }

            &.campaign-progress-ride-goal {
                color: @gray-87;
                cursor: pointer;
                margin-left: -16px;
                white-space: nowrap;

                &:hover {
                  .description,
                  .goal-ride-number {
                    text-decoration: underline;
                  }
                }

                .goal-tags {
                  height: 39px;

                    > span {
                        .font-family(heavy);
                        background-color: @gray-87;
                        box-sizing: border-box;
                        color: @scWhiteC;
                        display: none;
                        font-size: 14px;
                        height: 24px;
                        letter-spacing: 2.1px;
                        margin: 0 8px 0 0;
                        padding: 6px;
                        text-align: center;
                        text-transform: uppercase;

                        &.goal-tag-next {
                            background-color: @gray-soultogether;
                            color: @gray-87;
                        }

                        &.goal-tag-up-next,
                        &.goal-tag-achieved {
                            background-color: @scYellowC;
                            color: @gray-87;
                        }
                    }
                }

                &.up-next .goal-tags > .goal-tag-up-next,
                &.your-goal .goal-tags > .goal-tag-your,
                &.next-goal:not(.goal-achieved) .goal-tags > .goal-tag-next,
                &.goal-achieved .goal-tags > .goal-tag-achieved {
                    display: inline-block;
                }



                .media {
                    .ride-number {
                        border: 3px solid @gray-59;
                        color: @gray-darker;
                        font-size: 40px;
                        height: 100px;
                        line-height: 108px;
                        width: 100px;
                    }
                }

                .description {
                    margin-left: 20px;

                    .goal-ride-number {
                        .font-family(heavy);
                        font-size: 36px;
                        line-height: 36px;

                      &:after {
                        background: url('/assets/images/campaign/soultogether-goal-arrow.svg') center / contain no-repeat;
                        content: '';
                        display: inline-block;
                        height: 25px;
                        vertical-align: middle;
                        width: 15px;
                      }
                    }

                    .goal-achived-date-and-location {
                        font-size: 14px;
                        height: 29px;
                        line-height: 30px;
                    }
                }

                &.goal-achieved .media .ride-number {
                    background-color: @scYellowC;
                }
            }
        }

        .action-button-container {
            background-color: @scWhiteC;
            margin-bottom: 90px;
            margin-top: 20px;
            margin-left: -20px;

            .respond-to(@bpMobileLarge, {
                margin-left: -60px;
            });

            .action-button {
                .border-radius(24px);
                .font-family(heavy);
                background-color: @scWhiteC;
                border: 2px solid @gray-soultogether;
                display: block;
                font-size: 14px;
                letter-spacing: 2.5px;
                padding: 10px;
                text-align: center;
                text-transform: uppercase;
                width: 178px;
            }
        }
    }
}

.change-goal-dialog-container {
    background: @gray-38;
    bottom: 0;
    display: none;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: @zOverlay;

    .change-goal-dialog-overlay {
        height: 100%;
        position: fixed;
        width: 100%;
    }

    .change-goal-dialog-box {
        .border-radius(4px);
        background: @scWhiteC;
        color: @gray-darker;
        font-size: 14px;
        left: 15px;
        margin-bottom: 15px;
        overflow: hidden;
        padding: 36px 0 40px;
        position: absolute;
        right: 15px;
        text-align: center;
        top: 15px;

        .respond-to(@bpMobileLarge, {
            margin: 30px auto;
            padding: 80px 0 46px;
            position: relative;
            width: 535px;
        });

        .respond-to(@bpDesktopSmall, {
            width: 625px;
        });

        .change-goal-dialog-close {
            background: url('/assets/images/confirm_dialog_close.png');
            background-size: cover;
            cursor: pointer;
            height: 16px;
            position: absolute;
            right: 8px;
            top: 16px;
            width: 16px;

            .respond-to(@bpMobileLarge, {
                right: 24px;
                top: 24px;
                height: 24px;
                width: 24px;
            });

            &:hover,
            &:active {
                background: url('/assets/images/confirm_dialog_close_hover.png');
                background-size: cover;
            }
        }

        .change-goal-dialog-section-action {
            .change-goal-dialog-social-section {
                .campaign-share-message {
                  display: none;
                }

                .campaign-share-links {
                    margin-top: 0;

                    a {
                        background-position: left center;
                        background-size: 20px;
                        border: none;
                        height: 21px;
                        margin: 0 12px;
                        width: 20px;

                        &.campaign-share-icon-link {
                          display: none;
                        }
                    }
                    .campaign-share-icon-link {
                        display: none;
                    }
                }
            }
        }

        .change-goal-dialog-header {
            border-bottom: 1px solid @gray-38;
            margin: 0 38px 17px;
            text-align: left;

            .respond-to(@bpMobileLarge, {
                margin: 0 auto 40px;
                width: 412px;
            });

            h1 {
                .font-family(heavy);
                font-size: 36px;

                .respond-to(@bpMobileLarge, {
                  font-size: 60px;
                });
            }

            h2 {
                .font-family(heavy);
                font-size: 12px;
                letter-spacing: 2.14px;
                text-transform: uppercase;
                margin-bottom: 8px;

                .respond-to(@bpMobileLarge, {
                  font-size: 14px;
                  letter-spacing: 2.5px;
                  line-height: 14px;
                });
            }
        }

        .change-goal-dialog-text-main {;
            line-height: 17px;

            .description {
                .font-family(light);
                font-size: 19px;
                line-height: 19px;

                .respond-to(@bpMobileLarge, {
                    font-size: 24px;
                    line-height: 24px;
                });

                .number-of-rides {
                    .font-family(heavy);
                }
            }
            .prize-container {
                font-size: 0;
                margin: 8px auto;
                text-align: center;
                width: 290px;

                .respond-to(@bpMobileLarge, {
                    margin: 24px auto;
                    width: auto;
                });

                > div {
                    display: none;
                }

                .text-description {
                    font-size: 12px;
                }

                .prize {
                    border: 0.5px solid @gray-12;
                    box-sizing: border-box;
                    display: inline-block;
                    height: 100px;
                    margin: 4px;
                    padding: 13px 10px;
                    vertical-align: top;
                    width: 90px;

                    .respond-to(@bpMobileLarge, {
                        height: 120px;
                        width: 108px;
                    });

                    .respond-to(@bpDesktopSmall, {
                        margin: 12px;
                    });

                    > div {
                        margin: 0 auto;
                    }

                    .media {
                        .border-radius(50%);
                        .font-family(heavy);
                        color: @gray-87;
                        font-size: 24px;
                        height: 40px;
                        line-height: 48px;
                        overflow: hidden;
                        position: relative;
                        text-align: center;
                        width: 40px;

                        .respond-to(@bpMobileLarge, {
                          font-size: 36px;
                          height: 60px;
                          line-height: 69px;
                          width: 60px;
                        });
                    }

                    .media {
                        background-color: @sc-yellow;
                        border: none;

                        img {
                            height: 100%;
                        }
                    }

                    .prize-description {
                        .font-family(light);
                        color: @gray-darker;
                        font-size: 12px;
                        line-height: 12px;
                        margin-top: 6px;

                        .respond-to(@bpMobileLarge, {
                            margin-top: 8px;
                        });
                    }
                }
                .prize-subtext {
                    font-size: 12px;
                    font-style: italic;
                    margin-top: 8px;
                }
            }
        }

        .change-goal-dialog-text-disclaimer {
            .font-family(light);
            font-size: 16px;
            margin: 0 auto 12px;
            max-width: 312px;

            .respond-to(@bpMobileLarge, {
                font-size: 18px;
                margin-top: 16px;
                max-width: 500px;
            });

            .golden-disclaimer {
                margin-top: 8px;
                font-size: 11px;
                line-height: 12px;

                a {
                    text-decoration: underline;
                }
            }
        }

        button {
            border: none;
            cursor: pointer;
        }

        .change-goal-dialog-button {
            .border-radius(4px);
            .font-family(medium);
            .transition(background, .1s);
            font-size: 14px;
            line-height: 40px;
            margin-bottom: 16px;
            padding: 0 60px;
            display: none;

            .respond-to(@bpMobileLarge, {
                margin-bottom: 24px;
            });

            &.button-done {
                background: @gray-darker;
                color: #fff;

                &:hover,
                &:active {
                    background: @sc-yellow;
                }
            }
        }

        .change-goal-dialog-decline {
            .font-family(medium);
            .transition(color, .1s);
            background: none;
            border: none;
            font-size: 12px;
            text-decoration: underline;

            &:hover,
            &:active {
                color: @gray-medium-darker;
            }
        }
    }
}
