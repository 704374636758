.pro-tip-container {
  bottom: 3.75rem;
  display: none;
  left: 0;
  margin: 0 auto;
  max-width: 95%;
  position: fixed;
  right: 0;
  width: fit-content;
  z-index: 10;

  .respond-to(@bpTablet, {
    max-width: 47.75rem;
  });

  .respond-to(@bpDesktopSmall, {
    bottom: 5.25rem;
  });
}

.pro-tip-container__flex {
  .align-items(center);
  .box-shadow(0 0.0625rem 0.375rem 0.0625rem @gray-dark);
  .flex-display(flex);
  .justify-content(space-between);
  background: @scWhiteC;
  padding: 1rem;
}

.pro-tip__body {
  color: @gray-59;
  line-height: 1.125rem;
}

.pro-tip__title {
  .font-family(bold);
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.pro-tip__message {
  .font-family(roman);
  font-size: 0.875rem;
}

.pro-tip__button {
  .font-family(medium);
  background: none;
  border: none;
  color: @gray-darker;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 1.5rem;
  text-decoration: underline;

  &:hover {
    color: @gray-59;
  }
}
