.supersoul__section__package {
  padding: 54px 8.8%;
  
  .respond-to(@bpTablet, {
    padding: 100px 16.8%;
  });

  &-content {
    .font-family(light);
    color: @tundoraGray;
    letter-spacing: 0.5px;

    h2 {
      .font-family(bold);
      font-size: 2.25rem;
      letter-spacing: 0.38px;

      .respond-to(@bpTablet, {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: 0.5px;
      });
    }

    p {
      font-size: 1.125rem;
      line-height: 2rem;
      padding: 30px 0 10px 0;

      .respond-to(@bpTablet, {
        font-size: 1.375rem;
        padding: initial;
      });
    }
  }
}
