.soulcup-dashboard {
  min-width: 320px;
  background-color: @gray-main;

  &__panel {
    background-color: #3D3D3D;
    color: @scWhiteC;
    height: 327px;
    position: relative;

    .respond-to(@bpTablet, {
      height: 31.25rem;
    });

    &-background {
      background: right top / cover no-repeat url(/assets/images/turnitup/FallChallenge_Mobile-2.jpg) #000;
      bottom: 0;
      left: 0;
      opacity: 0.6;
      position: absolute;
      right: 0;
      top: 0;
      .respond-to(@bpTablet, {
        background: right top / cover no-repeat url(/assets/images/turnitup/2024TIU-Desktop-GeneralBG.jpg) #000;
      });
    }

    &-background-started {
      background: right top / cover no-repeat url(/assets/images/turnitup/2024TIU-Desktop-DashboardSolo.jpg) #000;
      bottom: 0;
      left: 0;
      opacity: 0.6;
      position: absolute;
      right: 0;
      top: 0;
      .respond-to(@bpTablet, {
        background: right top / cover no-repeat url(/assets/images/turnitup/2024TIU-Desktop-GeneralBG.jpg) #000;
      });
    }

    &-container {
      .flex-display(flex);
      align-items: center;
      flex-direction: column;
      padding-top: 146px;

      .respond-to(@bpTablet, {
        padding-top: 210px;
      });
    }

    &__logo {
      margin-bottom: 1.25rem;
      width: 220.52px;
      z-index: 1;
      height: 30px;

      .respond-to(@bpTablet, {
        width: 210px;
      });

      .respond-to(@bpDesktopLarge, {
        width: 354px;
      });
    }

    &__message {
      .font-family(bold);
      color: @white;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 23px;
      margin-bottom: 1.875rem;
      text-align: center;
      z-index: 1;
    }

    &__ride-goal {
      .font-family(bold);
      .flex-display(flex);
      color: @greenWhite;
      flex-direction: row;
      font-size: 14px;
      line-height: 17px;
      z-index: 1;

      span {
        padding: 0 3px;
      }
    }

    &__goal-icon {
      background: center no-repeat url(/assets/images/soulcup/Award@3x.png);
      background-size: 60%;
      height: 14px;
      width: 14px;
    }
  }

  &__panel-started {
    .flex-display(flex);
    .justify-content(center);

    &-container {
      .flex-display(flex);
      align-items: center;
      flex-direction: column;
      padding-top: 100px;
      width: 327px;

      .respond-to(@bpTablet, {
        margin-left: 0;
        width: 725px;
      });

      h2 {
        margin: 0 0 .25em 0;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__logo {
      width: 301px;
      height: 41px;
      margin-bottom: 1rem;
      z-index: 1;

      .respond-to(@bpTablet, {
        width: 400px;
        height: 40px;
        margin-top: 10%;
      });
      .respond-to(@bpDesktopLarge, {
        width: 500px;
        height: 80px;
        margin-top: 10%;
      });
    }

    &__clock {
      height: 14px;
      width: 13px;
    }

    &__days-left {
      .font-family(roman);
      color: @white;
      flex-direction: column;
      font-size: 0.875rem;
      line-height: 23px;
      margin-bottom: 36px;
      text-align: center;
      z-index: 1;

      span {
      .font-family(bold);
      }
    }

    &__ride-goal {
      .flex-display(flex);
      .flex-direction(column);
      z-index: 1;

      p {
        .font-family(bold);
        font-size: 0.625rem;
        letter-spacing: 1.42px;
        line-height: 12px;
      }

      div {
        font-size: 2.75rem;
        letter-spacing: -1.63px;
        line-height: 2.625rem;
        span {
          .font-family(bold);
        }
      }
    }

    &__goal-icon {
      background: center no-repeat url(/assets/images/soulcup/Award@3x.png);
      background-size: 60%;
      height: 14px;
      width: 14px;
    }
  }

  &__card-wrapper {
    .flex-display(flex);
    flex-direction: column;

    .respond-to(@bpTablet, {
      flex-direction: row;
      height: 204px;
      justify-content: center;
      position: relative;
      top: -65px;
    });
  }

  &__app {
    .flex-display(flex);
    align-self: center;
    background: linear-gradient(180deg, #626262 0%, #333333 100%);
    border-radius: 4px;
    color: @white;
    height: 230px;
    margin: 16px 16px 8px;
    max-width: 343px;
    overflow: hidden;

    .respond-to(@bpTablet, {
      align-self: normal;
      width: 343px;
    });

    &-container {
      .flex-display(flex);
      flex-direction: column;
      padding: 1rem 1rem 0;
      width: 100%;

      h2 {
        .font-family(bold);
        font-size: 1.125rem;
        grid-column: 2 ;
        grid-row: 1;
        line-height: 1.375rem;
        margin-bottom: 0;
        padding-top: 8px;
        width: 100%;

        .respond-to(@bpTablet, {
          grid-column: 2;
          grid-row: 1;
          height: auto;
        });
      }

      p {
        .font-family(light);
        font-size: 1rem;
        line-height: 20px;
        margin: 0 0 24px 0;
        padding: 0;
        text-align: left;
      }

      h2, p {
        padding-left: 6px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      height: 100%;
    }

    &-description-container {
      .flex(0 0 50%);
      flex-direction: row;

      .respond-to(@bpTablet, {
        grid-column: 2;
        grid-row: 2;
      });

      p {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    &-share-icon {
      display: inline-block;
      background-image: url("/assets/images/soulcup/share_icons/ios-appstore.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-left: 4px;
      height: 27.91px;
      width: 86.04px;

      .respond-to(@bpTablet, {
        margin-top: -0.5rem;
      });
    }

    &-iphone {
      .flex(0 0 50%);
      background-image: url("/assets/images/turnitup/918_TurnItUp_WebAssets-Phone.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: 85% 100%;
      grid-column: 1;
      grid-row: 1 /span2;
      height: auto;
    }
  }

  &__team-info {
    align-self: center;
    background-color: @white;
    border-radius: 4px;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    margin: 16px;
    max-width: 343px;
    position: relative;
    width: 100%;

    .respond-to(@bpTablet, {
      align-self: normal;
      height: 230px;
      width: 365px;
      max-width: 365px;
    });

    &__join-team {
      .flex-display(flex);
      flex-direction: column;
      padding: 24px;

      h2 {
        .font-family(bold);
        font-size: 1.125rem;
        line-height: 1.25rem;
      }

      button {
        .font-family(heavy);
        border-radius: 0px;
        color: @black;
        height: 48px;
        margin: 23px auto 0 0;
        width: 135px;
      }
    }
  }

  &__progress {
    background-color: @white;
    border-bottom: 1px solid @gray-soultogether;
    padding: 40px 16px;
  }

  &__progress-nav {
    padding: 0 0 40px;

    .respond-to(@bpTablet, {
      text-align: center;
    });
  }

  &__progress-nav-item {
    .font-family(bold);
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  &__get-started {
    .align-items(center);
    .flex-display();
    .flex-direction(column);
    text-align: center;
  }

  &__get-started-message {
    font-size: 1.25rem;
    line-height: 1;
  }

  &__get-started-action {
    margin-top: 25px;
  }

  &__get-started-btn {
    font-size: 0.875rem;
    line-height: 1.2;
    padding: 15px 45px;
  }

  .soulcup-dashboard__team-info--has-team .soulcup-dashboard__team-info__join-team,
  .soulcup-dashboard__team-info--no-team .soulcup-dashboard__team-summary-container {
    display: none;
  }

  &__join-team-form-label {
    color: @gray-54;
    font-size: 0.875rem;
    line-height: 1.06rem;
    margin: 12px 0 5px;
  }

  &__join-team-form-label--error {
    color: @red-error;
  }

  &__join-team-form-input--error {
    border-color: @red-error;
    color: @red-error;
  }

  &__join-team-form-error {
    bottom: 78px;
    color: @red-error;
    display: none;
    font-size: 0.875rem;
    line-height: 1.06rem;
    margin: 0;
    position: absolute;
  }

  &__team-summary-container {
    box-sizing: border-box;
    height: 189px;
    padding: 24px;

    .respond-to(@bpTablet, {
      height: 232px;
    });
  }

  &__team-summary-name,
  &__team-leave-question {
    .font-family(bold);
    color: @black;
    font-size: 1rem;
    line-height: 1.25;
  }

  &__team-summary-members {
    .font-family(bold);
    color: @gray-rolling-stone;
    font-size: 0.625rem;
    letter-spacing: 1.5px;
    line-height: 1;
    margin: 6px 0 0;
    text-transform: uppercase;
  }

  &__team-summary-leave,
  &__team-leave-button {
    .font-family(bold);
    background: #EBEBEB;
    border: 0;
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 32px 0 0;
    padding: 12px 32px;

    &:hover {
      background: @gray-really-light;
    }
  }

  &__team-summary-leave {
    bottom: 0;
    position: absolute;

    .respond-to(@bpTablet, {
      position: static;
    });
  }

  &__team-leave-button {
    width: 135px;
  }

  &__team-leave-confirm-button {
    background: @goldenYellow;
  }

  &__team-leave-cancel-button {
    margin-right: 16px;
  }

  &__team-summary {
    height: 100%;
    position: relative;
  }

  &__team-leave-confirmation {
    display: none;
    height: 100%;
    position: relative;
  }

  &__team-leave-action-buttons {
    bottom: 0;
    position: absolute;

    .respond-to(@bpTablet, {
      position: static;
    });
  }

  &__questions {
    background-color: @white;
    color: @black;
    height: 230px;

    .wrapper {
      .flex-display(flex);
      align-items: center;
      flex-direction: column;
      padding-top: 75px;
    }

    h2 {
      .font-family(bold);
      color: @black;
    }
    p {
      .font-family(light);
      font-size: 0.75rem;

      a {
        border-bottom: 1px solid rgba(0,0,0,0.87);
      }
    }
  }
}

.soulcup-progress {
  .align-items(center);
  .flex-display();
  .flex-direction(column);
  margin-bottom: 40px;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    margin-left: 110px;
    padding: 0;
  });

  &__container {
    margin-top: 15px;
    padding-bottom: 50px;
    position: relative;
    text-align: left;
  }

  &__line {
    background-color: @gray-soultogether;
    height: 100%;
    left: 36px;
    position: absolute;
    width: 2px;
    z-index: 0;

    &:before,
    &:after {
      .border-radius(50%);
      background-color: @gray-soultogether;
      bottom: 0px;
      content: '';
      height: 18px;
      left: -8px;
      position: absolute;
      width: 18px;
    }

    &:before {
      top: -20px;
    }
  }

  &__action-button-container {
    margin: 20px 0 30px -20px;
    position: relative;

    .respond-to(@bpMobileLarge, {
      margin-left: -60px;
    });
  }

  &__action-button {
    .border-radius(24px);
    .font-family(heavy);
    background-color: @scWhiteC;
    border: 2px solid @gray-soultogether;
    display: block;
    font-size: 0.875rem;
    height: 10px;
    letter-spacing: 2.5px;
    padding: 11px 10px;
    text-align: center;
    text-transform: uppercase;
    width: 178px;
  }
}

.soulcup-progress-goal,
.soulcup-progress-item {
  margin: 40px 0;

  &:last-of-type {
    margin-bottom: 200px;
  }

  &__media {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  &__description {
    display: inline-block;
    vertical-align: middle;
  }

  &__instructor-image {
    border: 2px solid @scYellowC;
    border-radius: 50%;
  }

  &__media-ride-number,
  &__ride-number {
    .border-radius(50%);
    .font-family(heavy);
    background-color: @scWhiteC;
    border: 2px solid @gray-soultogether;
    color: @gray-soultogether;
    font-size: 2.25rem;
    height: 69px;
    line-height: 4.75rem;
    position: relative;
    text-align: center;
    width: 69px;
  }

  &__ride-with {
    .font-family(light);
    font-size: 1rem;
    line-height: 1.875;
    margin: 0 0 0 20px;
  }

  &__ride-info-container {
    .font-family(medium);
  }

  &__is-up-next {
    .flex(none);
    .font-family(heavy);
    background-color: @scYellowC;
    box-sizing: border-box;
    color: @gray-87;
    display: inline-block;
    font-size: 0.75rem;
    height: 18px;
    letter-spacing: 2.14px;
    line-height: 0.875rem;
    margin-left: 0;
    padding: 3.5px;
  }

  &__ride-at {
    font-size: 0.75rem;
    line-height: 1.375rem;
    margin: 0 0 0 20px;
    text-align: left;
  }

  &__long-placeholder,
  &__short-placeholder {
    .border-radius(2px);
    background-color: @gray-soultogether;
    height: 18px;
    margin: 10px 0 0 20px;
    width: 180px;
  }

  &__long-placeholder {
    width: 203px;
  }
}

.soulcup-progress-goal {
  color: @gray-87;
  cursor: pointer;
  margin-left: -16px;
  white-space: nowrap;

  &:hover {
    .soulcup-progress-goal__description,
    .soulcup-progress-goal__ride-number {
      text-decoration: underline;
    }
  }

  &__goal-tags {
    height: 39px;
  }

  &__tag-up-next,
  &__tag-next,
  &__tag-achieved,
  &__tag-your {
    .font-family(heavy);
    background-color: @gray-87;
    box-sizing: border-box;
    color: @scWhiteC;
    display: none;
    font-size: 0.875rem;
    height: 24px;
    letter-spacing: 2.1px;
    margin: 0 8px 0 0;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
  }

  &--up-next .soulcup-progress-goal__tag-up-next,
  &--your-goal .soulcup-progress-goal__tag-your,
  &--next-goal .soulcup-progress-goal__tag-next,
  &--goal-achieved .soulcup-progress-goal__tag-achieved {
    display: inline-block;
  }

  &__tag-next {
    background-color: @gray-soultogether;
    color: @gray-87;
  }

  &__tag-up-next,
  &__tag-achieved {
    background-color: @scYellowC;
    color: @gray-87;
  }

  &__media-ride-number {
    border: 3px solid @gray-59;
    color: @gray-darker;
    font-size: 2.5rem;
    height: 100px;
    line-height: 2.7;
    width: 100px;

    .goal-achieved & {
      background-color: @scYellowC;
    }
  }

  &__description {
    margin-left: 20px;
  }

  &__ride-number {
    .font-family(heavy);
    background: none;
    border: 0;
    color: @gray-87;
    font-size: 2.25rem;
    height: auto;
    line-height: 1;
    text-align: left;
    width: auto;

    &:after {
      background: center / contain no-repeat url('/assets/images/campaign/soultogether-goal-arrow.svg');
      content: '';
      display: inline-block;
      height: 25px;
      vertical-align: middle;
      width: 15px;
    }
  }

  &__achieved-date-location {
    font-size: 0.875rem;
    height: 29px;
    line-height: 1.875rem;
  }
}

.soulcup-top-message {
  box-sizing: border-box;
  display: none;
  overflow: hidden;
  padding: 20px;
  position: fixed;
  text-align: center;
  top: 55px;
  width: 100%;
  z-index: 1;

  .respond-to(@bpTablet, {
    top: 95px;
  });

  .respond-to(@bpDesktopMedium, {
    top: 55px;
  });

  &--open {
    display: block;
  }

  &__joined,
  &__left {
    display: none;
  }

  &__content {
    background: @white;
    display: none;
    padding: 40px 20px;
    position: relative;
  }

  &__close {
    background: none;
    border: 0;
    cursor: pointer;
    height: 16px;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
  }

  &__close-icon {
    height: 100%;
  }

  &__title {
    .font-family(heavy);
    color: @gray-darker;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  &__subtext {
    color: @gray-59;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
