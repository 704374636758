.contact-us-modal {
  .siya-container {
    display: none;
    height: 100%;
    width: 100%;
    .box-sizing(border-box);
    text-align: center;
    padding: 6%;
    top: 65px;
    position: fixed;
    overflow: scroll;
    h2{
      font-size: 16px;
      .font-family(medium);
      line-height: 1.14em;
      margin-bottom: 10px;
      letter-spacing: 0px;
    }
    input, textarea, select {
      width: 100%;
      margin-right: 0;
    }
    input, textarea {
      background-color: @gray-main;
    }
    input:focus, textarea:focus {
      background-color: @scWhiteC;
    }
    textarea {
      min-height: 100px;
      margin: 0px;
    }
    select:invalid {
      color: @gray-dark;
    }
    form {
      width: 280px;
      margin: 0 auto;
      #siya-submit-btn {
        margin: 6px auto 50px;
        background-color: #FEE000;
      }
    }
    label.error {
      font-size: 12px;
      .font-family(medium);
      color: @error;
      float: left;
      margin-top: -5px;
      margin-bottom: 2px;
      .error-message {
        float: left;
        clear: both;
        padding-top: 10px;
        top: 0;
      }
    }
    input.error, select.error {
      margin-left: 0px !important;
      border: 1px solid @error !important;
    }
  }
}

/* MEDIA QUERIES - CONTACT US STYLES
-----------------------------------------------------------------------------*/

@media only screen and (min-width: 600px) {
  .contact-us-modal {
    .siya-container {
      h2 {
        font-size: 1.15em;
      }
      form {
        padding: 5% 15%;
        #siya-submit-btn {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .contact-us-modal {
    .siya-container {
      font-size: 1.18em;
      padding: 7% 10% 0;
      position: relative;
      left: 0;
      top: 0;
      right: 0;
      margin: 0;
      letter-spacing: .5px;
      overflow: hidden;
      form {
        padding: 5% 20%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .contact-us-modal {
    .siya-container {
      padding: 10% 5% 0;
      form {
        padding: 5% 22%;
        button {
          font-size: 0.8em;
          padding: 5px;
        }
      }
    }
  }
}