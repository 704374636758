/*
New To Soul/Experience/people Page Styles
*/

.new-to-soul__section--people {
  .flex-display(flex);
}

.new-to-soul__section__people-content-wrapper {
  .flex-display(flex);
  .flex-wrap(wrap);
  .justify-content(center);
  background-color: @scWhiteC;
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    .justify-content(flex-end);
  });
}

.new-to-soul__section__people-background {
  background-size: cover;
  height: 25rem;
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    height: 40rem;
  });
}

.new-to-soul__section__people-description {
  .flex-direction(column);
  .flex-display(flex);
  background-color: @scWhiteC;
  color: @gray-darker;
  font-size: 0.937rem;
  line-height: 1.5;
  max-width: 23.4375rem;
  padding: 3rem 1rem 3.5rem;
  text-shadow: 0 2px 15px 0 @gray-67;
  z-index: 2;

  h3 {
    font-size: 2.5rem;
  }

  p {
    .font-family(roman);
    max-width: inherit;
  }

  .respond-to(@bpMobileLarge, {
    margin-top: -4rem;
    max-width: 34.75rem;
    padding: 0;
  });

  .respond-to(@bpTablet, {
    margin-top: -2rem;
    max-width: 37.5rem;

    &.is-old-browser {
      max-width: 31.5rem;
    }
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 1.125rem;
    height: fit-content;
    max-width: none;
    margin: 10% 0 0;
    position: absolute;
    width: 37.5%;

    h3 {
      font-size: 3.12rem;
    }

    p {
      margin-top: 1rem;
    }

    &.is-old-browser {
      max-width: none;
    }
  });
}

.new-to-soul__section--people-description-text-wrapper {
  max-width: inherit;

  .respond-to(@bpMobileLarge, {
    margin: 2rem 1.625rem 3.5rem;
  });

  .respond-to(@bpTablet, {
    margin: 2rem 3rem 3.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    margin: 2.5rem 2.5rem 3.5rem;
  });
}

.new-to-soul__section__people-description-button-wrapper {
  .align-items(end);
  .flex-display(flex);
  .justify-content(flex-start);

  .button__text__icon-link {
    .mixin-text-icon-link(
      @fontFamily: bold,
      @fontSize: 0.6875rem,
      @hoverColor: @gray-59,
      @letterSpacing: 0.125rem
    );
    line-height: 0.9375rem;
    margin: 0;
    padding: 0;

    svg {
      .transform(rotate(180deg));
    }
  }
}

.new-to-soul__section__people-description-right-arrow {
  font-size: 0.8rem;
  margin-left: 0.625rem;
  position: static;
}
