@font-face {
  font-family: 'HelveticaNeueLTStd-Roman';
  src: url('@{font_uri_path}/helveticaneueltstdroman.eot');
  src: url('@{font_uri_path}/helveticaneueltstdroman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('@{font_uri_path}/helveticaneueltstdlt.eot');
  src: url('@{font_uri_path}/helveticaneueltstdlt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Bd';
  src: url('@{font_uri_path}/helveticaneueltstdbd.eot');
  src: url('@{font_uri_path}/helveticaneueltstdbd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Md';
  src: url('@{font_uri_path}/helveticaneueltstdmd.eot');
  src: url('@{font_uri_path}/helveticaneueltstdmd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Th';
  src: url('@{font_uri_path}/helveticaneueltstdth.eot');
  src: url('@{font_uri_path}/helveticaneueltstdth.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Cn';
  src: url('@{font_uri_path}/helveticaneueltstdcn.eot');
  src: url('@{font_uri_path}/helveticaneueltstdcn.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-CnBlk';
  src: url('@{font_uri_path}/helveticaneueltstdcnblk.eot');
  src: url('@{font_uri_path}/helveticaneueltstdcnblk.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// heavy
@font-face {
  font-family: "HelveticaNeueHeavy";
  src: url('@{font_uri_path}/helveticaneueltstdhv.eot');
  src: url('@{font_uri_path}/helveticaneueltstdhv.woff') format('woff');
}

// Same fonts but with their vertical metrics normalized for consistency between macOS / Windows

@font-face {
  font-family: 'HelveticaNeueLTStd-Roman-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdroman.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdroman.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Lt-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdlt.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdlt.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Bd-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdbd.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdbd.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Md-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdmd.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdmd.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Th-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdth.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdth.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Cn-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdcn.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdcn.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-CnBlk-Normalized';
  src: url('@{font_uri_path}/normalized/helveticaneueltstdcnblk.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdcnblk.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Hv-Normalized";
  src: url('@{font_uri_path}/normalized/helveticaneueltstdhv.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdhv.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueLTStd-BdOu-Normalized";
  src: url('@{font_uri_path}/normalized/helveticaneueltstdbdou.eot');
  src: url('@{font_uri_path}/normalized/helveticaneueltstdbdou.woff') format('woff');
  font-display: swap;
}
