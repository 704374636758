.pref__instructors__modal {
  background-color: @gray-30;
  display: none;
  height: 82vh;
  left: 0%;
  position: fixed;
  top: 8.3125rem;
  width: 100%;
  z-index: 10;

  .respond-to(@bpDesktopSmall, {
    height: 90vh;
    top: 3.75rem;
  });

  & .pref__favorite__instructor {
    &:hover {
      transform: none;
    }
  }
}

.pref__instructors__modal--active {
  display: block;
}

.pref__instructors__modal-inner {
  .box-shadow(0 0.0625rem 0.375rem 0.0625rem @gray-dark);
  .box-sizing(border-box);
  .transform(translateX(-50%));
  background-color: @scWhiteC;
  height: 100%;
  left: 50%;
  overflow: auto;
  padding: 3rem 2em;
  position: absolute;
  top: 1rem;
  width: 90%;

  .respond-to(@bpDesktopSmall, {
    .transform(translate(-50%, -50%));
    height: 80%;
    top: 50%;
    width: 70%;
  });
}

.pref__instructors__modal__close {
  .default-browser-focus;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 2rem;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: -1.5rem;
  width: 2rem;
}

.pref__instructors__modal__close-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.pref__instructors__search {
  display: block;
  margin: 1rem 0;
  position: relative;
}

input.pref__instructors__search__input {
  margin: 0;
  padding: 1rem 0.9375rem;
  width: 100%;
}

.pref__instructors__list {
  .flex-display();
  .flex-wrap(wrap);
  .justify-content(start);
  list-style: none;
}

.pref__instructors__modal {
  & .pref__favorite__instructor {
    .default-browser-focus;
    .transition(all, .2s, ease-in-out);

    &:hover {
      opacity: 1;

      + .pref__favorite__heart {
        background-color: @scYellowC;

        & .pref__favorite__heart-icon {
          opacity: 0.5;
        }
      }

      &:active {
        .transform(scale(1.1));
      }
    }
  }
}

.pref__instructor__item {
  margin-top: 2rem;
  text-align: center;
  width: 50%;

  .respond-to(@bpMobileMedium, {
    width: 33.333%;
  });

  .respond-to(@bpTablet, {
    width: 25%;
  });

  .pref__favorite__remove-button {
    display: none !important;
  }
}

.pref__favorite__heart {
  .border-radius(50%);
  .box-shadow(0 0.125rem 0.3125rem 0 @gray-light);
  .default-browser-focus;
  background-color: @scWhiteC;
  border: 0;
  cursor: pointer;
  height: 1.875rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.875rem;
  z-index: 1;

  &:hover {
    background-color: @scYellowC;

    & .pref__favorite__heart-icon {
      opacity: 0.5;
    }
  }
}

.pref__favorite__heart--faved {
  background-color: @scYellowC;

  & .pref__favorite__heart-icon {
    opacity: 0.5;
  }
}

.pref__favorite__heart-icon {
  .transform(translate(-50%,-50%));
  color: @gray-light;
  left: 50%;
  opacity: 0.2;
  position: absolute;
  top: 50%;
}

.pref__instructors__modal__button {
  .border-radius(0.25rem);
  background-color: @scWhiteC;
  border: @borderDarkGray;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 2rem auto 1rem;
  padding: 0.625rem 0;
  width: 10.3125rem;
}
