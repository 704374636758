.global-form__header {
  margin-bottom: 1.5rem;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    margin-bottom: 3rem;
  });
}

.global-form__title {
  .font-family(medium);
  font-size: 1.3rem;
  line-height: 1.75rem;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.5rem;
    line-height: 2rem;
  });

  .respond-to(@bpDesktopMedium, {
    .font-family(medium);
    font-size: 1.75rem;
    line-height: 2.25rem;
  });
}

.global-form__subtitle {
  .font-family(medium);
  color: @gray-darker;
  font-size: 0.4rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  line-height: 0.75rem;
  margin: 1rem 0 0;
  text-align: center;
  text-transform: uppercase;

  .respond-to(@bpDesktopSmall, {
    font-size: 0.6875rem;
    line-height: 0.9375rem;
  });
}

.global-form-container {
  margin-bottom: 1rem;
}

.global-form__error {
  .font-family(medium);
  color: @red-error;
  font-size: 0.8125rem;
  margin: 0 0 1.5rem;
}

.authentication-form__error {
  .font-family(medium);
  color: @red-error;
  font-size: 0.8125rem;
  margin: 0 0 1.5rem;
}

.global-form__buttons-container {
  .mixin-buttons-1x2();
}

.global-form__buttons-container--vertical {
  .mixin-buttons-vertical-1x2();
}
