.privacy {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 2rem auto 5rem auto;
    width: 19rem;

    h1 {
        .font-family(bold);
        font-size: 1.5rem;
        font-weight: bold;
        padding: 2rem 0;
        text-align: center;
    }

    p {
        .font-family(roman);
    }

    button {
        border-radius: 0.3rem;
    }

    #privacy-form {
        align-items: center;
        display: flex;
        flex-direction: column;

        button:disabled{
            background-color: #9B9B9B;
            color: #000;
            .font-family(roman);
            font-size: 0.875rem;
            height: 3rem;
            width: 100%;
        }

        button {
            span {
                position: relative;
                top: .5rem;

                .respond-to(@bpTablet, {
                    position: relative;
                    top: initial;
                });
            }
        }

        .privacy-btn {
            .font-family(roman);
            font-size: 0.875rem;
            height: 3rem;
            line-height: 1rem;
            width: 100%;
    
            .respond-to(@bpTablet, {
                line-height: 1.5rem;
            });
        }
    }

    .privacy-header-p {
        color: @abbeyGray;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin: 2rem auto 3rem auto;
    }

    .privacy-region {
        flex-direction: column;
        display: flex;
        width: 19rem;

        .privacy-select {
            display: flex;
            flex-direction: column;
            margin: 0.5rem 0;

            label {
                .font-family(roman);
                font-size: 0.75rem;
            }

            select {
                background-image: url(/assets/images/black-dropdown.png);
                background-position: calc(~"100% - 0.35em") center;
                background-size: 0.5rem;
                border: 0;
                border-bottom: 0.01rem solid black;
                border-radius: 0px;
                .font-family(light);
                font-size: 1.57rem;
                margin: 0;
                padding: 0.5rem 1rem 0 0;
            }

            .respond-to(@bpTablet, {
                width: 50%;
            });
        }

        .respond-to(@bpTablet, {
            flex-direction: row;
            width: 38rem;

            .privacy-select:first-child {
                margin-right: 0.5rem;
            }
    
            .privacy-select:nth-child(2) {
                margin-left: 0.5rem;
            }
        });
    }

    .privacy-btn-container {
        color: #000;
        font-size: 0.75rem;
        padding: 1.5rem 0;
        width: 19rem;

        .privacy-btn-header {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0;

            h4 {
                .font-family(bold);
            }

            p {
                line-height: 1.06rem;
                margin: 0.5rem 0 0 0;
            }
        }

        .privacy-content {
            text-align: center;

            p {
                color: @gray-darker;
            }
        }

        .respond-to(@bpTablet, {
            width: 38rem;
        });
    }

    .respond-to(@bpTablet, {
        width: 38rem;
    });
}

#privacy-confirm {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    width: 18rem;

    h1 {
        .font-family(bold);
        font-size: 1.5rem;
        font-weight: bold;
        padding: 2rem 0;
        text-align: center;
    }

    button {
        box-sizing: border-box;
        .font-family(roman);
        font-size: 0.9rem;
        height: 3.1rem;
        line-height: 30px;
        margin: 0.75rem 0;
        width: 18.5rem;
    }

    #cancel-req-btn {
        .respond-to(@bpTablet, {
            margin: 0.5rem 0.5rem 0.5rem 0;
        });
    }

    #submit-req-btn {
        .respond-to(@bpTablet, {
            margin: 0.5rem 0 0.5rem 0.5rem;
        });
    }

    p {
        color: @abbeyGray;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
    
    .privacy-confirm-btn {
        color: #000;
        margin: 1.5rem 0;
        text-align: left;
    }

    .respond-to(@bpTablet, {
        width: 39rem;
    });

    .error-msg {
        color: @red-error;
        padding: 1rem;
        text-align: center;
    }
}

.privacy-thank-you {
    font-size: 1rem;
    
    input {
        background-color: @sc-yellow;
        padding: 1rem 4rem;
        margin: 2rem 0 0 0;
    }
}
