/* STUDIO GRID STYLES
-----------------------------------------------------------------------------*/
.newrider-experience__container {
  #studios-map-canvas {
    background-color: @gray-12-bg;
    background-size: 39.75rem 100%;
    height: 33rem;
    width: 100%;

    img {
      opacity: 1;
      transition: none;
    }

    .respond-to(@bpDesktopSmall, {
      height: 100%;
    });
  }
}

.newrider-experience__map-header {
  background-color: @sc-yellow;
  border: none;
  color: @gray-darker;
  font-size: 2.5rem;
  margin: 0 auto -1.8rem;
  padding: 0.6rem;
  text-align: center;
  width: 22rem;
  z-index: 1;

  .respond-to(@bpDesktopSmall, {
    font-size: 3.12rem;
    height: 2.8rem;
    margin: 0 auto -4rem;
    padding: 0.6rem 5rem;
  });
}

.newrider-experience__studio-elements {
  .respond-to(@bpDesktopSmall, {
    .flex(1);
    .flex-direction(row);
    .flex-display();
  });
}

.newrider-experience__studio-elements__map {
  color: @gray-darker;
  font-size: 0.875rem;
  margin: 0 0 1.56rem 0;

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    margin: 0;
  });
}

.newrider-experience__studio-elements__studio-list {
  .flex-direction(column);
  .flex-display();
  margin: 0 1.5rem;

  .map-info-window {
    color: @gray-darker;
    line-height: 1.35;
    overflow: hidden;
    white-space: nowrap;

    h3 {
      .font-family(bold);
      font-size: 1.6em;
      margin: 1.25rem 0.625rem 0.625rem;
    }

    div {
      line-height: 1.6em;
      margin-left: 0.625rem;
      width: 35%;
    }

    p {
      .font-family(bold);
      font-size: .9em;
      margin-left: 0.625rem;
    }
  }

  .respond-to(@bpMobileLarge, {
    margin: 0 3rem;
  });

  .respond-to(@bpDesktopSmall, {
    .flex(1);
    margin: 0;
  });
}

.newrider-experience__header-options {
  .flex-display();
  .justify-content(center);
  margin-bottom: 2rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0 3rem 2rem;
  });
}

.newrider-experience__region-dropdown {
  position: relative;
  text-align: center;
  width: 100%;
}

.newrider-experience__region-dropdown__button {
  .font-family(roman);
  background-color: @scWhiteC;
  border: 0.062rem solid @black;
  cursor: pointer;
  font-size: 1.56em;
  padding: 1rem 0;
  width: 100%;

  svg {
    .transform(rotate(270deg));
  }
}

.newrider-experience__region-dropdown__button__region-title {
  margin-right: 1.06rem;
}

.newrider-experience__region-dropdown__button__region {
  .font-family(bold);
}

.newrider-experience__region-dropdown-list {
  .box-sizing(border-box);
  background-color: @gray-main;
  border-bottom: 0.062rem solid @black;
  border-left: 0.062rem solid @black;
  border-right: 0.062rem solid @black;
  display: none;
  max-height: 18.75rem;
  overflow-y: scroll;
  position: absolute;
  top: 98%;
  width: 100%;
  z-index: 1;

  .region-select-row {
    padding: 0;
  }
}

.newrider-experience__region-dropdown-list--active {
  .flex-direction(column);
  .flex-display();
}

.newrider-experience__studio-blocks {
  .respond-to(@bpDesktopSmall, {
    margin: 0 3rem;
  });

  & .button__text__icon-link {
    .mixin-text-icon-link(
      @fontFamily: bold,
      @fontSize: 0.6875rem,
      @textTransform: uppercase
    );
    line-height: 0.875rem;
    margin: 0;
    padding: 0;

    svg {
      .transform(rotate(180deg));
    }
  }
}

.newrider-experience__studio-elements__studio-list__body {
  .flex-direction(column);
  .flex-display();

  .respond-to(@bpDesktopSmall, {
    height: 40rem;
    margin: 0;
    overflow-y: auto;
  });
}

.newrider-experience__region-header {
  margin-top: 0.125rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0 3rem;
  });
}

.newrider-experience__studio-wrapper {
  background-color: @scWhiteC;
  font-size: 0.875rem;
}

.newrider-experience__studio-wrapper__info-cc {
  .flex(1);
  .flex-direction(column);
  .flex-display();
  .font-family(roman);
  font-size: 0.875rem;
  left: 1.25rem;
  line-height: 1.375rem;
  top: 2.812rem;
}

.newrider-experience__info-cc__address {
  .flex-direction(column);
  .flex-display();
  margin: 0;
}

.newrider-experience__info-cc__street {
  .flex-display();
  .justify-content(space-between);
}

.newrider-experience__info-cc__name {
  .font-family(bold);
  display: block;
  font-size: 0.687rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

.newrider-experience__studio-wrapper__studio-link-wrapper {
  .align-self(flex-end);
  .flex-display();
  .justify-content(flex-end);
  min-width: 9.37rem;

  .icon-arrow-right-dark-small {
    margin-top: 0.125rem;
    position: static;
  }
}

.newrider-experience__studio-wrapper__studio-link {
  .font-family(bold);
  font-size: 0.687rem;
  margin-right: 0.431rem;
  text-transform: uppercase;
}

.newrider-experience__back-to-top {
  bottom: -1.5rem;
  height: 2.625rem;
  left: 0;
  margin: 0 auto;
  max-width: 9.375rem;
  position: absolute;
  right: 0;
  text-align: center;
  width: 9.375rem; // Need max-width and width for IE11 support.

  .respond-to(@bpDesktopSmall, {
    bottom: 1.25rem;
    z-index: 2;
  });
}

.newrider-experience__back-to-top__button {
  .font-family(bold);
  .box-shadow(1px 4px 15px -1px @gray-darker);
  background: url(/assets/images/dropdown_arrow_up.png) no-repeat 89% center;
  background-color: white;
  background-size: 0.875rem 0.5rem;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 0.687rem;
  padding: 1rem 2.5rem 1rem 1rem;
  text-indent: 0;
  text-transform: uppercase;
}

.info-line {
  background-color: @gray-38;
  content: '';
  display: block;
  height: 0.0625rem;
  margin: 2rem 0;
  width: 100%;
}
