.login-container {
  position: relative;
  width: 100%;

  .respond-to(@bpMobileSmall, {
    margin: 3rem auto;
  });

  .respond-to(@bpMobileLarge, {
    margin: 5rem auto;
  });


  label.field-error {
    text-align: left;

    span.error-message {
      top: -0.687rem;
      width: auto !important;
    }
  }
}

.login-inner-container {
  margin: 0 auto;
  width: 80%;

  .respond-to(@bpTablet, {
    max-width: 28.75rem;
    width: 67%;
  });
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-message {
  display: none;
  padding-bottom: 3rem;

  &.too-many-attempts {
    display: block;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  p > a {
    color: @blue-main;
  }
}

button.third-party-login-button {
  padding: 0px 35px 0px 35px;
  text-transform: none !important;
  min-width: 255px;
}

.third-party-login-text {
  display: inline-block;
  position: relative;
  top: -8px;
  vertical-align: bottom;
}

#register-with-google > span:first-child {
  margin-right: 15px;
}

#register-with-apple > span:first-child {
  margin-right: 8px;
  margin-left: -15px;
  display: inline-block;
  margin-top: 5px;
}

.global-form__unlinked-account {
  .font-family(medium);
  color: @gray-darker;
  font-size: 0.8125rem;
  margin: 0 0 1.5rem;
  background: @gray-main;
  padding: 1rem;
  text-align: center;
}
input:read-only {
    color: @gray-medium;
}
