.new_rider_create_account {
  .swiper-container {
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      overflow: visible;
    });
  }

  .swiper__slide {
    .flex-shrink(0);
    .justify-content(center);
    align-items: center;
    background: @scWhiteC;
    display: block;
    font-size: 1.125rem;
    height: 100%;
    opacity: 0.3;
    text-align: left;
    width: 100%;
  }

  .swiper__slide--active {
    opacity: 1;
  }

  .swiper-slide__step {
    margin: 1rem auto;
    width: 80%;

    .respond-to(@bpDesktopSmall, {
      margin-top: 1rem;
      width: 100%;
    });
  }

  .swiper-slide__step__title {
    .font-family(medium);
    color: @gray-darker;
    font-size: 0.4rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
    line-height: 0.75rem;
    text-align: center;
    text-transform: uppercase;

    .respond-to(@bpDesktopSmall, {
      font-size: 0.6875rem;
      line-height: 0.9375rem;
    });
  }

  .swiper-slide__step__indicator {
    .swiper-slide__step__title();
  }

  .swiper-slide__form {
    margin: 2.5rem auto 0;
    width: 80%;

    .respond-to(@bpMobileLarge, {
      width: 67%;
    });

    .respond-to(@bpTablet, {
      width: 28.75rem;
    });
  }

  .step3 {
    .swiper-slide__form {

      .respond-to(@bpDesktopSmall, {
        margin-top: 1.5625rem;
      });
    }
  }

  .swiper-slide__form__wrapper {
    display: inline-block;
    height: 5.2rem;
    position: relative;
    width: 100%;

    .swiper-slide__form__wrapper__input__extra-text {
      clear: both;
    }

    &.has-field-error {
      .swiper-slide__form__wrapper__input {
        border-color: @red-error;
      }
    }

    .respond-to(@bpDesktopSmall, {
      height: 5.22rem;
    });

    .swiper-slide__form__wrapper__input {
      border: none;  /* This need to be first to remove default border style */
      background-color: @transparent-color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0.0625rem solid @gray-darker;
      font-size: 1.625rem;
      margin: 0.625rem 0 0.9375rem 0;
      padding-bottom: 0;
      padding-left: 0.3rem;
      position: relative;
      width: 100%;
      z-index: 2;

      .respond-to(@bpMobileSmall, {
        margin-top: 1.6rem;
        padding-top: 0.6rem;
      });

      .respond-to(@bpDesktopSmall, {
        margin: 0.625rem 0 0.9375rem 0;
        padding-top: 0.2rem;
      });

      &:focus {
        &+ .swiper-slide__form__wrapper__label {
          color: @gray-darker;
          font-size: 0.75rem;
          left: 0;
          top: 0.75rem;
          z-index: 1;

          .respond-to(@bpDesktopSmall, {
            top: -0.6rem;
          });
        }
      }

      &+ .swiper-slide__form__wrapper__label {
        &.show {
          color: @gray-darker;
          font-size: 0.75rem;
          left: 0;
          top: 0.75rem;
          z-index: 1;

          .respond-to(@bpDesktopSmall, {
            top: -0.6rem;
          });
        }
      }
    }
  }

  .swiper-slide__form__wrapper__label {
    .transition(all, 0.1s, linear);
    color: @gray-lighter;
    cursor: auto;
    font-size: 1.625rem;
    left: 0;
    letter-spacing: 0;
    position: absolute;
    text-align: left;
    top: 1.5rem;
    z-index: 1;

    .respond-to(@bpMobileSmall, {
      top: 2.5rem;
    });

    .respond-to(@bpDesktopSmall, {
      top: 1.4rem;

    });
  }

  .swiper-slide__form__wrapper__field-error {
    color: @red-error;
    font-size: 0.75rem;
  }

  .swiper-slide__form__wrapper__checkbox__input {
    margin: 0;
    padding: 0;
    zoom: 1.7;
  }

  .swiper-slide__form__wrapper__checkbox__label {
    font-size: 0.9rem;
    margin: 0.4rem 0.3125rem 0;
    position: absolute;

    .respond-to(@bpDesktopSmall, {
      font-size: 0.9375rem;
      margin: 0.3125rem 0.3125rem 0;
    });
  }

  .swiper-slide__form__wrapper__terms {
    font-weight: bold;
  }

  .swiper-slide-prev {
    .respond-to(@bpDesktopSmall, {
      margin-left: -9%;
      padding-right: 9%;
    });
  }

  .swiper-slide-next {
    .respond-to(@bpDesktopSmall, {
      margin-left: 5rem;
    });

    .respond-to(@bpDesktopLarge, {
      margin-left: 4rem;
    });
  }

  .auth__swiper__progress {
    .transform(scaleX(0.25)); // js overrides this
    .transition(all, 0.2s);
    background-color: @black;
    display: none;
    height: 0.3125rem;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: left center;
    width: 100%;
  }

  .auth__swiper__progress--active {
    display: block;

    .respond-to(@bpDesktopSmall, {
        display: none;
    });
  }

  .swiper-slide__form__terms-and-conditions {
    display: inline-block;
    margin-bottom: 1.5rem;
    position: relative;
    width: 100%;

    .form_terms_and_conditions {
      border-radius: 0.5rem;
      border: 1px solid @gray-tiu;
      height: 6.25rem;
      margin: 1.125rem 0 0.75rem;
      padding: 0.5625rem 1.375rem 0 1rem;

      .respond-to(@bpMobileLarge, {
        max-width: 100%;
      });

      .respond-to(@bpTablet, {
        height: 8.75rem;
      });

      .footer-page-container {
        p {
          font-size: 0.6875rem;

          .respond-to(@bpTablet, {
            font-size: 0.75rem;
            line-height: 0.9375rem;
          });
        }

        .category-list {
          .question {
            font-size: 0.6875rem;

            .respond-to(@bpTablet, {
              font-size: 0.75rem;
              line-height: 0.9375rem;
            });
          }
        }
      }
    }

    .terms-agreement {
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin: 0 auto;
      text-align: center;
      width: 90%;

      .respond-to(@bpMobileLarge, {
        line-height: 1.375rem;
        width: 100%;
      });

      .respond-to(@bpTablet, {
        font-size: 0.8125rem;
        line-height: 1.375rem;
      });

      a {
        text-decoration: underline;
      }
    }
  }
}
