.suggested-series-container {
  .flex-direction(column);
  .flex-display(flex);
  height: 100%;

  .respond-to(@bpDesktopSmall, {
    .flex-direction(row);
  });
}

.suggested-series__sidebar {
  .flex(1);
  position: relative;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    .flex(2);
    min-height: calc(~"100vh - 7.189375rem");
  });

  &.is-old-browser {
    background-position: center center;
    background-size: cover;
    height: 12.5rem;
    min-height: 12.5rem;

    .respond-to(@bpDesktopSmall, {
      height: auto;
    });
  }
}

.suggested-series__sidebar_image {
  width: 100%;

  .respond-to(@bpMobileLarge, {
    height: 12.375rem;
    object-fit: cover;
  });

  .respond-to(@bpDesktopSmall, {
    height: 100%;
  });
}

.suggested-series__sidebar__message {
  .font-family(bold);
  color: @scWhiteC;
  font-size: 1.5rem;
  left: 0;
  letter-spacing: 0;
  line-height: 1.75rem;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-shadow: 0 0.125rem 1.4375rem @gray-38;
  top: 35%;
  width: 13.5rem;

  .respond-to(@bpMobileLarge, {
    .transform(translateY(-50%));
    font-size: 1.75rem;
    line-height: 2.25rem;
    top: 50%;
    width: auto;
  });

  .respond-to(@bpDesktopSmall, {
    display: none;
  });
}

.suggested-series__content,
.suggested-series__bottom {
  .flex-direction(column);
  .flex-display(flex);
  width: 100%;

  .respond-to(@bpMobileLarge, {
    width: auto;
  });

  .respond-to(@bpDesktopSmall, {
    align-self: center;
  });
}

.suggested-series__bottom {
  .align-items(right);
  margin-top: 1.125rem;

  .respond-to(@bpDesktopSmall, {
    .flex-direction(row);
    .justify-content(flex-end);
  });

  .respond-to(@bpTablet, {
    margin-bottom: 4.0625rem;
  });

  .respond-to(@bpDesktopSmall, {
    display: block;
    text-align: right;
    width: 100%;
  });

.suggested-series__all-series,
  .suggested-series__no-thanks {
    .font-family(medium);
    margin-top: 1.5rem;
  }

  .button__text-link {
    text-decoration: underline;
    text-transform: none;
  }

  .button__secondary {
    margin-bottom: 1.125rem;

    .respond-to(@bpDesktopSmall, {
      float: right;
      margin-bottom: 0;
      margin-left: 1.5rem;
    })
  }
}

.suggested-series__no-thanks {
  font-size: 0.8125rem;
  line-height: 0.9375rem;
}

.suggested-series-subcontainer {
  .flex(3);
  .flex-display(flex);
  .justify-content(center);
  padding: 2rem 1rem 3rem;

  .respond-to(@bpMobileLarge, {
    padding: 2rem 1.875rem 3rem;
  });

  .respond-to(@bpTablet, {
    padding: 2.5rem 7.125rem 0;
  });

  .respond-to(@bpDesktopSmall, {
    padding: 2rem 5.625rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding: 2rem 11.25rem;
  });

  &.is-old-browser {
    .flex(auto);
  }
}

.suggested-series__header {
  box-sizing: border-box;
  padding: 0 1.25rem 2rem;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    padding: 0 4.0625rem 2rem;
  });

  .respond-to(@bpTablet, {
    padding: 0 2.3125rem 2.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    padding: 0;
  });
}

.suggested-series__header,
.suggested-series__purchase,
.suggested-series__bottom {

  .respond-to(@bpMobileLarge, {
    max-width: 33.75rem;
  });

  .respond-to(@bpDesktopSmall, {
    max-width: 31.6875rem;
  });

  .respond-to(@bpDesktopLarge, {
    max-width: 40.5rem;
  });
}

.suggested-series__header__message {
  .font-family(bold);
  color: @gray-darker;
  display: none;
  font-size: 1.5rem;
  line-height: 2.25rem;

  .respond-to(@bpDesktopSmall, {
    display: block;
    font-size: 1.75rem;
    line-height: 2.25rem;
  });
}

.suggested-series__header__description {
  .font-family(roman);
  color: @gray-darker;
  font-size: 1rem;
  line-height: 1.5rem;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.25rem;
    margin: 1.5625rem 0;
  });
}

.suggested-series__purchase {
  .flex-direction(column);
  .flex-display(flex);
  .flex-wrap(wrap);
  .justify-content(space-between);
  width: 100%;

  .respond-to(@bpMobileLarge, {
    .flex-direction(row);
    .flex-wrap(nowrap);
  });

  .respond-to(@bpDesktopSmall, {
    .flex-direction(row);
  });
}

// card layout
.suggested-series__card-container {
  .box-sizing(border-box);
  margin-bottom: 2rem;

  .respond-to(@bpMobileLarge, {
    width: 50%;
    margin-right: 2rem;
  });

  &:first-child {
    .respond-to(@bpMobileLarge, {
      margin-right: 0.75rem;
    });

    .respond-to(@bpDesktopSmall, {
      margin-right: 1.5rem;
    });

    .respond-to(@bpDesktopLarge, {
      margin-right: 4rem;
    });
  }
}
