// SHARED STYLES
.aboutme__section__title {
  .font-family(light);
  border-bottom: @borderGrayHex97;
  color: @gray-darker;
  font-size: 0.875rem;
  padding-bottom: 0.3125rem;
}

.aboutme__section-container {
  clear: both;
  margin-bottom: 2.5rem;
}

.aboutme__section-inner-container {
  padding-top: 1.25rem;
  a {
    .default-browser-focus;
    .font-family(medium);
    .right-arrow;
    color: @gray;
    cursor: pointer;
    display: block;
    font-size: 0.875rem;
  }
}

.aboutme__form {
  & input,
  & select {
    .default-browser-focus;
    display: none;
  }
}

.aboutme__label {
  .font-family(medium);
  color: @gray-darker;
  font-size: .857rem;
}

.aboutme__input__text {
  .border-radius(0.3125rem);
  border: 0.0625rem solid @borderLightGray;
}

.aboutme__value {
  .font-family(medium);
  color: @gray-darker;
  font-size: .857rem;
  margin-bottom: 0.3125rem;
  padding: 0;
}

.personal__option {
  & select {
    padding: 0;
  }

  & .field-error {
    display: none;
  }
}
// END SHARED STYLES

// HEADER CONTAINER - BIRTHDAY/GENDER
.aboutme__personal__form {
  display: none;
}

.aboutme__personal__form--active {
  display: block;
}

.aboutme__header-container {
  background-color: @gray-darker-70;
  left: 50%;
  margin: -3rem -50vw 3rem -50vw;
  position: relative;
  right: 50%;
  width: 100vw;

  .respond-to(@bpTablet, {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    width: auto;
    left: 0;
    right: 0;
  });
}

// reset the exterior containers restrictions
.aboutme__header-inner-container {
  &:extend(.profile-mysoul-inner-container);
  color: @scWhiteC;
  padding-bottom: 0.6125rem;
  padding-top: 3.125rem;

  .respond-to(@bpTablet, {
    margin-left: -5rem;
    margin-right: -5rem;
  });

  .respond-to(@bpDesktopSmall, {
    margin-left: -8.75rem;
    margin-right: -8.75rem;
  });

  .respond-to(@bpDesktopLarge, {
    margin-left: -20rem;
    margin-right: -20rem;
  });
}

.aboutme__birthday__header {
  margin-bottom: 2rem;
}

.birthday__header__new {
  .font-family(heavy);
  background-color: @scYellowC;
  color: @gray-darker;
  display: inline-block;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  margin-bottom: 0.3125rem;
  padding: 0.376rem 0.1875rem 0.1875rem 0.3125rem;
}

.birthday__header__text {
  .font-family(medium);
  font-size: 0.875rem;
}

.birthday__title,
.gender__title {
  color: @scWhiteC;
}

.personal__value__birthday-day {
  text-indent: -0.0625rem;
}

.personal__value__birthday-year {
  text-indent: -0.1875rem;
}

.personal__option__birthday,
.personal__gender {
  display: inline-block;

  & .aboutme__value {
    color: @scWhiteC;
  }
}

.gender-container {
  margin-bottom: 1.5rem;
}

.birthday__controls-container {
  margin-bottom: 1.5rem;
}
// END HEADER CONTAINER

// PERSONAL INFO
.personal__option {
  & input,
  & .allow-dropdown {
    display: none;
  }

  & input {
    .font-family(medium);
    background-color: transparent;
    color: @gray-darker;
    font-size: 0.857rem;
    margin-bottom: 0.875rem;
    padding: 1rem 0.9375rem;
    position: relative;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      width: 19rem;
    });
  }

  & .allow-dropdown {
    &.intl-tel-input {
      & .flag-container {
        top: 0 !important;
      }
    }
  }
}

.personal__firstname,
.personal__email,
.personal__emergency {
  .respond-to(@bpDesktopSmall, {
    float: left;
    margin-right: 0.3125rem;
  });
}

.personal__phone {
  clear: both;
}

.personal__password__form {
  clear: both;
}

.personal__password__form,
.personal__emergency__form {
  .respond-to(@bpDesktopSmall, {
    .flex-display(flex);
  });
}

.personal__password__option,
.personal__emergency__option {
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    width: 50%;
  });
}

.personal__value__password {
  .default-browser-focus;
  border: none;
  cursor: pointer;
}
// END PERSONAL INFO

// PAYMENT INFO
.payment__cc {
  position: relative;

  .payment__cc__edit {
    background-color: @scWhiteC;
  }
}

.payment__value {
  color: @gray-darker;
  float: left;
  margin-bottom: 1rem;
  padding: 1rem 0.9375rem 1rem 0;
}

.payment__delete__button {
  .default-browser-focus;
  border: none;
  cursor: pointer;
  margin-left: 1.5rem;
  opacity: 0.5;
  padding-top: 1rem;
}

.payment__cc__edit {
  .default-browser-focus;
  .font-family(medium);
  .right-arrow;
  border: none;
  clear: both;
  color: @gray;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
}

.payment__input {
  display: none;
  float: left;
}
// END PAYMENT INFO

// BIKE SETUP
.bikesetup__form {
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    .flex-display(flex);
    .flex-wrap(wrap);
  });
}

.bikesetup__option {
  .box-sizing(border-box);
  .flex-display(flex);
  .justify-content(start);
  .align-items(center);
  height: 1.75rem;

  & input {
    width: auto;
  }

  & select {
    margin-right: 0;
  }

  & input,
  & select {
    .default-browser-focus;
    display: none;
  }

  .respond-to(@bpDesktopSmall, {
    width: 50%;

    &:nth-child(2n) {
      padding-left: 1.25rem;
    }
  });
}

.bikesetup__value {
  margin: 0;
  padding: 0 0.3125rem;
}
// END BIKE SETUP

// CONTROLS
.aboutme__controls-container {
  .flex-direction(column);
  .flex-display(flex);
  margin: 3rem auto;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    .align-items(center);
    .flex-direction(row-reverse);
    .flex-display(flex);
    .justify-content(space-between);
  });

  &.aboutme__edit-top-container {
    .align-items(flex-end);
    .flex-flow(column);
    margin: 0;
    margin-bottom: 5rem;

    .aboutme__edit {
      margin: 0;
    }
  }
}

.aboutme__edit {
  .border-radius(0.25rem);
  .default-browser-focus;
  background-color: @scWhiteC;
  border-color: @gray-darker;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 0.3125rem 0;
  width: 6.25rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });
}

.aboutme__edit__birthday {
  background-color: @scWhiteC;
}

.aboutme__edit-container {
  display: table;
  width: 100%;
}

.aboutme__edit__text,
.aboutme__edit__icon-container {
  display: table-cell;
  font-size: 0.75rem;
  vertical-align: middle;

  .respond-to(@bpDesktopSmall, {
    width: 50%;
  });
}

.aboutme__edit__text {
  text-align: right;
}

.aboutme__edit__icon-container {
  text-align: left;
}

.aboutme__edit__icon {
  margin-left: 0.3125rem;
}

.aboutme__handle-container {
  display: none;
  text-align: center;
}

.aboutme__save {
  .border-radius(0.25rem);
  .default-browser-focus;
  .font-family(medium);
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.625rem 0;
  width: 10.3125rem;

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });

  .respond-to(@bpMobileLarge, {
    display: inline-block;
  });
}

.aboutme__cancel {
  .default-browser-focus;
  .font-family(medium);
  background-color: @scWhiteC;
  border: none;
  color: @gray-darker;
  cursor: pointer;
  font-size: 0.875rem;
  margin-right: 24px;

  &:hover {
    color: @black;
    text-decoration: none;
  }
}

.aboutme__save {
  background-color: @scYellowC;

  .aboutme__edit-action-contents {
    .align-items(center);
    .flex-display(flex);
    .justify-content(center);

    .aboutme__edit__text,
    .aboutme__edit__icon-container {
      text-align: initial;
      width: auto;
    }
  }
}

.aboutme__delete-account {
  .default-browser-focus;
  .font-family(light);
  background-color: @scWhiteC;
  border: none;
  color: @gray-dark;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 2rem;

  &:hover {
    color: @black;
    text-decoration: none;
  }

  .respond-to(@bpDesktopSmall, {
    margin: 0;
  });
}
// END CONTROLS

// DELETE ACCOUNT MODAL
.aboutme__modal {
  background: @gray-50;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 101;
}

.modal__delete-container {
  display: none;
}

.modal__delete-container--active {
  display: block;
}

.modal__delete {
  .border-radius(0.25rem;);
  .box-sizing(border-box);
  .transition(all 0.2s ease-in-out);
  background-color: @scWhiteC;
  border: @borderLightGray;
  display: inline-block;
  left: 0;
  margin: 0 auto;
  padding: 2.5rem 1.125rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 30%;
  width: 18.75rem;

  .respond-to(@bpTablet, {
    padding: 2.5rem 3.75rem;
    width: 25rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding: 5rem 10rem;
    width: 38rem;
  });
}

.modal__delete__title {
  .font-family(bold);
  display: block;
  font-size: 1.6em;
  margin-bottom: 1.25rem;
}

.modal__delete__message {
  color: @gray-darker;
  font-size: 1.3em;
  line-height: 1.6;
  margin: 0 0 1.25rem;
}

.modal__delete__cancel,
.modal__delete__submit {
  .border-radius(0.1875rem);
  .default-browser-focus;
  .font-family(medium);
  border: none;
  color: @gray-darker;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.875rem;
  margin-top: 0.8125rem;
  padding: 0.1875rem;
  position: relative;
  text-align: center;
  width: 7.813rem;
}

.modal__delete__cancel {
  background-color: @gray-light;

  .respond-to(@bpTablet, {
    margin-right: 0.75rem;
  });
}

.modal__delete__submit {
  background-color: @scYellowC;
}
// END DELETE ACCOUNT MODAL

// CUSTOM DROPDOWNS
.custom__select {
  cursor: pointer;
  color: @black;
  display: none;
  font-size: 0.875rem;
  margin-right: 0.3125rem;
  min-height: 3.125rem;
  min-width: 4.75rem;
  position: relative;

  .respond-to(@bpMobileLarge, {
    min-width: 5.625rem;
  });

  & .field-error {
    display: block;
    position: relative;
    top: 4.063rem;

    & span.error-message {
      white-space: normal !important;
      width: 100% !important;
    }
  }
}

.personal__password__option.personal__option input {
  display: block;

  &[readonly] {
    background-color: @gray-main;
    cursor: pointer;
  }
}

.personal__option__birthday,
.personal__option__gender {
  .custom__select {
    margin-right: 0;
    width: 100%;
  }
}

.custom__select--hidden {
  display: none !important;
  padding-right: 0.6125rem;
  visibility: hidden !important;
}

.custom__select--styled {
  .border-radius(0.3125rem);
  .default-browser-focus;
  background-color: @scWhiteC;
  border: 0.0625rem solid @gray-light;
  bottom: 0;
  cursor: pointer;
  font-size: 0.875rem;
  left: 0;
  line-height: 0.875rem;
  padding: 1.25rem 0.625rem 1.875rem;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 100%;

  .respond-to(@bpMobileLarge, {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  });

  &:after {
    content:"";
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.3125rem solid @gray-darker;
    height: 0;
    margin-top: -0.125rem;
    position: absolute;
    right: 0.75rem;
    top: 50%;
    width: 0;
  }
}

.custom__select__options {
  .border-radius(0.3125rem);
  .scrollbar(@black, 0.6125rem, 0.3125rem);
  background-color: @scWhiteC;
  border: 1px solid @gray-38;
  display: none;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 12.5rem;
  overflow-y: scroll;
  padding: 0.4375rem 0 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.custom__select__item {
  .default-browser-focus;
  .transition(all 0.15s ease-in);
  background-color: @scWhiteC;
  border: none;
  color: @gray-38;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.75rem 0 0.75rem 0.6125rem;
  text-align: left;
  text-indent: 0.6125rem;
  width: 100%;

  &:hover,
  &:checked,
  &:active {
    color: @gray-darker;
  }
}

.custom__select__item-default {
  color: @gray-darker;
}

// PAYMENT EDIT FORM
.back__to__personal {
  .default-browser-focus;
  .font-family(bold);
  background: none;
  border: 0;
  color: @gray-dark;
  cursor: pointer;
  font-size: 1rem;
  margin: 2rem 0;
  padding: 0;
}

.aboutme__payment__form {
  display: none;
  margin-top: -3rem;

  .aboutme__label {
    display: block;
    margin-bottom: 0.3125rem;
  }

  .custom__select {
    width: 100%;
  }

  .custom__select--styled {
    width: 98%;
  }
}

.aboutme__payment__form--active {
  display: block;
}

.aboutme__payment_accepted_cc {
  .respond-to(@bpDesktopSmall, {
    .flex-display(flex);
  });
}

.accepted__cards-container {
  margin-bottom: 1rem;
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    display: inline-block;
    margin-right: 0.6125rem;
    width: 50%;
  });
}

.accepted__cards-image {
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    position: relative;
    top: 1rem;
  });
}

.personal__form__cc-wrapper {
  margin-top: 1rem;
  white-space: nowrap;

  .respond-to(@bpDesktopSmall, {
    .flex-display(flex);
  });
}

.personal__payment__cc__num {
  .respond-to(@bpDesktopSmall, {
    width: 50%;
  });
 }

.personal__option__payment__cc__month,
.personal__option__payment__cc__year {
  display: inline-block;
  margin-bottom: 1rem;
  width: 50%;

  .respond-to(@bpDesktopSmall, {
    margin-right: 1rem;
    width: 25%;
  });
}

.personal__option__payment__cc__year {
  margin-right: 0.6125rem;
}

.cards-div {
  .adyen_cc_label {
    display: inline-block;
  }

  .input-field {
    border-radius: 0.245rem;
    border: @borderLightGray;
    display: block;
    height: 3.125rem;
    margin-bottom: 0.5rem;
    margin-right: .6125rem;

    &.adyen-input-error {
      border: 1px solid @red-error;
    }

    &.adyen-input-focus {
      border-color: rgba(59, 153, 252, 0);
      outline: 0;
      outline: thin dotted \9;
      outline: -webkit-focus-ring-color auto 0.3125rem;
    }
  }

  .cc-ccv {
    width: 18.13rem;
  }
}
.personal__option__payment__cc__ccv {
  display: block;
  position: relative;

  & input.cc-ccv {
    width: 18.13rem;
  }

  .respond-to(@bpDesktopSmall, {
    display: inline-block;
    width: 50%;
  });
}

.personal__option__ccv__info {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 0.3785rem;
  top: 1rem;

  .respond-to(@bpDesktopSmall, {
    right: -0.6125rem;
  });

  & .tooltip {
    background-color: @scWhiteC;
    border: @borderDarkGray;
    display: none;
    left: auto;
    padding: 0.5em;
    position: absolute;
    right: -0.4em;
    top: calc(100% + 0.5em);
    z-index: 10;

    &:after {
      .transform(rotate(45deg));
      content: '';
      border-bottom: none;
      border-left: @borderDarkGray;
      border-right: none;
      border-top: @borderDarkGray;
      display: block;
      height: 0.5rem;
      left: auto;
      position: absolute;
      right: 0.375rem;
      top: 0.375rem;
      width:  0.5rem;
    }

    &:before {
      border-right: none !important;
    }
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

.personal__form__billing__address,
.personal__form__shipping__address {
  .personal__option {
    margin-bottom: 1rem;
  }

  .aboutme__label {
    display: block;
  }
}

.aboutme__payment__billing__name-container,
.aboutme__payment__billing__address-container,
.aboutme__payment__billing__city-container,
.aboutme__payment__shipping__name-container,
.aboutme__payment__shipping__address-container,
.aboutme__payment__shipping__city-container {
  .flex-display(flex);
  .flex-wrap(wrap);
  white-space: nowrap;

  .respond-to(@bpDesktopSmall, {
    .flex-wrap(nowrap);
    white-space: nowrap;
    width: 100%;
  });

  & .personal__option {
    display: inline-block;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      display: inline-block;
      width: 50%;
    });
  }
}

.personal__option__billing__country,
.personal__option__shipping__country,
.personal__option__billing__zip,
.personal__option__shipping__zip {
  &.personal__option{
    margin-bottom: 2rem;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      width: 50%;
    });
  }
}

.shipping__checkbox__field {
  .border-radius(0.1875rem);
  .box-sizing(border-box);
  background-color: transparent;
  height: 1.125rem;
  margin-left: 0;
  margin-right: 0.375rem;
  margin-top: 0.75rem;
  padding: 0;
  width: 100%;

  .respond-to(@bpDesktopSmall, {
    width: 50%;
  });

   & label {
     display: inline-block;
   }

   & input {
    margin-left: 0;
    margin-right: 0;
    width: 1.5rem;
   }
}

.payment__controls-container {
  margin: 3rem auto;
  text-align: center;
}

.payment__cancel,
.payment__save {
  .border-radius(0.25rem);
  .default-browser-focus;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  margin: 0 auto;
  padding: 0.625rem 0;
  width: 10.3125rem;

  .respond-to(@bpMobileLarge, {
    display: inline-block;
  });
}

.payment__save {
  background-color: @scYellowC;
}
// END PAYMENT EDIT FORM

// EDIT MODE
.edit-mode {
  .personal__form__birthday,
  .personal__form__gender {
    min-height: 3rem;
    white-space: nowrap;
  }

  .personal__option__birthday {
    width: 33%;

    .respond-to(@bpDesktopSmall, {
      width: 16.6%;
    });

    &:last-child {
      select {
        margin-right: 0;
      }
    }

    & .personal__value {
      display: none;
    }
  }

  .personal__option__gender {
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      width: 50%;
    });
  }

  .personal__gender {
    &.personal__value {
      display: none;
    }
  }

  .personal__option {
    & input,
    & .allow-dropdown {
      display: block;
    }

    & .intl-tel-input {
      float: none;
    }

    & .field-error {
      display: block;
    }
  }

  .personal__name-container,
  .personal__email-container,
  .personal__password__form,
  .personal__emergency__form {
    display: block;

    .respond-to(@bpDesktopSmall, {
      .flex-display(flex);
    });

    & .personal__option {
      .respond-to(@bpDesktopSmall, {
        width: 50%;
      });
    }
  }

  .bikesetup__option {
    .justify-content(space-between);
    height: 3.75rem;

    & input{
      display: block;
      margin-right: 1rem;
      margin-top: 0.875rem;
    }
  }

  .aboutme__label {
    .font-family(medium);
    font-size: 0.875rem;
  }

  .aboutme__value {
    display: none;

    &.payment__value {
      display: block;
    }

    &.shipping__value {
      display: block;
    }
  }

  .payment__input {
    display: block;
    width: 100%;

    .respond-to(@bpDesktopSmall, {
      width: 19rem;
    });
  }

  .aboutme__handle-container {
    .align-items(center);
    .flex-direction(row);
    .flex-display(flex);
    .justify-content(center);

    .respond-to(@bpDesktopSmall, {
      .justify-content(flex-end);
    });
  }

  .aboutme__edit {
    display: none;
  }

  .custom__select {
    display: inline-block;
  }
}
