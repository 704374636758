@font_uri_path: "../fonts";

@gridColumns: 12;
@colWidth: 150px;
@gutter: 12px;
@baseFont: 16;

@transparent-color: rgba(0, 0, 0, 0);

//blue colors
@blue-main: #0B6CF8;

//Grey colors
@gray-default: #424143;
@gray: @gray-default;
@gray-really-light: #e8e8e8;
@gray-user-info: #B7B9BB;
@gray-medium-dark: #929696;
@gray-medium-darker: #65656A;

@yellow-link: #FEE000;
@yellow-profile: #FEE000;
@yellow-instructor: #FEE000;

//black colors
@black: #000;
@red-error: #FF3232;

@gray-dropdown-nav-noalpha: rgb(140,140,144);
@wrapper-top-margin: 15px;

@gray-main: #F8F8F8;
@gray-main-darker: #F0F0F0;
@gray-light: #EEE;
@gray-lighter: #CCC;
@gray-medium: #BABABA;
@gray-medium-lighter: #8B8A8D;
@gray-dark: #AAA;
@gray-darker: #333;
@gray-category: #8B8A8D;
@gray-strong-dark: #0A0A0A;
@gray-annex: #65656A;
@gray-tiu: #D8D8D8;
@gray-soultogether: #DBD9D6;
@gray-hex97: #979797;
@gray-rolling-stone: #74777A;

// Grey colors created with black + opacity
@gray-87: rgba(0,0,0,0.87);
@gray-87-bg: #212121;
@gray-70: rgba(0,0,0,0.70);
@gray-67: rgba(0,0,0,0.67);
@gray-59: rgba(0,0,0,0.59);
@gray-54: rgba(0,0,0,0.54);
@gray-darker-70: rgba(51, 51, 51, 0.70);
@gray-darker-54: rgba(51, 51, 51, 0.54);
@gray-54-bg: #757575;
@gray-50: rgba(0,0,0,0.50);
@gray-45: rgba(0,0,0,0.45);
@gray-38: rgba(0,0,0,0.38);
@gray-38-bg: #9e9e9e;
@gray-30: rgba(0,0,0,0.30);
@gray-20: rgba(0, 0, 0, 0.2);
@gray-12: rgba(0,0,0,0.12);
@gray-12-bg: #e0e0e0;
@gray-6: rgba(0,0,0,0.06);
@gray-2: rgba(0, 0, 0, 0.02);
@gray-0: rgba(0,0,0,0);

//New White Shades
@white-70: rgba(255,255,255,0.70);
@white-60: rgba(255,255,255,0.60);
@white-30: rgba(255,255,255,0.30);
@white-gray-08: rgba(246, 244, 244, 0.08);
@white-gray-bg: #F6F4F4;
@white-blue-bg: #F5F6F7;

@sc-yellow: #FEE000;
@yellow-light: #FEE000;
@yellow-medium: #FFDE42;
@yellow-dark: #FED600;
@error: #FF3232;
@gray-button: #EEE;

@body-font-color: #919194;

// Misc Colors
@abbeyGray: #53565a;
@armadilloGray: #3d3d32;
@blueRibbonBlue: #0d52ed;
@milanoRed: #b80c09;
@mineShaftGray: #212121;
@saharaSandYellow: #f6ea86;
@schoolBusYellow: #fede02;
@tundoraGray: #4a4a4a;
@white: #fff;
@goldenYellow: #FFDE00;
@greenWhite:  #D9D9D6;

// Pride Month colors
@prideRedColor: #FB3C2F;
@prideOrangeColor: #FB933D;
@prideYellowColor: #FEE000;
@prideGreenColor: #20C56E;
@prideBlueColor: #23A4F6;
@pridePurpleColor: #948DFF;

// SoulCycle Branding Colors
// -------------------------
@scYellowC: #FEE000;
@scWhiteC: #FFFFFF;
@scCoolGray7C: #9A9B9C;
@scCoolGray8C: #8B8D8E;
@scCoolGray9C: #747678;
@scCoolGray10C: #616365;
@scPureBlackC: #1E1E1E;
@scFirstRide: #ABCC25;
@scGreen: #50E376;

// Breakpoints
// -------------------------
@bpMobileSmall: 320px;
@bpMobileSmallPlus: 375px;
@bpMobileMediumSmall: 414px;
@bpMobileMedium: 480px;
@bpMobileLarge: 600px;
@bpTablet: 768px;
@bpDesktopSmall: 1024px;
@bpDesktopMedium: 1200px;
@bpDesktopLarge: 1440px;
@bpDesktopXL: 2000px;

// Content Container Widths
// -------------------------
@widthMobile: 36rem;
@widthTablet: 48rem;
@widthDesktopSmall: 60rem;
@widthDesktopLarge: 75rem;

// Header Heights
// -------------------------
@headerHeight: 3.75rem;
@subNavHeight: 70px;

// Footer Heights
// -------------------------
@footerHeightDesktop: 3.44rem;

// Gutters
// -------------------------
@gutterMobile: 1.875rem;
@gutterTop: 2.625rem; /* padding between main header and content */
@bpMobileSmallGutter: 20px;
@bpMobileLargeGutter: 40px;
@bpTabletGutter: 60px;
@bpDesktopSmallGutter: 112px;
@bpDesktopLargeGutter: 320px;

// Borders
// -------------------------
@borderLightGray: 1px solid @gray-light;
@borderMediumGray: 1px solid @gray-medium;
@borderDarkGray: 1px solid @gray-dark;
@borderDarkerGray: 1px solid @gray-darker;
@borderGray12: 1px solid @gray-12;
@borderGrayHex97: 1px solid @gray-hex97;
@borderGray38-thick: 1.5px solid @gray-38;
@borderBlack: 1px solid @black;

// Box Shadows
@whiteShadow2px: -2px 0 0 0 @scWhiteC, 0 0 0 2px @scWhiteC;

// Opacity
// -------------------------
@opacity-70: 0.7;
@opacity-54: 0.54;

// Z-Indexes
// -------------------------
@zIndexDropdownList: 10;
@zIndexRegionDropdown: 150;
@zOverlay: 200;

// Button Gradients
@primary-button-gradient-start: rgba(254, 215, 3, 1) 0%;
@primary-button-gradient-end: rgba(254, 224, 0, 1) 80%;

@secondary-button-gradient-start: rgba(240, 240, 240, 1) 0%;
@secondary-button-gradient-end: rgba(255, 255, 255, 1) 80%;

@tertiary-button-gradient-start: @secondary-button-gradient-start;
@tertiary-button-gradient-end: @secondary-button-gradient-end;

// CARDS
@single-card-shadow: 0 0 0.9375rem 0 @gray-12;
@max-card-size: 18.25rem;

// Typography
// -------------------------
@helveticaNeueFont: "Helvetica Neue", Helvetica, sans-serif;
