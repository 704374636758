.london-studios__info {
  .flex-direction(column);
  .flex-display(flex);

  .respond-to(
          @bpTablet,
  {.flex-direction(row) ; &--flipped{.flex-direction(row-reverse) ;}}
  );

  &-image-container {
    .flex(0 0 auto);
    background-color: @black;
    background-position: center;
    background-size: cover;
    min-height: 360px;

    .respond-to(@bpTablet, {.flex(0 0 50%) ; height: initial;});
    .respond-to(@bpDesktopLarge, {min-height: 720px;});
  }

  &-content {
    .flex(0 1 auto);
    color: @gray-87;
    padding: 1.4rem 4.45%;

    .respond-to(
            @bpTablet,
    {line-height: 1.8125rem; padding: 4.25rem 1.56% 4.8rem;}
    );

    .respond-to(@bpDesktopSmall,{
      padding: 4.25rem 7.56% 4.8rem;
    });

    .respond-to(
            @bpDesktopMedium,
    {
      padding: 6.875rem 6.39% 7.81rem;
    });

    .respond-to(
            @bpDesktopLarge,
    {
      line-height: 1.5rem;
      padding: 15.875rem 8.39% 7.81rem;
    });


    h2 {
      font-size: 20px;
      margin: 21px 5% -6px;
      .font-family(heavy);

      .respond-to(@bpTablet, {
        font-size: 32px;
        padding-bottom: 10px;
        line-height: 40px;
        width: 357px;
      });

      .respond-to(@bpDesktopMedium, {
        font-size: 40px;
        margin: 0px;
        padding-bottom: 10px;
        line-height: 40px;
      });

      .respond-to(@bpDesktopLarge, {
        font-size: 32px;
      });
    }

    p {
      .font-family(roman);
      line-height: 24px;
      width: 312px;
      font-size: 16px;
      margin: 21px 5% -6px;
      .respond-to(@bpTablet, {padding-top: 0.44%; width: 328px;});

      .respond-to(@bpDesktopMedium, {
        padding-top: 1.92%;
        margin: 0px;
        width: 464px;
      });
    }

    #view-now-button {
      .font-family(heavy);
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 0.75rem;
      padding: 0;
    }
  }
}
