.email-capture {
  background-color: white;
  bottom: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 4px solid @goldenYellow;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;

  .respond-to(@bpTablet, {
    bottom: 10px;
    float: right;
    margin: 10px;
    width: 25rem;
  });

  .respond-to(@bpDesktopMedium, {
    bottom: 65px;
    margin: 0;
    position: fixed;
    right: 10px;
  });

  &-text {
    padding-right: 1rem;

    h2 {
      .font-family(bold);
      color: @black;
      font-size: 0.875rem;
      line-height: 1.125rem;
      width: 10rem;
  
      .respond-to(@bpTablet, {
        font-size: 1.625rem;
        line-height: 2.5rem;
        width: 15rem;
      });
    }
  
    p {
      .font-family(roman);
      color: @gray-rolling-stone;
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin: 0;
  
      .respond-to(@bpTablet, {
        padding-bottom: 1rem;
      });
    }

    a {
      .font-family(bold);
      appearance: button;
      -moz-appearance: button;
      -webkit-appearance: button;
      background-color: @goldenYellow;
      border: 1px solid @goldenYellow;
      display: block;
      font-size: 0.875rem;
      padding: 0.625rem 0;
      text-align: center;
      margin: 1.5rem 0 0.9rem 0;
      width: 11rem;

      .respond-to(@bpTablet, {
        margin: 0;
        width: 8rem;
      });

      &:hover {
        background-color: @yellow-dark;
        border: 1px solid @yellow-dark;
      }
    }
  }

  &-closed {
    display: none;
  }

  &-container {
    padding: 1.5rem 1.5rem .75rem 1.5rem;
  }

  &-completed {
    .email-capture {
      width: 100%;

      &-form {
        display: none;
      }

      &-success {
        .align-items(center);
        .flex-direction(row);
        .flex-display(flex);
        background-color: @white;

        &-message {
          .flex(1 1 auto);
          .font-family(roman);
          font-size: 1rem;
        }
      }
    }
  }

  &-heading {
    &-button {
      background-color: @white;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 13px;
      top: 20px;
      z-index: 200;

      svg {
        fill: @abbeyGray;
      }
    }
  }

  &-form {
    background-color: @white;

    &-container {
      display: flex;
      padding: 0.75rem 0 0.4rem 0;

      &-submit {
        .flex(0 1 auto);
        background-color: @goldenYellow;
        border: 1px solid @goldenYellow;
        padding: 0;
        width: 3.5rem;
      
        svg {
          width: 1.75rem;
          height: 1rem;
        }

        &:hover {
          background-color: @yellow-dark;
          border: 1px solid @yellow-dark;
        }
      }
    }

    &-footer {
      .flex-direction(row);
      .flex-display(flex);
      padding-top: 5px;
      width: 100%;

      &-legal {
        .flex(1 1 auto);
        .font-family(light);
        font-size: 0.75rem;
        line-height: 1rem;
        position: relative;
        text-align: right;

        a {
          color: @gray-rolling-stone;
        }

        .separator {
          color: @greenWhite;
        }
      }
    }

    input[type=email] {
      background-color: @white;
    }

    &-input {
      .flex(1);
      .font-family(roman);
      background-color: @white;
      border-radius: 0;
      border: 1px solid @gray-medium;
      border-right: 0;
      color: @abbeyGray;
      font-size: 0.8125rem;
      height: 2.5rem;
      line-height: 2.5rem;
      margin: 0;
      padding: 0 1rem;
      width: 100%;

      &-error {
        border-color: @milanoRed;
      }

      &::placeholder {
        color: @gray-rolling-stone;
        text-transform: uppercase;
      }
    }

    &-label {
      .visually-hidden();
    }

    &-error {
      .font-family(roman);
      color: @milanoRed;
      display: none;
      font-size: 0.875rem;
      margin: 0;
      padding-top: 5px;
    }
  }

  &-success {
    display: none;
  }
}
