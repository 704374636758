/* FOOTER PAGE - MQ < 600px
-----------------------------------------------------------------------------*/
.footer-page-container {
  padding: 90px 0 0;
  font-size: 16px;
  line-height: 1.688em;

  /////////////////////
  // FAQ PAGE STYLES //
  /////////////////////
  .category-filters-container {
    .category-selected-wrap {
      display: block;
      position: relative;
      text-align: center;
      .category-selected {
        color: @gray-darker;
        font-size: 2em;
        .font-family(medium);
        position: relative;
        top: 30px;
        padding: 25px 0;
      }
    }
    .category-filter-list {
      text-align: center;
      padding-top: 50px;
      li {
        padding: 25px 0;
        position: relative;
        display: block;
        a {
          font-size: 2em;
          .font-family(medium);
        }
      }
    }
  }
  .category-list {
    border-bottom: 1px solid @gray-light;
    margin-bottom: 20px;
    padding: 10px 8.25%;
    box-sizing: border-box;
    display: none;
    &.active {
      display: block;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .category, .question, .answer {
    line-height: 1.688em;
  }
  .category {
    font-size: 1.313em;
    //font-weight: bold;
    .font-family(medium);
    color: @gray-darker;
    &:first-child {
      margin: 20px 0;
    }
  }
  .question, .answer {
    font-size: 1.0em;
  }
  .question {
    .font-family(medium);
    color: @gray-darker;
    text-decoration: underline;
  }
  .answer {
    .font-family(light);
    color: @gray-darker;
    margin-bottom: 30px;
    ol, ul {
      padding-left: 40px;
    }
    .no-margin {
      margin: 0;
    }
  }
  .offsetted-anchor {
    margin-top: -60px;
    padding-top: 60px;
    z-index: -1;
  }
  table {
    width: 100%;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  ///////////////////////
  // PRESS PAGE STYLES //
  ///////////////////////
  &.press-container {
    margin-bottom: 1px;
    padding: 66px 0 0;
    width: 100%;
    .press-image {
      background-size: 70%;
      box-sizing: border-box;
      padding: 25%;
      position: relative;
      display: inline-block;
      border: @borderLightGray;
      width: 50%;
      background-repeat: no-repeat;
      background-position: center;
      border: @borderLightGray;
      border-top: 0;
      border-left: 0;
      margin-bottom: -8px;
      margin-right: -1px;
      margin-left: 0;
      a {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .press-image-date {
        color: @gray-medium;
        font-size: 0.750em;
        position: absolute;
        bottom: 10px;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
      }
    }
  }

  ///////////////////////////////////
  // GIFT CARD BALANCE PAGE STYLES //
  ///////////////////////////////////
  &.gift-card-balance {
    margin: 0;
    padding: 0;
    .gift-card-balance-hero {
      height: 180px;
      canvas#confetti {
        position: absolute;
        opacity: 0.25;
        width: 100%;
      }
      //background: url('/assets/images/gift-card-balance-hero.png') top center no-repeat transparent;
      .title {
        padding: 75px 7.8125% 0;
        .font-family(light);
        text-align: center;
        font-size: 3.0em;
        line-height: 1em;
      }
    }
    .gift-card-balance-content-container {
      padding: 60px 7.8125%;
      .gift-card-balance-text-wrap {
        padding: 0 5px;
        text-align: center;
        h3 {
          .font-family(medium);
          font-size: 1.125em;
          padding: 10px 0;
        }
        .description {
          padding-top: 5px;
          font-size: 0.750em;
          display: block;
        }
      }
      .gift-card-balance-form {
        padding: 40px 0;
        max-width: 280px;
        margin: 0 auto;
        input, .check-balance {
          width: 100%;
          margin-right: 0;
        }
        .check-balance {
          margin-top: 2em;
        }
      }
    }
  }

  /////////////////////////////
  // TERMS/LEGAL PAGE STYLES //
  /////////////////////////////
  &.legal-container {
    margin: 0;
    padding: 40px 0;
    .category-list{
      display: block;
    }

    h4,
    h5 {
      font-weight: bold;
    }
  }

  ////////////////////////////////
  // CAREER LISTING PAGE STYLES //
  ////////////////////////////////
  &.career-listing-container {
    display: none;
    &.active {
      display: block;
    }
    .category-list {
      font-size: 1em;
      display: block;
      .category {
        font-size: 1.313em;
      }
      .question, .answer {
        font-size: 1em;
        line-height: 1.688em;
      }
    }
    &.by_position, &.by_location {
      display: none;
      &.active {
        display: block;
      }
      .link {
        margin-bottom: 4.5625em;
        a {
          padding: 5px 5px 0;
          border: 1px solid @gray-darker;
          border-radius: 0.5em;
          width: 61px;
          display: inline-block;
          height: 30px;
          text-align: center;
          vertical-align: bottom;
          position: relative;
          .font-family(medium);
          font-size: 0.750em;
        }
      }
    }
    &.listing {
      padding: 2.8125em 7.8125%;
      display: block;
      .title {
        margin-bottom: 1.25em;
        h3 {
          font-size: 1.313em;
          .font-family(medium);
        }
      }
      .small-title {
        .font-family(medium);
        font-size: 1em;
        margin-bottom: 0.5em;
      }
      .description {
        font-size: 1em;
        line-height: 1.688em;
        margin-bottom: 2em;
      }
      ul {
        list-style-type: none;
        li {
          list-style-type: none;
          &:before {
            content: '—';
          }
        }
      }
    }
  }
}

/* MEDIA QUERIES - FOOTER STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .footer-page-container {

    /////////////////////
    // FAQ PAGE STYLES //
    /////////////////////
    .category-list {
      padding: 10px 12%;
    }
    .question {

    }

    ///////////////////////////////////
    // GIFT CARD BALANCE PAGE STYLES //
    ///////////////////////////////////
    &.gift-card-balance {
      .gift-card-balance-hero {
        .title {
          padding-top: 92px;
        }
      }
      .gift-card-balance-text-wrap {
        text-align: center;
      }
      .gift-card-balance-content-container {
        padding: 40px 18.35%;
        .gift-card-balance-form {
          max-width: 376px;
        }
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .footer-page-container {
    .category-list {
      padding: 10px 13.025%;
    }

    ///////////////////////////////////
    // GIFT CARD BALANCE PAGE STYLES //
    ///////////////////////////////////
    &.gift-card-balance {
      .gift-card-balance-content-container {
        padding: 40px 25%;
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .footer-page-container {
    padding-bottom: @footerHeightDesktop;

    /////////////////////
    // FAQ PAGE STYLES //
    /////////////////////
    .category-list {
      padding: 10px 17.09%;
    }

    ///////////////////////////////////
    // GIFT CARD BALANCE PAGE STYLES //
    ///////////////////////////////////
    &.gift-card-balance {
      .gift-card-balance-content-container {
        padding: 40px 31.5%;
      }
    }

    ////////////////////////////////
    // CAREER LISTING PAGE STYLES //
    ////////////////////////////////
    &.career-listing-container {
      &.listing {
        padding: 2.8125em 7.8125% 11.25em;
      }
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .footer-page-container {

    //////////////////////
    // FAQ PAGE STYLES //
    /////////////////////
    line-height: 1.313em;
    .category-list {
      padding: 20px 12.675%;
      .category {
        font-size: 2em;
      }
      .question, .answer {
        font-size: 1.313em;
      }
    }

    ///////////////////////////////////
    // GIFT CARD BALANCE PAGE STYLES //
    ///////////////////////////////////
    &.gift-card-balance {
      padding-bottom: 200px;
      .gift-card-balance-content-container {
        padding: 40px 36.5%;
      }
    }

    ////////////////////////////////
    // CAREER LISTING PAGE STYLES //
    ////////////////////////////////
    &.career-listing-container {
      font-size: 22px;
      &.by_position, &.by_location {
        .link {
          a {
            padding: 10px 5px 0;
          }
        }
      }
      &.listing {
        padding: 2.8125em 12.8% 11.25em;
        .title {
          .h3 {
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
}