.shop_item_detail {
  .main-container {
    overflow: visible;
    z-index: 3;
  }
  .shop-detail-grid-container {
    .center-container();
  }
  .disabled {
    opacity: .2;
  }
  .for_mobile {
    display: none;
  }

  .product-thumbs {
    padding-top: 20px;
    padding-bottom: 8px;
  }
  .product-thumb {
    margin-right: 10px;
  }

  .product-container {
    overflow: auto;
    .product-outer {
      .center-container();
      padding: 0 50px;
      position: relative;
      height: 100%;
    }

    .product-inner {
      .center-container();
      width: 960px;
      position: relative;
      height: 100%;
    }
    /* Flash Sale Styles */
    &.flash-sale-margin {
      margin-top: 57px;
    }
  }

  .product-title {
    .font-family(thin);
    font-size: 1.8em;
    padding-top: 15px;
    padding-bottom: 10px;

    .fav-it {
      .icon-heart-off-small;
      font-size: .7em;
      top: -15px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      -moz-transition: -moz-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;

      &.active {
        .icon-heart-on-small;
        top: -15px;
        font-size: .7em;
      }

      &:hover {
        -webkit-transform: rotate(1080deg);
        -moz-transform: rotate(1080deg);
        -ms-transform: rotate(1080deg);
        transform: rotate(1080deg);
      }
    }
  }

  .product-price {
    .font-family(light);
    font-size: 1.8em;

    &.discounted-price {
      color: @red-error;
    }

    &.is-discounted {
      color: @gray-user-info;
      display: inline-block;
      margin-right: 5px;
      position: relative;

      &:before {
        border-bottom: 1px solid @gray-user-info;;
        content: '';
        position: absolute;
        right: 0;
        top: calc(~'50% - 3px');
        width: 100%;
      }
    }
  }

  .product-colors {
    .color-container {
      margin-top: 10px;
      margin-left: 3px;
    }

    .color {
      cursor: pointer;
      position: relative;
      display: inline-block;
      background-color: white;
      width: 23px;
      height: 23px;
      margin-left: 22px;
      text-indent: -9999px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      &:first-child {
        margin-left: 0;
      }
      &.selected {
        box-shadow: 0 0 0 2px #F5F5F6, 0 0 0 3px #727174;
      }
    }
  }

  .color-selected,
  .size-selected {
    .font-family(light);
  }

  .color-head {
    display: block;
    font-size: 1.4em;
    margin-top: 36px;
  }
  .details-head {
    display: block;
    font-size: 1.4em;
    .font-family(light);
    margin: 30px 0 20px;
  }

  .product-details {
    clear: both;
    margin-top: 30px;
    border-top: 1px solid @gray-light;
    p {
      margin: 0;
    }
  }
  .details-description {
    font-size: 1.2em;
    line-height: 2;
    width: 90%;
  }

  .product-sizes {
    padding-top: 20px;
    cursor: default;
    .size-container {
      cursor: default;
      margin-left: -22px;
      .out-of-stock {
        font-size: 1.3em;
        display: none;
        color: @red-error;
        width: 100px;
        text-align: left;
        padding-top: 5px;
      }
      .size {
        cursor: pointer;
        position: relative;
        display: inline-block;
        background-color: @gray-light;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin-left: 22px;
        margin-top: 10px;
        text-align: center;
        font-size: 1.2em;
        line-height: 33px;
        .font-family(medium);
        &.selected {
          background-color: #000;
          color: #fff;
        }
        &.unavailable {
          cursor: default;
          background-color: @gray-main;
          .size-text {
            opacity: 0.15;
            cursor: default;
          }
        }

      }
    }
  }

  .size-head {
    display: block;
    font-size: 1.4em;
    margin-top: 20px;
  }

  .product-quantity {
    padding-top: 25px;
    padding-bottom: 30px;
    height: 50px;
    .quantity-container {
      margin-top: 8px;
      display: inline;
    }
  }

  .quantity-container {
    .select-quantity {
      margin-top: 13px;
      z-index: 20;
      top: 0;
      visibility: visible;
      -webkit-appearance: none;
      padding-right: 25px;
      padding-top: 5px;
      padding-bottom: 0;
    }
    &.disabled {
      .add-to-bag {
        cursor: default;
      }
    }

    .yellow-button.add-to-bag {
      width: 280px;
      position: fixed;
      bottom: 20px;
      left: 5%;
      right: 5%;
      z-index: 10;
    }

    .select-quantity {
      .font-family(medium);
      display: inline-block;
      height: 35px;
      border: 1px solid @gray-light;
      padding-left: 8px;
      background-size: 8px 8px !important;
    }
  }

  .quantity-head {
    display: inline-block;
    font-size: 1.4em;
    margin-top: 25px;
    margin-right: 10px;
    float: left;
  }

  .product-thumbs {
    text-align: center;
  }

  .product-thumb {
    width: 71px;
    height: 71px;
    margin-bottom: 18px;
    overflow: hidden;
    cursor: pointer;

    &.selected {
      border-color: #8B8B8F;
      cursor: default;
    }
  }

  .product-enlarged {
    img {
      margin-left: auto;
      margin-right: auto;
      max-height: 170px;
      display: block;
    }
  }

  .product-image {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid @gray-light;
  }

  .product-details-container {
    width: 280px;
    padding-top: 8px;
    padding-bottom: 82px;
    .center-container();
  }

  .product-details-share {
    @padding: 13px;
    .font-family(bold);
    width: 100%;
    position: absolute;
    left: 0;
    border-top: 1px solid @gray-light;

    .details {
      display: inline-block;

      padding-left: @padding;
      //background: url('/assets/images/product-icon-sprite.png') no-repeat 0 -41px;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;

      &.active {
        p {
          padding-top: 12px;
          margin-top: 0;
          display: block;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      position: absolute;
      display: none;
      left: 0;
      width: 410px;
      text-transform: none;
      .font-family(roman);
      color: #919194;
      font-size: 1.1em;
      line-height: 1.6;
    }

    .share {
      cursor: pointer;
      width: 320px;
      margin: 0 auto;

      .share-icons {
        display: block;
        //padding-left: 20px;
        padding-top: 37px;
        padding-bottom: 5px;

        .icon {
          list-style-type: none;
        }
      }

      .icon-email, .icon-facebook, .icon-twitter, .icon-pinterest {
        .transition(all, 0.3s, ease-in-out);
        float: left;
        height: 22px;
        margin-left: 17px;
        margin-top: -5px;
        width: 20%;
      }

      .icon-email {
        margin-left: 0;
      }
    }

  }
}

/* MEDIA QUERIES - SHOP DETAIL STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .shop_item_detail {
    .product-details-container {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    .product-enlarged img {
      max-height: 300px;
    }
    .product-thumbs {
      padding-bottom: 25px;
    }
    .product-colors {
      .color-container {
        margin-top: 15px;
      }
    }
    .product-sizes {
      .size-container {
        margin-top: 10px;
      }
    }
    .quantity-container {
      .yellow-button {
        &.add-to-bag {
          position: relative;
          margin-top: 40px;
          left: 0;
        }
      }
    }
    .product-details-share {
      border-top: none;
      padding-top: 55px;
      position: relative;
      top: 10px;
      .share {
        padding-left: 12px;
        width: 320px;
        .share-icons {
          padding-left: 0;
        }
        .icon-email, .icon-facebook, .icon-twitter, .icon-pinterest {
          width: 20px;
          margin-right: 40px;
        }
      }
    }
  }

}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .shop_item_detail {
    .product-title {
      .font-family(light);
    }
    .product-image,
    .product-details-container {
      width: 45%;
      padding-left: 4%;
      float: left;
      border-bottom: none;
      border-left: 1px solid @gray-light;
      padding-bottom: 50px;
    }
    .product-thumbs {
      padding-top: 30px;
    }
    .product-thumb {
      margin-right: 15px;
    }
    .quantity-container {
      .yellow-button {
        &.add-to-bag {
          float: left;
          clear: both;
        }
      }
    }
    .product-details-share {
      padding-top: 0;
      .share {
        margin: 20px 0 0;
        clear: both;
        .icon-email, .icon-facebook, .icon-twitter, .icon-pinterest {
          width: 20px;
          margin-right: 40px;
        }
        .share-icons {
          padding-top: 15px;
        }
      }
    }
  }

}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .shop_item_detail {
    .main-container {
      padding-bottom: 54px;
    }
    .product-thumbs {
      padding-top: 50px;
    }
    .product-container {
      border-bottom: 1px solid @gray-light;
    }
    .product-enlarged {
      img {
        max-height: 400px;
      }
    }
    .color-head {
      margin-top: 50px;
    }
    .product-sizes,
    .product-quantity {
      padding-top: 40px;
    }

  }

}
