/* FOOTER STYLES
-----------------------------------------------------------------------------*/

.global-footer {
  width: 100%;
  overflow: hidden;
}

/* FOOTER WHEN UI-KIT IS ACTIVE
-----------------------------------------------------------------------------*/
.react-ui-kit {

  .global-footer {
    background-color: @gray-main;
    margin-top: 0;
    padding-top: 0;
    position: relative;
    z-index: 98 !important;

    .respond-to(@bpTablet, {
      height: auto;
    });

    .respond-to(@bpDesktopSmall, {
      height: auto;
      position: relative;
      z-index: 100;
    });

    .footer-container {
      padding: 2rem 1.5rem;

      .respond-to(@bpTablet, {
        height: auto;
        padding: 2rem 1.75rem;
      });

      .respond-to(@bpDesktopSmall, {
        height: auto;
        padding: 3.5rem 3.75rem;
      });

      .respond-to(@bpDesktopLarge, {
          height: auto !important;
          position: relative;
      });

      .copyright {
        color: #000;
      }
    }

    //reset input from SCS global that is overriding ui-kit input
    #email {
      background-color: @white;
      border-radius: 0;
      box-sizing: content-box;
      margin: 0;
    }

    #alert-triangle {
      font: initial;
    }
  }

  &.has-subnav,
  &.lower-footer,
  &.shopping_bag,
  &.checkout,
  &.about {
    .global-footer {
      top: 56px;
      z-index: 98 !important;
      .respond-to(@bpTablet, {
        top: 175px;
      });

      .respond-to(@bpDesktopSmall, {
        top: 120px;
      });
    }
  }
}

.logged-in.react-ui-kit {
  .show-email {
    width: 24rem;
  }
}
