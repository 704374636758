// PROFILE TABS STYLES
.profile-tabs-container {
  border-bottom: 2px solid @gray-light;
  margin-top: 36px;

  .respond-to(@bpDesktopSmall, {
    margin-top: 0;
  });
}

.profile-tabs {
  .flex-display(flex);
  .justify-content(space-around);
  margin: 0 auto;
  width: 20.75rem;
}

.profile-tabs__tab {
  .font-family(heavy);
  color: @gray-38;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 0.15625rem;
  list-style: none;
  padding: 0.5rem 0;
  text-transform: uppercase;

  &:hover {
    color: @gray-darker;
  }

  & .profile-tabs__tab--new {
    background-color: @gray-38;
    color: @scWhiteC;
    padding: 0.376rem 0.1875rem 0.1875rem 0.3125rem;
  }
}

.profile-tabs__tab--active {
  color: @gray-darker;
  border-bottom: 4px solid @gray-darker;

  & .profile-tabs__tab--new {
    background-color: @black;
  }
}
