/* HOMEPAGE STYLES
-----------------------------------------------------------------------------*/

.homepage {
  .main-container {
    top: 0;
  }
  #sc_content, .main-container,
  .homepage-container {
    height: 100%;
    #home-main-carousel {
      top: @headerHeight;
    }
  }
  footer {
    margin-top: 0;
  }
}

.homepage-height-fix {
  height: initial !important;
}

/* MEDIA QUERIES - INSTRUCTOR STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .homepage-height-fix {
    height: 100% !important;
  }
}