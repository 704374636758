
.modal-notification {
  background-color: @white;
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .modal-notification-content {
    .font-family(roman);
    background-color: @white;
    border: 1px solid @gray-lighter;
    box-shadow: 0 0 5px 2px @gray-lighter;
    box-sizing: border-box;
    margin: 6rem auto;
    text-align: center;
    width: 18rem;

    .respond-to(@bpTablet, {
      margin: 10rem auto;
      width: 32rem;
    });

    .close {
      color: @gray-strong-dark;
      float: right;
      font-size: 1.875rem;
      font-weight: bold;
      padding: 0.5rem;

      &:hover,
      &:focus {
        color: @gray-dark;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .modal-notification-text {
      padding: 0 1.5rem 0 1.5rem;

      .respond-to(@bpTablet, {
        padding: 0 4rem 0 4rem;
      });

      h2 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-top: 4rem;
        width: 100%;
  
        .respond-to(@bpTablet, {
          margin-top: 3rem;
        });
      }

      p {
        font-size: 0.825rem;
        line-height: 1.2rem;
      }
  
      button {
        margin-top: 1rem;
      }
    }

    .button__primary {
      margin: 1rem 0 1.5rem 0;
    }
  }
}
