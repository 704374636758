// CLASS BLOCK STYLES
.profile-class-container {
  .flex-display(flex);
  .flex-wrap(wrap);
  .font-family(light);
  .justify-content(flex-start);
  margin-bottom: 15px;
  padding: 1.875rem 1rem 0;

  .respond-to(@bpMobileLarge, {
    padding: 1.875rem 2rem 0;
  });

  .respond-to(@bpTablet, {
    padding: 2rem 2.25rem 0 4rem;
  });

  .respond-to(@bpDesktopSmall, {
    .align-items(center);
    border-bottom: @borderLightGray;
    padding: 1.5rem 0;
  });
}

.profile-instructor {
  display: block;
  margin-right: 0.75rem;
  max-height: 4rem;
  max-width: 4rem;
  overflow: hidden;
  position: relative;

  .respond-to(@bpDesktopSmall, {
    .align-self(stretch);
    margin-right: 1.75rem;
  });
}

.profile-instructor__image {
  border-radius: 100%;
  max-height: 100%;
  max-width: 100%;
}

.profile-class-info {
  .flex(1);
  min-width: 5.25rem;
}

.profile-class-info__studio,
.profile-class-info__type,
.profile-class-info__status {
  font-size: 0.75rem;
  line-height: 0.9375rem;

  .respond-to(@bpDesktopSmall, {
    font-size: 0.875rem;
    line-height: 1.125rem;
  });
}

.profile-class-info__studio,
.profile-class-info__type {
  padding-bottom: 0.25rem;
}

.profile-class-info__studio {
  display: inline-block;

  &:hover {
    color: @gray-54 !important;
  }
}

.profile-class-info__instructor:hover {
  color: @gray-54 !important;
}

.profile-class-info__time {
  .font-family(medium);
  font-size: 0.875rem;
  line-height: 0.9375rem;
  padding-bottom: 0.25rem;

  .respond-to(@bpDesktopSmall, {
    font-size: 1.125rem;
    line-height: 1.125rem;
  });
}

.profile-button-container {
  .respond-to(@bpDesktopSmall, {
    height: fit-content;
  })
}

.profile-button {
  .font-family(medium);
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0;
  width: 5.25rem;
}

.profile-button--unreserve {
  background-color: @scWhiteC;
  border: @borderDarkerGray;
  border-radius: 0.3125rem;
  height: 2.25rem;

  &:hover {
    color: @gray-54;
  }
}

//BOOKMARK SPECIFIC STYLES
.remove-bookmark-container {
  .align-items(center);
  .flex-display(flex);
  height: 2.375rem;
}

.remove-bookmark {
  cursor: pointer;
  height: 0.875rem;
  object-fit: none;
  object-position: -14.6875rem -12.5625rem;
  width: 2.5rem;
}

.profile-button--reserve {
  background-color: @scYellowC;
  border-radius: 0.3125rem;
  border: none;
  display: inline-block;
  padding: 0.8125rem 0 0.8125rem;
  text-align: center;

  &:hover {
    color: inherit;
    background: -moz-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(254,215,3,1)), color-stop(80%,rgba(254,224,0,1)));
    background: -webkit-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -o-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -ms-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: linear-gradient(to bottom, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
  }
}


.profile-button--waitlist {
  background-color: @gray-light;
  border: none;
  border-radius: 0.3125rem;
  display: inline-block;
  padding: 0.75rem 0 0.75rem;
  text-align: center;

  &:hover {
    background-color: @gray-54;
    color: @scWhiteC !important;
  }
}
