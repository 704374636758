.email-capture-landing {
  padding-top: 1.25rem;
  transition: max-height 1s ease, color 1s ease, visibility 0.5s;

  .respond-to(@bpDesktopLarge, {
    padding-bottom: 4.6rem;
  });

  &__phrase {
    color: @white;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    max-height: 600px;
  }

  &__form-privacy-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 540px;

    .respond-to(@bpDesktopLarge, {
      flex-direction: row;
    });
  }

  &__form {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 0.5rem;

    .respond-to(@bpTablet, { 
      flex-direction: row;
      width: 512px;
      height: auto;
    });

    .respond-to(@bpDesktopSmall, {
      overflow: initial; width: 650px;
    });

    .input-field {
      position: relative;
    }

    &-error {
      .font-family(roman);
      color: @white;
      display: none;
      font-size: 0.875rem;
      height: 0;
      line-height: 1.5rem;
      margin: 0;
      padding-top: 5px;
      position: absolute;
      transition: all 0.5s ease-in-out;
    }

    &-error-expanded {
      height: auto;
      display: contents;
    }

    .frm-label {
      .font-family(medium);
      -webkit-transition: all 0.28s ease;
      background: @black;
      color: @greenWhite;
      font-size: 1rem;
      font-weight: normal;
      height: fit-content;
      left: 0.875rem;
      padding: 0 3px;
      pointer-events: none;
      position: absolute;
      top: 1rem;
      transition: all 0.28s ease;
      width: fit-content;
      z-index: 1;

      .respond-to(@bpTablet, {
        top: 1rem;
      });
      .respond-to(@bpDesktopLarge, {
        top: 1rem;
      });

      &.floated {
        color: @white;
        font-size: 0.875rem;
        top: -0.3em;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      // To make the autocomplete stay with the color black after vendor libs take over
      color: @white !important;
      -webkit-text-fill-color: @white !important;
      -webkit-box-shadow: 0 0 0 30px @black inset !important;
    }

    input {
      background-color: @black;
      border: 2px solid @white;
      border-radius: 2px;
      color: @white;
      display: block;
      float: none;
      height: 2.5rem;
      width: 100%;

      .respond-to(@bpTablet, {
        width: 319px;
      });

      .respond-to(@bpDesktopLarge, {
        max-width: 20rem;
      });

      &:focus {
        background-color: @black !important;

        & + .frm-label {
          color: @white;
          top: -0.3em;
          font-size: 0.875rem;
        }
      }

      &:hover {
        background-color: @black;
      }
    }
    &-input-error {
      border-color: @milanoRed;
    }

    .intl-tel-input {
      float: none;
    }

    &__submit {
      .flex(0 1 auto);

      &-button {
        .font-family(bold);
        background: @gray-medium;
        border: none;
        color: @abbeyGray;
        float: right;
        font-size: 1.125rem;
        height: 3.5rem;
        letter-spacing: 1.5px;
        margin: 0.5rem 0 1rem;
        padding-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        .respond-to(@bpTablet, {
          height: 2.5rem;
          margin-top: 0;
          min-width: 178px;
        });
        &-enabled {
          background: @schoolBusYellow;
          color: @black;
          cursor: pointer;

          &:hover {
            .linear-gradient(
              @primary-button-gradient-start,
              @primary-button-gradient-end
            );
          }
        }
      }
    }
  }

  &__privacy-policy {
    .font-family(light);
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 1.3rem 0;
    text-align: center;
    width: 100%;
    ul {
      list-style: none;
      .respond-to(@bpTablet, {
        display: flex;
        flex-direction: column-reverse;
        text-align: left;
      });

      li {
        display: inline;
        padding: 0.25rem 0.5rem;
        .respond-to(@bpTablet, {
          display: block; 
          padding-left: 0;
        });
      }
    }

    .respond-to(@bpDesktopLarge, {
      margin: 0 0px 0 1.875rem;
      padding-top: 0.5rem;
    });

    a {
      color: @white;
      &:hover {
        color: @white;
        cursor: pointer;
      }
    }
  }
  &__success-message {
    font-size: 1rem;
    margin: 3rem 0;
    max-height: 0;
    visibility: hidden;

    a {
      color: @white;
      text-decoration: underline;
      &:hover {
        color: @white;
        cursor: pointer;
      }
    }
  }
}
.email-capture-landing-completed {
  .email-capture-landing__phrase,
  .email-capture-landing__form-privacy-wrapper,
  .email-capture-landing__privacy-policy {
    color: @black;
    max-height: 0;
    margin: 0;
    opacity: 0.5s ease;
    transition: max-height 1s ease, color 1s ease, visibility 0.5s;
    visibility: hidden;
  }

  .email-capture-landing__success-message {
    color: @white;
    max-height: 7rem;
    transition: visibility 0.5s, opacity 0.5s ease;
    visibility: visible;
  }
}
