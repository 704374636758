.account-creation-container {
    width: 100%;

    .respond-to(@bpMobileSmall, {
        margin: 3rem auto;
    });

    .respond-to(@bpMobileLarge, {
        margin: 5rem auto;
    });


    label.field-error {
        text-align: left;

        span.error-message {
            top: -11px;
            width: auto !important;
        }
    }
}

/* Crete Account Style */
.account-creation__header {
    text-align: center;
}

.account-creation__header__title {
    .font-family(medium);
    font-size: 1.3rem;
    line-height: 1.75rem;

    .respond-to(@bpDesktopSmall, {
        font-size: 1.5rem;
        line-height: 2rem;
    });

    .respond-to(@bpDesktopMedium, {
        .font-family(medium);
        font-size: 1.75rem;
        line-height: 2.25rem;
    });

}

// Swiper Styles
.account-creation__form__swiper-container,
.account-login__form__swiper-container  {
    width: 100%;
}

.account-creation__form__swiper__slide,
.account-login__form__swiper__slide  {
    align-items: center;
    background: @scWhiteC;
    display: block;
    flex-shrink: 0;
    font-size: 1.125rem;
    height: 100%;
    justify-content: center;
    opacity: 0.3;
    text-align: left;
    width: 100%;
}

.account-creation__form__swiper__slide--active,
.account-login__form__swiper__slide--active {
    opacity: 1;

    #location_placeholder-label,
    #dob_placeholder-label {
        text-transform: initial;
    }
}

.account-creation__buttons {
    .mixin-buttons-1x2();
    padding: 0.2rem 0;
    #handle-create-account {
        margin-left: 3.988rem;
    }
}

.select_group {
    // display: flex; //enable in JS
    display: none;
    justify-content: space-between;

    select {
        background-image: url(/assets/images/black-dropdown.png);
        background-position: calc(~"100% - 0.25em") center;
        background-size: 0.5rem;
    }

    label {
        text-transform: initial;
    }

    .global__input-select-dob_group {
        width: 31%;
    }

    .global__input-select-location_group {
        width: 48%;
    }

    .global__input-select {
        min-width: auto;
    }
}

#opt_selling-container,
#custom-container {
    display: none;
}
