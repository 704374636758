.supersoul__section__faqs {
  .flex-direction(column-reverse);
  .flex-display(flex);
  min-height: 550px;

  .respond-to(@bpTablet, {
    .flex-direction(row);
  });

  &-image-container {
    .flex(0 0 auto);
    background-color: @black;
    background-position: center;
    background-size: cover;
    height: 320px;

    .respond-to(@bpTablet, {
      .flex(0 0 50%);
      height: initial;
    });
  }

  &-content {
    .flex(0 1 auto);
    color: @armadilloGray;
    letter-spacing: 0.5px;
    padding: 21.33% 9.33% 13.87%;

    .respond-to(@bpTablet, {
      .flex(0 1 50%);
      padding: 6.94% 6.25%;
    });

    h2 {
      .font-family(medium);
      font-size: 2rem;
    }

    p {
      .font-family(roman);
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin: 0;
      padding: 75px 0 105px 7px;

      .respond-to(@bpTablet, {
        padding: 40px 0;
      });
    }

    #view-now-button {
      .font-family(heavy);
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 0.75rem;
      padding: 0;
    }
  }
}
