.comingsoon {
  .main-container {
    .respond-to(@bpDesktopSmall, {
      padding-bottom: 56px;
    });
  }
  .comingsoon-hero-container {
    width: 100%;
    height: 332px;
    background-image: url("/assets/images/comingsoon/comingsoon_cover_v2.jpg");
    background-position: center;
    background-size: cover;
    .respond-to(@bpDesktopSmall, {
      height: 442px;
    });
    .respond-to(@bpDesktopLarge, {
      height: 620px;
    });
  }
  .comingsoon-hero-container, .comingsoon-studio-container {
    margin-bottom: 3.2%;
    position: relative;
    .respond-to(@bpDesktopSmall, {
      margin-bottom: 3.5%;
    });
  }
  .comingsoon-main-container {
    .respond-to(@bpMobileLarge, {
      display:flex;
      justify-content: space-between;
      flex-wrap: wrap;
    });
    img {
      width: 100%;
      opacity: 0.5;
    }
    h2 {
      color: white;
      font-size: 78px;
      .font-family(heavy);
      text-align: center;
      .respond-to(@bpDesktopLarge, {
        font-size: 110px;
      });
    }
    h3 {
      color: @gray-darker;
      font-size: 35px;
      .font-family(medium);
      text-align: center;
      line-height: 28px;
      letter-spacing: -1px;
      margin-top: -35px;
      .respond-to(@bpDesktopLarge, {
        font-size: 48px;
        letter-spacing: -2px;
        line-height: 40px;
        margin-top: -50px;
      });
    }
    h4 {
      color: @gray-darker;
      font-size: 18px;
      .font-family(bold);
      text-align: center;
      margin-top: 10px;
      letter-spacing: -0.75px;
      .respond-to(@bpDesktopLarge, {
        font-size: 24px;
        margin-top: 20px;
        letter-spacing: -1px;
      });
    }
  }
  .comingsoon-studio-container {
    a {
      display: block;
    }
    .respond-to(@bpMobileLarge, {
      display: inline-block;
      width: 48.1%;
    });
    .respond-to(@bpDesktopSmall, {
      width: 31%;
      &:hover {
        img {
          visibility: hidden;
        }
        h2 {
          color: @sc-yellow;
        }
      }
    });
  }
  .comingsoon-studio-info {
    position: absolute;
    top: 27%;
    width: 100%;
  }

}
