/*
New To Soul/Experience Page Styles
*/

/*
NEW TO SOUL SHARED STYLES
Styles: newrider/experience/new-to-soul.twig.html
*/
.new-to-soul-container {
  position: relative;
}

.new-to-soul__section {
  background: @black;
  position: relative;
  width: 100%;

  &.newrider-experience__container {
    background: @scWhiteC;
    height: auto;
    margin-bottom: 2.5rem;

    .respond-to(@bpDesktopSmall, {
      margin-bottom: 3.5rem;
      margin-top: 2rem;
    });
  }
}

.new-to-soul__section__description {
  p {
    margin: 1.5rem 0 2rem;
  }
}

/*
NEW TO SOUL SHARED STYLES
Styles: newrider/experience/landing,experience,classes,instructors,people.twig.html
*/

.new-to-soul__section__classes-description,
.new-to-soul__section__experience-description,
.new-to-soul__section__instructors-description,
.new-to-soul__section__landing-description-button-wrapper {
  .button__text__icon-link {
    .mixin-text-icon-link(
      @color: @scWhiteC,
      @fontFamily: bold,
      @fontSize: 0.6875rem,
      @hoverColor: @white-70,
      @letterSpacing: 0.125rem
    );
    line-height: 0.9375rem;
    margin: 0;
    padding: 0;

    .button__base__icon-wrapper {
      height: 0.8125rem;
    }
  }
}

/*
 * New Soul Variation B Styles
 */
.new-to-soul-container--variation-find-a-class-cta {
  .new-to-soul__section__experience-description-button-wrapper,
  .new-to-soul__section__classes-description-button-wrapper,
  .new-to-soul__section__instructors-description-button-wrapper,
  .new-to-soul__section__people-description-button-wrapper {
    display: none;
  }
}
