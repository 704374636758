/* HEADER STYLES
-----------------------------------------------------------------------------*/
.bubble-indicator() {

  .soul-cycle-focus-state(){

    &.rider-cycle-credit {
      .box-shadow(2px 0px 0px 0px @scWhiteC);
      right: -9px;
      z-index: 1;
    }
  }
}
.logo {
  display: inline-block;

  .logo-name {
    .font-family(bold);
    display: block;
    font-size: 1.8em;
    letter-spacing: -1px;
    margin-top: 22px;
    text-transform: uppercase;
  }
}


.global-header {
  height: @headerHeight;
  border-bottom: 1px solid @gray-light;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  z-index: 100000;
  min-width: 320px;
  width: 100%;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.header-container {
  width: 100%;
  height: 60px;
  padding-left: 0;

  #logo-container {
    float: left;

    .logo {
      font-size: 1em;
    }
  }

  .top-cta a:hover {
    color: @sc-yellow;
  }

  #header-studios-list a {
    color: black;
  }
}

.mobile-nav-icon {
  float: left;
  cursor: pointer;
  position: relative;
  margin: 19px 10px 0;
  box-sizing: border-box;
  width: 35px;
  height: 30px;
}

.mobile-nav-container {

  .credits {

    .bubble-indicator();
  }
}

.mobile-nav-series-indicator {
  .box-shadow(-2px 0px 0px 0px @scWhiteC);
  color: @gray-87;
  .font-family(medium);
  font-size: .5em;
  display: block;
  background-color: @sc-yellow;
  border-radius: 50%;
  line-height: 21px;
  position: absolute;
  z-index: 10;
  width: 14px;
  height: 16px;
  padding: 2px 4px 4px;
  text-align: center;
  right: -9px;
  top: -12px;

  .bubble-indicator();
}

.vertical-separator {
  color: @gray-54;
  display: inline-block;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 5px;
  vertical-align: middle;
}

.mobile-nav-close {
  position: absolute;
  z-index: 100;
  font-weight: bold;
  cursor: pointer;
  right: 24px;
  margin: 0;
  width: 20px;
  height: 20px;
  top: 24px;
}

.buy-series-close-button,
.close-button-x {
  .gray-close-icon();
  top: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.mobile-nav {
  z-index: 10;
  display:block;
  padding: 36px 0 0 20px;
  color: #999;
  font-size: 4.8em;
  height: 95%;
  position: relative;

  .user-name a, .login, a .register , a.mobile-nav-logout {
    font-size: .35em;
    color: @gray-54;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      color: @gray-87;
    }
  }

  a .register {
    padding-left: 5px;
  }
  .mobile-nav-logout {
    display: block;
  }
  .credits {
    font-size: 12px;
    cursor: pointer;
    vertical-align: middle;
  }
  ul {
    padding: 10% 0 0 0;
    list-style: none;
    .font-family(bold);

    > li {
      padding-bottom: 10px;
    }

    li {
      position: relative;
      letter-spacing: 0;

      a {
        color: @gray-54;
      }

      a:hover {
        color: @sc-yellow;
        .dropdown-nav-container {
          z-index: 2; // Ben changed from 1 to avoid intermittant overlapping
        }
      }
      a.new-your-soul:hover {
        color: initial;
      }
    }
    .icon-tote-small {
      margin: 4px 0 0 9px;
    }
    /* li */
  }
  #mobile-region {
    .font-family(medium);
    font-size: 14px;
    color: @gray-87;
    position: relative;
    top: 0;
    cursor: pointer;

    .respond-to(@bpTablet, {
      display: none;
    });
    .rider-region-title {
      .default-browser-focus;
      display: inline-block;
      position: absolute;
      top: 12px;
    }
  }
  .country-flag {
    background-size: 90px 30px;
    background: url("/assets/images/flags.png") no-repeat;
    display: inline-block;
  }
  .flag-ca {
    background-position: -3px 7px;
    background-size: 65px 20px;
    height: 30px;
    width: 30px;
  }
  .flag-us {
    background-position: -32px 5px;
    background-size: 65px 20px;
    height: 30px;
    width: 48px;
  }
}



/* authentication */
.reset-password,
.reset-password-confirmation,
.join-now,
.sign-in,
.join-now .step2,
.join-now .step3
{
  display: none;
}

.join-now, .join-now .step1 {
  display: block;
}





/* Global Nav Select Items */
.find-a-class-row,
.region-select-row {
  padding-bottom: 80px;
  padding-top: 20px;
  li {
    position: relative;
    background-color: white;
    padding: 3px 16px;
    font-size: 16px;
    list-style: none;
    a {
      position: relative;
      display: block;
      color: @gray-54;
      .font-family(bold);
      line-height: 38px;
      height: 35px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.fav {
        &:after {
          content: "";
          .icon-heart-on-small;
          font-size: 1em;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -6px;
        }
      }

      &.active {
        color: @gray-87;
        &:after {
          content: "";
          .icon-check-dark-small;
          font-size: 1em;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -6px;
        }
      }
    }

    &:last-child a {
      border: 0;
    }

    &:hover {
      cursor: pointer;
      a {
        border: 0;
        color: @gray-70;
        &.fav:after {
          -webkit-filter: brightness(200%) grayscale(100%) contrast(200%);
        }
      }
    }
  }
}
.region-select-row {
  padding-bottom: 100px;
  border-top: @borderLightGray;
}

// specific to top nav region dropdown
.header-user-dropdown .region-select-row {
  li {
    &:hover {
      a {
        color: @gray-54;
      }
    }
  }
  li.country-label {
    .font-family(bold);
    color: @gray-87;
    padding: 15px 15px 10px;
  }
  li.region-list {
    padding: 0;
  }
  li.selectable-region {
    padding: 3px 24px 3px 45px;

    &:hover {
      a {
        color: @gray-87;
      }
    }
  }
}

.no-csstransitions .dropdown-nav-container {
  display: none;
}

.no-csstransitions .header-nav li:hover .dropdown-nav-container {
  display: block;
}

.no-csstransitions .find-a-class:hover .dropdown-nav-container {
  display: block;
}

.find-a-class {
  float: right;
  font-size: 1.3em;
  margin: 15px 8px 0 0;
  z-index: 1;

  &.touch_hover {

    &.top-level > a {
      // THIS IS GOLD! Keep top-level item highlighted while <a> tags inside nested <li> elements aren't affected
      color: @gray-54;
    }

    .dropdown-nav-container {
      z-index: 1;
    }


    .buy-series {
      display: block;
      z-index: 50;
      left: 0;
    }

    .header-user-dropdown {
      visibility: visible;
      min-height: 100%;
      top: 0;
      z-index: 20;
      opacity: 1;
      &.nav0 {
        height: 0 !important;
      }
    }

    .header-user-dropdown .find-left-nav::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      z-index: 1000;
    }
    .header-user-dropdown .find-left-nav::-webkit-scrollbar-track {
      background-color: @gray-really-light;
    }
    .header-user-dropdown .find-left-nav::-webkit-scrollbar-thumb {
      background-color: @sc-yellow;
      border-radius: 10px;
    }

    .find-a-class-toggle {
      background-position: 0 -11px;
    }
  }

  .buy-series {
    background-color: #fff;
    height: 80px;
    text-align: center;
    padding-top: 9px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    z-index: 10;
    bottom: 0;
    display: none;
  }

  .buy-series-btn {
    .default-browser-focus;
    width: 280px;
    z-index: 10;
  }

  .header-user-dropdown {
    position: fixed;
    visibility: hidden;
    overflow: hidden;
    right: 0;
    background-color: white;
    width: 100%;
    opacity: 0;

    .current-region,
    .active-region,
    .buy-series-close {
      position: relative;
      background-color: white;
      padding: 15px 16px 10px 16px;
      font-size: 1.1em;
      .font-family(bold);
      border-bottom: @borderLightGray;
    }

    .current-region,
    .active-region{
      font-size: 1.5em;
      height: 40px;
      padding-top: 25px;
      padding-bottom: 0;
    }
    .active-region {
      padding-left: 45px;
      cursor: pointer;
      &:before {
        .icon-arrow-left-dark-small;
        content: "";
        font-size: .8em;
        position: absolute;
        left: 16px;
        top: 23px;
      }
    }

    .buy-series-close {
      border-bottom: none;
      font-weight: normal;
      padding: 22px 0 16px 17px;
      background-color: @gray-main;
      color: #aaa;
      .logo {
        .logo-name {
          font-size: 1.6em;
          margin: 0;
          padding: 0;
        }
      }
    }

    .close-second-panel{
      float: right;
    }

    .change-region-link,
    .active-region-title {
      .default-browser-focus;
      .font-family(medium);
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;

      &:active {
        border: none;
        outline: 0;
      }
    }

    .change-region-link {
      color: @gray-lighter;
      font-size: 12px;

      &:hover {
        color: @gray-darker;
      }
    }

    .active-region-title {
      font-size: 20px;
      line-height: 1;
    }

    .change-region-nav {
      background-color: @gray-main;
      font-size: 14px;
      padding: 15px 16px 10px 16px;

      &:hover {
        color: @gray-87;
      }
    }

    .change-region-nav-mob {
      padding: 5px 0 0;
      width: 85px;
      float: right;
      color: @gray-54;
      font-size: .6em;
      background-color: #FFF;
      border-bottom: none;
      cursor: pointer;
    }

    .find-left-nav {
      .transition(all, 0.2s);
      height: 100%;
      max-height: 430px;
      overflow-y: scroll;
      width: 100%;

      .respond-to(@bpTablet, {
        max-height: 390px;
      });
    }

    .find-right-nav {
      .transition(all, 0.2s);
      background-color: #fff;
      height: 100%;
      left: 100%;
      max-height: 430px;
      overflow-y: scroll;
      position: absolute;
      top: 0;
      width: 100%;

      .respond-to(@bpTablet, {
        max-height: 390px;
      });

      .buy-series-close-button {
        position: relative;
        top: -22px;
        margin-right: 0;
      }
      .change-region-nav {
        .font-family(bold);
        color: @gray-dark;
        padding: 21px 16px 17px 16px;
      }
    }

    &.swipe-left {
      &.swipe-left-completed {
        .find-left-nav {
          display: none;
        }

        .find-right-nav {
          display: block;
        }
      }

      .find-left-nav {
        left: -100%;
      }

      .find-right-nav {
        display: block;
        left: 0;
      }
    }

    &.swipe-right {
      &.swipe-right-completed {
        .find-left-nav {
          display: block;
        }

        .find-right-nav {
          display: none;
        }
      }

      .find-left-nav {
        display: block;
        left: 0;
      }

      .find-right-nav {
        display: block;
        left: 100%;
      }
    }
  }
  .hover-height {
    position: relative;
    top: 14px;
    width: 100%;
    z-index: -1;
    cursor: default;
  }

}

/* START: Global SERIES DROPDOWN Styles */

.header-user-name {
  .font-family(medium);
  color: @gray-user-info;
  display: none;
  font-size: 1.2em;
  position: relative;
  top: 8px;

  .user-name {
    .default-browser-focus;
    border: 0;
    cursor: pointer;
    display: block;
    margin-top: 24px;

    .name {
      padding-right: 10px;
    }
  }
  .register.name {
    padding-right: 0;
  }
  .log-in.name {
    padding-right: 10px;
  }

  &.logged-in {
    .credits.desktop_only {
      cursor: pointer;
    }
  }
  &.not-logged-in {
    display: inline-block;
    height: 25px;
    .credits {
      display: none;
    }
    .header-user-name-block {
      margin-right: 10px;
      padding-top: 24px;
    }
  }
  .header-user-dropdown.rider-series-dropdown {
    position: relative;
    float: right;
    top:-10px;

    .row {
      margin-top: 15px;
      border-top: @borderLightGray;
    }
    .no-reservations-container {
      font-size: 1.8em;
      color: @gray-darker;
      text-align: center;
      line-height: 1.2em;
      border-top: none;
      padding-top: 75px;
      padding-bottom: 45px;
      span {
        width: 90%;
        padding-left: 5%;
        display: block;
      }
      .font-family(thin);
      .series-circle {
        margin: auto;
        margin-top: 30px;
        height: 34px;
        width: 54px;
        font-size: 24px;
        padding-top: 20px;
      }
      &.row {
        border: none;
      }
    }

    .buy-series-close-button {
      font-size: 18px;
    }

    .close-credit-panel {
      margin: 0;
      right: 24px;
      top: 24px;
    }
    .series-menu {
      display: none;
    }
    .section-container {
      position: fixed;
      top: 0;
      overflow-y: scroll;
      height: 100%;
      width: 100%;
      clear: both;
      z-index: 101;
      padding-bottom: 20px;
    }

    .buy-series-close-button {
      .default-browser-focus;
      background: none;
      border: none;
      cursor: pointer;
      display: inline-block;
      font-size: 0;
      padding: 0;
      position: absolute;
      right: 20px;
      text-indent: 0;
      top: 22px;
      z-index: 999;

      img {
        .transform(scale(1.2));
        height: 15px;
        object-fit: none;
        object-position: -252px -201px;
        width: 17px;

        &::before {
          background-color: @scWhiteC;
          color: @gray-54;
          content: attr(alt);
          font-size: 13px;
          font-weight: 600;
          height: 18px;
          left: -15px;
          position: absolute;
          top: -1px;
        }
      }
    }

    .buy-series {
      .center-container();
      text-align: center;
      .yellow-button {
        width: 280px;
        margin-top: 10px;
        margin-bottom: 20px;
        &:hover {
          color: @gray-87;
        }
      }
    }
    .credits-gray {
      background-color: @gray-light;
      color: @gray-70;
      font-size: 12px;
    }
  }

  &.logged-in.touch_hover .header-user-dropdown {
    visibility: visible;
  }

  .header-user-name-block {
    cursor: pointer;
    display: block;
    float: left;
    height: @headerHeight;
    margin-top: -8px;
    position: relative;
  }

  #desktop-region {
    display: none;
    cursor: pointer;
    height: 30px;

    .respond-to(@bpTablet, {
      display: inline;
      margin-right: 5px;
    });

    .region-title-container {
      display: inline-block;
      float: left;
      height: 30px;
      position: relative;
      top: 16px;
    }

    .rider-region-title {
      .default-browser-focus;
      .font-family(medium);
      background: none;
      border: none;
      color: @gray-87;
      cursor: pointer;
      font-size: 1em;
      margin-top: 0;
      outline: inherit;
      padding: 0;
    }

    .flag-container {
      display: inline-block;
      float: left;
      height: 30px;
      margin-left: 3px;
      position: relative;
      top: 10px;
      width: 46px;

      &.ca {
        top: 3px;
      }

      img {
        font-size: 0;
      }
    }
  }
}
/* END: Global SERIES DROPDOWN Styles */


/* START: Global REGION DROPDOWN Styles */
.section-container,
.section-region-container,
.section-region,
.section-options,
.usable-header,
.account-options {
  color: @gray-darker;
  width: 100%;
  background-color: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 24px;
}

.usable-header {
  .font-family(medium);
  font-size: 12px;
  letter-spacing: 2.75px;
  margin-bottom: 22px;
}

.section-container {
  padding: 0;
  left: 0;
}

.section-headline {
  color: @gray-darker;
  padding: 40px 0 20px 30px;
  .font-family(thin);
  font-size: 1.7em;
  display: block;
  position: relative;
  box-sizing: border-box;
}

.section-region-container {
  border-top: @borderLightGray;
  display: block;
  padding: 15px 4px 15px 0;

  &:last-child {
    border-bottom: @borderLightGray;
  }

  .section-region {
    border-top: 0;
  }

  &.supersoul {
    .left-col {
      padding-top: 4px;
    }
  }
}

.section-region-container.first {
  margin-top: 40px;
  border-top: none;

  .left-col {
    padding-top: 4px;

    .credits {
      margin: 4px 0 0;
    }
  }

  .section-region {
    padding-bottom: 24px;
  }
}

.section-region {
  .clear-all();
  color: @gray-darker;
  font-size: 12px;
  text-transform: none;

  &:first-child {
    border-top: 0;
  }

  .transfer-message {
    font-size: 14px;
    line-height: 22px;
    .font-family(light);
    width: 95%;
    padding-left: 0;
    margin-top: -10px;
    clear: both;
  }

  .left-col {
    display: block;
    float: right;
    height: auto;
    padding-top: 8px;
  }

  .right-col {
    .font-family(bold);
    float: left;
    font-size: 16px;
    width: 80%;

    .region {
      cursor: pointer;
      padding: 10px 0;

      span {
        display: block;
      }

      .region-name {
        .font-family(light);
        font-size: 14px;
        padding-top: 4px;
      }

      .ww-text {
        .font-family(light);
        font-size: 14px;
        padding-top: 4px;

        .ww-date {
          display: inline-block;
          font-weight: bold;
          padding-top: 6px;
        }
      }
    }
  }
}

.section-options {
  position: relative;
  padding-bottom: 20px;
  text-transform: none;
  font-size: 1.2em;
  .toggle-transfer {
    .class-transfer-message {
      margin: -22px 0 0 45px;
      color: @gray-darker;
      .font-family(bold);
      font-size: 16px;
    }
    .learn-more a {
      color: @gray-medium;
      position: absolute;
      left: 215px;
      top: 26px;
      .font-family(light);
      &:hover {
        color: @gray-darker;
      }
    }
  }
  .switch {
    padding: 0px 20px;
    border-radius: 40px;
    position: relative;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    height: 18px;
    padding: 8px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: @gray-darker;
    cursor: pointer;
    margin-top: 10px;
    height: 2px;
    width: 17px;

    span {
      display: inline-block;
      width: 14px;
      padding-top: 4px;

      &.on {
        padding-left: 3px;
      }

      &.off {
        padding-right: 3px;
      }
    }

    .toggle-switch {
      border-radius: 40px;
      width: 6px;
      height: 6px;
      padding: 5px;
      margin-left: 2px;
      position: absolute;
      top: 1px;
      background: @gray-light;
    }

    &.on {
      color: #fff;
      .toggle-switch {
        left: -2%;
      }
    }

    &.off {
      .toggle-switch {
        background-color: @sc-yellow;
        left: 40%;
      }
    }
  }

  .learn-series-transfer {
    display: inline-block;
    position: relative;
    margin-top: 13px;
    &:hover {
      color: @gray-darker;
    }
  }

  &.section-region {
    .transfer-message {
      padding-left: 47px;
      margin-top: 3px;
    }
    .transfer-message.on {
      width: 65%;
    }
    .transfer-message.off {
      width: 95%;
      margin-left: 0;
    }
    .credits-gray {
      margin-top: 8px !important;
    }
    .bold {
      .font-family(bold);
    }
    .learn-more-arrow {
      background-color: @sc-yellow;
      padding: 3px 5px;
      border-radius: 50%;
      color: #fff;
      margin-left: 5px;
    }
  }

}

/* END: Global REGION DROPDOWN Styles */

/* Homepage Carousel Styles */

.splash-image-carousel {
   width:100%;
}
.swiper-container {
  margin-top: 59px;
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100%;
  position:relative;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  a {
    height: inherit;
    width: inherit;

    img {
      height: inherit;
      object-fit: cover;
      width: inherit;
    }
  }
}

/* END: Homepage Carousel Styles */


/* MEDIA QUERIES - HEADER STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width : 600px) {

  .header-container {

    .logo {
      .logo-name {
        font-size: 2em;
      }
    }

    .mobile-nav-icon {
      .mobile-nav-series-indicator {
        display: none;
      }
    }
  }
  .header-user-name {
    display: inline-block;
    position: absolute;
    right: 144px;
    z-index: 60;

    .name {
      .font-family(roman);
      color: @gray-54;
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0;
      vertical-align: middle;

      &:hover, &.active {
        color: @gray-87;
      }
    }

    .register {
      padding: 0 5px;
    }

    &.logged-in {
      .header-user-dropdown {
        &.rider-series-dropdown {
          right: 0;
          .credits {
            display: block;
            float: left;
            margin: 4px 10px 0 0;
          }
          .credits.desktop_only {
            margin-top: 14px;
            position: relative;

            .bubble-indicator();

          }
          .series-menu {
            min-height: 250px;
            position: absolute;
            right: -144px;
            top: 45px;
            width: 322px;
          }
          .section-container {
            border-top: @borderLightGray;
            position: absolute;
            top: 14px;
            &.inactive {

            }
          }
        }
      }
    }
  }

  .header-user-dropdown {
    &.rider-series-dropdown {
      .section-container {
        .section-region-container {
          .left-col {
            padding-top: 4px;

            .credits {
              margin-right: 0;
            }
          }

          &.supersoul {
            .left-col {
              padding-top: 0;
            }
          }
        }
      }
    }
    }

  .section-region .left-col {
    padding-top: 5px;
  }

  .mobile-nav {
    .user-name,
    a.mobile-nav-logout {
      display: none;
    }
  }

  .find-a-class {
    position:absolute;
    right: 0;
    z-index: 50;
    &.touch_hover {
      .buy-series {
        position: absolute;
      }
      .header-user-dropdown {
        padding-bottom: 0;
        min-height: 219px;
        width: 320px;
        max-height: 466px;
        margin-top: 46px;
        overflow-y: auto;
      }
      .header-user-dropdown.nav0 {
        height: 320px !important;
      }
    }
    .buy-series-close,
    .find-right-nav .change-region-nav {
      display: none;
    }
  }

  .find-a-class {
    margin-right: 0;
    margin-top: 14px;

    .find-a-class-btn {
      .default-browser-focus;
      float: right;
      margin-right: 20px;
    }

    &.touch_hover {
      .header-user-dropdown {
        clear: both;
        position: relative;

      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width : 768px) {
  .header-user-name {
    &.logged-in {
      .header-user-dropdown {
        &.rider-series-dropdown {
          .section-container {
            overflow-y: initial;
          }
        }
      }
    }
  }
  .mobile-nav {
    a.new-your-soul {
      display: none;
    }
  }
  .find-a-class {
    &.touch_hover {
      .header-user-dropdown.nav0 {
        height: 466px !important;
      }
    }
  }
}

// css width (px) >= 1250
// Note: The normal DesktopLarge breakpoint is at 1440px.
// Setting to 1250px as a temporary measure while a long term fix is made to expose the links but prevent overlap.
@media only screen and (min-width : 1250px) {
  .header-nav {
    color: @gray-54;
    display:block;
    float: left;
    font-size: 1.3em;
    letter-spacing: 0.0625rem;
    margin: 1.5rem 0 0 1.25rem;

    ul {
      .font-family(medium);
      list-style: none;

      > li {
        padding-bottom: 1.25rem;
      }

      &.dropdown-ul li {
        padding-bottom: 0;
      }

      li {
        float: left;
        letter-spacing: 0;
        margin-left: 1.25rem;
        position: relative;

        &:first-child {
          margin-left: 0.625rem;
        }

        &:last-child {
          padding-right: 0.4375rem;
        }

        &.active > a {
          border-bottom: solid 0.125rem;
          color: @gray-87;
          display: inline-block;
          padding-bottom: 0.25rem;
        }

        a:hover {
          color: @gray-87;
        }
      }
      .header-shop {
        position: relative;
      }

      .bag-quantity {
        .transform(translate(-50%, -50%));
        left: 3.25rem;
        position: absolute;
        top: 0.375rem;
      }

      #accessible-bag {
        color: @gray-54;
        height: 1.5rem;
        left: 2.437rem;
        position: absolute;
        top: -0.625rem;
        width: 1.562rem;

        &:before {
          .font-family(roman);
          background-color: white;
          content: attr(alt);
          font-size: 1.2em;
          left: 0;
          line-height: 2.5rem;
          margin-top: -0.562rem;
          position: absolute;
          top: 0.375rem;
          width: 2.625rem;
          z-index: 10;
        }

        &:hover {
          color: @gray-87;
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }
  }

  .header-user-name {
    width: initial;
  }

  .desktop_only {
    display: block;
  }

  .mobile_only {
    display: none;
  }

  .mobile-nav-icon,
  .white-overlay-opaque,
  .mobile-nav {
    display: none !important;
  }

  .logo {
    .logo-name {
      font-size: 2.3em;
    }
  }

  #logo-container {
    margin-left: 2.5rem;
  }
}

// Authenticated User Dropdown
.authenticated-user__dropdown {
  .box-sizing(border-box);
  .box-shadow(0px 3px 5px 0px @gray-medium-dark);
  background: @scWhiteC;
  cursor: default;
  display: none;
  font-size: 1.1em;
  margin-top: -1px;
  padding: 1.5em 1.5em 0.825em;
  position: absolute;
  right: 0;
  text-align: left;
  top: calc(@headerHeight + 0.05);
  width: 16em;
  z-index: 2;

  .respond-to(@bpMobileLarge, {
    &.active {
      display: block;
    }
  });
}

.authenticated-username {
  cursor: pointer;

  &:after {
    .transform(rotate(135deg));
    border-bottom: 0;
    border-left: 0;
    border-right: 0.125em solid @gray-darker;
    border-top: 0.125em solid @gray-darker;
    content: '';
    display: inline-block;
    height: 0.5em;
    margin-left: 0.5em;
    position: relative;
    top: -0.2em;
    width: 0.5em;
  }

  &.active {
    &:after {
      .transform(rotate(-45deg));
      top: 0.2em;
    }
  }
}

.authenticated-user__title {
  .font-family(bold);
  border-bottom: 2px solid @gray-light;
  color: @black;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}

.authenticated-user__menu {
  list-style: none;
}

.authenticated-user__menuitem {
  padding: 0.825em 0;
}

a.authenticated-user__menulink {
  color: inherit;
}

/* HEADER WHEN UI-KIT IS ACTIVE
-----------------------------------------------------------------------------*/
.react-ui-kit {
  .global-header {
    border-bottom: 0;
    height: auto;
  }

  .header-container {
    height: auto;

    > div {
      .respond-to(@bpTablet, {
        box-sizing: border-box;

      });
    }
  }

  .main-container {
    margin-bottom: 56px;
    top: 56px;

    .respond-to(@bpTablet, {
      margin-bottom: 96px;
      top: 96px;
    });

    .respond-to(@bpDesktopMedium, {
      margin-bottom: 56px;
      top: 56px;
    });
  }

  .main-container .subnav-dropdown {
    .respond-to(@bpTablet, {
      top: 110px;
    });

    .respond-to(@bpDesktopMedium, {
      top: 56px;
    });
  }

  &.has-subnav {
    .main-container {
      .respond-to(@bpTablet, {
        margin-bottom: 152px;
        top: 152px;
      });

      .respond-to(@bpDesktopMedium, {
        margin-bottom: 112px;
        top: 112px;
      });
    }
  }

  // studios specific
  &.studio_list {
    .studio-list {
      margin-top: 0;
    }

    .studios-elements .map {
      top: 0;
    }
  }

  .studios-container {
    .fixed-secondary-nav {
      position: static;
    }
  }

  // instructors specific
  .instructors-container {
    .instructors-header-options,
    .instructor-search {
      top: 3.5rem;

      .respond-to(@bpTablet, {
        top: 9.5rem;
      });

      .respond-to(@bpDesktopMedium, {
        top: 7rem;
      });
    }
  }

  // community specific
  .community-container .fixed-secondary-nav,
  .soul-container .confirmation {
    top: 3.5rem;

    .respond-to(@bpTablet, {
      top: 6rem;
    });

    .respond-to(@bpDesktopMedium, {
      top: 7rem;
    });
  }

  // shop specific
  .shop-nav {
    top: 3.5rem;

    .respond-to(@bpTablet, {
      top: 6rem;
    });

    .respond-to(@bpDesktopMedium, {
      top: 3.5rem;
    });

    .shop-counter.mobile {
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 14px;

      .bag-quantity {
        left: 10px;
        top: 16px;
      }
    }

    &[data-expanded="true"] .shop-counter.mobile {
      display: none;
    }
  }

  // about specific
  &.about .main-container {
    .respond-to(@bpTablet, {
      margin-bottom: 175px;
      top: 175px;
    });

    .respond-to(@bpDesktopMedium, {
      margin-bottom: 120px;
      top: 120px;
    });
  }

  // profile pages specific
  .profile__nav-container {
    top: 3.5rem;

    .respond-to(@bpTablet, {
      top: 6rem;
    });

    .respond-to(@bpDesktopSmall, {
      top: auto;
    });
  }

  // soulcup specific
  &.soulcup_dashboard .confirmation {
    top: 3.5rem;

    .respond-to(@bpTablet, {
      top: 10.2rem;
    });

    .respond-to(@bpDesktopMedium, {
      top: 6.2rem;
    });
  }

  &.checkout .checkout-header {
    top: 3.5rem;
  }

  .find-class-container .fix-days-of-week {
    top: 7rem;
  }

  #header-studios-buy-series-button {
    color: #0D52E3;

    &:hover {
      background-color: #3D74E8;
      color: @white;
    }

    &:active {
      background-color: #0B49CB;
      color: @white;
    }
  }
}
