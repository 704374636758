.profile-section-container {
  padding-top: 5rem;
}

.mobile-padding {
  .respond-to(@bpMobileSmall, {
    padding-top: 0;
  });

  .respond-to(@bpDesktopSmall, {
    padding-top: 5rem;
  });
}

.profile-headernav-container {
  margin: 0 auto;
  overflow: hidden;
  padding-top: 5rem;

  .respond-to(@bpDesktopSmall, {
    min-width: 862px;
    padding-bottom: 3.44rem;
    width: 90%;
  });

  .respond-to(@bpDesktopLarge, {
    width: 75%;
    max-width: 1100px;
  });
}

.profile__header-container {
  display: none;

  .respond-to(@bpDesktopSmall, {
    .box-sizing(border-box);
    .flex-display(flex);
    margin: 0 auto 4em;
    min-width: 54rem;
    padding: 0 2rem;
    position: relative;
    width: 60%;
  });
}

.profile__header__info {
  .flex(1);
  .flex-direction(column);
  .flex-display(flex);
  .justify-content(space-between);
  height: 4.5rem;
}

.profile__header__name {
  .font-family(heavy);
  font-size: 2.4em;
}

.profile__header__rider-info {
  .font-family(medium);
  color: @gray-darker-54;
  font-size: 1.6em;
}

.profile--logout--text {
  .font-family(light);
  color: @gray-darker-54 !important;
  font-size: 1.3em;

  &:hover {
    color: @gray-darker !important;
  }
}

.profile__header__challenge {
  .align-self(center);
  .border-radius(0.5em);
  .font-family(medium);
  background-color: @gray-darker;
  cursor: pointer;
  font-size: 1.2em;
  height: 3.6em;
  text-align: center;
  width: 13.8em;
  padding: 0 5px;

  span {
    color: @scWhiteC;
    display: inline-block;
    margin-top: 15px;
  }

  &:hover {
    color: initial;
  }
}
