// UPCOMING CLASSES STYLES
.profile-tabcontent-container {
  padding-top: 2rem;

  .respond-to(@bpDesktopSmall, {
    padding-top: 0.5rem;
  });
}

// RESERVED CLASSES STYLES
.profile-content-container {
  padding: 0 0 6rem;
}

.profile-content-container--upcoming {
  .respond-to(@bpTablet, {
    margin: auto;
    max-width: 39rem;
    padding: 2rem 0 6rem;
  });

  .respond-to(@bpDesktopSmall, {
    max-width: 42.5rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding: 2.625rem 0 6rem;
    max-width: 52rem;
  });
}

.profile-day__title {
  .font-family(medium);
  border-bottom: @borderGrayHex97;
  font-size: 0.75rem;
  padding: 1.875rem 0 0.25rem 1rem;

  .respond-to(@bpMobileLarge, {
    padding: 2rem 0 0.25rem 2rem;
  });

  .respond-to(@bpTablet, {
    padding-left: 4rem;
  });

  .respond-to(@bpDesktopSmall, {
    border-bottom: @borderLightGray;
    font-size: 0.875rem;
    padding: 1.125rem 0 0.875rem;
  });
}

.profile-no-classes {
  .flex-wrap(wrap);
  .justify-content(center);
  display: none;
}

.profile-no-classes--active {
  .flex-display(flex);
}

.profile-no-classes__message {
  font-size: 2.625rem;
  min-width: 100%;
  padding: 7.25rem 0 2.25rem;
  text-align: center;

  .respond-to(@bpDesktopSmall, {
    font-size: 4.5rem;
    padding: 6rem 0 3.25rem;
  });

  .respond-to(@bpDesktopLarge, {
    padding-top: 4.75rem;
  });
}

.profile-no-classes__button {
  .font-family(medium);
  background-color: @scYellowC;
  border: none;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  height: 2.25rem;
  line-height: 2.25rem;
  margin-bottom: 8rem;
  text-align: center;
  width: 17.5rem;

  .respond-to(@bpMobileLarge, {
    margin-bottom: 5.75rem;
  });

  .respond-to(@bpTablet, {
    margin-bottom: 16.5rem;
  });

  .respond-to(@bpDesktopSmall, {
    font-size: 0.875rem;
    margin-bottom: 5rem;
    width: 10.375rem;
  });

  .respond-to(@bpDesktopLarge, {
    margin-bottom: 2.5rem;
  });

  &:hover {
    background: -moz-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(254,215,3,1)), color-stop(80%,rgba(254,224,0,1)));
    background: -webkit-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -o-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: -ms-linear-gradient(top, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
    background: linear-gradient(to bottom, rgba(254,215,3,1) 0%, rgba(254,224,0,1) 80%);
  }
}
