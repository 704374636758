.overlay {
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease;
  width: 100%;
  z-index: 10;
}

.overlay--hidden {
  opacity: 0;
  visibility: hidden;
}

.privacy-modal {
  .border-radius(0.25rem;);
  .box-sizing(border-box);
  .transition(all 0.2s ease-in-out);
  background-color: @scWhiteC;
  border: 4px solid @black;
  display: none;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 15%;
  visibility: hidden;
  width: 18.75rem;
  z-index: 100;

  .respond-to(@bpTablet, {width: 35.5rem;});

  button {
    .font-family(heavy);
    background: @schoolBusYellow;
    border: none;
    color: @black;
    cursor: pointer;
    float: right;
    font-size: 1rem;
    height: 3.5rem;
    letter-spacing: 0.8px;
    line-height: 1.25rem;
    margin: 1.25rem 0;
    padding: 15px 20px 10px;
    text-align: center;
    width: 100%;

    .respond-to(@bpTablet, {max-width: 10.625rem;});
  }

  &__container {
    padding: 1.375rem 1.125rem;
    .respond-to(@bpTablet, {padding: 2.44rem 2.75rem 2.75rem;});
  }

  &__buttons-container {
    .font-family(bold);
    display: flex;
    flex-direction: column;

    .respond-to(@bpTablet, {flex-direction: row-reverse;});
  }

  &__learn-more {
    align-self: center;
    color: @black;
    font-size: 1rem;
    letter-spacing: 0.8px;
    line-height: 1.25rem;
    margin: 1rem 0;
    text-decoration: underline !important;

    .respond-to(@bpTablet, {margin-right: 2rem;});
  }

  &__message {
    color: @black;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
    text-align: left;

    a {
      color: @blueRibbonBlue;
      &:hover {
        color: @blueRibbonBlue;
        text-decoration: underline;
      }
    }
  }
}
.privacy-modal--active {
  display: block;
  opacity: 1;
  visibility: visible;
}
