/* ABOUT PAGES STYLES
-----------------------------------------------------------------------------*/

.info-detail-container {
  padding-top: 90px;
  font-size: 16px;
  .respond-to(@bpMobileLarge, {
    padding-bottom: 30px;
  });
  .respond-to(@bpTablet, {
    font-size: 18px;
  });
  .respond-to(@bpDesktopSmall, {
    font-size: 20px;
    padding-bottom: @footerHeightDesktop;
    overflow: hidden;
  });
  .hero-image-container {
    min-width: 463px;
    .respond-to(@bpMobileLarge, {
      min-width: 925px;
      padding-bottom: 30px;
    });
    .respond-to(@bpDesktopLarge, {
      max-height: 600px;
      overflow: hidden;
      padding-bottom: 0;
    });
    .hero-image {
      width: 100%;
      .respond-to(@bpMobileLarge, {
        width: 100%;
      });
      .respond-to(@bpDesktopLarge, {
        width: 100%;
      });
    }
  }
  .hero-image-square-container {
    min-width: 320px;
    .respond-to(@bpTablet, {
      width: 40%;
      min-width: auto;
      display: inline-block;
      position: absolute;
      top: 60px;
    });
    .respond-to(@bpDesktopLarge, {
      width: 44%;
      top: 75px;
    });
    .hero-image-square {
      width: 88%;
      display: block;
      margin: auto;
      padding: 10px 0 25px 0;
      max-width: 475px;
      .respond-to(@bpTablet, {
        min-width: 165px;
        padding: 0;
      });
      .respond-to(@bpDesktopSmall, {
        min-width: 215px;
      });
      .respond-to(@bpDesktopLarge, {
        min-width: 330px;
        width: 44%;
        top: 75px;
      });
    }
  }
  .hero-video-container {
    .hero-video {
      width: 100%;
    }
  }
  .row {
    border: none;
  }
  .row-content {
    margin: auto;
    .respond-to(@bpMobileLarge, {
      width: 75%;
    });
    h3 {
      .font-family(medium);
      padding: 20px 0;
      text-transform: capitalize;
      .respond-to(@bpDesktopLarge, {
        padding-top: 70px;
        font-size: 1.6em;
      });
    }
    p {
      font-size: 1em;
      line-height: 1.688em;
      margin: 0;
      .respond-to(@bpDesktopLarge, {
        font-size: 1.05em;
        line-height: 1.6em;
      });
    }
    .link {
      text-transform: lowercase;
      margin: 10px auto 50px;
      text-align: center;
      .respond-to(@bpMobileLarge, {
        margin: 30px 0 20px 0;
        text-align: left;
        width: 60%;
      });
    }
  }
  .border-bottom {
    border-bottom: @borderLightGray;
    .respond-to(@bpMobileLarge, {
      border: none;
    })
  }
  .copy-head {
    display: block;
    text-transform: capitalize;
    .font-family(bold);
    padding-top: 5px;
  }
  &.rider-tips {
    .row {
      padding: 0;
      .respond-to(@bpDesktopSmall, {
        display: inline-block;
        float: left;
        &.menu-container {
          width: 20%;
        }
        &.content-container {
          width: 80%;
          .box-sizing(border-box);
        }
      });
    }
  }
  &.your-first-ride,
  &.class-transfer {
    .row {
      border-top: @borderLightGray;
      .respond-to(@bpMobileLarge, {
        border-top: @borderLightGray;
      });
      .respond-to(@bpTablet, {
        position: relative;
        min-height: 425px;
      });
      .respond-to(@bpDesktopSmall, {
        padding-bottom: 80px;
        min-height: 500px;
      });
    }
    .row-content {
      p {
        padding: 10px 0 30px 0;
      }
      .respond-to(@bpTablet, {
        padding-top: 30px;
        width: 45%;
        padding-left: 40%;
        display: inline-block;
      });
    }
  }
}
.about_rider_tips {
  .info-detail-container {
    padding-top: 85px;
  }
}
