.supersoul__section__testimonials {
  height: 450px;
  min-height: initial;

  .respond-to(@bpTablet, {
    height: 720px;
  });

  &-content {
    .font-family(light);
    padding: 7.64% 15% 0;
    text-align: left;

    .respond-to(@bpTablet, {
      padding: 7.64% 30% 0 10%;
    });

    h2 {
      color: @tundoraGray;
      font-size: 0.8125rem;
      letter-spacing: 2.08px;
      text-transform: uppercase;

      .respond-to(@bpTablet, {
        font-size: 1.625rem;
        letter-spacing: 4.16px;
      });

      &::after {
        border-bottom: @borderBlack;
        content: "";
        display: block;
        padding: 10px 0;
        width: 1.5rem;
      }
    }

    &-divider {
      padding: 15px 0;

      .respond-to(@bpTablet, {
        padding: initial;
      });
    }

    &-message {
      color: @tundoraGray;
      font-size: 1.25rem;
      letter-spacing: 1.1px;
      margin: 0;
      overflow-wrap: break-word;
      padding: 25px 0 30px;
      word-wrap: break-word;

      .respond-to(@bpTablet, {
        font-size: 2.5rem;
        padding: 40px 0 60px;
      });

      .respond-to(@bpDesktopMedium, {
        font-size: 3.3125rem;
      });
    }

    &-instructor-details {
      .font-family(bold);
      bottom: 7.5%;
      color: @black;
      font-size: 0.75rem;
      letter-spacing: 0.25px;
      opacity: 0.5;
      position: absolute;

      .respond-to(@bpTablet, {
        font-size: 1.5rem;
        letter-spacing: 0.5px;
      });

      &-name {
        text-transform: uppercase;

        &::after {
          content: " ";
          .visually-hidden();
        }
      }

      span {
        display: block;
      }
    }

    &-rider-details {
      color: @tundoraGray;
      font: 0.875rem @helveticaNeueFont;
      letter-spacing: 0.5px;

      .respond-to(@bpTablet, {
        font-size: 1.125rem;
      });

      &-name {
        font-weight: bold;

        &::after {
          content: "/";
          font-weight: normal;
          padding: 0 5px 0 10px;

          .respond-to(@bpTablet, {
            padding: 0 5px 0 15px;
          });
        }
      }
    }
  }

  &-instructor-image {
    bottom: 0;
    height: 95%;
    opacity: 0.2;
    position: absolute;
    z-index: -1;
  }

  .swiper-container {
    height: 100%;
    width: 100%;

    ul {
      list-style: none;
    }
  }

  .swiper-slide {
    .align-items(normal);
    .flex-display(flex);
    .justify-content(center);
    text-align: center;

    &-a {
      background-color: @saharaSandYellow;

      .supersoul__section__testimonials-content {
        &-instructor-details {
          right: 10%;
          text-align: right;
        }
      }

      img {
        right: 2.5%;
      }
    }

    &-b {
      background-color: @white;

      .supersoul__section__testimonials-content {
        padding: 7.64% 15% 0;

        .respond-to(@bpTablet, {
          padding: 7.64% 7.5% 0 44.5%;
        });

        &-instructor-details {
          left: 10%;
          text-align: left;
        }
      }

      img {
        left: 2.5%;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: initial;
    background-image: url('/assets/images/supersoul/swiper-arrow.svg');
    background-size: 32px 44px;
    border: none;
    width: 32px;

    &:hover {
      opacity: 0.5;
    }
  }

  .swiper-button-prev {
    .transform(scaleX(-1));
    left: 1%;
  }

  .swiper-button-next {
    right: 1%;
  }

  .swiper-pagination-bullets span.swiper-pagination-bullet {
    margin: 0 4px;

    .respond-to(@bpTablet, {
      margin: 0 6px;
    });
  }

  .swiper-container-horizontal>.swiper-pagination {
    bottom: 10px;

    .respond-to(@bpTablet, {
      bottom: 40px;
    });
  }

  .swiper-pagination-bullet {
    background: @black;
    border: none;
    height: 4px;
    margin: 0 4px;
    vertical-align: middle;
    width: 4px;

    .respond-to(@bpTablet, {
      height: 6px;
      margin: 0 6px;
      width: 6px;
    });

    &-active {
      background: transparent;
      border-radius: 50%;
      border: @borderBlack;
    }
  }
}
