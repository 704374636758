.sc-promo-code-form {
  border-bottom: 1px solid @gray-light;
  font-size: 1rem;
  margin: 0 auto;
  padding: 1.68rem 1.875rem;

  .form-button {
    cursor: not-allowed;
  }

  .respond-to(@bpMobileLarge, {
    max-width: 17.5rem;
    padding: 2.5rem 0 1.68rem 0;
  });

  .respond-to(@bpDesktopSmall, {
    margin: 0;
    padding: 1.68rem @gutterMobile;
  });

  .expandable-title {
    background-color: @scWhiteC;
    cursor: pointer;
    margin: 0 auto;
    max-width: 376px;

    &.expanded {
      > .arrow-down-grey {
        .transform(rotate(180deg));
        margin-top: 0;
        padding-top: 0;
      }
    }

    span {
      .font-family(medium);
      color: @gray-darker;
      font-size: 1.125em;
      margin-bottom: 2rem;

      &.arrow-down-grey {
        filter: contrast(0);
        margin-left: 7.3rem;
        padding-left: 0;

        .respond-to(@bpMobileMedium, {
          margin-left: 9.3rem;
        });
        .respond-to(@bpMobileLarge, {
          margin-left: 5.3rem;
        });
      }
    }
  }

  .expandable-content {
    .transition(height, 0.4s);
    height: 0;
    overflow: hidden;
    margin-top: 20px;
    padding-top: 0;

    &.expanded {
      height: 110px;
      padding: 5px;
      padding-top: 20px;
      margin: -5px;
      margin-top: 0;
    }

    .field-row {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 23.5rem;
      position: relative;
      width: 100%;

      input {
        width: 100%;
      }

      .field-error {
        input {
          float: none;
        }

        & + .frm-label:not(.not-floated) {
          left: 0;
          top: -19px;
        }
      }

      .frm-label {
        color: @gray-59;
        font-size: 14px;
        left: 0;
        position: absolute;
        top: -19px;

        &:not(.not-floated) {
          .transition(all, 0.1s, linear);
          left: 1.2em;
          top: 20px;
        }

        &.floated {
          left: 0;
          top: -19px;
        }
      }

      input {
        &:focus {
          & + .frm-label {
            left: 0;
            top: -19px;
          }
        }
      }
    }
  }

  .applied-promo-message {
    color: @scGreen;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 0;

    & > div {
      line-height: 16px;
    }

    .remove-promo-code {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      margin-left: 5px;
      padding: 0;
    }

    .check-mark {
      margin-right: 0.35rem;
    }

    .message {
      position: relative;
      top: -2px;
    }
  }
}
