/* FOOTER PAGE - MQ < 600px
-----------------------------------------------------------------------------*/
.press-page-container {
  padding: 90px 0;
  font-size: 16px;
  line-height: 1.688em;
  text-align: center;

  ///////////////////////
  // PRESS PAGE STYLES //
  ///////////////////////
  &.press-container {
    display: block;
    position: relative;
    margin-bottom: -3px;
    padding: 66px 0 0;
    width: 100%;

    .press-image {
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
      border: @borderLightGray;
      border-top: 0;
      border-left: 0;
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      border: @borderLightGray;
      width: 50vw;
      height: 50vw;
      padding: 0;
      margin: 0 0 -10px;

      a {
        display: table;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .press-image-date {
        color: @gray-medium;
        font-size: 0.750em;
        position: absolute;
        bottom: 10px;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
      }
    }
  }
}

/* MEDIA QUERIES - FOOTER STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .press-page-container {
    ///////////////////////
    // PRESS PAGE STYLES //
    ///////////////////////
    &.press-container {
      .press-image {
        width: 33.33333333333334vw;
        height: 33.33333333333334vw;

        .press-image-date {
          bottom: 20px;
        }
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .press-page-container {
    ///////////////////////
    // PRESS PAGE STYLES //
    ///////////////////////
    &.press-container {
      .press-image {

        .press-image-date {
          bottom: 25px;
        }
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .press-page-container {
    ///////////////////////
    // PRESS PAGE STYLES //
    ///////////////////////
    &.press-container {

      .press-image {
        width: 25vw;
        height: 25vw;

        .press-image-date {
          bottom: 25px;
        }
      }
    }
  }
}

// css width (px) >= 1440 < 1600
@media only screen and (min-width: 1440px) {
  .press-page-container {
    &.press-container {

      .press-image {
        width: 20vw;
        height: 20vw;
        .press-image-date {
          bottom: 25px;
        }
      }
    }
  }
}

// css width (px) >= 1600 < 1920
//@media only screen and (min-width: 1600px) {
//  .press-page-container {
//    &.press-container {
//
//      .press-image {
//        width: 10vw;
//        height: 10vw;
//        .press-image-date {
//          bottom: 25px;
//        }
//      }
//    }
//  }
//}

// css width (px) > 1920
@media only screen and (min-width: 1920px) {
  .press-page-container {
    &.press-container {

      .press-image {
        width: 10vw;
        height: 10vw;
        .press-image-date {
          bottom: 25px;
        }
      }
    }
  }
}