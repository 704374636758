/* CONTACT US STYLES
-----------------------------------------------------------------------------*/
.lightbox-repos {
  .featherlight {

    &:last-of-type {

      background: transparent !important;
      top: 0;

      .featherlight-content {
        width: 100%;
        height: 100%;
        .box-sizing(border-box);
        top: 59px;
        margin: 0;
        padding: 0;

        .featherlight-close-icon {
          .gray-close-icon();
        }
      }
    }
    &:before {
      margin-right: 0;
    }
  }
}

.contact-us-modal {
  display: none;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);

  .box-sizing(border-box);
  background: rgba(255,255,255,.95);
  width: 100%;
  height: 100%;
  background-color: @scWhiteC;
  background-size: 100%;
  height: 100%;
  padding: 0;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  border-top: @borderLightGray;

  .contact-us-close-icon {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    outline: inherit;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 22px;
    z-index: 999;

    img {
      .transform(scale(1.2));
      height: 20px;
      object-fit: none;
      object-position: -252px -201px;
      width: 20px;

      &::before {
        background-color: @scWhiteC;
        color: @gray-medium;
        content: attr(alt);
        font-size: 13px;
        font-weight: 600;
        height: 18px;
        left: -15px;
        position: absolute;
        top: -1px;
      }
    }
  }

  .contact-us-container {
    height: 100%;
    width: 100%;
    letter-spacing: 0.1px;
    .box-sizing(border-box);
    text-align: center;
    padding: 10% 6%;
    left: 50%;
    top: 15%;
    position: fixed;
    margin-left: -50%;
    overflow: scroll;

    .title, .description {
      .font-family(bold);
      font-size: 1.3em;
      line-height: 1.15em;
    }

    .content {
      padding: 50px 0;

      div {
        margin-bottom: 50px;
        h4 {
          font-size: .9em;
          margin-bottom: 15px;
          .font-family(medium);
          color: @gray-medium;
        }

        a {
          .font-family(medium);
          font-size: 0.875em;
          padding: 5px;
        }

        #siya-request-btn {
          width: 280px;
          margin: 0 auto;
        }
      }
    }

  }
}

/* MEDIA QUERIES - CONTACT US STYLES
-----------------------------------------------------------------------------*/

// 600px < css width (px) < 767px
@media only screen and (min-width: 600px) {
  .featherlight-content {
    vertical-align: top;
  }
  .contact-us-modal {
    .contact-us-container {
      .title {
        font-size: 1.3em;
      }
      .content {
        padding: 50px 10px;
      }
    }
  }
}

// 768px < css width (px) < 1023px
@media only screen and (min-width: 768px) {
  .featherlight-content {
    vertical-align: top;
  }
  .contact-us-modal {
    border: none;
    width: 600px;
    height: 615px;
    right: 0;
    border-radius: 10px;
    .contact-us-container {
      padding: 14.35% 10%;
      letter-spacing: 0px;
      position: relative;
      left: 0;
      top: 0;
      right: 0;
      margin: 0;
      overflow: hidden;
      .title, .description {
        font-size: 1.325em;
        line-height: 1.325em;
      }
      .content {
        padding: 40px 0;
        div {
          font-size: 0.9em;
          margin-bottom: 35px;
          h4 {
            font-size: 1em;
            letter-spacing: 0px;
          }
          a {
            font-size: 1em;
          }
          button {
            font-size: 1em;
            margin: 0 auto;
            &.full {
              width: 280px;
            }
          }
        }
      }
    }
  }
}

// 1024px < css width (px) < 1439px
@media only screen and (min-width: 1024px) {
  .contact-us-modal {
    width: 550px;
    height: 645px;
    .contact-us-container {
      padding: 11% 5%;
    }
  }
}

// css width (px) >= 1440
@media only screen and (min-width: 1440px) {
  .contact-us-modal {
    .contact-us-container {
      .title {
        line-height: 1.25em;
      }
      .content {
        div {
          button {
            &.full {
              width: 57%;
            }
          }
        }
      }
    }
  }
}